import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeUserScreen from 'screens/HomeUserScreen';
import AppBar from "components/AppBar";
import { MENU_NAVIGATION } from 'constant';
import MainScreen from './screens/MainScreen';
import MainManageParentScreen from './screens/MainManageParentScreen';
import ValidateProfileParents from './screens/ValidateProfileParents';
import RejectProfileParents from './screens/RejectProfileParents';
import CreateContractparent from './screens/CreateContractparent';

import MainManageTeacherScreen from './screens/MainManageTeacherScreen';
import ValidateProfileTeachers from './screens/ValidateProfileTeachers';
import RejectProfileTeachers from './screens/RejectProfileTeachers';
import CreateContractTeachers from './screens/CreateContractTeachers';
import ContactParent from './screens/ContactParent';
import CreateInvoiceParent from './screens/CreateInvoiceParent';
import CreateDirectMessage from './screens/CreateDirectMessage';
import CreateBroadcast from './screens/CreateBroadcast';
import ListContractsParent from './screens/ListContractsParent';
import ListContractsTeacher from './screens/ListContractsTeacher';
import CreateUserAccount from './screens/CreateUserAccount';
import MainManageUser from './screens/MainManageUser';
import ListContactParents from './screens/ListContactParents';
import ListContactTeachers from './screens/ListContactTeachers';
import ValidatePrestationList from './screens/ValidatePrestationList';
import ValidatePrestation from './screens/ValidatePrestation';
import ListUserAccounts from './screens/ListUserAccounts';
import MainManagerConfig from './screens/MainManagerConfig';
import ListCities from './screens/ListCities';
import ListInvoicesAllParents from './screens/ListInvoicesAllParents';
import CreateSalaryTeacher from './screens/CreateSalaryTeacher';
import ListInvoices from './screens/ListInvoices';
import ListSalaries from './screens/ListSalaries';
import ListSalariesAllTeachers from './screens/ListSalariesAllTeachers';
import UpdateContractparent from './screens/UpdateContractparent';
import UpdateContractTeacher from './screens/UpdateContractTeacher';
import ListSubjects from './screens/ListSubjects';
import ShowTeacherProfile from 'screens/ShowTeacherProfile';
import ResetUserAccount from './screens/ResetUserAccount';
import MainManagerLearningScreen from './screens/MainManagerLearningScreen';
import CreateLearningResource from './screens/CreateLearningResource';
import UpdateLearningResource from './screens/UpdateLearningResource';
import UpdateInvoiceParent from './screens/UpdateInvoiceParent';
import { useState } from 'react';
import { setNavigationPreviousScreen } from 'stacks/utils';

const DirectionStack = createNativeStackNavigator();

export default function DirectionStackNavigator() {
  const [backScreen, setBackScreen] = useState(null)
  console.log("#####backScreen" , backScreen)
  return (
    <DirectionStack.Navigator screenOptions={{ 
      headerShown: false,
      // headerBackButtonMenuEnabled: true,
      // headerBackground: (navigationProps) => <AppBar {...navigationProps} />,
    }} 
    screenListeners={({route}) => ({
      // state: (event) => {
      //   console.log("\n\n*********************CHANGE STTAE ", route.name)
      //   if(event.data.state.routes.length > 1) {
      //     const local = event.data.state.routes[event.data.state.routes.length-2]
      //     setNavigationPreviousScreen(JSON.stringify(local))
      //     // .then(() => {
      //       console.log("#### LOADED ", local.name, " AMONG ", event.data.state.routes.map(i => i.name))
      //       console.log(route)
      //       setBackScreen(local)
      //     // }).catch(console.log)
      //   }
      // },
      beforeRemove: (event) => {
          setNavigationPreviousScreen({
            name: route.name,
            seed: Math.ceil(Math.random() * 100)
          })
          setBackScreen(route.name)
      }
    })}
    >
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.Main.name} component={MainScreen} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.CreateDirectMessage.name} component={CreateDirectMessage} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.CreateBroadcast.name} component={CreateBroadcast} />
      
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.Main.name} component={MainManageParentScreen} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ValidateProfile.name} component={ValidateProfileParents} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.RejectProfile.name} component={RejectProfileParents} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.CreateContract.name} component={CreateContractparent} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ContactParent.name} component={ContactParent} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.CreateInvoice.name} component={CreateInvoiceParent} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.UpdateInvoice.name} component={UpdateInvoiceParent} />
      {/* <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ValidateCourseSessionListContracts.name} component={ValidateCourseSessionListContracts} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ValidateCourseSessionDetails.name} component={ValidateCourseSessionDetails} /> */}
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ValidatePrestationList.name} component={ValidatePrestationList} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ValidatePrestation.name} component={ValidatePrestation} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ListContracts.name} component={ListContractsParent} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ListContact.name} component={ListContactParents} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ListInvoicesAllParents.name} component={ListInvoicesAllParents} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.ListInvoices.name} component={ListInvoices} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageParents.UpdateContract.name} component={UpdateContractparent} />
      
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.Main.name} component={MainManageTeacherScreen} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Common.ShowTeacherProfile.name} component={ShowTeacherProfile} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.ValidateProfile.name} component={ValidateProfileTeachers} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.RejectProfile.name} component={RejectProfileTeachers} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.CreateContract.name} component={CreateContractTeachers} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.ListContracts.name} component={ListContractsTeacher} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.ListContact.name} component={ListContactTeachers} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.CreateSalary.name} component={CreateSalaryTeacher} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.ListSalaries.name} component={ListSalaries} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.ListSalariesAllTeachers.name} component={ListSalariesAllTeachers} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageTeachers.UpdateContract.name} component={UpdateContractTeacher} />
      
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageUsers.Main.name} component={MainManageUser} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageUsers.CreateUserAccount.name} component={CreateUserAccount} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageUsers.ListUserAccounts.name} component={ListUserAccounts} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageUsers.ResetUserAccount.name} component={ResetUserAccount} />

      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageConfigs.Main.name} component={MainManagerConfig} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageConfigs.ListCities.name} component={ListCities} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageConfigs.ListSubjects.name} component={ListSubjects} />

      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageLearning.Main.name} component={MainManagerLearningScreen} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageLearning.CreateLearningResource.name} component={CreateLearningResource} />
      <DirectionStack.Screen name={MENU_NAVIGATION.Direction.ManageLearning.UpdateLearningResource.name} component={UpdateLearningResource} />
      
    </DirectionStack.Navigator>
  );
}