import {BACKEND_API} from "@env"

export const IS_EXPO_GO = true

export const BASE_API_URL = "https://api.astraining.ci/api"

export const INVOICE_STATUS = {
    pending: "pending",
    achieved: "achieved"
}

export const BROADCAST_PUBLICATION_STATUS = {
    pending: "pending",
    published: "published"
}

export const BROADCAST_LEVEl = {
    all: {
        label: "Tout le monde",
        value: "all"
    },
    direction: {
        label: "La direction",
        value: "direction"
    },
    parent: {
        label: "Les parent",
        value: "parent"
    },
    teacher: {
        label: "Les enseignants",
        value: "teacher"
    }
}

export const MSG_STATUS = {
    pending: "pending",
    received: "received",
    read: "read"
}

export const COURSE_SESSION_STATUS = {
    pending: "pending",
    achieved: "achieved",
    cancelled: "cancelled",
    reported: "reported"
}

export const MENU_NAVIGATION = {
    Login: {
        stackname: 'LoginStack',
        Main: {
            name: "ExploreApp"
        },
        ListApprovedTeachers: {
            name: "ListApprovedTeachers"
        },
        Login: {
            name: "Login"
        },
        SignIn: {
            name: "SignIn"
        },
    },
    Welcome: {
        stackname: "WelcomeStack",
        Main: {
            name: "Welcome"
        },
        Details: {
            name: "Details"
        }
    },
    Common: {
        ShowTeacherProfile: {
            name: "Common.ShowTeacherProfile",
        },
        DisplayPDF: {
            name: 'Common.DisplayPDF'
        },
        Agreement: {
            name: "Settings.Agreement"
        },
        ListLearningVideos: {
            name: "Common.ListLearningVideos"
        },
        ListLearningExercises: {
            name: "Common.ListLearningExercises"
        },
        ListLearningLessons: {
            name: "Common.ListLearningLessons"
        },
        VideoReaderScreen: {
            name: "Common.VideoReaderScreen"
        },
        DeleteMyAccount: {
            name: "Common.DeleteMyAccount"
        },
        NotificationsListScreen: {
            name: "Common.NotificationsListScreen"
        },
        NotificationDetailsScreen: {
            name: "Common.NotificationDetailsScreen"
        }
    },
    User: {
        stackname: "UserStack",
        Main: {
            name: "User"
        },
        ShowTeacherProfile: {
            name: "Direction.ShowTeacherProfile",
        },
    },
    StatusScreen: {
        name: 'StatusScreen'
    },
    Settings: {
        stackname: "SettingsStack",
        Main: {
            name: "Settings"
        },
        ChangePassword: {
            name: "Settings.ChangePassword"
        },
        ShowMyProfile: {
            name: "Settings.ShowMyProfile"
        },
        EditMyProfile: {
            name: "Settings.EditMyProfile"
        },
    },
    Direction: {
        stackname: "DirectionStack",
        Main: {
            name: "Direction"
        },
        CreateDirectMessage: {
            name: "CreateDirectMessage"
        },
        CreateBroadcast: {
            name: "CreateBroadcast"
        },
        ManageParents: {
            stackname: "ManageParentsStack",
            Main: {
                name: "ManageParents"
            },
            ValidateProfile: {
                name: "ValidateProfileParent"
            },
            RejectProfile: {
                name: "RemoveProfileParent"
            },
            ListContracts: {
                name: "ListContractsParent"
            },
            ListInvoicesAllParents: {
                name: "ListInvoicesAllParents"
            },
            ListInvoices: {
                name: "ListInvoicesParent"
            },
            ListContact: {
                name: "ListContactParents"
            },
            CreateContract: {
                name: "CreateContractParent"
            },
            CreateInvoice: {
                name: "CreateInvoiceParent"
            },
            UpdateInvoice: {
                name: "UpdateInvoiceParent"
            },
            UpdateContract: {
                name: "UpdateContractParent"
            },
            ValidateCourseSessionListContracts: {
                name: "Direction.ValidateCourseSessionListContracts"
            },
            ValidateCourseSessionDetails: {
                name: "Direction.ValidateCourseSessionDetails"
            },
            ValidatePrestationList: {
                name: "Direction.ValidatePrestationList"
            },
            ValidatePrestation: {
                name: "Direction.ValidatePrestation"
            },
            ContactParent: {
                name: "Direction.ContactParent"
            },
        },
        ManageTeachers: {
            stackname: "ManageTeachersStack",
            Main: {
                name: "ManageTeachers"
            },
            ValidateProfile: {
                name: "ValidateProfileTeacher"
            },
            RejectProfile: {
                name: "RemoveProfileTeacher"
            },
            CreateContract: {
                name: "CreateContractTeacher"
            },
            CreateSalary: {
                name: "CreateSalaryTeacher"
            },
            UpdateContract: {
                name: "UpdateContractTeacher"
            },
            ListSalariesAllTeachers: {
                name: "ListSalariesAllTeachers"
            },
            ListSalaries: {
                name: "ListSalariesTeacher"
            },
            ListContracts: {
                name: "ListContractsTeacher"
            },
            ListContact: {
                name: "ListContactTeachers"
            },
        },
        ManageUsers: {
            stackname: 'Direction.ManageUsersStack',
            Main: {
                name: "Direction.ManageUsers"
            },
            CreateUserAccount: {
                name: "Direction.CreateUserAccount"
            },
            ListUserAccounts: {
                name: "Direction.ListUserAccounts"
            },
            ResetUserAccount: {
                name: 'Direction.ResetUserAccount'
            }
        },
        ManageConfigs: {
            stackname: "Direction.ManageConfigsStack",
            Main: {
                name: "Direction.ManageConfigs"
            },
            ListCities: {
                name: "Direction.ListCities"
            },
            ListSubjects: {
                name: "Direction.ListSubjects"
            },
        },
        ManageLearning: {
            stackname: "Direction.ManageLearningStack",
            Main: {
                name: "Direction.ManageLearning"
            },
            CreateLearningResource: {
                name: "Direction.CreateLearningResource"
            },
            UpdateLearningResource: {
                name: "Direction.UpdateLearningResource"
            }
        }
    },

    Parents: {
        stackname: "ParentStack",
        Main: {
            name: "Parent"
        },
        ShowContract: {
            name: "Parent.ShowContractParent"
        },
        ShowTeacherProfile: {
            name: "Parent.ShowTeacherProfile"
        },
        ContactDirection: {
            name: "Parent.ContactDirection"
        },
        ContactTeacher: {
            name: "Parent.ContactTeacher"
        },
        ListTeachers: {
            name: "Parent.ListTeachers"
        },
        OrderTeacher: {
            name: "Parent.OrderTeacher"
        },
        OrderTeacherWithoutTeacher: {
            name: "Parent.OrderTeacherWithoutTeacher"
        },
        ListChildrenMarks: {
            name: "Parent.ListChildrenMarks"
        },
        Timeplan: {
            name: "Parent.Timeplan"
        },
        CreateChildUnavailability: {
            name: "Parent.CreateChildUnavailability"
        },
        CreateRemarkOnTeacher: {
            name: "Parent.CreateRemarkOnTeacher"
        },
        CreateReportOnTeacher: {
            name: "Parent.CreateReportOnTeacher"
        },
        ValidateCourseSession: {
            name: "Parent.ValidateCourseSession"
        },
        ListPrestation: {
            name: "Parent.ListPrestation"
        },
        ListInvoices: {
            name: "Parent.ListInvoices"
        },
    },

    Teachers: {
        stackname: "TeacherStack",
        Main: {
            name: "Teacher"
        },
        ListApprovedTeachers: {
            name: "Teacher.ListApprovedTeachers"
        },
        ShowTeacherProfile: {
            name: 'Teacher.ShowTeacherProfile'
        },
        ShowContract: {
            name: "Teacher.ShowContractTeacher"
        },
        ContactDirection: {
            name: "Teacher.ContactDirection"
        },
        ContactParent: {
            name: "Teacher.ContactParent"
        },
        ListSalaries: {
            name: "Teacher.ListSalaries"
        },
        ListTeachers: {
            name: "Teacher.ListTeachers"
        },
        ListChildrenMarks: {
            name: "Teacher.ListChildrenMarks"
        },
        ListPrestation: {
            name: "Teacher.ListPrestation"
        },
        Timeplan: {
            name: "Teacher.Timeplan"
        },
        CreateRemarkOnChild: {
            name: "Teacher.CreateRemarkOnChild"
        },
        CreateReportOnChild: {
            name: "Teacher.CreateReportOnChild"
        },
        CreateRemarkOnParent: {
            name: "Teacher.CreateRemarkOnParent"
        },
        CreatePrestation: {
            name: "Teacher.CreatePrestation"
        },
        CreateTeacherUnavailability: {
            name: "Teacher.CreateTeacherUnavailability"
        }
    },
}