import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    HStack,
    VStack,
    Box,
    Button,
    Icon,
    IconButton,
    useToast,
    Skeleton
  } from "native-base";
  import { Pressable, StyleSheet } from 'react-native';
import { getIsParent, getIsSuperuser, getIsTeacher, getToken } from "store/login/selectors";
import { getFileLink, listClassLevels, listLearningExercises, listLearningLessons, listLearningVideos, listSubs } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { AntDesign } from "@expo/vector-icons";
import { apiErrorNotify } from "services/notify";
import * as React from 'react';
import {Picker} from '@react-native-picker/picker';


export default function ({
    resource,
    onSubmit,
    initialValues = {
      classLevelIndex: null, 
      subIndex: null,
      isExpanded: false
    }
  }) {
    if(!["videos", "exercises", "lessons"].includes(resource)) throw '`resource` not in ["videos", "exercises", "lessons"]'
    
    const token = getToken()
    const [classLevels, setClassLevels] = useState([])
    const [state, setState] = useState({
      selectedClassLevelIndex: initialValues.classLevelIndex,
      selectedSubIndex: initialValues.subIndex,
      isExpanded: initialValues.isExpanded,
      isLoading: false,
      isReady: false
  
    })
    
    const load = () => {
        listClassLevels(token).then(
            res_classlevels => {
                setClassLevels([{name: "Toutes les classes"}, ...res_classlevels.data])
                setState(prev => ({...prev, isReady: true}))
            }
        )
        .catch(err => console.log(err, err.response.data))
    }
    
    const fetchVideos = () => {
      setState(prev => ({...prev, isLoading: true}))
      const data = {
        class_level_ids: state.selectedClassLevelIndex === null ? [] : (state.selectedClassLevelIndex === 0 ? classLevels.slice(1, classLevels.length-1).map(item => item.id) : [state.selectedClassLevelIndex] ),
        sub_ids: state.selectedSubIndex === null ? [] : (state.selectedSubIndex === 0 ? classLevels[state.selectedClassLevelIndex].subs.map(item => item.id) : [classLevels[state.selectedClassLevelIndex].subs[state.selectedSubIndex].id])
      }
  
      let fetchFunction = null
      switch (resource) {
        case "videos":
          fetchFunction = listLearningVideos
          break;
      
        case "exercises":
          fetchFunction = listLearningExercises
          break;
  
        case "lessons":
          fetchFunction = listLearningLessons
          break;
      }

      fetchFunction(token, data).then(
          res => {
            setState(prev => ({...prev, isLoading: false}))
            onSubmit(res.data)
          }
      )
      .catch(err => console.log("errrrrr", err, err.response.data))
  
    }
    useEffect(() => {
      load()
    }, [setClassLevels])

      return (
        <>
            {
                state.isReady ? (
                    <VStack px="5" space={5}>
                    {
                        state.isExpanded ? (
                        <>
                            <VStack alignItems={'flex-start'} >
                            <Text w="20%" >Classe: </Text>
                            <Picker
                                selectedValue={state.selectedClassLevelIndex}
                                onValueChange={(itemValue) => {
                                console.log(state, classLevels[itemValue]?.subs?.map(i => i.id))
                                setState(prev => ({...prev, selectedClassLevelIndex: itemValue, selectedSubIndex: null})) 
                                }}
                                style={{
                                borderWidth: 1,
                                backgroundColor: '#f5f5f5',
                                borderRadius: 10,
                                width: "100%"
                                }}
                                >
                                {
                                    classLevels.map((item, index) => <Picker.Item label={item.name} value={index} />)
                                }
                            </Picker>
                            </VStack>
            
                            <VStack alignItems={'flex-start'} >
                            <Text w="20%">Matière: </Text>
                            {
                                state.selectedClassLevelIndex == 0 ? (
                                <Text fontWeight='thin' w="80%" ml="4">Toutes les matières</Text>
                                ) : (
                                classLevels[state.selectedClassLevelIndex]?.subs?.length > 0 ? (
                                    <Picker
                                    selectedValue={state.selectedSubIndex}
                                    onValueChange={
                                        (itemValue, itemIndex) => setState(prev => ({
                                        ...prev, 
                                        selectedSubIndex: itemIndex
                                        }))}
                                    style={{
                                        borderWidth: 1,
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: 10,
                                        width: "100%"
                                    }}
                                    >
                                        {/* <Picker.Item label={"Toutes les matières"} value={0} /> */}
                                        {
                                        ([{name: "Toutes les matières"}, ...classLevels[state.selectedClassLevelIndex]?.subs]).map((item, index) => <Picker.Item label={item.name} value={index} />)
                                        }
                                    </Picker>
                    
                                ) :  <Text fontWeight='thin' w="80%">Aucune matière pour cette classe</Text>
            
                                )
                            }
            
                            </VStack>
            
                            <HStack justifyContent={'space-between'}>
                            <Button 
                                bg={'#f5f5f5'}
                                _text={{
                                color: 'black'
                                }}
                                onPress={()=> setState(prev => ({...prev, isExpanded: !prev.isExpanded}))}
                            >Annuler</Button>
                            <Button
                                isLoading={state.isLoading} 
                                isDisabled={
                                state.selectedClassLevelIndex === null || (
                                    false
                                // (state.selectedClassLevelIndex === 0 && state.selectedSubIndex === null)
                                )
                                } 
                                onPress={fetchVideos}
                                >
                                Filtrer
                                </Button>
                            </HStack>
                        </>
                        ): (
                        <HStack justifyContent={'flex-end'} alignItems={"center"} >
                            <Text>Filtres</Text>
                            <IconButton 
                            icon={<Icon as={AntDesign} name="filter" />}
                            borderRadius='full'
                            onPress={()=> setState(prev => ({...prev, isExpanded: !prev.isExpanded}))}
                            />
                        </HStack> 
                        )
                    }
                    </VStack>
                ) : (
                    (
                    <VStack space='10' >
                        <VStack space={1} px={2} >
                            <Skeleton h={200} color='blue.200' borderRadius={3} />
                            <HStack justifyContent={'space-between'} >
                                <Skeleton h={10} w={10} color='blue.200' borderRadius={'full'} />
                                <Skeleton h={10} w={200} color='blue.200' borderRadius={3} />
                            </HStack>
                        </VStack>
                        <VStack space={1} px={2} >
                            <Skeleton h={200} color='blue.200' borderRadius={3} />
                            <HStack justifyContent={'space-between'} >
                                <Skeleton h={10} w={10} color='blue.200' borderRadius={'full'} />
                                <Skeleton h={10} w={200} color='blue.200' borderRadius={3} />
                            </HStack>
                        </VStack>
                    </VStack>
                )
                )
            }
        
        </>
        
      )
}