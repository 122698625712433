import { Heading, HStack, Spacer } from "native-base";

export default ({ _header = {} }) => (
    <HStack px='1' bg='blue.400' {..._header._box}  >
        <Heading {..._header._title} >Jours</Heading>
        <Spacer />
        {/* <Heading {..._header._title}>Heures</Heading>
        <Spacer />
        <Heading {..._header._title}>Matières</Heading> */}
    </HStack>
)