import Annonce from "components/Annonce";
import Chip from "components/Annonce/Chip";
import NumberInput from "components/NumberInput";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Avatar,
    Divider,
    Spacer,
    FormControl,
    Input,
    useToast,
    Icon,
    TextArea,
    Select
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { getFileLink, getMeUser, updateMeUser, uploadFile } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { getIsTeacher, getLoginState, getToken, getUserAvatar } from "store/login/selectors";
import { useMergeLoginState } from 'store/login/hooks'
import { apiErrorNotify } from "services/notify";
import { display_user_role } from "services/utils";

import * as DocumentPicker from "expo-document-picker";
import { AntDesign } from "@expo/vector-icons";
import ScreenWrapper from "components/ScreenWrapper";

export default ({ navigation }) => {
    
    const token = getToken()
    const toast = useToast()
    const [me, setMe] = useState(getLoginState())
    const is_teacher = getIsTeacher()
    
    const mergeLoginStore = useMergeLoginState()
    
    const [isLoading, setLoading] = useState({
      submit: false,
      media_avatar: false,
      media_cv: false,
      media_diploma: false
    })
    
    const [username, setUsername] = useState(me.username)
    const [teacherMetadata, setMeta] = useState(me.teacher_metadata || {} )
    const [full_name, setFullName] = useState(me.full_name)
    const [error, setError] = useState(null)

    useEffect(() => {
        getMeUser(token).then(
            res => {
                setMe({
                  ...res.data,
                  teacher_metadata: {
                    ...res.data.teacher_metadata
                  }
                })
                setMeta(res.data.teacher_metadata || {})
            }
        )
    }, [setMe])

    const handleSubmit = () => {
      const data = {username, full_name}
      if(is_teacher) data.teacher_metadata = teacherMetadata
      setLoading(prev => ({...prev, submit: true}) )
      updateMeUser(token, data)
      .then(res => {
        // navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {time: 2000, update: () => navigation.goBack()})
        toast.show({
          description: "Opération effectuée avec succès"
        })
        mergeLoginStore(res.data)
        // setTimeout(()=> navigation.goBack(), 1500)
        setLoading(prev => ({...prev, submit: false}) )
      })
      .catch(err => {
        apiErrorNotify({response: err.response, toast: toast})
        setLoading(prev => ({...prev, submit: false}) )
      })
    }

    const handleUpdateAvatar = ({remote_name}) => {
      const data = { media_avatar : remote_name}
      updateMeUser(token, data)
      .then(async res => {
        // navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {time: 2000, update: () => navigation.goBack()})
        toast.show({
          description: "Photo de profil mise à jour"
        })
        // setTimeout(()=> navigation.goBack(), 1500)
        setMe(prev => ({
          ...prev,
          media_avatar: remote_name
        }))
        setLoading(prev => ({...prev, media_avatar: false}) )
        const merged = await mergeLoginStore({media_avatar: remote_name})
        console.log("mergeLoginStore", merged)
      })
      .catch(err => {
        apiErrorNotify({response: err.response, toast: toast})
        setLoading(prev => ({...prev, media_avatar: false}) )
      })
    }

    const handleUpdateFile = ({media_field, remote_name}) => {
      const data = {
        teacher_metadata: { [media_field] : remote_name}
      }
      updateMeUser(token, data)
      .then(res => {
        // navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {time: 2000, update: () => navigation.goBack()})
        toast.show({
          description: "Photo de profil mise à jour"
        })
        // setTimeout(()=> navigation.goBack(), 1500)
        setMe(prev => ({
          ...prev,
          teacher_metadata: {
            ...prev.teacher_metadata,
            [media_field] : remote_name
          }
        }))
        setLoading(prev => ({...prev, [media_field]: false}) )
        mergeLoginStore({teacher_metadata: {
          ...state.teacher_metadata,
          [media_field] : remote_name
        }})
        setMeta(prev => ({...prev, [media_field] : remote_name }))
      })
      .catch(err => {
        apiErrorNotify({response: err.response, toast: toast})
        setLoading(prev => ({...prev, [media_field]: false}) )
      })
    }

    

    const check_file = (file) => {
      if(file.size/(1024*1024*5) > 1 ) {
        return "La taille fichier a dépassé la limite de 5Mo"
      }
      return null
    }

    const pickDocument = async ({media_field, type = ["application/pdf"]}) => {
      let result = await DocumentPicker.getDocumentAsync({multiple: false, type: type});
  
      if(result.type == 'success'){
        const errors = check_file(result)
        if(errors) setError(errors)
        else {
          setLoading(prev => ({...prev, [media_field]: true}) )
            const formData = new FormData()
            let { name, size, uri } = result;
            let nameParts = name.split('.');
            let fileType = nameParts[nameParts.length - 1];
            formData.append('file', {
              name: name,
              size: size,
              uri: uri,
              type: "application/" + fileType
            })
            console.log("result uploaded ", result)
            uploadFile(token, formData)
            .then(({data: remote_name}) => {
              handleUpdateFile({media_field, remote_name: remote_name})
            })
            .catch((err) => apiErrorNotify({response: err.response, toast: toast}) );
          }
          
        }
    }

    const pickAvatar = async ({type = ["image/jpeg", "image/jpg", "image/png" ]}) => {
      let result = await DocumentPicker.getDocumentAsync({multiple: false, type: type});
  
      if(result.type == 'success'){
        const errors = check_file(result)
        if(errors) setError(errors)
        else {
            setLoading(prev => ({...prev, media_avatar: true}) )
            const formData = new FormData()
            let { name, size, uri } = result;
            let nameParts = name.split('.');
            let fileType = nameParts[nameParts.length - 1];
            formData.append('file', {
              name: name,
              size: size,
              uri: uri,
              type: "application/" + fileType
            })
            console.log("result uploaded ", result)
            uploadFile(token, formData)
            .then(({data: remote_name}) => {
              console.log("Got server res ", remote_name)
              handleUpdateAvatar({remote_name})
              // mergeLoginStore({media_avatar: remote_name})
              // setMe(prev => ({...prev, [media_field]: remote_name}))
              // setMe(prev => ({
              //   ...prev,
              //   media_avatar: {
              //     ...result,
              //     remote_name: remote_name,
              //   }
              // }))
            })
            .catch((err) => apiErrorNotify({response: err.response, toast: toast}) );
          }
          
        }
    }
    
  return (
    <ScreenWrapper title={"Changer mon profil"} nav={navigation}>
      <ScrollView>
          <VStack px="4" py="5" space={"4"} > 
          {
          error && (
            <Modal isOpen={true} collapsable={true} onClose={() => setError(null)} >
              <Modal.Content>
                <Modal.CloseButton   />
                <Modal.Header bgColor={'red.500'}>
                  <Heading color='white' size='lg'>Attention ! </Heading>
                  
                </Modal.Header>
                <Modal.Body bg={'white'} >
                  <Center>
                    <Heading size='xs'>{error}</Heading>
                  </Center>
                </Modal.Body>
              </Modal.Content>
              
            </Modal>
          )
        }
              <Center >
                <Pressable onPress={() => pickAvatar({type: ["image/*"]}) } >
                  <HStack>
                    { !isLoading.media_avatar ? (
                      me.media_avatar ? 
                            <Avatar bg="white" size={100} source={{
                            uri: typeof me.media_avatar == 'string' ? getFileLink(me.media_avatar) : me.media_avatar.uri
                        }} />
                        : <Center p='1' bg='white' rounded={'full'} ><Icon name='user' as={AntDesign} color='primary.500' size={100} /></Center>
                      )
                      : <Spinner />
                    }
                      <Icon as={AntDesign} name='edit'  size='sm' color='primary.500' />
                  </HStack>
                </Pressable>
                  <Heading size='lg'>{me.full_name || 'Sans nom' }</Heading>
              </Center>    

              <Center>
                  <HStack>
                      {/* <Text>Type de compte: </Text> */}
                      <Chip   px='3' py='2' >
                          <Heading alignSelf={'center'} size='sm' color='white' >{display_user_role(me)}</Heading>
                      </Chip>
                  </HStack>
              </Center>
                  
              <VStack space='4'>
                    <FormControl>
                      <FormControl.Label>Nom et prénoms</FormControl.Label>
                      <Input
                          value={full_name}
                          onChangeText={setFullName}
                      />
                    </FormControl>

                    <FormControl>
                      <FormControl.Label>Numéro de téléphone</FormControl.Label>
                      <NumberInput
                          value={username}
                          onChangeText={setUsername}
                          maxLength={10}
                      />
                    </FormControl>

                    {
                      is_teacher && (
                        <>
                          <FormControl>
                            <FormControl.Label>Profession</FormControl.Label>
                            <Select
                                selectedValue={teacherMetadata.job || 'Etudiant' }
                                onValueChange={(val) => setMeta(p => ({...p, job: val}))}
                            >
                              <Select.Item value='Etudiant' label='Etudiant' />
                              <Select.Item value='Enseignant' label='Enseignant' />
                            </Select>
                          </FormControl>

                          <FormControl>
                            <FormControl.Label>Niveau universitaire</FormControl.Label>
                            <Input
                                value={teacherMetadata.level}
                                onChangeText={(val) => setMeta(p => ({...p, level: val}))}
                            />
                          </FormControl>

                          <FormControl>
                            <FormControl.Label>Zone de prestation souhaitée</FormControl.Label>
                            <Input
                                value={(teacherMetadata.disired_areas || [] ).length == 0 ? '' : teacherMetadata.disired_areas[0]}
                                onChangeText={(val) => setMeta(p => ({...p, disired_areas: [val]}))}
                            />
                          </FormControl>

                          <FormControl>
                            <FormControl.Label>À propos de moi</FormControl.Label>
                            <TextArea
                                numberOfLines={10}
                                value={teacherMetadata.about}
                                onChangeText={(val) => setMeta(p => ({...p, about: val}))}
                            />
                          </FormControl>

                          <HStack space='2' >
                            {console.log(me.teacher_metadata, teacherMetadata)}
                            <FormControl w='45%' >
                                <FormControl.Label>Joindre un diplome</FormControl.Label>
                                <VStack space='2'>
                                  {console.log(me.teacher_metadata, teacherMetadata)}
                                  <Center>
                                    { me.teacher_metadata?.media_diploma && (
                                        <Box w={70} px='2' height={100} bg='primary.500'  flex={1} justifyContent={'center'} rounded='md' >
                                          <Heading color='white' alignSelf={'center'} size='xs' >Diplôme uploadé</Heading>
                                          <Button 
                                            isLoading={isLoading.media_diploma}
                                            onPress={() => navigation.navigate(MENU_NAVIGATION.Common.DisplayPDF.name, {uri: me.teacher_metadata?.media_diploma }) }
                                            bg='white' 
                                            _text={{color: 'primary.500'}} >Voir</Button>
                                        </Box>
                                      )
                                    }
                                  </Center>
                                  
                                  <HStack space='4' justifyContent={'space-around'} >
                                    <Button 
                                      isLoading={isLoading.media_diploma}
                                      endIcon={<Icon as={AntDesign} name='upload' size='md' />} 
                                      onPress={() => pickDocument({media_field: 'media_diploma', type: ["application/pdf"]})} 
                                      >
                                      { me.teacher_metadata?.media_diploma ? 'Changer' : 'Uploader' }
                                    </Button>
                                  </HStack>
                                  <FormControl.Label >Aucun fichier</FormControl.Label>
                                </VStack>
                            </FormControl>

                            <FormControl w='45%' >
                              <FormControl.Label>Joindre un CV</FormControl.Label>
                              <VStack space='2'>
                                <Center>
                                  { me.teacher_metadata?.media_cv && ( 
                                        <Box w={70} height={100} px='2' bg='primary.500' rounded='md' justifyContent={'center'}>
                                          <Heading color='white' alignSelf={'center'} size='xs'  >CV uploadé</Heading>
                                          <Button 
                                          isLoading={isLoading.media_diploma}
                                            onPress={() => navigation.navigate(MENU_NAVIGATION.Common.DisplayPDF.name, {uri: me.teacher_metadata?.media_cv }) }
                                            bg='white' 
                                            _text={{color: 'primary.500'}} >Voir</Button>
                                        </Box>
                                    )
                                  }
                                </Center>
                                
                                <HStack space='4' justifyContent={'space-around'} >
                                  <Button 
                                    isLoading={isLoading.media_cv}
                                    endIcon={<Icon as={AntDesign} name='upload' size='md' />} 
                                    onPress={() => pickDocument({media_field: 'media_cv', type: ["application/pdf"]})} 
                                    >
                                    { me.teacher_metadata?.media_cv ? 'Modifier' : 'Uploader un CV' }
                                  </Button>
                                </HStack>
                              </VStack>
                            </FormControl>
                          </HStack>
                        </>
                      )
                    }
                    <Button
                    isLoading={isLoading.submit}
                      isDisabled={
                        // (username == me.username && full_name == me.full_name 
                        //   && (
                        //     me.teacher_metadata && (
                        //       teacherMetadata.level == me.teacher_metadata?.level
                        //       && teacherMetadata.job == me.teacher_metadata?.job
                        //       && teacherMetadata.disired_areas ? (teacherMetadata.disired_areas[0] == me.teacher_metadata?.disired_areas[0]) : false
                        //       && teacherMetadata.about == me.teacher_metadata?.about
                        //     )
                        //   )
                        // )
                        false
                      }
                      onPress={handleSubmit}
                    >Appliquer la modification</Button>
              </VStack>
          </VStack>
      </ScrollView>
    </ScreenWrapper>
  );
}