// import { MMKV } from 'react-native-mmkv';
import storage from '@react-native-async-storage/async-storage';
import { IS_EXPO_GO } from 'constant';
import { Platform } from 'react-native';

const persist_return = (key, value) => {
    storage.setItem(key, JSON.stringify(value));
    return value
}

const set_item = (key, value) => {
    storage.setItem(key, JSON.stringify(value));
}

const get_item = (key) => {
    return storage.getItem(key);
    // return null
}


export { persist_return, get_item, set_item }

/*
export const get_item = (key) => {
    // console.log(typeof item, Object.keys(item) )
    let value = "mama"
    const max = 1000000

    // console.log(MMKV)
    // for(let i = 0; i<= max; i++){
    //     if(i%1000 == 0) console.log(value)
    //     if(value !== "mama") return value
    //     else if ( i === 0){
    //         console.log("RUNUNUNUNU")
    //         storage.getItem(key)
    //         .then(v =>{ 
    //             console.log("Endup")
    //         });
    //     }
    // }
    return value
}
*/
