import { AntDesign } from "@expo/vector-icons";
import ConfirmationDialog from "components/ConfirmationDialog";
import { Pressable, VStack, HStack, Heading, Button, Text, Spacer, useDisclose, Icon } from "native-base";
import AddTimeplan from "../AddTimeplan";
import EditChild from "../EditChild";

export default ({childValues, onEditChild, onRemoveChild, index, subs}) => {
  const { isOpen, onClose, onOpen } = useDisclose()
  const { isOpen: isOpenDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclose()
  const { isOpen: isOpenTimeplan, onClose: onCloseTimeplan, onOpen: onOpenTimeplan } = useDisclose()
  
  const handleRemoveChild = () => {
    return new Promise((resolve, reject) => {
      onRemoveChild()
      .then(() => {
        setTimeout(() => {
          resolve(true)
          onCloseDelete()
          }, 1000);  
      })
      .catch(reject)
    })
    
  }
  
  return (
    <>
      <Pressable>
        <VStack bg='gray.200' borderRadius={5} p="3" >
            <HStack>
              <Text fontSize={14} >Nom: </Text>
              <Heading alignSelf={'center'} size='sm'>{childValues.full_name}</Heading>
            </HStack>
            <HStack>
              <Text fontSize={14}>Classe: </Text>
              <Heading alignSelf={'center'} size='sm'>{childValues.child_level}</Heading>
            </HStack>
            <HStack>
              <Text fontSize={14}>Lieu des cours: </Text>
              <Heading alignSelf={'center'} size='sm' >{childValues.place_courses}</Heading>
            </HStack>

            <HStack justifyContent={'space-between'} >
              <Button boxSize={'10'} bg='red.700' _text={{fontSize: 12}} startIcon={<Icon as={AntDesign } name='delete' /> } p='1' colorScheme={'danger'} onPress={onOpenDelete} ></Button>
              <Button boxSize={'10'} bg='orange.500' startIcon={<Icon as={AntDesign } name='edit' /> } onPress={onOpen} ></Button>
              <Button boxSize={'10'} _text={{fontSize: 12}} startIcon={<Icon as={AntDesign } name='calendar' /> } p='1'  onPress={onOpenTimeplan} ></Button>
            </HStack>

        </VStack>
      </Pressable>
      {
          isOpen && <EditChild onClose={onClose} 
              isOpen={isOpen} 
              onSubmit={onEditChild} 
              index={index}
              subs={subs} 
              initialValues={childValues}
              label='Modifer ce truc' /> 
          // : state.display_edit_child && <AddChild onClose={onClose} index={index} isOpen={isOpen} subs={subs} initialValues={childValues} onSubmit={handleEditChild} label='Editting' /> 
      } 
      {
          isOpenTimeplan && <AddTimeplan onClose={onCloseTimeplan} 
              isOpen={isOpenTimeplan} 
              onSubmit={onEditChild} 
              index={index}
              subs={subs} 
              subjects_affected={childValues.subjects_affected}
              initialValues={childValues}
              label='Modifer ce truc' /> 
          // : state.display_edit_child && <AddChild onClose={onClose} index={index} isOpen={isOpen} subs={subs} initialValues={childValues} onSubmit={handleEditChild} label='Editting' /> 
      } 
      {
        isOpenDelete && <ConfirmationDialog 
          isOpen={isOpenDelete}
          msg={`Voulez-vous vraiment retirer l'enfant ${childValues.full_name} ? Cette action est irreversible`}
          onAgree={handleRemoveChild}
          />
      }
    </>
  )
}