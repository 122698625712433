import { Text, VStack } from "native-base";

export default ({msg}) => (
    <VStack
    alignSelf='center'
    alignContent={'center'}
    justifyContent={'center'}
    w={150} h={150} 
    bg='#f5f5f5' 
    borderRadius={"full"} 
    >
        <Text 
        alignSelf='center'
        fontWeight={'thin'}   
        >
            {msg || "Aucun résultat"}
        </Text>
      </VStack>
)