import { HStack, Text } from "native-base"
import { from_month_index_to_name } from "services/utils"

export default ({value}) => {
    const day = value.getDate(),
        month = value.getMonth(),
        year = value.getFullYear();
    return (
        <HStack space='1' >
            <Text bg='primary.500' color='white' fontWeight={'bold'} px='3' py={2} rounded='full' >{`${day.toString().length == 1 ? '0': ''}${day}`}</Text>
            <Text py='2' fontSize={15} >/</Text>
            <Text bg='primary.500' color='white' fontWeight={'bold'} px='3' py={2} rounded='full' >{from_month_index_to_name(month)}</Text>
            <Text py='2' fontSize={15} >/</Text>
            <Text bg='primary.500' color='white' fontWeight={'bold'} px='2' py={2} rounded='full' >{year}</Text>
        </HStack>
    )
}