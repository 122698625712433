import Annonce from "components/Annonce";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
	Actionsheet,
	Input,
	useToast,
	FormControl
  } from "native-base";
import { Image, Pressable } from 'react-native';
import SwitchColorMode from 'components/SwitchColorMode'
import { Layout } from "components/Layout";
import { MasonaryLayout } from "components/MasonaryLayout";
// import { useGetTeachersNoApprovedQuery, useLazyLoginQuery } from "services/api-rest";
import { changePassword as changePasswordAPI } from 'services/api-axios'
import { useState } from "react";
import { useLogin } from "store/login/hooks";
import { getLoginState, getToken } from "store/login/selectors";
import {BACKEND_API} from "@env"
import { BASE_API_URL } from "constant";
import { MENU_NAVIGATION } from "constant";
import { apiErrorNotify } from "services/notify";
import { get_window_height } from "services/dimensions";
import PhoneInput from 'components/PhoneInput';
import PasswordInput from "components/PasswordInput";
import ScreenWrapper from "components/ScreenWrapper";

const selectedImage = null;
export default ({ navigation }) => {
	const token = getToken()
	const toast = useToast()

	const [ state, setState ] = useState({
		current_password: '',
		new_password: '',
	})
	
	const trigger = () => {
		changePasswordAPI(token, state)
		.then(res => {
			toast.show({
				title: 'Succès',
				description: "Opération effectuée",
				variant: 'success'
			})
			setTimeout(() => navigation.goBack(), 1500)
		})
		.catch(
			err => apiErrorNotify({response: err.response, toast: toast})
		)
	}

  return (
	<ScreenWrapper title={"Changer mon mot de passe"} nav={navigation}>
		<View flex={1} >
			{/* <Actionsheet isOpen={true} onClose={() => null} collapsable={false} >
				<Actionsheet.Content  bg="white" > */}
				<ScrollView showsVerticalScrollIndicator={false}  >
					<VStack w="100%" h={get_window_height()} px={{ base: 4, md: "30%" }} space="4"  >
						{/* <Heading>Changer mon mot de passe</Heading> */}
						<FormControl>
							<FormControl.Label>Mot de passe actuel</FormControl.Label>
							<PasswordInput 
								color='black'
								value={state.current_password} 
								onChangeText={txt => setState(prev => ({...prev, current_password: txt}))} 
								placeholder="Mot de passe actuel"
								// rightElement={<Text>YES</Text>}
								/>
						</FormControl>

						<FormControl>
							<FormControl.Label>Nouveau mot de passe</FormControl.Label>
							<PasswordInput
								borderRadius={10} 
								value={state.new_password} 
								onChangeText={txt => setState(prev => ({...prev, new_password: txt}))}  
								placeholder="Nouveau mot de passe"  />
						</FormControl>
						
						<Button onPress={trigger} mx={50} mt={6} >Continuer</Button>
					</VStack>
				</ScrollView>
				{/* </Actionsheet.Content>
			</Actionsheet> */}
		</View>
	</ScreenWrapper>
  );
}