import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeUserScreen from 'screens/HomeUserScreen';
import AppBar from "components/AppBar";
import { getIsSuperuser, getIsManager, getIsParent, getIsTeacher } from 'store/login/selectors';
import DirectionStackNavigator from 'stacks/DirectionStack';
import { Box, Center, Text } from 'native-base';
import { MENU_NAVIGATION } from 'constant';
import ParentsStack from 'stacks/ParentsStack';
import TeachersStack from 'stacks/TeachersStack';
import LoginScreen from 'screens/LoginScreen';
import SignInScreen from 'screens/SignInScreen';
import AgreementScreen from 'screens/AgreementScreen';
import AppExplorerScreen from 'screens/AppExplorerScreen';
import ListApprovedTeachers from 'screens/ListApprovedTeachers';
import StatusScreen from 'screens/StatusScreen';
import AppBarNoLogin from 'components/AppBarNoLogin';
import UserStackNavigator from 'stacks/UserStack';
import ShowTeacherProfile from 'screens/ShowTeacherProfile';
import DisplayPDFScreen from 'screens/DisplayPDFScreen';
import OrderTeacher from 'screens/PublicOrderTeacher';
import ChildFormScreen from 'screens/PublicOrderTeacher/ChildFormScreen';
import ContactDirection from 'stacks/ParentsStack/screens/ContactDirection';

const LoginStack = createNativeStackNavigator();

export default function LoginStackNavigator() {
  return (
    <LoginStack.Navigator screenOptions={{ 
      headerShown: true
    }}>
      <LoginStack.Screen name={MENU_NAVIGATION.Login.Main.name} component={AppExplorerScreen}  options={{
        headerShown: true,
        // title: "AS TRAINING",
        // headerStyle: {
        //   backgroundColor: '#282262'
        // },
        // headerTitleStyle: {
        //   color: 'white'
        // }
        header: () => <AppBarNoLogin />
      }} /> 
      <LoginStack.Screen name={MENU_NAVIGATION.Login.ListApprovedTeachers.name} component={ListApprovedTeachers}  options={{
        headerShown: false
      }} />
      <LoginStack.Screen name={MENU_NAVIGATION.Login.Login.name} component={LoginScreen}  options={{
        title: "Connexion",
        statusBarColor: "#282262",
        headerStyle: {
          backgroundColor: '#282262',
        },
        headerTitleStyle: {
          color: 'white'
        }
      }}
       /> 
       <LoginStack.Screen name={MENU_NAVIGATION.Login.SignIn.name} component={SignInScreen} options={{
        title: "Créer un compte"
      }} /> 
      <LoginStack.Screen name={MENU_NAVIGATION.Parents.OrderTeacher.name} component={OrderTeacher} options={{
        title: ""
      }} /> 
      <LoginStack.Screen name={"ADD_CHILD_TO_FORM"} component={ChildFormScreen} options={{
        title: ""
      }} /> 
      <LoginStack.Screen 
        name={MENU_NAVIGATION.Parents.ContactDirection.name} 
        component={ContactDirection}
        options={{
          headerShown: false
        }} />
      <LoginStack.Screen name={MENU_NAVIGATION.Common.Agreement.name} component={AgreementScreen} options={{
        title: "Politique de confidentialité",
        headerShown: false
      }} /> 
      <LoginStack.Screen name={MENU_NAVIGATION.StatusScreen.name} component={StatusScreen}  options={{
        headerShown: false
      }} />
      <LoginStack.Screen name={MENU_NAVIGATION.Common.ShowTeacherProfile.name}
            component={ShowTeacherProfile}
            options={{ 
              headerShown: false
            }}
          />
      <LoginStack.Screen name={MENU_NAVIGATION.Common.DisplayPDF.name} component={DisplayPDFScreen} /> 
    
    </LoginStack.Navigator>
  );
}