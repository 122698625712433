// import {combineReducers, createStore, applyMiddleware, compose} from 'redux';
// import {Provider} from 'react-redux';
// import {persistStateEnhancer} from './_enhancers/persist-state-enhancer';
// import {promiseResolverMiddleware} from './_middlewares/promise-resolver-middleware';
import LoginReducer from './login'
import NotificationReducer from './notifications'
import { configureStore } from '@reduxjs/toolkit'
import { api } from 'services/api-rest'
/**
 * Create root reducer, containing
 * all features of the application
 */
const store = configureStore({
  reducer : {
    login: LoginReducer,
    notifications: NotificationReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export default store
/**
 * Use Redux Dev Tools, if they are installed in browser, otherwise compose from Redux
 */
/* eslint-disable-next-line no-underscore-dangle */
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// /** Create Redux store with root reducer and middleware included */
// const middlewareEnhancer = applyMiddleware(promiseResolverMiddleware);

// const composedEnhancers = composeEnhancers(
//   middlewareEnhancer,
//   persistStateEnhancer()
// );

// export default createStore(rootReducer, undefined, composedEnhancers);
