import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Divider,
    IconButton,
    Icon,
    useToast,
    Spacer,
    TextArea
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { createReportOnTeacher, getMyContractParent, getTeacherInfosFromContractParent } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { apiErrorNotify } from "services/notify";
import Chip from "components/Annonce/Chip";
import ContactOptionsInlineFlex from "components/ContactOptionsInlineFlex";
import DateTimePicker from '@react-native-community/datetimepicker';
import DateTimeInput from "components/DateTimeInput";
import { humanize_date_YMD } from "services/utils";
import UserWithoutContract from "components/UserWithoutContract";
import PageLoading from "components/PageLoading";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";


export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        contract_id: null,
        teachers: {},
        selected_contract_teacher_id: 0,
        selected_teacher_obj: null,
        selected_teacher_id: null,
        isLoaded: false
    })
    const [date, setDate] = useState(new Date());
    const [description, setDescription] = useState('');
    
    const handleSubmit = () => {
        const data = {
            description,
            date: humanize_date_YMD(date),
            teacher_id: state.teachers[state.selected_contract_teacher_id].id
            // teacher_id: parseInt(state.teachers[state.selected_contract_teacher_id])
        }
        console.log(data)
        createReportOnTeacher(token, data)
            .then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {time: 2500, navigation: navigation}))
            .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }
    const refresh = () => {
        getMyContractParent(token).then(
            res_contract => {
                const contract =  res_contract.data
                if(contract) {
                    setState(prev => ({...prev, contract_id: contract.id}))
    
                    const contract_teacher_dict_ids = {}
                    contract.subjects_affected.map(s_a => {
                        if(s_a.contract_teacher_id) {
                            if(!contract_teacher_dict_ids[s_a.contract_teacher_id]) contract_teacher_dict_ids[s_a.contract_teacher_id] = [];
                            contract_teacher_dict_ids[s_a.contract_teacher_id].push(s_a)
                        }
                    })
                    const list_ids = Object.keys(contract_teacher_dict_ids)
                    if(list_ids.length == 0) {
                        setState(prev => ({...prev, isLoaded: true}))
                    }else {
                        list_ids.forEach( (cteacher_id, loop_index) => {
                            getTeacherInfosFromContractParent(token, parseInt(cteacher_id))
                            .then( res_teacher => setState(prev => {
                                const data = {
                                    ...res_teacher.data,
                                    contract_teacher_id: cteacher_id,
                                    subjects_affected: contract_teacher_dict_ids[cteacher_id]
                                }
                                return {
                                    ...prev, 
                                    contract_id: contract.id,
                                    selected_contract_teacher_id: list_ids[0],
                                    teachers: {
                                        ...prev.teachers,
                                        [cteacher_id]: data,
                                    },
                                    isLoaded: loop_index === list_ids.length-1
                                }
                            }))
                            .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
                            // .catch(console.log)
                            
                        }
                        )
                    }
                } else {
                    setState(prev => ({
                        ...prev, 
                        isLoaded: true
                    }
                    ))
                }
            }
        )
        .catch(err => {
            apiErrorNotify({response: err.response, toast: toast})
            setState(prev => ({
                ...prev, 
                isLoaded: true
            }))
        } )
    }

    useEffect(() => {
        refresh()
    }, [setState])
    
    return (
    <ScreenWrapper nav={navigation} title="Rapport mensuel sur l'enseignant">
        <ScrollView>
            <VStack space={3} my='4' >
                {
                    ! state.isLoaded ? (
                        <PageLoading />
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <>
                            <VStack space="5" px='3'>
                                <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                    <HStack space={5}>
                                        {
                                            Object.values(state.teachers).length > 0  ? 
                                            
                                            Object.values(state.teachers).map(
                                                (teacher, index) => (
                                                    <Pressable key={index} onPress={() => setState(prev => {
                                                        return {
                                                            ...prev, 
                                                            selected_contract_teacher_id: teacher.contract_teacher_id, 
                                                            selected_teacher_obj: teacher,
                                                            selected_teacher_id: teacher.id
                                                        }
                                                    }) } >
                                                        <Chip py='2' px='4' bg={state.selected_contract_teacher_id == teacher.contract_teacher_id ? 'black' : 'gray.400'} color={state.selected_contract_teacher_id == teacher.contract_teacher_id ? 'white' : 'black' } borderRadius={20} >{teacher.full_name} </Chip>
                                                    </Pressable>
                                                )
                                            ) : !state.isLoaded ? <Spinner /> : <NoData msg="Aucun enseignant assigné à cet enfant !" /> 
                                        }
                                    </HStack>
                                </ScrollView>
                                
                                {
                                    state.teachers[state.selected_contract_teacher_id] && (
                                        <VStack>
                                            <VStack  bg={'blue.500'} p='3'  flex={1} borderRadius={10} >
                                                <VStack>
                                                    <Heading color='white' size='lg'>{state.teachers[state.selected_contract_teacher_id].full_name}</Heading> 
                                                    <Text>Contact: {state.teachers[state.selected_contract_teacher_id].username}</Text>
                                                </VStack>
                                                <Spacer />
                                                
                                                <ContactOptionsInlineFlex 
                                                    phone={state.teachers[state.selected_contract_teacher_id].username}
                                                    is_whatsapp={true}
                                                    Wrapper={VStack} 
                                                    _wrapper={{space: '2'}} />
                                                
                                            </VStack>
                                            <VStack space='3' mb='4' >
                                            <FormControl isRequired>
                                                <FormControl.Label>Date du rapport</FormControl.Label>
                                                    <DateTimeInput value={date} setValue={setDate} />
                                                </FormControl>
                                            <FormControl isRequired>
                                                <FormControl.Label>Description</FormControl.Label>
                                                <TextArea
                                                    numberOfLines={10}
                                                    value={description}
                                                    onChangeText={setDescription}
                                                />
                                                </FormControl>
                                                <Button 
                                                    onPress={handleSubmit}
                                                    isDisabled={!description}
                                                    >Envoyer le rapport</Button>
                                            </VStack>
                                        </VStack>
                                    )
                                }
                        
                            </VStack>
                        </>
                    
                    )
                }

                </VStack>

            </ScrollView>
    </ScreenWrapper>
  );
}