import { AntDesign } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import Chip from "components/Annonce/Chip";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Spacer,
    Avatar,
    Icon,
    useToast
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { getFileLink, getMyContractParent, listSubs } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import { getIsDirection, getToken } from "store/login/selectors";

export default ({ navigation, route }) => {
    const { teacher } =  route.params
    const teacher_metadata = teacher?.teacher_metadata || {}
    const [subs, setSubs] = useState([])

    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const toast = useToast()

    const is_direction = getIsDirection()

    const [contract, setContract] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)
    
    useEffect(()=> {
        // getMyContractParent(token)
        //     .then(res => {
        //         setContract(prev => res.data)
        //         setIsLoaded(true)
        //     })
        //     .catch(err => setError("Une erreur est survenue lors du chargement") )
        listSubs(token)
        .then(({data}) => setSubs(data))
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }, [])
  return (
    <ScreenWrapper nav={navigation} title={"Profil d'un enseignant"} >
        <ScrollView>
            <VStack px="4" py="5" space={"4"} > 
                <HStack>
                    {/* <Button>Vali</Button> */}
                </HStack>
                <Center >
                    { teacher.media_avatar ? <>
                            <Avatar bg="white" size={100} source={{
                            uri: getFileLink(teacher.media_avatar)
                        }} />
                        </>
                        : <Center p='1' bg='primary.500' rounded={'full'} ><Icon name='user' as={AntDesign} color='white' size={100} /></Center>
                    }
                </Center>     
                <VStack>
                    <HStack>
                        <Text>Nom</Text>
                        <Spacer />
                        <Heading size='sm'>{teacher.full_name}</Heading>
                    </HStack>
                    <HStack>
                        <Text>Profession</Text>
                        <Spacer />
                        <Heading size="sm">{teacher_metadata.job}</Heading>
                    </HStack>
                    <HStack>
                        <Text>Niveau universitaire</Text>
                        <Spacer />
                        <Heading size="sm">{teacher_metadata.level}</Heading>
                    </HStack>
                    <HStack>
                        <Text>Ville</Text>
                        <Spacer />                    
                        <Chip bg='black' ><Heading size="sm" color='white' >{teacher.cities[0].name}</Heading></Chip>
                    </HStack>
                </VStack>

                <VStack>
                    {
                        teacher_metadata ? (
                            <VStack>
                                <Heading>Domaines d'enseignement</Heading>
                                <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                    <HStack space='4' >
                                        {
                                            teacher_metadata.disired_subs && (
                                                
                                                    teacher_metadata.disired_subs.map(
                                                        sub => <Chip px='3' py='2' >{subs.find( s => s.id == sub)?.name}</Chip>
                                                    )
                                            )
                                        }
                                    </HStack>
                                </ScrollView>
                            </VStack>
                        ) : null
                    }
                </VStack>

                <VStack space='10' >
                    {
                        is_direction && (
                            <>
                                <VStack>
                                    <Heading>Zone de prestation souhaitée</Heading>
                                    <HStack space='4' >
                                        {
                                            teacher_metadata.disired_areas && <Chip px='3' py='2' >{teacher_metadata.disired_areas[0]}</Chip>
                                        }
                                    </HStack>
                                </VStack>
                                
                                <VStack>
                                    <Heading>Dernier diplome</Heading>
                                    <HStack space='4' >
                                        <Center>
                                            <Box  borderRadius={10} px='3' justifyContent='center' alignContent='center' bg='primary.500' opacity={0.9} h={150} w={120} >
                                                <Button bg='white'_text={{color: 'black'}} isDisabled={!teacher_metadata.media_diploma} onPress={() => navigation.navigate(MENU_NAVIGATION.Common.DisplayPDF.name, {uri: teacher_metadata.media_diploma }) }>
                                                    Voir le diplome
                                                </Button>
                                            </Box>
                                        </Center>
                                    
                                    </HStack>
                                </VStack>
                            </>
                        )
                    }
                    <VStack>
                        <Heading>Curriculum Vitae</Heading>
                        <HStack space='4' >
                            <Center>
                                <Box  borderRadius={10} px='3' justifyContent='center' alignContent='center' bg='primary.500' opacity={0.9} h={150} w={120} >
                                    <Button bg='white'_text={{color: 'black'}} isDisabled={!teacher_metadata.media_cv} onPress={() => navigation.navigate(MENU_NAVIGATION.Common.DisplayPDF.name, {uri: teacher_metadata.media_cv }) }>
                                        Voir le CV
                                    </Button>
                                </Box>
                            </Center>
                           
                        </HStack>
                    </VStack>
                    <VStack>
                        <Heading>A propos de moi</Heading>
                        <Text>{teacher_metadata.about || "Aucune donnée" }</Text>
                    </VStack>
                </VStack>
            </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}