import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    TextArea,
    Modal,
    Checkbox,
    useToast,
    IconButton,
    CloseIcon
  } from "native-base";
  import { Alert, Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { createBroadcast, createInvoiceParent, createMsgMulti, listContractsParents, listUsers } from "services/api-axios";
import { useState } from "react";
import { useEffect, Component } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import MultiSelect from 'react-native-multiple-select';
import { BROADCAST_LEVEl } from "constant";
import { humanize_date_YMD } from "services/utils";
import { useRef } from "react";
import { BROADCAST_PUBLICATION_STATUS } from "constant";
import { apiErrorNotify, apiNotify } from "services/notify";
import ScreenWrapper from "components/ScreenWrapper";
import DateTimeInput from "components/DateTimeInput";

const DisapearingAlert=({time = 3000, message='Success', update})=>{
  setTimeout(update, time);
  return (
    <Modal isOpen={true}>
      <Modal.CloseButton />
      <Modal.Content>
        <Center>
          <Heading color="red.500" > {message} </Heading>
        </Center>
      </Modal.Content>
    </Modal>
  )
  // return (
  //   <Actionsheet isOpen={true} collapsable={false} >
  //     <Actionsheet.Content bg="white" height={get_screen_height()} >
  //       <Center>
  //         <Heading color="red.500" > {message} </Heading>
  //       </Center>
  //     </Actionsheet.Content>
  //   </Actionsheet>  
  // )
 
}

export default ({ navigation }) => {
    const token = getToken()

    const [state, setState] = useState({
      users: [],
      isSubmitting: false,
      done: false,
      error: false
    })
    const [msgTitle, setMsgTitle] = useState('')
    const [msgStartAt, setMsgStartAt] = useState(humanize_date_YMD(new Date()))
    const [msgEndAt, setMsgEndAt] = useState(humanize_date_YMD(new Date()))
    const [msgDescription, setMsgDescription] = useState('')
    const [msgLevel, setMsglevel] = useState(BROADCAST_LEVEl.all.value)
    const [msgCanPublish, setPublish] = useState(true)

    const {
      isOpen,
      onOpen,
      onClose
    } = useDisclose()

  
    const toast = useToast()

    const handleSubmit = () => {
      const data = {
        title: msgTitle,
        start_at: humanize_date_YMD(new Date(msgStartAt)) + ' 0:0',
        end_at: humanize_date_YMD(new Date(msgEndAt)) + ' 0:0',
        description: msgDescription,
        status: msgCanPublish ? BROADCAST_PUBLICATION_STATUS.published : BROADCAST_PUBLICATION_STATUS.pending,
        receiver_type: msgLevel
      }
      setState( prev => ({...prev, isSubmitting: true}))
      console.log(data)
      createBroadcast(token, data)
      .then( res => setState( prev => ({...prev, done: true}) )  )
      .catch( err => {
        apiErrorNotify({response: err.response, toast: toast})
      })
    }
    
    return (
    <ScreenWrapper title="Creation d'annonce" nav={navigation}>
      <ScrollView>
        {
          state.done ? <Center><Heading>Opération effectuée !</Heading></Center> : state.error ? <DisapearingAlert message={state.error } update={() => setState(prev => ({...prev, error: false}))} /> : (

            <VStack space={3} px="3" pt="10" mb='3' >
                <FormControl isRequired>
                  <FormControl.Label>Niveau de diffusion</FormControl.Label>
                    <Select selectedValue={msgLevel} color="black" onValueChange={setMsglevel} >
                      <Select.Item label="Sélectionnez le niveau"  />
                      {
                        Object.values(BROADCAST_LEVEl).map( (br_kv, ind) => (
                          <Select.Item key={ind} 
                                label={br_kv.label}
                                value={br_kv.value} /> )
                        )
                      }
                    </Select> 
                </FormControl>
                <ScrollView horizontal>
                  <HStack>
                    
                  </HStack>
                </ScrollView>
                <FormControl isRequired>
                  <FormControl.Label>Objet du message</FormControl.Label>
                    <Input 
                      value={msgTitle} 
                      placeholder="Entrez l'objet du message"
                      onChangeText={setMsgTitle} />
                </FormControl>
              
                <FormControl isRequired>
                  <FormControl.Label>Début de publication</FormControl.Label>
                      <DateTimeInput 
                        width={'45%'}
                        value={new Date(msgStartAt)} 
                        placeholder="Début"
                        setValue={setMsgStartAt} />
                </FormControl>

                <FormControl isRequired>
                  <FormControl.Label>Fin de publication</FormControl.Label>
                      <DateTimeInput
                        width={'45%'}
                        value={new Date(msgEndAt)} 
                        placeholder="Fin"
                        setValue={setMsgEndAt} />
                </FormControl>
                
                <FormControl isRequired>
                  <FormControl.Label>Contenu du message</FormControl.Label>
                    <TextArea
                      totalLines={10}
                      color='black' 
                      value={msgDescription} 
                      placeholder="Entrez le contenu message"
                      onChangeText={setMsgDescription} />
                </FormControl>

                  
                <FormControl>
                    <Checkbox
                      bg='white'
                      isChecked={msgCanPublish}
                      onChange={() => setPublish(prev => !prev)} 
                      >
                        <Text color="black">Voulez-vous publier automatiquement une fois dans l'intervalle de publication ?</Text>
                      </Checkbox>
                </FormControl>

                  
                    <Button 
                      isDisabled={msgLevel == null || msgTitle === '' || msgDescription === ''}
                      // isLoading={state.done && state.isSubmitting }
                      my='5'
                      onPress={handleSubmit}
                    >Enregistrer</Button>
              
            </VStack>
          )
        }
      </ScrollView>
    </ScreenWrapper>
  );
}