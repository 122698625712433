import { Button, HStack, Text, useDisclose } from "native-base"
import DateTimePicker from '@react-native-community/datetimepicker';
import { DatePickerModal as DateTimePickerWeb, fr, registerTranslation } from 'react-native-paper-dates';
import { Fragment, useEffect } from "react";
import { Pressable, Platform } from "react-native";
import { from_month_index_to_name } from "services/utils";
import DisplayDate from "components/DisplayDate";
import { TextInput } from "react-native-web";



registerTranslation('fr', fr)

export default ({ value, setValue, is24Hour, ComponentClosed, ComponentOpen, ...props}) => {
    const {isOpen, onClose, onOpen} = useDisclose(false)
    const handleChange = () => {
        if(isOpen) onClose()
        else onOpen()
    }
    
    return (
        <>
            {
                !value ? (
                    ComponentClosed ? <ComponentClosed isOpen={isOpen} onClose={onClose} onOpen={onOpen} /> : (
                        <Button onPress={handleChange} >
                            Selectionnez une date
                        </Button>
                    )
                ) : (
                        ComponentOpen ? <ComponentClosed isOpen={isOpen} onClose={onClose} onOpen={onOpen} /> : (
                                <Pressable onPress={onOpen}>
                                    <DisplayDate value={value} />
                                </Pressable>
                        )
                )
            }
            {
                isOpen && ( 
                    Platform.OS === 'web' ? (
                        <DateTimePickerWeb
                            locale="en"
                            mode="single"
                            visible={isOpen}
                            onDismiss={onClose}
                            date={value || new Date()}
                            onConfirm={({ date }) => {
                                setValue(new Date(date))
                                onClose()
                            }}
                        />
                    ) : (
                    <DateTimePicker
                        testID="dateTimePicker"
                        value={value || new Date() }
                        mode={'date'}
                        onChange={({ nativeEvent, type }) => {
                            if(nativeEvent.timestamp) {
                                setValue(new Date(nativeEvent.timestamp))
                            }
                            onClose()
                        }}
                        is24Hour={is24Hour}
                        {...props}
                        />
                    )
                )
            }
        </>
    )
}