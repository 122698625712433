import GoBack from "components/GoBack"
import { Heading, HStack, View } from "native-base"

export default ({title, nav, hideBackButton, children, _view, _title}) => {
    return (
        <View mx={{base: 0, md: '1/6', lg: '1/3'}} {..._view}>
            <HStack m='4' space={'3'} alignItems={'center'} >
                { !hideBackButton && <GoBack nav={nav}  /> }
                <Heading w='90%' flexWrap={'wrap'} {..._title} >{title}</Heading>
            </HStack>
            {children}
        </View>
    )
}