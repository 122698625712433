import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Spacer,
    FlatList,
    Icon,
    useToast,
    Modal
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { getFileLink, listAllUsers, resetUserPassword } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import ContactOptionsInline from "components/ContactOptionsInline";
import Chip from "components/Annonce/Chip";
import { display_user_role } from "services/utils";
import ScreenWrapper from "components/ScreenWrapper";
import { AntDesign } from "@expo/vector-icons";
import PageLoading from "components/PageLoading";
import SelectMultipleInput from "components/SelectMultipleInput";
import { apiErrorNotify } from "services/notify";


export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        users: [],
        selected_user: null,
        selected_users: [],
        isLoaded: false,
        successMsg: null,
        isSubmitting: false
    })
    const refresh = () => {
        listAllUsers(token).then(
            res => {
                // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                setState(prev => ({...prev, users: res.data, isLoaded: true }))
            }
        )
        .catch(err => console.log(err))
    }

    const handleSubmit = () => {
        setState(prev => ({...prev, isSubmitting: true}))
        resetUserPassword(token, state.selected_user.username)
        .then(res => setState(prev => ({...prev, successMsg: res.data.msg})) )
        .catch(err => {
            apiErrorNotify({response: err.response, toast: toast})
            setState(prev => ({...prev, isSubmitting: false}))
        })  
    }
    
    useEffect(() => {
        refresh()
    }, [setState])
    console.log(state.users.length)
    return (
    <ScreenWrapper title="Réinitialiser un compte utilisateur" nav={navigation}>
        <ScrollView>
            <Heading size='xs' > {state.isLoaded && `${state.users.length} comptes`}</Heading>
            <VStack space={3} px='4' >
                {
                    ! state.isLoaded && <PageLoading />
                }
                {
                    state.isLoaded && (
                        <VStack space='3' >
                            <SelectMultipleInput
                                single
                                displayKey={'full_name'}
                                items={state.users}
                                onSelectedItemsChange={(ids) => setState(prev => ({...prev, selected_users: ids, selected_user: prev.users.find(u => u.id == ids[0]) })) }
                                selectedItems={state.selected_users}
                                selectText="Sélectionnez un compte "
                            />
                            {
                                state.selected_user  && (
                                    <Box bg='gray.200' shadpw='6' rounded={'lg'} p='4' >
                                        <HStack>
                                            <Text>Nom: </Text>
                                            <Heading alignSelf={'center'} size='xs'>{state.selected_user.full_name}</Heading>
                                        </HStack>
                                        <HStack>
                                            <Text>Téléphone: </Text>
                                            <Heading alignSelf={'center'} size='xs'>{state.selected_user.username}</Heading>
                                        </HStack>
                                        <HStack>
                                            <Text>Role: </Text>
                                            <Heading alignSelf={'center'} size='sm'>{display_user_role(state.selected_user)}</Heading>
                                        </HStack>
                                    </Box>
                                )
                            }
                        </VStack>
                    )
                }
                {
			state.successMsg && (
				<Modal isOpen={true} collapsable={true} onClose={() => navigation.goBack()} >
					<Modal.Content>
						<Modal.CloseButton   />
						<Modal.Header bgColor={'green.500'}>
							<Heading color='white' size='lg'>Succès</Heading>
							
						</Modal.Header>
						<Modal.Body bg={'white'} >
							<Center>
								<Heading size='xs'>{state.successMsg}</Heading>
							</Center>
						</Modal.Body>
					</Modal.Content>
					
				</Modal>
			)
		}
                <HStack>
                    <Button bg='red.500' 
                        isLoading={state.isSubmitting}
                        onPress={handleSubmit} 
                        isDisabled={!state.isLoaded || !state.selected_user } 
                    >Réinitialiser le compte</Button>

                </HStack>
                </VStack>
            </ScrollView>

    </ScreenWrapper>
  );
}