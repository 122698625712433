import { AddIcon, Button, useDisclose, useToast, VStack } from "native-base"
import { useEffect } from "react"
import { useState } from "react"
import { addChildToContract, deleteChild, updateChild } from "services/api-axios"
import { apiErrorNotify } from "services/notify"
import { getToken } from "store/login/selectors"
import AddChild from "../AddChild"
import ResumeChildInfos from "../ResumeChildInfos"

export default ({ children_data , subs, contract_id}) => {
    const token = getToken()
    const [state, setState] = useState({
        selected_child_index: null,
        display_add_child: false,
        display_edit_child: false,
        children: children_data
    })
    const toast = useToast()
    const { isOpen, onClose, onOpen } = useDisclose()
    
    const handleAddChild = ({values}) => {
        console.log("Going to add child ", " with ", values)
        return addChildToContract(token, contract_id, values)
        .then(response => {
            setState(prev => {
              return {
                ...prev,
                children: prev.children.concat([{
                    ...values,
                    timeplan_week_model: response.data ? response.data.timeplan_week_model : {}
                }])
              }
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        })
    }

    const handleEditChild = async ({values, index}) => {
        // console.log("Going to update child ", index, " with ", values)
        return new Promise((resolve, reject) => {
            console.log("\n\n GONNA EDIT TIMEPLAN ", values)
            updateChild(token, contract_id, state.children[index].id, values )
            .then(response => {
                setState(prev => {
                    const new_children = prev.children
                    new_children[index] = {
                        ...prev.children[index],
                        ...values,
                        timeplan_week_model: response.data ? response.data.timeplan_week_model : {}
                    }
                    return {
                        ...prev,
                        children: new_children
                    }
                })
                toast.show({
                    title:  "Opération effectuée",
                    variant: "subtle",
                    status: 'success',
                    bg: 'green.600'
                })
                resolve()
            })
            .catch(err => {
                apiErrorNotify({response: err.response, toast: toast}) 
                reject()
            })
        })
      }

    const handleRemovechild = (_index) => {
        const child = state.children[_index]
        
        console.log("Removing child ", child, " at index ", _index)

        return deleteChild(token, contract_id, child.id)
        .then(response => {
            setState(prev => {
              return {
                ...prev,
                children: prev.children.slice(0, _index).concat(prev.children.slice(_index+1, prev.children.length))
              }
            })
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }
    console.log("Received ", children_data.length, state.children.length)
    // useEffect(() => {
    //     setState(prev => ({...prev, children: children_data}))
    //     console.log(children_data.length, state.children.length)
    // }, [setState])
    return (
        <VStack space='3' >
            {
                state.children.map( (c, index) => (
                    <>
                    {console.log("initialValues in DisplayChild ", c.full_name)}
                    <ResumeChildInfos 
                    key={index} 
                    index={index} 
                    subs={subs}
                    childValues={c} 
                    onEditChild={handleEditChild}
                    onRemoveChild={() => handleRemovechild(index)}
                    />
                    </>
                )
                )
            }
            {
                isOpen && <AddChild onClose={onClose} 
                    isOpen={isOpen} 
                    onSubmit={handleAddChild} 
                    subs={subs} 
                    label='Creation' /> 
                // : state.display_edit_child && <AddChild onClose={onClose} index={index} isOpen={isOpen} subs={subs} initialValues={childValues} onSubmit={handleEditChild} label='Editting' /> 
            } 
            <Button startIcon={<AddIcon />} rounded={'full'}  onPress={onOpen} >Ajouter un enfant</Button>
        </VStack>
    )
}