import { FontAwesome } from "@expo/vector-icons"
import { FormControl, Icon, IconButton, Input, Text } from "native-base"
import { useEffect } from "react"
import { useRef } from "react"
import { useState } from "react"

// const format = (value) => {
//     is
// }

export default ({value, onChangeText, checkPrefix = true, prefixReg= "^0[157]+", cleanWhenInvalid= false, ...props}) => {
    const [state, setState] = useState(value)
    const [isVisible, setVisibility] = useState(false)
    const [err, setError] = useState('')
    const ref = useRef()
    useEffect(() =>{
        // ref.current.()
    } , [ref])

    const handleChange = (val= '') => {
        onChangeText(val)
    }
    return (
        <FormControl isInvalid={err} >
        <FormControl.Label>{props.label}</FormControl.Label>
        <Input 
            ref={props.innerRef || ref}
            type={!isVisible ? 'password' : 'text'}
            value={value} 
            leftElement={
                <IconButton _pressed={{bg: 'rgba(1,1,1,0)'}} icon={<Icon ml='2' as={FontAwesome} name={!isVisible ? "eye-slash" : 'eye'} size="md" color='black' />} onPress={() => setVisibility(prev => !prev)} />
            }
            {...props}
            onChangeText={onChangeText}
            />
            <Text color='red.500' fontSize={13} >{props.error}</Text>
    </FormControl>
    )
}