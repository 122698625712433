import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast,
    Modal,
    Divider,
    Spacer,
    Checkbox,
    CloseIcon,
    IconButton
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { listApprovedTeachersWithoutContract, listCities, createContractParent, listContractsParents, createContractTeacher } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import PageLoading from "components/PageLoading";
import DateTimeInput from "components/DateTimeInput";
import NumberInput from "components/NumberInput";
import { useCallback } from "react";
import Chip from "components/Annonce/Chip";
import { humanize_date_YMD } from "services/utils";
import ScreenWrapper from "components/ScreenWrapper";

const DisplayChildForSA = ({s_a, isChecked, onUpdate, num }) => {
  // const [checked, setCheck] = useState(initialValue)
  return (
    <Pressable onPress={isChecked ? () => onUpdate(s_a) : () =>  onUpdate(s_a) }>
      <HStack >
        <Heading size='xs'>{num}</Heading>
        
        <VStack pl='1' ml='1' borderLeftWidth={2} borderLeftColor={isChecked ? 'primary.500': 'rgba(0,0,0,0)' } >
          <Heading size='xs' >{s_a.child.full_name} </Heading>
          <Text>Classe: {s_a.child.child_level} </Text>
          <Text>Lieu des cours: {s_a.child.place_courses} </Text>
        </VStack>
        <Spacer />
        <Box position='absolute'  right='2' bg='white' borderWidth={1} rounded={'full'} >
          <CheckIcon  m='1' size='md' color={!isChecked ? 'white': 'primary.500' }  />
        </Box>
      </HStack>
    </Pressable>
  )
}

const SelectSA = ({isOpen, onConfirm, onClose, teacher_obj, list_contract_parents, global_list_sas_selected_ids, enableErasePrevious= true}) => {
  const [state, setState] = useState({
    contract_parent_index: null,
    local_list_sas_selected_ids: [],
  })
  console.log("Sélected ", state.local_list_sas_selected_ids.length)
  const handleLocaleSAChange = (s_a) => {
    if(state.local_list_sas_selected_ids.findIndex(_ => _.id === s_a.id ) === -1) {
          const firstSAInList = state.local_list_sas_selected_ids[0]
          if(
            !firstSAInList || (firstSAInList && firstSAInList.contract_parent_id === list_contract_parents[state.contract_parent_index].id) || !enableErasePrevious
          ){
            setState(prev => ({
              ...prev,
              local_list_sas_selected_ids: state.local_list_sas_selected_ids.concat(s_a)
            })) 

          } else {
              setState(prev => ({
                ...prev,
                local_list_sas_selected_ids: [s_a]
              })) 
            }
          
      
    } else {
        setState(prev => ({
          ...prev,
          local_list_sas_selected_ids: state.local_list_sas_selected_ids.filter( s => s.id !== s_a.id)
        })) 

    }
  }
  
  const collect_sas_by_sub = useCallback(() => {
    let res = {}
    if(state.contract_parent_index !== null) {
      list_contract_parents[state.contract_parent_index].subjects_affected.forEach( 
        (s_a, index) =>  {
          delete s_a.course_sessions
          if(s_a.contract_teacher_id == null){
            if(!res[s_a.sub_id]) res[s_a.sub_id] = []
            res[s_a.sub_id].push(s_a)
          }
        }
      )
    }
    return res
  }, [state.contract_parent_index]
  )

  const handleConfirmSelection = () => {
    onConfirm({
      contract_parent_index: state.contract_parent_index,
      selected_sas_list: state.local_list_sas_selected_ids
    })
    // onClose()
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} >
      <Modal.Content w='90%' >
        <Modal.Header>Affecter des matières à l'enseignant {teacher_obj.full_name} </Modal.Header>
        <Modal.Body bg ='white'>
          <FormControl >
            <FormControl.Label>Sélectionnez un parent sous contrat</FormControl.Label>         
              <Select 
                selectedValue={state.contract_parent_index} 
                onValueChange={val => setState(prev => ({...prev, contract_parent_index: val}))  }
                color="black"  >
                <Select.Item label="Sélectionnez un parent" />
                {
                  list_contract_parents.map( (c, index) =>  <Select.Item label={c.parent.full_name || c.parent.username } value={`${index}`} /> )
                }
              </Select> 
          </FormControl>

          <FormControl mt='4' >
            <FormControl.Label>Sélectionnez les matières à affecter</FormControl.Label>         
            {
              state.contract_parent_index !== null && (
                <>
                  <VStack space='2' mb='3' >
                  {
                            Object.entries(collect_sas_by_sub()).map(
                              ([sub_id, list_sas], index) => {
                                return (
                                  <Box bg='gray.200' p='2' borderRadius={10}  >
                                    <Heading size='md'>{list_sas[0].sub.name}</Heading>
                                    
                                        {
                                          list_sas.map(
                                            (s_a, c_index) => {
                                              const isChecked = state.local_list_sas_selected_ids.findIndex(_ => s_a.id === _.id ) > -1
                                              const isHidden = global_list_sas_selected_ids.some(g => g.id == s_a.id )
                                              
                                              
                                              return (
                                                <>
                                                  {
                                                    !isHidden && (
                                                      <VStack my='1' space={2} >
                                                        <DisplayChildForSA 
                                                          isChecked={isChecked} 
                                                          // isChecked={state.global_list_sas_selected_ids.findIndex(_ => s_a.id === _.id ) > -1} 
                                                          s_a={s_a} 
                                                          num={c_index+1} 
                                                          onUpdate={handleLocaleSAChange}
                                                          />
                                                        <Divider />
                                                      </VStack>
                                                    )
                                                  }
                                                </>
                                              )
                                            }
                                          )
                                        }
                                  </Box>
                                )
                              }
                            
                            )
                    }
                        
                  </VStack>
                  <Button onPress={handleConfirmSelection} >Confirmer l'affectation de matières</Button>
                </>
              )
            }
          </FormControl>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [formValues, setFormvalues] = useState({
      teacher_id: null,
      city_id: null,
      price: 0,
      signed_at: new Date(),
      start_at: new Date(),
      end_at: new Date(),
      subjects_affected_ids: []
    })
    
    const [state, setState] = useState({
      contract_parents: [],
      teachers: [],
      cities: [],

      selected_contract_parent_index: null,
      selected_teacher_index: null,
      selected_subjects_affected_by_cp_id: {},
      display_selector: false,
      isLoaded: false,
      isSubmitting: false,
    })

    const handleSubmit = () => {
      console.log("Ready to submit ")
      const data = {
        teacher_id: state.teachers[state.selected_teacher_index].id,
        city_id: formValues.city_id,
        price: parseInt(formValues.price),
        signed_at: humanize_date_YMD(formValues.signed_at),
        start_at: humanize_date_YMD(formValues.start_at),
        end_at: humanize_date_YMD(formValues.end_at),
        subjects_affected_ids: Object.values(state.selected_subjects_affected_by_cp_id).reduce(
          (total, current) => [...total, ...current],
          []
        ).map(s => s.id)
      }
      console.log(data)
      createContractTeacher(token, data)
      .then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {time: 2000, navigation: navigation}) )
      .catch(err =>{
        console.log(err.response)
        apiErrorNotify({response: err.response, toast: toast})
      })
    }

    const toggleSelector = () => setState(prev =>  ({...prev, display_selector: false}))
    
    const handleChangeTeacherSAs = ({contract_parent_index, selected_sas_list}) => {
      // console.log("Received ", selected_sas_list)
      // console.log("Before spread ", state.selected_subjects_affected_by_cp_id)
      let tempo = Object.values(state.selected_subjects_affected_by_cp_id).reduce(
        (total, current) => [...total, ...current],
        []
      )
      tempo = tempo.concat(selected_sas_list)
      let set_version = new Set(tempo)

      let locale_selected_sas_by_cp = {}
      tempo.forEach(
        s_a => {
          // console.log("Found at ", s_a, '\n')
          if(!locale_selected_sas_by_cp[s_a.contract_parent_id]) locale_selected_sas_by_cp[s_a.contract_parent_id] = []
          if(locale_selected_sas_by_cp[s_a.contract_parent_id].findIndex(s => s.id === s_a.id) === -1) {
            locale_selected_sas_by_cp[s_a.contract_parent_id].push(s_a)
          }
          
        }
      )
      // console.log("Final ", locale_selected_sas_by_cp)
      setState(prev => ({
        ...prev,
        selected_subjects_affected_by_cp_id: locale_selected_sas_by_cp,
        display_selector: false
      }))
    }

    const handleDeleteSA = ({cp_id, sa_id}) => {
        setState(prev => ({
          ...prev,
          selected_subjects_affected_by_cp_id: {
            ...prev.selected_subjects_affected_by_cp_id,
            [cp_id]: prev.selected_subjects_affected_by_cp_id[cp_id].filter( _ => _.id != sa_id)
          }
        }))
    }
    
    useEffect(() => {
      listApprovedTeachersWithoutContract(token).then(
        res_teachers => {
          listCities(token).then(
            res_cities => {
              listContractsParents(token).then( res_contract_parents => {
                let selected_subjects_affected_by_cp_id = {}
                // res_contract_parents.data.forEach(
                //   cp => selected_subjects_affected_by_cp_id[cp.id] = {}
                // )
                setState(prev => ({
                    ...prev, 
                    cities:  res_cities.data, 
                    teachers: res_teachers.data, 
                    contract_parents: res_contract_parents.data,
                    selected_subjects_affected_by_cp_id: selected_subjects_affected_by_cp_id,
                    isLoaded: true,
                  }))
                
              })
            }
          )
        }
      ).catch(err => apiErrorNotify({response: err.response, toast: toast}))

      

    }, [setState])
    return (
    <ScreenWrapper title="Enregistrer un contrat de d'enseignant" nav={navigation}>
      <ScrollView>
        {
          !state.isLoaded ? <PageLoading />: (

            <VStack space={3} px="3" pt="10" >
                {/* <Heading color="black" >Enregistrer un contrat d'enseignant</Heading> */}
                <FormControl isRequired>
                  <FormControl.Label>Enseignant</FormControl.Label>
                    <Select selectedValue={formValues.teacher_id} 
                        color="black" 
                        onValueChange={val => setState(prev => ({...prev,  val, selected_teacher_index:  val}))} >
                      <Select.Item label="Sélectionnez l'enseignant" />
                      {
                        state.teachers.map( (t, index) =>  <Select.Item label={`${t.full_name} (${t.username})`} value={`${index}`} /> )
                      }
                    </Select> 
                </FormControl>
                <FormControl isRequired isDisabled={formValues.teacher_id == 0} >
                  <FormControl.Label>Ville</FormControl.Label>         
                    <Select 
                    selectedValue={formValues.city_id} 
                      onValueChange={val => setFormvalues(prev => ({...prev, city_id: val}))  }
                      color="black"  >
                      <Select.Item label="Ville des cours" />
                      {
                        state.cities.map( c =>  <Select.Item label={c.name} value={`${c.id}`} /> )
                      }
                    </Select> 
                </FormControl>
                <FormControl isRequired isDisabled={formValues.teacher_id == 0}>
                  <FormControl.Label>Montant</FormControl.Label>
                    <NumberInput 
                      value={formValues.price} 
                      placeholder="Entrez un montant"
                      onChangeText={val => setFormvalues(prev => ({...prev, price: val})) } />
                  </FormControl>

                  <FormControl  isRequired isDisabled={formValues.parent_id == 0}>
                    <FormControl.Label>Date de signature</FormControl.Label>
                      <DateTimeInput 
                        value={formValues.signed_at} 
                        placeholder=""
                        setValue={val => setFormvalues(prev => ({...prev, signed_at: val})) } />
                </FormControl>
                
                  <FormControl width={"45%"} isRequired isDisabled={formValues.parent_id == 0}>
                    <FormControl.Label>Date de début</FormControl.Label>
                      <DateTimeInput 
                        value={formValues.start_at} 
                        setValue={val => setFormvalues(prev => ({...prev, start_at: val})) } />
                  </FormControl>

                  <FormControl width={"45%"}isRequired isDisabled={formValues.parent_id == 0}>
                    <FormControl.Label>Date de fin</FormControl.Label>
                      <DateTimeInput 
                        value={formValues.end_at} 
                        placeholder=""
                        setValue={val => setFormvalues(prev => ({...prev, end_at: val})) } />
                  </FormControl>

                  <Divider />
                  <Heading size={'lg'}>Affectation d'élèves</Heading>
                  <VStack space='2'>
                      {
                        Object.keys(state.selected_subjects_affected_by_cp_id).map(
                          cp_id => {
                            const _parent = state.contract_parents.find(_ => {
                              return _.id == cp_id
                            })?.parent
                            const _ordered_by_subs = {}
                            state.selected_subjects_affected_by_cp_id[cp_id].forEach(
                              s_a => {
                                if(!_ordered_by_subs[s_a.sub.name]) _ordered_by_subs[s_a.sub.name] = []
                                _ordered_by_subs[s_a.sub.name].push(s_a)
                              }
                            )
                            return (
                              <VStack key={cp_id} bg='gray.300' borderRadius={10} p='2' >
                                  <HStack>
                                    <Text>Nom du parent </Text>
                                    <Spacer />
                                    <Heading size='lg'>{_parent ? (_parent.full_name || _parent.username) : "Nom non trouvé" }</Heading>
                                    <Spacer />
                                  </HStack>
                                
                                <VStack space='5'>
                                {
                                  Object.keys(_ordered_by_subs).map(
                                    sub => (
                                        <HStack space='4' ml='2' pl='2' borderLeftWidth={1} >
                                          <Heading size='xs'>{sub} </Heading>
                                          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                                              <HStack flexWrap={'wrap'}  space='4' >
                                              {_ordered_by_subs[sub].map(
                                                s_a => (
                                                  <HStack key={s_a.id} rounded='full' space='3' p='1' px='2' bg='white' alignContent='center' >
                                                    <IconButton 
                                                      bg='gray.200' 
                                                      p='1' 
                                                      rounded='full' 
                                                      _pressed={{
                                                        bg: 'primary.500'
                                                      }}
                                                      icon={
                                                        <CloseIcon 
                                                            size='xs'   
                                                            color='red.500' 
                                                            onPress={() => handleDeleteSA({cp_id: cp_id, sa_id: s_a.id})}
                                                        />
                                                      } 
                                                    />
                                                    <Text fontSize='15'  >{s_a.child.full_name}</Text>
                                                  </HStack>
                                                )
                                              )}
                                              
                                              </HStack>
                                          </ScrollView>
                                        </HStack>
                                    
                                    )
                                  )
                                }
                                </VStack>
                              </VStack>
                            )

                          }
                        )
                      }
                  </VStack>
                      
                  {
                    state.display_selector && state.selected_teacher_index !== null && (
                        <SelectSA 
                          isOpen={state.display_selector}
                          onClose={toggleSelector}
                          onConfirm={handleChangeTeacherSAs}
                          list_contract_parents={state.contract_parents}
                          // global_list_sas_selected_ids={state.selected_subjects_affected_by_cp_id}
                          global_list_sas_selected_ids={Object.values(state.selected_subjects_affected_by_cp_id).reduce(
                            (total, current) => [...total, ...current],
                            []
                          )}
                          teacher_obj={state.teachers[state.selected_teacher_index]}
                          // list_checked_sas={state.selected_subjects_affected_by_cp_id[state.selected_contract_parent_index] || [] }
                        />  
                    )
                  }
                  <Center>
                    <Button mt="10" 
                    isDisabled={state.selected_teacher_index === null} 
                    onPress={()=> setState(prev => ({...prev, display_selector: true}) )} >
                      Affecter des élèves
                  </Button>
                  
                  </Center>

                
                <Button mt="10" 
                  isDisabled={state.selected_teacher_index === null || !formValues.city_id } 
                  isLoading={state.isSubmitting}
                  onPress={handleSubmit} >
                    Enregistrer ce contrat
                </Button>
                
      
            </VStack>
          )
        }
      </ScrollView>
    </ScreenWrapper>
  );
}