import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Divider,
    IconButton,
    Icon,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { listContractTeacherChildren, getMyContractTeacher, getParentInfosFromContractParent, createPrestationByTeacher } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";



export default ({ navigation, route }) => {
    // const {time, update, msg, } = route.params
    const params = !route ? {} : route.params ? route.params : {}
    const nav = params.navigation || navigation
    const color = params.color || "emerald.500"
    const time = typeof params.time == 'number' ? params.time : 1500
    const update = params.update || function () {nav.goBack(); nav.goBack()}
    const msg = params.msg || "Opération effectuée"
    const autoBack = params.autoBack == undefined ? false : params.autoBack
    
    useEffect(() => {
        if(autoBack) {
            setTimeout(update, time);
        }
    }, [])

    return (
        <Actionsheet isOpen={true} collapsable={false} onClose={update} >
        <Actionsheet.Content bg="white" minH={'100px'}>
          <Center>
            <Heading color={color} > {msg} </Heading>
          </Center>
        </Actionsheet.Content>
      </Actionsheet>  
  );
}