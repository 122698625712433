const { HStack, Button, Text } = require("native-base")
const { useState } = require("react")
import { MENU_NAVIGATION, INVOICE_STATUS } from "constant";

export default ({invoice_id, status, handleSubmit, toast, setStatus}) => {
    const [isSubmiting, setSubmitting] = useState(false)
    const handlePress = () => {
        setSubmitting(true)
        handleSubmit(invoice_id)
        .then(res => {
            setStatus(res.data.status) 
            setSubmitting(false)
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }
    return (
        <HStack justifyContent={'flex-end'} alignContent='center' >
            {
                status == INVOICE_STATUS.pending ? <Button bg={'danger.500'} onPress={handlePress} isLoading={isSubmiting} size='xs'>Régler</Button>  : <Text fontSize='12'>Déjà réglée</Text>
            }
        </HStack>        
    )
}