import React from 'react';
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    
    FormControl,
    Input,
    Modal,
    Spacer
  } from "native-base";
import NumberInput from 'components/NumberInput';
import BaseFloatNumberInput from 'components/BaseFloatNumberInput';
import DateTimeInput from 'components/DateTimeInput';
import { humanize_date_YMD } from 'services/utils';

export default function ({ isOpen, onClose, onSubmit, selected_subject_affected: s_a, default_values= {}, is_optional, optional_already_exist }) {
    const default_raw_mark = default_values.value ? default_values.value.split('/') : []
    const [markValue, setMarkValue] = React.useState(default_raw_mark[0] || '' );
    const [markMax, setMarkMax] = React.useState(default_raw_mark[1] || '20' );
    const [dateValue, setDateValue] = React.useState(default_values.date ? new Date(default_values.date) : new Date() );
    const [isSubmiting, setIsSubmiting] = React.useState(false);
    const [isSucess, setIsSucess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(default_values.value);

    // console.log("modals ", default_values, default_raw_mark, markValue, markMax)
    return <>
        <Modal isOpen={true} onClose={ isSubmiting ? () => null : onClose} >
          <Modal.Content bg='white' >
            <Modal.CloseButton rounded='full' />
            <Modal.Header bg='primary.500'>{isUpdating ?  "Modifier une note" : s_a.sub.name }</Modal.Header>
            <Modal.Body>
              <FormControl>
                <FormControl.Label>Note obtenue</FormControl.Label>
                    {/* <Input w='100px' /> */}
                <HStack space='3' >
                    <BaseFloatNumberInput isDisabled={isSucess} w={75} value={markValue} onChangeText={setMarkValue} />
                    <Center>
                        <Heading>/</Heading>
                    </Center>
                    <BaseFloatNumberInput isDisabled={isSucess} w={75} defaultValue={markMax} value={markMax} onChangeText={setMarkMax} />
                </HStack>
              </FormControl>
              <FormControl mt="3">
                <FormControl.Label>Date</FormControl.Label>
                <DateTimeInput value={dateValue} setValue={setDateValue} />
                {/* <Input placeholder='"YYYY-MM-DD' value={dateValue} onChangeText={setDateValue}  /> */}
              </FormControl>
            </Modal.Body>
            <Modal.Footer bg='white'>
                
                {
                    !isSucess ? (
                        <Button.Group space={2}>
                        <Button isDisabled={isSubmiting} variant="ghost" colorScheme="blueGray" onPress={onClose}>
                        Annuler
                        </Button>
                        <Spacer />
                        <Button isLoading={isSubmiting} 
                        isDisabled={!markValue || parseFloat(markValue) > parseFloat(markMax) }
                            onPress={async () => {
                                await setIsSubmiting(true)
                                setTimeout(() => {
                                    try {
                                        let val = {}
                                        if(is_optional) {
                                            val = {
                                                value: markValue+'/'+markMax,
                                                date: humanize_date_YMD(dateValue),
                                                optional_subject_id: default_values.optional_subject_id || s_a.id,
                                                optional_already_exist: optional_already_exist
                                            }
                                        } else {
                                            val = {
                                                value: markValue+'/'+markMax,
                                                date: humanize_date_YMD(dateValue),
                                                subject_id: default_values.subject_id || s_a.id
                                            }
                                        }
                                        // console.log("Gonna add/update ", val, is_optional, optional_already_exist)
        
                                        onSubmit(val).then(
                                            () => setIsSucess(true)
                                        ).catch(
                                            () => setIsError(true)
                                        )
                                    } catch (error) {
                                        // console.log("err", error)
                                    } finally {
                                        setIsSubmiting(false)
                                    }
                                }, 1000)
                                
                            }}
                            >
                        Enregistrer
                        </Button>
                    </Button.Group>
                    ) : <Heading color='green.500'>{ isUpdating ? "Note modifiée " : "Note ajoutée avec succès "} !</Heading>
                }
                {
                    isError && <Heading color='red.500'>Une erreur s'est produite!</Heading>
                }
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </>;
  }