import {
    Text,
    VStack,
    Image,
    Heading,
    HStack,
    Skeleton,
} from "native-base";
import { getIsSuperuser, getToken } from "store/login/selectors";
import { getFileLink, listLearningExercises } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { AntDesign } from "@expo/vector-icons";
import ScreenWrapper from "components/ScreenWrapper";
import * as React from 'react';
import { Pressable } from "react-native";
import { MENU_NAVIGATION } from "constant";


export default function ({navigation, ...props}) {
    const [image, setImage] = useState(props.cover_url);
    
    return (
    <Pressable onPress={() => navigation.push(MENU_NAVIGATION.Common.DisplayPDF.name, {uri: props.media}) } >
        <VStack bg='gray.300' borderRadius={10} space={2}>
            {image ? <Image alt="image" source={{ uri: getFileLink(image) }} h={150} w='100%' borderTopRightRadius={10} borderTopLeftRadius={10} /> : <Skeleton h={150} color='blue.200' borderRadius={3} />  }
              
              <VStack px='3' >
                <HStack space={2}>
                    <Image alt="As Training" source={require("assets/icon.png")} borderRadius={'full'} h={10} w={10} />  

                    <VStack>
                        <Heading fontSize={18} >{props.title}</Heading>
                        <Text fontWeight='thin' >{props.subtitle}</Text>
                    </VStack>
                </HStack>

              </VStack>
        </VStack>
    </Pressable>
    )
}