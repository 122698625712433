import { AntDesign } from "@expo/vector-icons";
import DisplayTimeLinear from "components/DisplayTimeLinear";
import { CloseIcon, HStack, Icon, Spacer, Text } from "native-base";

export default ({ s_a, onRemoveLine, onEdit }) => (
    <HStack 
        // key={index} 
        borderLeftWidth={1} 
        borderRadius={0} 
        alignContent='center' 
        space='1'
        >
        <HStack pl='2'>
        <Icon
            as={AntDesign} 
            
            name='delete'
            
            alignSelf={'center'}
            
            size='sm'
            
            color='red.500'
            
            _pressed={{
                bg: 'red.500',
                color: 'white'
            }}

            onPress={onRemoveLine}
        />
        </HStack>
        <HStack space='0.5'  alignContent={'center'} >
        <DisplayTimeLinear fontSize={10} value={s_a.start_at} />
        <Text>-</Text>
        <DisplayTimeLinear fontSize={10} value={s_a.end_at} />
        </HStack>
        {/* <Spacer /> */}
        <Text fontSize={14} onPress={onEdit} >{s_a.sub.name}</Text>
        <Spacer />

        </HStack>
)