import Annonce from "components/Annonce";
import PageLoading from "components/PageLoading";
import ScreenWrapper from "components/ScreenWrapper";
import UserWithoutContract from "components/UserWithoutContract";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Badge,
    useToast
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { getMyContractTeacher } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import { getToken } from "store/login/selectors";

export default ({ navigation }) => {
    const redGrad = {
        linearGradient: {
            colors: [
                'red.500',
                'orange.700',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const toast = useToast()
    
    const [contract, setContract] = useState(null)
    const [state, setState] = useState({
        isLoaded: false,
        error: null
    })
    
    const sort_by_children = () => {
        const dict = {}
        if(contract) contract.subjects_affected.forEach((s_a) => {
            if (!dict[s_a.child_id]) dict[s_a.child_id] = {
                child_infos: s_a.child,
                subjects_affected: []
            }
            if(dict[s_a.child_id].subjects_affected.findIndex( s => s.sub_id === s_a.sub_id)  === -1) {
                dict[s_a.child_id].subjects_affected.push(s_a)
            }
        });
        return dict
    }
    const sorted_dict_by_children = sort_by_children()
    
    useEffect(()=> {
        getMyContractTeacher(token)
            .then(res => {
                setContract(prev => {
                    setState( prev => ({
                        ...prev,
                        isLoaded: true,
                        isContractvalid: contract ? new Date(contract.end_at) >= new Date() : true
                    }))
                    return res.data
                })
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}) )

    }, [setState, setContract ])
  return (
    <ScreenWrapper title="Mon contrat" nav={navigation}>
        <ScrollView>
            <VStack px="2" pb="5" space={"4"} > 
                <Box my='4' >
                    {
                        !state.isLoaded ? (
                            <Center  >
                                <HStack space={2} justifyContent="center">
                                <PageLoading />
                            </HStack>
                            </Center>
                        ) : !contract ? <UserWithoutContract /> : (
                            <VStack space='5'>
                                <Box minHeight={100}  bg={'primary.500'} p='3' width="100%" flex={1} borderRadius={10} >
                                    <Heading color="orange.500">Mon contrat</Heading>
                                    <Text color="white">Salaire : {contract.price} </Text>
                                    <Text color="white">Signé le : {contract.signed_at} </Text>
                                    <Text color="white">Valable du {contract.start_at} au {contract.end_at} </Text>
                                </Box>
                                
                                {
                                    !state.isContractvalid && (
                                        <Box  bg={redGrad} p='3'  flex={1} borderRadius={10} >
                                            <Heading color='white' size='lg'>Votre contrat a expiré !</Heading> 
                                        </Box>
                                    )
                                }
                                <Box minHeight={100}  p='3'  flex={1} borderRadius={10} >
                                    <Heading color="orange.500">Les enfants à encadrer</Heading>
                                    <VStack>
                                        {
                                            Object.keys(sorted_dict_by_children).length == 0 ? <Heading color='white' size='sm'>Vous n'encadrez aucun enfant !</Heading> : Object.keys(sorted_dict_by_children).map(
                                                child_key => (
                                                    <VStack key={child_key} bg="white" space='1' shadow={5} borderRadius={10} my='3' p='2' >
                                                        <Heading color='black' size='xs'>{sorted_dict_by_children[child_key].child_infos.full_name}</Heading>  
                                                        <Heading color='black' size='xs'>Classe: {sorted_dict_by_children[child_key].child_infos.child_level}</Heading>  
                                                        <Heading color='black' size='xs'>Ecole: {sorted_dict_by_children[child_key].child_infos.school}</Heading>  
                                                        <Heading color='black' size='xs'>Lieu des cours: {sorted_dict_by_children[child_key].child_infos.place_courses}</Heading>  
                                                        <HStack>
                                                            <Heading color='black' size='xs'>Matières enseignées: {Object.values(sorted_dict_by_children[child_key].subjects_affected).length}</Heading>
                                                        </HStack>
                                                        <ScrollView horizontal>
                                                            <HStack>
                                                                {
                                                                    Object.values(sorted_dict_by_children[child_key].subjects_affected).map(
                                                                        s_a => (
                                                                            <Text key={s_a.id} py='2' px='4' mx='1' color='white' bg='primary.500' borderRadius={20} >{s_a.sub.name}</Text>  
                                                                        )
                                                                    )
                                                                } 
                                                            </HStack>
                                                        </ScrollView>
                                                    </VStack>
                                                ) 
                                            )
                                        }
                                    </VStack>
                                </Box>
                            </VStack>
                        )
                    }
                    
                </Box>
        </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}