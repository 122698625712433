import { useState } from "react"
import { SwipeRow } from "react-native-swipe-list-view"
import { apiErrorNotify } from "services/notify"
import ButtonValidateInvoice from "../ButtonValidateInvoice"
import DisplayInvoiceDetails from "../DisplayInvoiceDetails"
import { MENU_NAVIGATION, INVOICE_STATUS } from "constant";
import { Box } from "native-base"

export default  ({invoice, handleNotify, handleEdit, handleSubmit, toast, _wrapper}) => {
    const [isSubmiting, setSubmitting] = useState(false)
    const [invoiceStatus, setStatus] = useState(invoice.status)
    const handlePress = () => {
        setSubmitting(true)
        handleSubmit(invoice.id)
        .then(res => {
            setStatus(res.data.status) 
            setSubmitting(false)
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    const handlePressEdit = () => handleEdit(invoice.id)
    const handleAlert = () => handleNotify(invoice.id)

    const isPending = invoiceStatus == INVOICE_STATUS.pending
    return (
        <Box {..._wrapper} >
        {
            // isPending ? (
                <SwipeRow rightOpenValue={-200} >
                    <ButtonValidateInvoice 
                        invoice_id={invoice.id} 
                        status={invoiceStatus} 
                        setStatus={setStatus} 
                        handleAlert={handleAlert}
                        handleUpdate={handlePressEdit} 
                        handleSubmit={handleSubmit} 
                        toast={toast}  />
                    <DisplayInvoiceDetails invoice={invoice} pending={!isPending} />
                </SwipeRow>
            // ) : <DisplayInvoiceDetails invoice={invoice} pending={true} />
        }
        </Box>
    )
}
