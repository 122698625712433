import { Button, FormControl, Heading, HStack, Input, Modal, Spacer } from "native-base"
import { useState } from "react"

export default ({ onSubmit, onClose}) => {
    const [city, setCity] = useState("")
    const [isLoading, setLoading] = useState(false)
    
    return (
        <Modal isOpen={true} collapsable={true} >
            <Modal.Content w='90%' bg='white'  >
                {/* <Modal.Header bg='primary.500' ><Heading color='white' >Ajouter</Heading></Modal.Header> */}
                <Modal.Body>
                    <FormControl isRequired >
                        <FormControl.Label>Nom</FormControl.Label>
                        <Input value={city} onChangeText={setCity}  /> 
                    </FormControl>
                </Modal.Body>
                <Modal.Footer bg='primary.500' >
                    <HStack w='100%' justifyContent={'space-between'} >
                        <Button _text={{color: 'white'}}  onPress={onClose} >Annuler</Button>
                        <Button 
                            bg='white'
                            _text={{color: 'primary.500'}}
                            isLoading={isLoading}
                            isDisabled={!city}
                            onPress={() => {
                                setLoading(true)
                                setTimeout(()=> onSubmit({name: city}), 1000)
                            }}>Ajouter cette matière</Button>
                    </HStack>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    )
}