import { createNativeStackNavigator } from '@react-navigation/native-stack';
import SettingsScreen from 'screens/SettingsScreen';
import DetailsScreen from 'screens/DetailsScreen';
import AppBar from "components/AppBar";
import { MENU_NAVIGATION } from 'constant';
import ChangePassword from 'screens/ChangePassword';
import ShowMyProfile from 'screens/ShowMyProfile';
import EditMyProfile from 'screens/EditMyProfile';
import DisplayPDFScreen from 'screens/DisplayPDFScreen';
import AgreementScreen from 'screens/AgreementScreen';

const SettingsStack = createNativeStackNavigator();

export default function SettingsStackNavigaor() {
  return (
    <SettingsStack.Navigator screenOptions={{ 
      headerShown: false, 
      // header: (navigationProps) => <AppBar {...navigationProps} /> 
    }}>
      <SettingsStack.Screen name={MENU_NAVIGATION.Settings.Main.name} component={SettingsScreen} />
      <SettingsStack.Screen name={MENU_NAVIGATION.Settings.ChangePassword.name} component={ChangePassword} />
      <SettingsStack.Screen name={MENU_NAVIGATION.Settings.ShowMyProfile.name} component={ShowMyProfile} />
      <SettingsStack.Screen name={MENU_NAVIGATION.Settings.EditMyProfile.name} component={EditMyProfile} />
    
      <SettingsStack.Screen name={MENU_NAVIGATION.Common.DisplayPDF.name} component={DisplayPDFScreen} /> 
      <SettingsStack.Screen name={MENU_NAVIGATION.Common.Agreement.name} component={AgreementScreen} /> 
    
    </SettingsStack.Navigator>
  );
}