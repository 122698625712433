import Annonce from "components/Annonce";
import PageLoading from "components/PageLoading";
import ScreenWrapper from "components/ScreenWrapper";
import UserWithoutContract from "components/UserWithoutContract";
import { INVOICE_STATUS } from "constant";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider,
    useToast
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { getMyContractParent } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import { format_price, humanize_natural_date } from "services/utils";
import { getToken } from "store/login/selectors";

const InvoiceItem = ({invoice}) => {
    return (
        <VStack bg='gray.200' shadow={5} borderTopLeftRadius={10}  borderTopRadius={10} p='2' >
            <HStack justifyContent={'space-between'} w='100%'>
                <Text fontSize={14}>Du {humanize_natural_date(new Date(invoice.start_at))}</Text>
                <Text fontSize={14}> au </Text>
                <Text fontSize={14}>{humanize_natural_date(new Date(invoice.end_at))}</Text>
            </HStack>
            <HStack space='5'>
                <Text fontSize={14} alignSelf='center' >Montant : </Text>
                <Heading size='lg' color='green.600' >{format_price(invoice.price)}F</Heading>    
            </HStack>
            <HStack justifyContent={'flex-end'} > 
                {/* <Center> */}
                    <Heading size='xs' color='red.500'>Deadline {invoice.deadline}</Heading>    
                {/* </Center> */}
            </HStack>
        </VStack>
    )
}

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const redGrad = {
        linearGradient: {
            colors: [
                'red.500',
                'orange.500',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const toast = useToast()

    const [state, setState] = useState({
        contract: null,
        isLoaded: false,
        error: false,
        lates_invoices: [],
        achieved_invoices: [],
        to_pay_invoices: [],
        current_invoice: null
    })
    useEffect(()=> {
        getMyContractParent(token)
            .then(res => {
                const inv_late = []
                const inv_done = []
                const inv_to_pay = []
                let current_invoice = null
                if(res.data) {
                    res.data.invoices.forEach(inv => {
                        if(inv.status === INVOICE_STATUS.pending ) {
                            if(new Date(inv.start_at) < new Date() && new Date() < new Date(inv.end_at)) {
                                current_invoice = inv
                            } else if (new Date(inv.deadline) > new Date() ) inv_late.push(inv)
                            else inv_to_pay.push(inv)
                            
                        } else if(inv.status === INVOICE_STATUS.achieved) {
                            inv_done.push(inv)
                        }
                    })
                    setState(prev => ({
                        ...prev, 
                        contract: res.data, 
                        current_invoice: current_invoice,
                        lates_invoices: inv_late,
                        achieved_invoices: inv_done,
                        to_pay_invoices: inv_to_pay,
                        isLoaded: true
                    }))
                } else {
                    
                }
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
            .finally(() => setState(prev => ({
                ...prev, 
                isLoaded: true
            })))

    }, [setState ])
  return (
    <ScreenWrapper nav={navigation} title="Mes factures"  >
        <ScrollView>
            <VStack px="2" py="5" > 
                <VStack space={4}>
                    {
                        !state.isLoaded ? (
                            <PageLoading />
                        ) : !state.contract ? <UserWithoutContract /> : (
                            <>
                                <Box minHeight={70} mx='2' borderRadius={10} >
                                    <Box bg='primary.500' mx='6' mb='2' p='3' borderRadius={10} >
                                        <Heading color="white">Ma facture du mois</Heading>
                                    </Box>
                                    {
                                        state.current_invoice ? (
                                            state.current_invoice.status === INVOICE_STATUS.achieved ? (
                                                <Heading size='md'>Déjà réglée</Heading>
                                            ): (
                                                <InvoiceItem invoice={state.current_invoice} />
                                            )
                                        ): (
                                            <Center>
                                                <Text>Aucune facture</Text>
                                            </Center>
                                        )
                                    }
                                </Box>
                                
                                <Box  minHeight={70} mx='2' borderRadius={10} >
                                    <Box   bg={'red.600'} p='3' mx='6'  flex={1} borderRadius={10} >
                                        <Heading color="white">Retards de paiement</Heading>
                                    </Box>
                                    
                                    {
                                        state.to_pay_invoices.length === 0 ? (
                                            <Center>
                                                <Text>Aucun retard</Text>
                                            </Center>
                                        ) : (
                                                <VStack space='2' mt='2' >
                                                    {
                                                        state.to_pay_invoices.map(
                                                            inv => (
                                                                <VStack key={inv.id}>
                                                                    <InvoiceItem invoice={inv} />
                                                                    <Divider />
                                                                </VStack>
                                                            )
                                                        )
                                                    }

                                                </VStack>
                                        )
                                    }
                                </Box>
                                
                                <Box  minHeight={70} mx='2' borderRadius={10} >
                                    <Box   bg={redGrad} p='3' mx='6'  flex={1} borderRadius={10} >
                                        <Heading color="white">Non réglées</Heading>
                                    </Box>
                                    
                                    {
                                        state.lates_invoices.length === 0 ? (
                                            <Center>
                                                <Text>Vous êtes à jour</Text>
                                            </Center>
                                        ) : (
                                                <VStack space='2' mt='2' >
                                                    {
                                                        state.lates_invoices.map(
                                                            inv => (
                                                                <VStack key={inv.id}>
                                                                    <InvoiceItem invoice={inv} />
                                                                    <Divider />
                                                                </VStack>
                                                            )
                                                        )
                                                    }

                                                </VStack>
                                        )
                                    }
                                </Box>
                            
                                <Box  minHeight={70} mx='2' borderRadius={10} >
                                    <Box   bg={'green.600'} p='3' mx='6'  flex={1} borderRadius={10} >
                                        <Heading color="white">Factures déjà réglées</Heading>
                                    </Box>
                                    
                                    {
                                        state.achieved_invoices.length === 0 ? (
                                            <Center>
                                                <Text>Aucune fature réglée</Text>
                                            </Center>
                                        ) : (
                                                <VStack space='2' mt='2' >
                                                    {
                                                        state.achieved_invoices.map(
                                                            inv => (
                                                                <VStack key={inv.id}>
                                                                    <InvoiceItem invoice={inv} />
                                                                    <Divider />
                                                                </VStack>
                                                            )
                                                        )
                                                    }

                                                </VStack>
                                        )
                                    }
                                </Box>
                            
                            
                            </>
                        )
                    }
                    
                </VStack>
        </VStack>
            </ScrollView>
    </ScreenWrapper>
  );
}