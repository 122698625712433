import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    TextArea
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { createInvoiceParent, createMsgMulti, listContractsParents, listUsers } from "services/api-axios";
import { useState } from "react";
import { useEffect, Component } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import MultiSelect from 'react-native-multiple-select';
import ScreenWrapper from "components/ScreenWrapper";

const DisapearingAlert=({time = 3000, message='Success', update})=>{
  setTimeout(update, time);
  return (
    <Actionsheet isOpen={true} collapsable={false} >
      <Actionsheet.Content bg="white" height={get_screen_height()} >
        <Center>
          <Heading color="red.500" > {message} </Heading>
        </Center>
      </Actionsheet.Content>
    </Actionsheet>  
  )
 
}


export default ({ navigation }) => {
    const token = getToken()

    const [state, setState] = useState({
      users: [],
      isSubmitting: false,
      done: false,
      error: false
    })
    const [msgReceivers, setMsgReceivers] = useState([])
    const [msgTitle, setMsgTitle] = useState([])
    const [msgDescription, setMsgDescription] = useState([])
    const {
      isOpen,
      onOpen,
      onClose
    } = useDisclose()

  
    useEffect(() => {
      listUsers(token)
      .then(res => setState( prev => ({...prev, users: res.data})) )
      .catch(err => setState(prev=> ({...prev, error: err})))
    }, [setState])
    return (
    <ScreenWrapper title="Communiniqué privé" nav={navigation}>
      <ScrollView>
        {
          state.done ? <Center><Heading>Opération effectuée !</Heading></Center> : state.error ? <DisapearingAlert message={state.error && "Une erreur s'est produite" } update={() => setState(prev => ({...prev, error: false}))} /> : (

            <VStack space={3} px="3" pt="10" mb='3' >
                <FormControl isRequired>
                  <FormControl.Label>Sélectionnez le compte destinataire</FormControl.Label>
                  <MultiSelect
                    hideTags
                    items={state.users}
                    uniqueKey="id"
                    displayKey="full_name"
                    // ref={(component) => { this.multiSelect = component }}
                    onSelectedItemsChange={setMsgReceivers}
                    selectedItems={msgReceivers}
                    selectText="Destinataires"
                    searchInputPlaceholderText="Recherche ..."
                    altFontFamily="ProximaNova-Light"
                    tagRemoveIconColor="#CCC"
                    tagBorderColor="#CCC"
                    
                    tagTextColor="#CCC"
                    selectedItemTextColor="blue"
                    selectedItemIconColor="#CCC"
                    itemTextColor="#000"

                    
                    searchInputStyle={{ color: '#CCC' }}
                    submitButtonColor="blue"
                    submitButtonText="Confirmer la sélection"
                    
                  />
                </FormControl>
                <ScrollView horizontal>
                  <HStack>
                    
                  </HStack>
                </ScrollView>
                <FormControl isRequired>
                  <FormControl.Label>Objet du message</FormControl.Label>
                    <Input 
                      value={msgTitle} 
                      placeholder="Entrez l'objet du message"
                      onChangeText={setMsgTitle} />
                </FormControl>
                
                <FormControl isRequired>
                  <FormControl.Label>Contenu du message</FormControl.Label>
                    <TextArea
                      totalLines={10}
                      color='black' 
                      value={msgDescription} 
                      placeholder="Entrez le contenu message"
                      onChangeText={setMsgDescription} />
                </FormControl>

                  {console.log(msgReceivers, msgTitle, msgDescription)}

                    <Button 
                      isDisabled={msgReceivers.length === 0 || msgTitle === '' || msgDescription === ''}
                      onPress={() => {
                        const data = {
                          title: msgTitle,
                          description: msgDescription,
                          receiver_ids: msgReceivers
                        }
                        console.log(data)
                        createMsgMulti(token, data)
                        .then( res => setState( prev => ({...prev, done: true}) )  )
                        .catch( err => {
                          console.log("Got err ", err, err.message, err.response.data.detail)
                          setState( prev => ({...prev, error: err.response.data.details}) )
                        })
                      }}
                    >Enregistrer</Button>
              
            </VStack>
          )
        }
      </ScrollView>
    </ScreenWrapper>
  );
}