import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import Annonce from "components/Annonce";
import Chip from "components/Annonce/Chip";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Avatar,
    Divider,
    Spacer,
    Icon,
    TextArea
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { getFileLink, getMeUser, getMyContractParent } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { useGetLoginState } from "store/login/hooks";
import { getIsTeacher, getLoginState, getToken, getUserAvatar } from "store/login/selectors";

const display_user_role = (user) => {
    if (user.is_superuser) return "Direction centrale"
    if (user.is_manager) return `Membre de la direction`
    if (user.is_parent) return `Parent`
    if (user.is_teacher) return `Enseignant`
    return `Inconnu`
}

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const avatar_path = getUserAvatar()
    const is_teacher = getIsTeacher()
    // const loginState = useGetLoginState()
    const [me, setMe] = useState(getLoginState())
    console.log('render')
    useEffect(() => {
        getMeUser(token).then(
            res => setMe(res.data)
        )
    }, [setMe])

    // useFocusEffect(() => {
    //     console.log("useFocusEffect")
    //     setTimeout(
    //       () => {
    //         console.log("Before")
    //         setMe(p => p)
    //         console.log("After")
    //       }  , 3000
    //     )
    //     // setMe(loginState())
    //     // getMeUser(token).then(
    //     //     res => setMe(res.data)
    //     // )
    // })
    
  return (
    <ScreenWrapper title={"Voir mon profil"} nav={navigation}>
        <ScrollView>
            <VStack px="4" py="5" space={"4"} > 
                <HStack>
                    <Spacer />
                    {/* <Button rounded={'lg'} onPress={()=> navigation.navigate(MENU_NAVIGATION.Settings.EditMyProfile.name)} > Modifier</Button> */}
                </HStack>
                <Center>
                    {/* <VStack> */}
                        <Center >
                            { avatar_path ? <>
                                <Avatar bg="white" size={100} source={{
                                uri: getFileLink(avatar_path)
                            }} />
                            </>
                            : <Center p='1' bg='primary.500' rounded={'full'} ><Icon name='user' as={AntDesign} color='white' size={100} /></Center>
                        }
                        </Center>
                        <Heading size='lg' color='orange.500' >{me.full_name || 'Sans nom' }</Heading>
                        <VStack justifyContent={'center'} >
                            <VStack maxW={'5/6'} space='2'>
                                <HStack space='4' alignSelf={'center'} >
                                    {/* <Icon size='lg' name='phone' as={FontAwesome} /> */}
                                    <Heading alignSelf={'center'} size='sm' color='primary.500' >{me.username}</Heading>
                                </HStack>
                                <HStack space='4' alignSelf={'center'}>
                                    {/* <Icon size='lg' name='user' as={FontAwesome} /> */}
                                    {/* <Chip   px='3' py='2' > */}
                                        <Heading alignSelf={'center'} size='sm' color='primary.500' >{display_user_role(me)}</Heading>
                                    {/* </Chip> */}
                                </HStack>
                                <HStack space='4'>
                                    <Icon size='lg' name='home' as={FontAwesome} />
                                    <ScrollView horizontal  >
                                        <HStack space='1' >
                                            {
                                                me.cities?.map(c => <Chip py='1' color='white' >{c.name}</Chip>)
                                                // me.cities.map(c => <Heading  color='primary.500'  >{c.name}</Heading>)
                                            }
                                        </HStack>
                                    </ScrollView>
                                </HStack>
                                
                            </VStack>
                            {/* <Text fontWeight={'bold'} size='sm'>{me.username}</Text> */}
                    </VStack>

                    {/* </VStack> */}
                </Center>    

                {/* <Center>
                    <HStack>
                        <Chip  bg='orange.500' px='3' py='2' >
                            <Heading alignSelf={'center'} size='sm' color='white' >{display_user_role(me)}</Heading>
                        </Chip>
                    </HStack>
                </Center> */}
                {
                    is_teacher && (
                        <VStack mt='5' space='3' >
                            <VStack>
                                <Heading size='md'>Profession</Heading>
                                <Text>
                                {(me.teacher_metadata || {}).job}
                                </Text>
                            </VStack>

                            <VStack>
                                <Heading size='md'>Niveau universitaire</Heading>
                                <Text>
                                {(me.teacher_metadata || {}).level}
                                </Text>
                            </VStack>

                            <VStack>
                                <Heading size='md'>Zone de prestation souhaitée</Heading>
                                <Text>
                                {(me.teacher_metadata || {}).disired_areas}
                                </Text>
                            </VStack>

                            <VStack>
                                <Heading size='md'>À propos de moi</Heading>
                                <Text>
                                {(me.teacher_metadata || {}).about}
                                </Text>
                            </VStack>
                        </VStack>
                    )
                }
                    
                <VStack>
                
                {/* <Divider />
                <HStack h={10} >
                    {
                        me.cities.map(c => <Chip py='1' bg='primary.500' color='white' >{c.name}</Chip>)
                    }
                </HStack> */}
                
                </VStack>
            </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}