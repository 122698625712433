export function from_day_index_to_day_name(index) {
    if(index == 0) return "Lundi"
    if(index == 1) return "Mardi"
    if(index == 2) return "Mercredi"
    if(index == 3) return "Jeudi"
    if(index == 4) return "Vendredi"
    if(index == 5) return "Samedi"
    if(index == 6) return "Dimanche"
}

export function from_month_index_to_name(index) {
    if(index == 0) return "Janvier"
    if(index == 1) return "Février"
    if(index == 2) return "Mars"
    if(index == 3) return "Avril"
    if(index == 4) return "Mai"
    if(index == 5) return "Juin"
    if(index == 6) return "Juillet"
    if(index == 7) return "Août"
    if(index == 8) return "Septembre"
    if(index == 9) return "Octobre"
    if(index == 10) return "Novembre"
    if(index == 11) return "Décembre"
    return "Inconnu"
}

export function humanize_natural_date(date, separator= ' ') {
    return `${date.getDate()}${separator}${from_month_index_to_name(date.getMonth())}${separator}${date.getFullYear()}`
}

export function humanize_date(date, separator= '-') {
    return `${date.getDate()}${separator}${date.getMonth()}${separator}${date.getFullYear()}`
}

export function humanize_date_YMD(date) {
    const D = date.getDate()
    const M = date.getMonth()+1
    const Y = date.getFullYear()
    return `${Y}-${M.toString().length == 1 ? '0'+M : M}-${D.toString().length == 1 ? '0'+D : D }`
}

export function humanize_time(date, separator= ':') {
    const h = date.getHours()
    const m = date.getMinutes()
    return `${h < 9 ? '0'+h : h}${separator}${m < 9 ? '0'+m : m}`
}

export const delay = (timeout) => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  }

export const format_time = (raw= '', old_separator= 'h', new_separator = ':') => {
    let lower = raw.toLowerCase()
    let [hour, min, sec] = lower.split(old_separator)
    return hour.trim() + new_separator +( min.trim() || '00')
}

export const split_time = (raw= '', separator= ':') => {
    let parts = raw.toLocaleString().split(separator)
    parts = parts.map(
        p => `${p.length == 1 ? '0' : ''}${p}`
    )
    console.log(parts)
    return parts.slice(0,2)
}

export const format_natural_time = (raw= '', separator=':') => {
    const [h, m] = split_time(raw)
    return h+separator+m
}

export const format_time_reactive = (raw= '', separator= 'h') => {
    console.log("raw ", typeof raw, raw)
    let lower = raw.toLowerCase()
    if( lower.length == 2) {
        if (lower[1] == 'h' || lower[1] == ':') {
            return '0' + lower[0] + 'h'
        }
        return raw + 'h'
    }
    else if(lower.length == 4) {
        let [_h, _m] = raw.split(separator)
        return _h.trim() + 'h' + _m.trim()
    } 
    else {
        return lower.slice(0, 5)
    }
}

export const  format_price = (x, separator= '.') => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export const display_user_role = (user) => {
    if (user.is_superuser) return "Direction centrale"
    if (user.is_manager) return `Membre de la direction`
    if (user.is_parent) return `Parent`
    if (user.is_teacher) return `Enseignant`
    return `Inconnu`
}

export const range = (start, end) => {
    if(start>=end) return []
    return Array(end-start).fill(0).reduce((t, c, i)=> t.concat(i), [])
}