import { FormControl, Input } from "native-base"
import { useRef } from "react"
import { useState } from "react"

// const format = (value) => {
//     is
// }

export default ({value, onChangeText, checkPrefix = true, prefixReg= "^0[157]+", cleanWhenInvalid= false, ...props}) => {
    const [state, setState] = useState(value)
    const [err, setError] = useState('')
    const ref = useRef()
    
    const handleChange = (val= '') => {
        setError("")
        const length = val.length
        // if(length == 1 && val != '0')setError("Le préfixe du numéro est incorrecte")
        // if (length > 1 && checkPrefix && !val.match(prefixReg) ){
        //     // if(cleanWhenInvalid !== true) onChangeText("")
        //     console.log(cleanWhenInvalid)
        //     setError("Le préfixe du numéro est incorrecte")
        //     return
        // }
        for (let i = 0; i < length; i++) {
            val = val.replace('-','')
            val = val.replace(' ','')
            val = val.replace('.','')
            val = val.replace(',','')
        }
        // const parsed = parseInt(val)
        // console.log(parsed)
        // if(!isNaN(parsed)) {
        //     console.log("ok", parsed, typeof parsed, val )
            
        // } else {
        //     setError("Ceci n'est pas un nombre")
        // }
        onChangeText(val)
    
    }
    return (
        <Input  
            ref={ref}
            keyboardType="number-pad" 
            value={value} 
            {...props}  
            onChangeText={handleChange}
            />
        
    )
}