import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Spacer,
    FlatList,
    Fab,
    Icon,
    IconButton,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { createCity, listCities, updateCity } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import ContactOptionsInline from "components/ContactOptionsInline";
import Chip from "components/Annonce/Chip";
import { display_user_role } from "services/utils";
import { AntDesign } from "@expo/vector-icons";
import ScreenWrapper from "components/ScreenWrapper";
import AddCity from "./components/AddCity";
import { apiErrorNotify } from "services/notify";
import PageLoading from "components/PageLoading";
import { SwipeRow } from "react-native-swipe-list-view";
import EditCity from "./components/EditCity";
import ConfirmationDialog from "components/ConfirmationDialog";


export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        cities: [],
        isLoaded: false,
        isAproving: false,
        display_add: false,
        display_delete: null,
        selected_city: null,
    })
    const refresh = () => {
        listCities(token).then(
            res => {
                // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                setState(prev => ({...prev, cities: res.data, isLoaded: true }))
            }
        )
        .catch(err => console.log(err))
    }

    const toggleAddCity = () => setState(prev => ({...prev, display_add: !prev.display_add }))
    const toggleEditCity = (init) => setState(prev => ({...prev, selected_city: init }))
    const toggleDeleteCity = (init) => setState(prev => ({...prev, display_delete: init }))


    const handleCreateCity = (values) => {
        createCity(token, values)
        .then(response => {
            setState(prev => ({
                ...prev, 
                display_add: false,
                cities: prev.cities.concat([response.data])
            }))
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    const handleEditCity = (values) => {
        updateCity(token, state.selected_city.id, values)
        .then(response => {
            setState(prev => ({
                ...prev, 
                selected_city: null,
                cities: [response.data].concat(prev.cities.filter(c => c.id != prev.selected_city.id))
            }))
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    const handleDeleteCity = (city) => {
        return new Promise((resolve, reject) => {
            updateCity(token, state.display_delete.id, {is_active: false})
                .then(response => {
                    setState(prev => ({
                        ...prev, 
                        display_delete: null,
                        cities: [response.data].concat(prev.cities.filter(c => c.id != prev.display_delete.id))
                    }))
                })
                .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        }) 
    }

    const handleActivateCity = (city) => {
        return new Promise((resolve, reject) => {
            updateCity(token, city.id, {is_active: true})
                .then(response => {
                    setState(prev => ({
                        ...prev, 
                        display_delete: null,
                        cities: [response.data].concat(prev.cities.filter(c => c.id != city.id))
                    }))
                })
                .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        }) 
    }
    
    useEffect(() => {
        refresh()
    }, [setState])

    console.log("selected_city_to_delete ", state.display_delete)
    return (
    <ScreenWrapper title="Liste des villes de AS-TRAINING" nav={navigation}>
        <ScrollView>
            <VStack space={3} >
                {
                    state.display_add && <AddCity onSubmit={handleCreateCity} onClose={toggleAddCity} />
                }
                
                {
                    state.selected_city && <EditCity initialName={state.selected_city.name} onSubmit={handleEditCity} onClose={() => toggleEditCity(null)} />
                }

                {
                    state.display_delete && <ConfirmationDialog isOpen={true} onCancel={()=> toggleDeleteCity(null)} onAgree={handleDeleteCity} msg={`Voulez-vous réellement désactiver la ville '${state.display_delete.name}' ? `} />
                }
                
                {
                    ! state.isLoaded && (
                        <PageLoading />
                    )
                }
                
                <IconButton onPress={toggleAddCity} bg='primary.500' ml='3/4' rounded={'full'} w={10} icon={<Icon color="white" as={<AntDesign name="plus" />} size={4} />}  />
                
                <VStack px='2' mt='5' space='3' >
                    {
                        state.isLoaded && state.cities.sort((a, b) => !a.is_active ).map( city => (
                            <Box key={city.id} >
                                {/* {console.log(city)} */}
                                <SwipeRow leftOpenValue={100} rightOpenValue={-100} alignContent='center' >
                                    {/* <ButtonValidateInvoice invoice_id={invoice.id} status={invoiceStatus} setStatus={setStatus} handleSubmit={handleSubmit} toast={toast}  /> */}
                                    <HStack h='100%' alignContent={'center'} justifyContent={'space-between'} >
                                        {/* <Button startIcon={<Icon as={AntDesign} name='delete' />} p={1} bg='red.500' >Supprimer</Button> */}
                                        {
                                            !city.is_active ? 
                                                <IconButton 
                                                    bg='green.700' 
                                                    onPress={() => handleActivateCity(city)} 
                                                    icon={<Icon as={AntDesign} name='check' color='white' />} 
                                                    size='md' rounded={"full"} /> :
                                                <IconButton 
                                                    bg='red.500' 
                                                    onPress={() => toggleDeleteCity(city)} 
                                                    icon={<Icon as={AntDesign} name='delete' color='white' />} 
                                                    size='md' rounded={"full"} />
                                        }
                                        <IconButton onPress={() => toggleEditCity(city)} icon={<Icon as={AntDesign} name='edit' color='orange.500' />} size='md' rounded={"full"} />
                                    </HStack>
                                    <Center bg={ city.is_active ? 'primary.500' : 'red.500'} borderRadius='full' p='2' >
                                        <Heading size='lg' color='white' >{city.name}</Heading>
                                    </Center>
                                </SwipeRow>
                            </Box>
                        ))
                        
                    }
                </VStack>
            </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}