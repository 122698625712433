import {
    Text,
    Heading,
    VStack,
    useToast
  } from "native-base";
import { getToken } from "store/login/selectors";
import { getFileLink} from "services/api-axios";
import ScreenWrapper from "components/ScreenWrapper";
import * as React from 'react';
import { VideoPlayer } from "components/VideoPlayer";


export default ({ navigation, route }) => {
    const routeInitialValues = route.params ? route.params.init : {}
 
    return (
    <ScreenWrapper nav={navigation}>  
      <VStack space={5} >
          <VideoPlayer uri={getFileLink(routeInitialValues.media)} {...routeInitialValues} />
          <VStack px='3' space={1} >
            <Heading>{routeInitialValues.title}</Heading>
            <Text fontSize={20} >{routeInitialValues.subtitle}</Text>
            <Text fontWeight={'hairline'} >{routeInitialValues.description}</Text>
          </VStack>

      </VStack>
    </ScreenWrapper>
  );
}