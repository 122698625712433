import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { getFileLink, listApprovedParents, rejectUser } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import RejectionButton from "./components/RejectionButton";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";

export default ({ navigation }) => {
    const token = getToken()
    const [ state, setState ] = useState({
        parents: [],
        isLoaded: false,
        isAproving: false,
    })
    const refresh = () => {
        console.log("Calling refresh")
        listApprovedParents(token).then(
            res => {
                // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                setState(prev => ({...prev, parents: res.data, isLoaded: true }))
            }
        )
        .catch(err => console.log(err))
    }
    
    useEffect(() => {
        refresh()
    }, [setState])
    console.log(state.parents.length)
    return (
    <ScreenWrapper title="Retirer des compte parents" nav={navigation}> 
        <ScrollView>
            <VStack space={3} mx='2' >
                {
                    ! state.isLoaded && (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <Spinner />
                            <Heading color="primary.500" fontSize="md">
                                Chargement
                            </Heading>
                        </HStack>
                        </Center>
                    )
                }
                { state.isLoaded && state.parents.length == 0 && <NoData /> }
                {
                    state.isLoaded && state.parents.map( p => (
                    <HStack key={p.id} space={30}  onPress={() => console.log("Touch validate parent profile ")} >
                        
                        <Box maxW={100} height={100}  flex={1} >
                            { p.media_avatar ? 
                                <Image borderRadius={'full'} height={"100%"} alt="Une photo" source={{uri: getFileLink(p.media_avatar)}} />
                                :
                                <Box maxW={100} height={100} rounded='full' bg='gray.300' />
                            }
                        </Box>
                        <VStack pt={5} space='2' >
                            {/* <Center> */}
                                <Text fontWeight={'bold'}  > { p.full_name ? p.full_name : p.username } </Text>
                                <HStack space={'2'} >
                                    <RejectionButton callback={() => {
                                        return rejectUser(token, p.id).then( res => {
                                            setState( prev => ({...prev, isAproving: false}) )
                                            refresh()
                                        } )
                                    }
                                    } />
                                    <Button onPress={
                                            () => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ContactParent.name, { phone: p.username, is_whatsapp: true })
                                        } >Contacter</Button>
                                </HStack>

                            {/* </Center> */}
                        </VStack>
                    </HStack>
                    ))
                }
                </VStack>
            </ScrollView>
    </ScreenWrapper>
  );
}