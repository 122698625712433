import { Box, Button, CheckIcon, Divider, FormControl, Heading, HStack, Modal, Select, VStack, Text, Pressable, Spacer } from "native-base"
import { useCallback, useState } from "react"

const DisplayChildForSA = ({s_a, isChecked, onUpdate, num }) => {
    // const [checked, setCheck] = useState(initialValue)
    return (
      <Pressable onPress={isChecked ? () => onUpdate(s_a) : () =>  onUpdate(s_a) }>
        <HStack >
          <Heading size='xs'>{num}</Heading>
          
          <VStack pl='1' ml='1' borderLeftWidth={2} borderLeftColor={isChecked ? 'primary.500': 'rgba(0,0,0,0)' } >
            <Heading size='xs' >{s_a.child.full_name} </Heading>
            <Text>Classe: {s_a.child.child_level} </Text>
            <Text>Lieu des cours: {s_a.child.place_courses} </Text>
          </VStack>
          <Spacer />
          <Box position='absolute'  right='2' bg='white' borderWidth={1} rounded={'full'} >
            <CheckIcon  m='1' size='md' color={!isChecked ? 'white': 'primary.500' }  />
          </Box>
        </HStack>
      </Pressable>
    )
}

export default ({isOpen, onConfirm, onClose, teacher_obj, list_contract_parents, global_list_sas_selected_ids, enableErasePrevious= true}) => {
    const [state, setState] = useState({
      contract_parent_index: null,
      local_list_sas_selected_ids: [],
      isSubmiting: false
    })
    console.log("Sélected ", state.local_list_sas_selected_ids.length)
    const handleLocaleSAChange = (s_a) => {
      if(state.local_list_sas_selected_ids.findIndex(_ => _.id === s_a.id ) === -1) {
            const firstSAInList = state.local_list_sas_selected_ids[0]
            if(
              !firstSAInList || (firstSAInList && firstSAInList.contract_parent_id === list_contract_parents[state.contract_parent_index].id) || !enableErasePrevious
            ){
              setState(prev => ({
                ...prev,
                local_list_sas_selected_ids: state.local_list_sas_selected_ids.concat(s_a)
              })) 
  
            } else {
                setState(prev => ({
                  ...prev,
                  local_list_sas_selected_ids: [s_a]
                })) 
              }
            
        
      } else {
          setState(prev => ({
            ...prev,
            local_list_sas_selected_ids: state.local_list_sas_selected_ids.filter( s => s.id !== s_a.id)
          })) 
  
      }
    }
    
    const collect_sas_by_sub = useCallback(() => {
      let res = {}
      if(state.contract_parent_index !== null) {
        // console.log(state.contract_parent_index, Object.keys(list_contract_parents), list_contract_parents[state.contract_parent_index])
        list_contract_parents[state.contract_parent_index].subjects_affected.forEach( 
          (s_a, index) =>  {
            delete s_a.course_sessions
            if(s_a.contract_teacher_id == null){
              if(!res[s_a.sub_id]) res[s_a.sub_id] = []
              res[s_a.sub_id].push(s_a)
            }
          }
        )
      }
      return res
    }, [state.contract_parent_index]
    )
  
    const handleConfirmSelection = () => {
      setState(prev => ({...prev, isSubmiting: true}))
      onConfirm({
        contract_parent_index: state.contract_parent_index,
        selected_sas_list: state.local_list_sas_selected_ids
      })
      // onClose()
    }
    return (
      <Modal isOpen={isOpen} onClose={onClose} >
        <Modal.Content w='90%' >
          <Modal.Header>Affecter des matières à l'enseigant {teacher_obj.full_name} </Modal.Header>
          <Modal.Body bg ='white'>
            <FormControl >
              <FormControl.Label>Sélectionnez un parent sous contrat</FormControl.Label>         
                <Select 
                  selectedValue={state.contract_parent_index} 
                  onValueChange={val => setState(prev => ({...prev, contract_parent_index: val}))  }
                  color="black"  >
                  <Select.Item label="Sélectionnez un parent" />
                  {
                    list_contract_parents.map( (c, index) =>  <Select.Item label={c.parent.full_name || c.parent.username } value={index} /> )
                  }
                </Select> 
            </FormControl>
  
            <FormControl mt='4' >
              <FormControl.Label>Sélectionnez les matières à affecter</FormControl.Label>         
              {
                state.contract_parent_index !== null && (
                  <>
                    <VStack space='2' mb='3' >
                    {
                              Object.entries(collect_sas_by_sub()).map(
                                ([sub_id, list_sas], index) => {
                                  return (
                                    <Box bg='gray.200' p='2' borderRadius={10}  >
                                      <Heading size='md'>{list_sas[0].sub.name}</Heading>
                                      
                                          {
                                            list_sas.map(
                                              (s_a, c_index) => {
                                                const isChecked = state.local_list_sas_selected_ids.findIndex(_ => s_a.id === _.id ) > -1
                                                const isHidden = global_list_sas_selected_ids.some(g => g.id == s_a.id )
                                                
                                                
                                                return (
                                                  <>
                                                    {
                                                      !isHidden && (
                                                        <VStack my='1' space={2} >
                                                          <DisplayChildForSA 
                                                            isChecked={isChecked} 
                                                            // isChecked={state.global_list_sas_selected_ids.findIndex(_ => s_a.id === _.id ) > -1} 
                                                            s_a={s_a} 
                                                            num={c_index+1} 
                                                            onUpdate={handleLocaleSAChange}
                                                            />
                                                          <Divider />
                                                        </VStack>
                                                      )
                                                    }
                                                  </>
                                                )
                                              }
                                            )
                                          }
                                    </Box>
                                  )
                                }
                              
                              )
                      }
                          
                    </VStack>
                    <HStack>
                      <Button variant={'ghost'} bg='gray.200' onPress={onClose} >Annuler</Button>
                      <Spacer />
                      <Button isLoading={state.isSubmiting} onPress={handleConfirmSelection} >Confirmer la sélection</Button>
                    </HStack>
                  </>
                )
              }
            </FormControl>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    )
  }