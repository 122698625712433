import React from 'react';
import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    Checkbox,
    TextArea,
    useToast,
    Spacer,
    FlatList,
    ArrowForwardIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    Icon,
    IconButton
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { getMyContractParent, getMyContractTeacher, listContractParentChildren, listContractTeacherChildren, listPrestationsByParent, listPrestationsByTeacher, validateCourseSession, validatePrestation } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_natural_time, from_day_index_to_day_name, humanize_natural_date } from "services/utils";
import { COURSE_SESSION_STATUS } from "constant";
import { apiErrorNotify } from 'services/notify';
import MultiSelect from 'react-native-multiple-select';
import UserWithoutContract from 'components/UserWithoutContract';
import ScreenWrapper from 'components/ScreenWrapper';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const ItemPrestation = ({prest, child}) => {
    const [state, setState] = useState({
        is_open: false
    })
    const navigation = useNavigation()
    return (
        <Pressable onPress={() => setState(prev => ({...prev, is_open: !prev.is_open}))} >
            <VStack my='1' p='3' bg='blue.400' borderRadius={10} space={2}  >
                {/* <Text>{JSON.stringify(prest)}</Text> */}
                <VStack>
                    <HStack alignContent={'center'} space="5" justifyContent={'space-between'} >
                        <Box w='1/2' overflow={'hidden'} flexWrap={'wrap'} >
                            <Text>{humanize_natural_date(new Date(prest.date))}</Text>
                            {/* <Heading size='md' color='white' >{s_a.sub.name}</Heading> */}
                        </Box>
                        <HStack space='3' >
                            <Text>{format_natural_time(prest.start_at)}</Text>
                            {/* <Spacer /> */}
                            <Text>{format_natural_time(prest.end_at)}</Text>
                        </HStack>
                    </HStack>
                    <Heading size='xs' color='gray.200' >{prest.link_subjects_affected[0].subject_affected?.child.full_name}</Heading>
                                            

                </VStack>

                <VStack space="2"  pl="1" >
                    {
                        prest.link_subjects_affected.map(
                            link => {
                                let s_a = link.subject_affected
                                console.log("My link ", s_a)
                                return (
                                    <VStack justifyContent={'space-between'} >
                                        <HStack>
                                            <Heading color="white" > * </Heading> 
                                            <Heading color="white" underline >{s_a?.sub.name}</Heading> 
                                        </HStack>
                                       {
                                        state.is_open && prest.details[s_a.id] && typeof prest.details[s_a.id] === 'object' && (
                                            <VStack space={4} pl='3' >
                                                {
                                                    Object.keys(prest.details[s_a.id]).map(
                                                        detailKey => (
                                                            <VStack>
                                                                <Text color={'white'}>{detailKey}: </Text>
                                                                <Text pl='2'>{prest.details[s_a.id][detailKey]}</Text>
                                                            </VStack>
                                                        )
                                                    )
                                                }
                                                
                                            </VStack>
                                        )
                                       }
                                    </VStack>
                                )
                            }
                        )
                    }

                </VStack>
            
                {/* { state.is_open && (
                    <VStack>
                        <Text fontWeight={"hairline"} underline >Observations</Text>
                        <Text color="gray.300" fontWeight="thin" > {prest.details?.details || "Aucune observation" } </Text>
                    </VStack>
                ) } */}

                <HStack justifyContent={"space-between"} >
                
                    <IconButton
                        icon={<Icon name="edit" as={AntDesign}  />}
                        bg='gray.200'
                        size="sm"
                        borderRadius="50"
                        onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.CreatePrestation.name, {init: prest}) }
                    />
                    <Spacer />
                    <IconButton
                        icon={<Icon name="book" as={AntDesign}  />}
                        bg='gray.200'
                        size="sm"
                        borderRadius="50"
                        onPress={() => setState(prev => ({
                            ...prev,
                            is_open: !prev.is_open
                        })) }
                    />
                </HStack>
                
            </VStack>
        </Pressable>
    )
}

export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        contract_id: null,
        subjects_affected: [],
        prestations: [],
        prestations_by_child: {},
        children: [],
        selected_children_ids: [],
        isLoaded: false,
        isSubmiting: false,
        prestation_status: 'pending'
    })

    const refresh = (prevent_update_selected_child_id = false) => {
        getMyContractTeacher(token)
        .then( res_contract =>  {
            if(res_contract.data){
                listPrestationsByTeacher(token)
                .then(res_prest => {
                    listContractTeacherChildren(token, res_contract.data.id)
                    .then( res_children => {

                        /*
                            prestations_by_child = {
                                [child_id]: prestation_object
                            }
                        */
                        let prestations_by_child = {}
                        
                        // Initializing prestations_by_child
                        res_children.data.forEach(
                            child => {
                                prestations_by_child[child.id] = []
                            }
                        )

                        // Populating prestations_by_child
                        res_prest.data.forEach(
                            p => {
                                p.link_subjects_affected.forEach(
                                    link => {
                                        if(!prestations_by_child[link.subject_affected.child_id].find(_p => _p.id == p.id))prestations_by_child[link.subject_affected.child_id].push(p)
                                    }
                                )
                            }
                        )
                        console.log("Refreshed")
                        setState(prev => ({
                            ...prev, 
                            contract_id: res_contract.data.id,
                            children: res_children.data,
                            selected_children_ids: res_children.data.map( _ => _.id),
                            prestations: res_prest.data,
                            prestations_by_child: prestations_by_child,
                            isLoaded: true
                        }))
                    })
                    .catch( err => apiErrorNotify({response: err.response, toast: toast}) )
                })
                .catch( err => apiErrorNotify({response: err.response, toast: toast}) )
            } else {
                setState(prev => ({...prev, isLoaded: true}))
            }
        })
        .catch(err => {
            apiErrorNotify({response: err.response, toast: toast})
            setState(prev => ({
                ...prev, 
                isLoaded: true
            }))
        } )
    }

    const handleValidate = (prest_id) => {
        validatePrestation(token, prest_id)
        .then( res => {
            setState(prev => {
                let prests = prev.prestations.filter( p => p.id !== prest_id)
                return {...prev, prestations: prests}
            })
            toast.show({description: 'Prestation validée !', variant: 'success'})
        } )
        .catch( err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    useEffect(() => {
        refresh()

    }, [setState])

    return (
    <ScreenWrapper nav={navigation} title="Mes prestations de cours">
        <ScrollView>
            <VStack space={3} >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <Spinner />
                            <Heading color="primary.500" fontSize="md">
                                Chargement
                            </Heading>
                        </HStack>
                        </Center>
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <ScrollView>
                            <VStack space="5" px='3'>
                                
                                <MultiSelect
                                    items={state.children}
                                    uniqueKey="id"
                                    displayKey="full_name"
                                    // ref={(component) => { this.multiSelect = component }}
                                    onSelectedItemsChange={(ids) => setState(prev => ({...prev, selected_children_ids: ids})) }
                                    selectedItems={state.selected_children_ids}
                                    selectText="Mes enfants"
                                    searchInputPlaceholderText="Recherche ..."
                                    tagRemoveIconColor="#CCC"
                                    tagBorderColor="#CCC"
                                    
                                    tagTextColor="#CCC"
                                    selectedItemTextColor="blue"
                                    selectedItemIconColor="#CCC"
                                    itemTextColor="#000"

                                    searchInputStyle={{ color: '#CCC' }}
                                    submitButtonColor="blue"
                                    submitButtonText="Confirmer la sélection"
                                    />
                                <VStack space='3'>

                                {
                                    // state.selected_children_ids.length > 0 && state.prestations.filter(p => {
                                    //     return state.selected_children_ids.findIndex(_ => _ == p.subject_affected.child_id) > -1 && p.status === state.prestation_status
                                    // }).map(
                                    //     prest => (
                                            // <VStack key={prest.id} p='3' bg='blue.400' borderRadius={10} >
                                            //     <HStack>
                                            //         <Heading size='md'>{prest.subject_affected.sub.name}</Heading>
                                            //         <Spacer />
                                            //         {/* <Button onPress={() => handleValidate(prest.id)} >Valider</Button> */}
                                            //     </HStack>
                                            //     {/* <Heading size='xs' color='white' >{prest.subject_affected.child.name}</Heading> */}
                                            //     {console.log("Prest ", prest.subject_affected)}
                                            //     <Text>{prest.date}</Text>
                                            //     <HStack>
                                            //         <Text>{prest.start_at}</Text>
                                            //         <Spacer />
                                            //         <Text>{prest.end_at}</Text>
                                            //     </HStack>
                                            // </VStack>
                                    //     )
                                    // )
                                    
                                    <FlatList 
                                        // data={state.selected_children_ids.length > 0 && state.prestations.filter(p => {
                                        //     return state.selected_children_ids.findIndex(_ => _ == p.subject_affected.child_id) > -1 //&& p.status === state.prestation_status
                                        // }).sort((a, b) => new Date(a.date) < new Date(b.date)) } 
                                        
                                        data={
                                            state.selected_children_ids.reduce(
                                                (prev, child_id) => {
                                                    return prev.concat(state.prestations_by_child[child_id])
                                                    // return prev
                                            }, [])
                                            .sort(function(p1, p2){
                                                // Turn your strings into dates, and then subtract them
                                                // to get a value that is either negative, positive, or zero.
                                                return new Date(p2.date) - new Date(p1.date);
                                              })
                                        }
                                        
                                        keyExtractor={i => i.id}

                                        renderItem={
                                            ({item: prest}) => {
                                                return (
                                                    <ItemPrestation
                                                        key={prest.id} 
                                                        // child={state.children.find(c => c.id == prest.subject_affected.child_id)} 
                                                        prest={prest}
                                                    />
                                                )
                                            }
                                        }
                                    />
                                    
                                }
                                {
                                    // state.selected_children_ids.length > 0 && state.prestations.filter(p => {
                                    //     return state.selected_children_ids.findIndex(_ => _ == p.subject_affected.child_id) > -1 && p.status === state.prestation_status
                                    // }).length == 0 && <Heading>Aucune prestation enregistrée !</Heading>
                                }
                                
                                
                                </VStack>
                            </VStack>
                        </ScrollView>
                    
                    )
                }

                </VStack>

        </ScrollView>
    </ScreenWrapper>
  );
}