import { HStack, Text } from "native-base"
import { Pressable } from "react-native"
import { from_month_index_to_name, split_time } from "services/utils"

export default ({value, onPress}) => {
    let [hour, min, sec] = split_time(value)
    return (
        <Pressable onPress={onPress} >
            <HStack space='1' >
                <Text bg='primary.500' color='white' fontWeight={'bold'} px='3' py={2} rounded='full' >{hour}</Text>
                <Text py='2' fontSize={15} >:</Text>
                <Text bg='primary.500' color='white' fontWeight={'bold'} px='3' py={2} rounded='full' >{min}</Text>
            </HStack>
        </Pressable>
    )
}