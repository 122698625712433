import { AntDesign } from "@expo/vector-icons"
import DateTimeInput from "components/DateTimeInput"
import NumberInput from "components/NumberInput"
import { Button, FormControl, Heading, Icon, Select, useToast, VStack } from "native-base"
import { useEffect, useState } from "react"
import { listCities, updateContractParent, updateContractTeacher } from "services/api-axios"
import { apiErrorNotify } from "services/notify"
import { humanize_date_YMD } from "services/utils"
import { getToken } from "store/login/selectors"

export default ({ contract  }) => {
    const token = getToken()
    const toast = useToast()

    const [cities, setCities] = useState([])
    const [formValues, setFormvalues] = useState({
        city_id: contract.city.id,
        price: contract.price,
        signed_at: new Date(contract.signed_at),
        start_at: new Date(contract.start_at),
        end_at: new Date(contract.end_at),
    })

    const [isSubmiting, setSubmiting] = useState(false)

    const handleSubmit = () => {
        setSubmiting(true)
        const data = {
            price: parseInt(formValues.price),
            start_at: humanize_date_YMD(formValues.start_at),
            end_at: humanize_date_YMD(formValues.end_at),
            signed_at: humanize_date_YMD(formValues.signed_at),
        }

        if(contract.city.id != formValues.city_id) {
            data.city_id = formValues.city_id
        }
        
        updateContractTeacher(token, contract.id, data)
            .then(response => {
                toast.show({
                    title:  "Opération effectuée",
                    variant: "subtle",
                    status: 'success',
                    bg: 'green.600'
                })
                const ctx = response.data
                const _new = {
                    price: ctx.price,
                    signed_at: new Date(ctx.signed_at),
                    start_at: new Date(ctx.start_at),
                    end_at: new Date(ctx.end_at),
                    city_id: formValues.city_id
                }
                console.log(_new)
                setFormvalues(_new)
                setSubmiting(false)
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }

    useEffect(() => {
        listCities(token)
        .then(response => setCities(response.data))
        .catch(console.error)
    }, [setCities])
    return (
        <VStack space='3' px='3' py='10' >
            <FormControl isRequired isDisabled >
            <FormControl.Label>Enseignant</FormControl.Label>
                <Select >
                    <Select.Item label={contract.teacher.full_name || contract.teacher.username }  />
                </Select> 
            </FormControl>

            <FormControl isRequired >
            <FormControl.Label>Ville</FormControl.Label>         
                <Select 
                    selectedValue={formValues.city_id?.toString()}
                    onValueChange={val => setFormvalues(prev => ({...prev, city_id: val}))}
                    >
                    {
                        cities.map(
                            city => <Select.Item value={city.id.toString()} label={city.name}  />
                        )
                    }
                </Select> 
            </FormControl>

            <FormControl isRequired isDisabled={formValues.parent_id == 0}>
            <FormControl.Label>Montant</FormControl.Label>
                <NumberInput 
                value={formValues.price.toString()} 
                placeholder="Entrez un montant"
                onChangeText={val => setFormvalues(prev => ({...prev, price: val})) } />
            </FormControl>
            
            <FormControl  isRequired >
                <FormControl.Label>Date de signature</FormControl.Label>
                <DateTimeInput 
                    value={formValues.signed_at} 
                    placeholder=""
                    setValue={val => setFormvalues(prev => ({...prev, signed_at: val})) } />
            </FormControl>
            
            <FormControl width={"45%"} isRequired>
            <FormControl.Label>Date de début</FormControl.Label>
                <DateTimeInput 
                value={formValues.start_at} 
                setValue={val => setFormvalues(prev => ({...prev, start_at: val})) } />
            </FormControl>

            <FormControl width={"45%"} isRequired>
            <FormControl.Label>Date de fin</FormControl.Label>
                <DateTimeInput 
                value={formValues.end_at} 
                placeholder=""
                setValue={val => setFormvalues(prev => ({...prev, end_at: val})) } />
            </FormControl>

            <Button bg='orange.500' onPress={handleSubmit} startIcon={<Icon as={AntDesign} name='edit' />} rounded='full' isLoading={isSubmiting} bg='primary.500'>Modifier les informations générales</Button>
        
        </VStack>
    )
}