import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Spacer,
    FlatList,
    Icon
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { getFileLink, listAllUsers } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import ContactOptionsInline from "components/ContactOptionsInline";
import Chip from "components/Annonce/Chip";
import { display_user_role } from "services/utils";
import ScreenWrapper from "components/ScreenWrapper";
import { AntDesign } from "@expo/vector-icons";


export default ({ navigation }) => {
    const token = getToken()
    const [ state, setState ] = useState({
        users: [],
        isLoaded: false,
        isAproving: false,
    })
    const refresh = () => {
        console.log("Calling refresh")
        listAllUsers(token).then(
            res => {
                // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                setState(prev => ({...prev, users: res.data, isLoaded: true }))
            }
        )
        .catch(err => console.log(err))
    }
    
    useEffect(() => {
        refresh()
    }, [setState])
    console.log(state.users.length)
    return (
    <ScreenWrapper title="Liste des comptes de l'application" nav={navigation}>
        <ScrollView>
            <Heading size='xs' > {state.isLoaded && `${state.users.length} comptes`}</Heading>
            <VStack space={3} >
                {
                    ! state.isLoaded && (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <Spinner />
                            <Heading color="primary.500" fontSize="md">
                                Chargement
                            </Heading>
                        </HStack>
                        </Center>
                    )
                }
                {
                    state.isLoaded && <FlatList data={state.users} keyExtractor={item => item.id} renderItem={( ({item: user}) => (
                    <HStack key={user.id} space='3'>
                        {
                            user.id != 1 && (
                                <>
                                    <Box alignSelf={'center'} maxW={100} height={100}  flex={1} >
                                        { user.media_avatar ? 
                                            <Image borderRadius={'full'} height={"100%"} alt="Une photo" source={{uri: getFileLink(user.media_avatar)}} />
                                            :
                                            <Box maxW={100} height={100} rounded='full' bg='gray.300' />
                                        }
                                    </Box>
                                    <VStack pt={5} alignSelf={'center'}>
                                        {/* <Center  > */}
                                            <HStack>
                                                <Chip  bg='orange.500' px='2' py='1' >
                                                    <Heading alignSelf={'center'} size='xs' color='white' >{display_user_role(user)}</Heading>
                                                </Chip>
                                                <Spacer />
                                            </HStack>
                                            <Box>
                                                <Heading color="black" size='sm' > {!user.full_name ? 'Sans nom' : user.full_name.length > 15 ? user.full_name.slice(0, 15)+'...' : user.full_name } </Heading>
                                            </Box>
                                            <HStack>
                                                <Text color="black" > Tel: {user.username } </Text>
                                            </HStack>
                                            <HStack space='1' >
                                                {
                                                    user.is_active ? 
                                                    <Button ml='3' size='xs' colorScheme={'danger'} 
                                                    isDisabled
                                                    bg='red.500'
                                                    onPress={
                                                        () => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ContactParent.name, { phone: user.username, is_whatsapp: true })
                                                    } >Bloquer</Button> 
                                                    : 
                                                    (
                                                        <Button ml='3'  size='xs' colorScheme={'green'} 
                                                            
                                                        onPress={
                                                            () => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ContactParent.name, { phone: user.username, is_whatsapp: true })
                                                        } >Débloquer</Button>
                                                    )
                                                    
                                                }
                                                <Button ml='3' size='xs' 
                                                    startIcon={<Icon as={AntDesign} name='phone' />}
                                                onPress={
                                                    () => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ContactParent.name, { phone: user.username, is_whatsapp: true })
                                                } ></Button>
                                                {/* <ContactOptionsInline {...{ phone: p.username, is_whatsapp: true }} /> */}

                                            </HStack>
                                        {/* </Center> */}
                                    </VStack>
                                </>
                            )
                        }
                    </HStack>
                    ))} />
                }
                </VStack>
            </ScrollView>

    </ScreenWrapper>
  );
}