const { Box, HStack, Text, Heading } = require("native-base");
const { format_price, humanize_natural_date } = require("services/utils");

export default({invoice, pending}) => (
    <Box bg={pending ? 'green.600' : 'danger.500'} shadow='4' p='3' borderRadius={10} >
        <HStack justifyContent={'space-between'} >
            <Text fontSize={12} color='white' >Du {humanize_natural_date(new Date(invoice.start_at))}</Text>
            <Text fontSize={12} color='white'>Au {humanize_natural_date(new Date(invoice.end_at))}</Text>
        </HStack>
        <HStack >
            <Text fontSize={12} alignSelf={'center'} color='white'>Montant: </Text>
            <Heading color='white' size='md'>{format_price(invoice.price)}F</Heading>    
        </HStack>
    </Box>
)