import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Divider,
    IconButton,
    Icon,
    useToast,
    Spacer,
    AddIcon
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { AntDesign, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import { createChildMark, createChildOptionalMark, updateChildMark } from "services/api-axios";
import { apiErrorNotify } from "services/notify";
import ModalAddMark from "../ModalAddMark";


export default ({ child, subs, contract_id, refresh }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        contract_id: contract_id,
        selected_subject_affected: null,
        create_optional_sa_id: null,
        optional_already_exist: false,
        isSubmitting: false
    })


    const addMark = (values) => {
        console.log("Adding ", values)
        return new Promise((resolve, reject) => {
            try {
                console.log("In Promise")
                createChildMark(token, state.contract_id, child.id, values)
                .then(res => {
                    console.log("Solved")
                    resolve(true)
                    setTimeout(refresh, 2000);
                })
                .catch((err) => {
                    console.log("rejected")
                    console.log(err)
                    reject()
                })

            } catch(e) {
                console.warn(e)
            }
        })
    }


    const editMark = (values) => {
        if(!values.date) delete values.date
        return new Promise((resolve, reject) => {
            updateChildMark(token, state.contract_id, state.selected_subject_affected.child_id, state.selected_mark.id, values)
            .then(res => {
                resolve(true)
                setTimeout(refresh, 1500);
            })
            .catch((err) => {
                reject()
                apiErrorNotify({response: err.response, toast: toast })
            })
        })
    }
    let ids_offical = child.subjects_affected.map( y => y.sub_id )
    let ids_optianals = child.optional_subjects_affected.map( y => y.sub_id )
    let ids_subs = subs.map( y => y.id )
    // let substract = subs.filter(x => extracted_offical.includes(x.id));
    let substract = ids_subs
        .filter(id => !ids_offical.includes(id))
        .concat(ids_offical.filter(sub_id => !ids_subs.includes(sub_id)));

    substract = substract
        .filter(id => !ids_optianals.includes(id))
        .concat(ids_optianals.filter(sub_id => !substract.includes(sub_id)));

    
    return (
        
        <VStack space='3' >
            { state.selected_subject_affected != null && <ModalAddMark
                    onClose={() => setState(prev => ({...prev, selected_subject_affected: null}))}
                    onSubmit={addMark}
                    selected_subject_affected = {state.selected_subject_affected}
                    /> 
            }
            { state.create_optional_sa_id != null && <ModalAddMark
                    onClose={() => setState(prev => ({...prev, create_optional_sa_id: null, optional_already_exist: false}))}
                    onSubmit={addMark}
                    selected_subject_affected = {{
                        id: state.create_optional_sa_id,
                        sub: state.optional_already_exist ? child.optional_subjects_affected.find(x => x.id == state.create_optional_sa_id ) : subs.find(x => x.id == state.create_optional_sa_id )
                    }}
                    is_optional={true}
                    optional_already_exist={state.optional_already_exist}
                    /> 
            }
            {
                state.openUpdateModal && <ModalAddMark 
                onClose={() => setState(prev => ({...prev, selected_subject_affected: null, openUpdateModal: false, create_optional_sa_id: null, optional_already_exist: false}))}
                onSubmit={editMark}
                selected_subject_affected = {state.selected_subject_affected}
                optional_already_exist={state.optional_already_exist}
                is_optional={state.optional_already_exist}
                default_values = {state.selected_mark}
                /> 
            }
            <Box  bg={'white'} shadow='5' p='3'  flex={1} borderRadius={10} >
                <Heading size='lg'>{child.full_name}</Heading> 
                <Text>Classe: {child.child_level}</Text>
                <Text>Ecole: {child.school}</Text>
                <Text>Lieu des cours: {child.place_courses}</Text>
                {/* <Text>Ville: {state.contract.city.name}</Text> */}
            </Box>
            <VStack space='5'>
                {
                    child.subjects_affected.map(
                        s_a => (
                            <VStack key={s_a.id}>
                                <HStack bg='gray.300' p='1' alignItems={'center'} >
                                    <Icon as={AntDesign} name='downcircle' size='sm' color='white' />
                                    <Heading >{s_a.sub.name}</Heading>
                                    <Spacer />
                                    <IconButton 
                                            bg='gray.200'
                                            rounded={'full'}
                                            onPress={()=> setState(prev => ({...prev,  selected_subject_affected: s_a}))}
                                            icon={<AddIcon size='sm' color='primary.500' /> } />
                                </HStack>
                                {
                                    s_a.marks.length == 0 ? <Text>Aucune note </Text> : (
                                        <VStack bg='gray.200'>
                                            <HStack my='1' >
                                                <Heading size={'sm'}>Note</Heading>
                                                <Spacer />
                                                <Heading size={'sm'}>Coefficient</Heading>
                                                <Spacer />
                                            </HStack>
                                            {
                                                s_a.marks.map(
                                                    mark => (
                                                        <VStack key={mark.id} py='1' >
                                                            <HStack key={mark.id} alignItems='center' alignContent={'center'} >
                                                                <Heading size={'sm'}>{mark.value}</Heading>
                                                                <Spacer />
                                                                <Heading size={'sm'}>{s_a.coeff}</Heading>
                                                                <Spacer />
                                                                <IconButton 
                                                                    alignSelf={'center'}
                                                                    bg='gray.200'
                                                                    icon={<Icon as={FontAwesome5} size='xs' name='pen' />}
                                                                    rounded='full'
                                                                    onPress={()=> setState(prev => ({...prev,  openUpdateModal: true, selected_mark: mark, selected_subject_affected: s_a}))}
                                                                />
                                                                {/* <Button size='xs' >Modifier</Button> */}
                                                            </HStack>
                                                        <Divider />
                                                    </VStack>
                                                    )
                                                )
                                            }
                                        </VStack>
                                    )
                                }
                                <HStack>
                                    <Spacer />
                                    
                                </HStack>
                            </VStack>
                        )
                    )
                }
                {
                    child.optional_subjects_affected.map(
                        s_a => (
                            <VStack key={s_a.id}>
                                <HStack bg='gray.300' p='1' >
                                    <Heading >{s_a.sub.name}</Heading>
                                    <Spacer />
                                    <IconButton 
                                            bg='gray.200'
                                            rounded={'full'}
                                            onPress={()=> setState(prev => ({...prev,  create_optional_sa_id: s_a.id, optional_already_exist: true}))}
                                            // onPress={() => {
                                            //     console.log("Pressed on ID ", s_a.id, " Between ids ", child.optional_subjects_affected.find(x => x.id == s_a.id ))
                                            // }}
                                            icon={<AddIcon size='sm' color='primary.500' /> } />
                                </HStack>
                                {
                                    s_a.marks.length == 0 ? <Text>Aucune note pour cette matière</Text> : (
                                        <VStack bg='gray.200'>
                                            <HStack my='1' >
                                                <Heading size={'sm'}>Note</Heading>
                                                <Spacer />
                                                <Heading size={'sm'}>Coefficient</Heading>
                                                <Spacer />
                                            </HStack>
                                            {
                                                s_a.marks.map(
                                                    mark => (
                                                        <VStack key={mark.id} py='1' >
                                                            <HStack key={mark.id} alignItems='center' alignContent={'center'} >
                                                                <Heading size={'sm'}>{mark.value}</Heading>
                                                                <Spacer />
                                                                <Heading size={'sm'}>{s_a.coeff}</Heading>
                                                                <Spacer />
                                                                <IconButton 
                                                                    alignSelf={'center'}
                                                                    bg='gray.200'
                                                                    icon={<Icon as={FontAwesome5} size='xs' name='pen' />}
                                                                    rounded='full'
                                                                    onPress={()=> setState(prev => ({...prev,  openUpdateModal: true, selected_mark: mark, selected_subject_affected: s_a, optional_already_exist: true}))}
                                                                />
                                                                {/* <Button size='xs' >Modifier</Button> */}
                                                            </HStack>
                                                        <Divider />
                                                    </VStack>
                                                    )
                                                )
                                            }
                                        </VStack>
                                    )
                                }
                                <HStack>
                                    <Spacer />
                                    
                                </HStack>
                            </VStack>
                        )
                    )
                }
                {
                    substract.map(
                        sub_id => (
                            <VStack key={sub_id}>
                                <HStack bg='gray.300' p='1' >
                                    <Heading >{subs.find(x => x.id == sub_id).name}</Heading>
                                    <Spacer />
                                    <IconButton 
                                            bg='gray.200'
                                            rounded={'full'}
                                            onPress={()=> setState(prev => ({...prev,  create_optional_sa_id: sub_id}))}
                                            icon={<AddIcon size='sm' color='primary.500' /> } />
                                </HStack>
                                <Text>Aucune note </Text>
                            </VStack>
                        )
                    )
                }
                
            </VStack>
        </VStack>
    
    )
}