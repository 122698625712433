import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import MenuBoxWrapper from "components/MenuBoxWrapper";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    Icon,
    CloseIcon
  } from "native-base";
  import { Image, Pressable } from 'react-native';

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 1],
            end: [1, 1],
        }
    }
  return (
    <ScreenWrapper title={"Gestion des parents"} nav={navigation}>
        <ScrollView>
            <VStack px="4" pb="5" space={"4"} >
                {/* <Heading m='3' >Gestion des parents</Heading> */}
                <HStack space={5}  >
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ValidateProfile.name)}>
                            <Center height="100%">
                                <Text rounded='full' p='2' bg='green.100' >
                                    <Icon mt='2'  mr='2' as={FontAwesome5} name='check' size={'xl'} color={greenGrad.linearGradient.colors[1]} />
                                </Text>
                                <Text fontSize={18} color="white">Valider les profils</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.RejectProfile.name) }>
                            <Center height="100%">
                                <Text rounded='full' p='2' bg='red.100' >
                                    <CloseIcon mt='2'  mr='2' as={FontAwesome} name='mark' size={'xl'} color="red.500"   />
                                </Text>
                                <Text fontSize={18} color="white">Retirer des profils</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>

                <HStack space={5}  >
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.CreateContract.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='file-signature' size={'xl'} color={'orange.300'} />
                                <Text fontSize={18} color="white">Enregistrer un contrat</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ListContact.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='phone' size={'xl'} color={'green.300'} />
                                <Text fontSize={18} color="white">Contacter un parent</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                <HStack space={5}  >
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100}  bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.CreateInvoice.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='file-invoice' size={'xl'} color={'white'} />
                                <Text fontSize={18} color="white">Enregistrer une facture</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100} bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ValidatePrestationList.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='chalkboard' size={'xl'} color={'white'} />
                                <Text fontSize={18} color="white">Voir les prestations</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                <HStack space={5}  >
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ListContracts.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='file-signature' size={'xl'} color="white" />
                                <Text fontSize={18} color="white">Lister les contrats enregistrés</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                <HStack space={5}  >
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}} height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ListInvoicesAllParents.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='wallet' size={'xl'} color="green.400" />
                                <Text fontSize={18} color="white">Lister les factures</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                

        </VStack>
            </ScrollView>
    </ScreenWrapper>
  );
}