import { get_item, set_item } from "services/persistence"

const PREVIOUS_NAVIGATION_SCREEN_STORAGE_KEY = "@navigationPreviousKey"

export const getNavigationPreviousScreen = () => {
    return get_item(PREVIOUS_NAVIGATION_SCREEN_STORAGE_KEY)
}

export const setNavigationPreviousScreen = (value) => {
    return set_item(PREVIOUS_NAVIGATION_SCREEN_STORAGE_KEY, value)
}