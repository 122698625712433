import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    TextArea,
    AddIcon,
    Spacer,
    Icon
  } from "native-base";
  import { Dimensions, Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { listApprovedTeachers, approveUser, orderTeacher, listSubs, orderTeacherNoLogin } from "services/api-axios";
import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import OrderButton from "./components/OrderButton";
import DisapearingAlert from "components/DisapearingAlert";
import MultiSelect from "react-native-multiple-select";
import { Formik } from 'formik';
import * as Yup from 'yup';
import NumberInput from "components/NumberInput";
import PageLoading from "components/PageLoading";
import PhoneInput from "components/PhoneInput";
import Chip from "components/Annonce/Chip";
import { useFocusEffect } from "@react-navigation/native";
import { getNavigationPreviousScreen, setNavigationPreviousScreen } from "stacks/utils";
import { AntDesign } from "@expo/vector-icons";

export default ({navigation}) => {
    const [error, setError] = useState('')
    const [state, setState] = useState({
        isLoaded: false,
        isSubmitting: false,
        isDone: false,
        seed: null,
        children: [],
        parent_phone: '',
        subs: [],
        isFormOpen: true
    })

    const formikRef = useRef()
    
    const submitApplyment = () => {
        const data = { 
            parent: {
                full_name: formikRef.current.values.parent_name,
                phone: formikRef.current.values.parent_phone
            },
            children_details: state.children.map(c => ({...c, child_subs: [1, 2], frequency: parseInt(c.frequency)}) )
        }
        setState(prev => ({...prev, isSubmitting: true}))
        orderTeacherNoLogin(data)
        .then(res => {
            navigation.navigate(MENU_NAVIGATION.StatusScreen.name)
            //setState(prev => ({...prev, isSubmitting: true, isDone: true}))
        })
        .catch(err => {
            setError("Une erreur s'est produite") 
            setState(prev => ({...prev, isSubmitting: false, isDone: false}))
        })
    }

    const addChild = useCallback((seed, childValuesStr) => {
        if(state.seed == seed) return
        setState(prev => ({...prev, children: prev.children.concat([JSON.parse(childValuesStr)])}))
        setNavigationPreviousScreen(null)
    })

    const editChild = useCallback((seed, index, childValuesStr) => {
        if(state.seed == seed) return
        if(state.children[index] === undefined) return
        
        setState(prev => ({
            ...prev, 
            children: prev.children.slice(0, index)
                .concat([JSON.parse(childValuesStr)])
                    .concat(prev.children.slice(index+1, prev.children.length))
        }))
        setNavigationPreviousScreen(null)
    })

    useFocusEffect(useCallback(
        () => {
          setTimeout(() => {
            getNavigationPreviousScreen().then(
              raw => {
                const parsed = JSON.parse((raw ?? null))
                const prevName = parsed?.name
                const newSeed = parsed?.seed
                const payload = parsed?.payload

                if(prevName === "ADD_CHILD_TO_FORM") {
                  if(state.seed == newSeed) return
                if(payload?.type == "ADD") {
                    addChild(newSeed, JSON.stringify(payload?.data))
                } else if(payload?.type == "EDIT") {
                    editChild(newSeed, payload?.data?.editIndex, JSON.stringify(payload?.data))
                } else {
                    setState(prev => ({...prev, seed: Math.random()}))
                }
                } 
              } 
            )
          }, 1000);
        }, [state.seed]
      ))
      
    useEffect(() => {
        listSubs().then(res => {
            setState(prev => ({...prev, isLoaded: true, subs: res.data}))
        })
    }, [setState])

    return (
        <ScrollView h={Dimensions.get("screen").height} bg='white'>
            {
                !state.isLoaded ? <PageLoading /> : 
                 (
                    <VStack p='4' space='6' >
                        <Heading>Formulaire de demande d'enseignant</Heading>
                        <Formik
                            innerRef={formikRef}
                            
                            initialValues={{
                                parent_name: '',
                                parent_phone: ''
                            }}
                            onSubmit={submitApplyment}
                            validateOnBlur={true}
                            validateOnChange={true}
                            validationSchema={Yup.object().shape({
                                parent_phone: Yup.string().required("Champ obligatoire"),
                                parent_name: Yup.string().required("Champ obligatoire"),
                                
                            })}
                            >
                                {({ handleChange, handleBlur, handleSubmit, errors , values }) => (
                                    <VStack >
                                        <FormControl isRequired>
                                            <FormControl.Label>Votre nom</FormControl.Label>
                                            <Input 
                                                value={values.parent_name}
                                                onChangeText={handleChange('parent_name')}
                                                onBlur={handleBlur('parent_name')}
                                                />
                                                <Text color={'danger.500'}>{errors.parent_name}</Text>
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormControl.Label>Votre numéro de téléphone</FormControl.Label>
                                            <PhoneInput 
                                                value={values.parent_phone}
                                                onChangeText={handleChange('parent_phone')}
                                                onBlur={handleBlur('parent_phone')}                                                />
                                                <Text color={'danger.500'}>{errors.parent_phone}</Text>
                                        </FormControl>

                                    </VStack>
                                ) }
                            </Formik>

                        <VStack>
                            <Heading size={'md'}>Les enfants à encadrer</Heading>
                            <Text fontSize={12} fontWeight={'light'}>Veuillez renseigner au moins un enfant</Text>
                        </VStack>
                        {
                            state.children.map(
                                (c, index) => (
                                    <Box index={index} borderWidth='1' borderRadius={5} p="3" >
                                            {/* <Button ml='1/2' >Modifer</Button> */}
                                            <HStack>
                                                <Heading size={"lg"}>{c.full_name}</Heading>
                                                <Spacer />
                                                <Icon
                                                    as={AntDesign}
                                                    name="edit"
                                                    color="orange.500"
                                                    onPress={() => navigation.navigate("ADD_CHILD_TO_FORM", {
                                                        editIndex: index,
                                                        initialValues: c,
                                                        subs: state.subs
                                                    })}
                                                />
                                            </HStack>
                                            <Text>Classe: {c.child_level}</Text>
                                            <Text>Lieu des cours: {c.place_courses}</Text>
                                            <Text>Séances: {c.frequency} / semaine</Text>
                                            <Text>Matières: {c.child_subs.length}</Text>
                                            {/* <ScrollView horizontal showsHorizontalScrollIndicator={false}> */}
                                                <HStack py='3' flexWrap={'wrap'} >
                                                    {
                                                        c.child_subs.map(cs => <Chip px='3' mx='1' mb='2' bg='gray.500' color={'white'}>{state.subs.find(s => s.id == cs)?.name}</Chip> )
                                                    }
                                                </HStack>   
                                            {/* </ScrollView> */}
                                    </Box>
                                )
                            )
                        }

                        {  (
                                <VStack mt='6' space={7}>
                                    <HStack>
                                        <Spacer />
                                        <Button 
                                            onPress={() => navigation.navigate("ADD_CHILD_TO_FORM", {
                                                initialValues: {

                                                },
                                                subs: state.subs
                                            })}
                                            //onPress={() => setState(prev => ({...prev, isFormOpen: true}))}  
                                            leftIcon={<AddIcon />} >Renseigner un enfant à encadrer</Button>
                                    </HStack>
                                    <Spacer />
                                    <Text color={'danger.500'}>{error}</Text>
                                    <Button
                                        isLoading={state.isSubmitting}
                                        isDisabled={state.children.length === 0}
                                        onPress={() => formikRef.current.handleSubmit()} 
                                        bg='emerald.500' >Soumettre ma demande</Button> 
                                </VStack>
                            ) 
                        }
                    </VStack>
                )
            }
        </ScrollView>
    )
}
