import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast,
    Divider,
    Modal,
    Spacer,
    IconButton,
    CloseIcon,
    AddIcon
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_natural_time, from_day_index_to_day_name, humanize_date_YMD } from "services/utils";
import { apiErrorNotify } from "services/notify";
import TimeInput from "components/TimeInput";
import DateTimeInput from "components/DateTimeInput";
import ResumeChildInfos from "stacks/DirectionStack/screens/UpdateContractparent/components/ResumeChildInfos";
import DisplayTime from "components/DisplayTime";

import ModalAddSubject from 'stacks/DirectionStack/screens/UpdateContractparent/components/ModalAddSubject';
import DisplayTimeLinear from "components/DisplayTimeLinear";
import DisplayTimeplanLine from "../DisplayTimeplanLine";

export default  ({isOpen, onClose, onOpen, onSubmit, subs, initialValues= {}, index, label}) => {
  const token = getToken()
  const toast = useToast()

  const [state, setState] = useState({
    subs: subs,
    timeplan: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: []
    },
    addSub: false,
    editSub: false,
    dayToAdd: null,
    isSubmitting: false,
    selected_s_a: null,
    sa_index_in_day: null
  })
  const [childValues, setChildValues] = useState({
    full_name: '',
    school: '',
    place_courses: '',
    child_level: '',
    subjects_affected: [],
    // ...initialValyyyyyyyyyues,
  })
  

  const handleSubmit = () => {
    setState(prev => ({...prev, isSubmitting: true}))

    const sa_by_sub = {}
    console.log(childValues, state.timeplan)
    Object.keys(state.timeplan).forEach(
      day_index => {
        state.timeplan[day_index].forEach(
          raw_sa => {
            if(!sa_by_sub[raw_sa.sub.id]) sa_by_sub[raw_sa.sub.id] = []
            sa_by_sub[raw_sa.sub.id].push({
              ...raw_sa,
              day_index: day_index
            })
          }
        )
      }
    )

    const sa_formated = []
    Object.keys(sa_by_sub).forEach(
      sub_id => {
        if(sa_by_sub[sub_id].length){
          const coeff = sa_by_sub[sub_id][0].coeff
          const data_sub = {
            sub_id: parseInt(sub_id),
            coeff: coeff,
            course_sessions: []
          }
          sa_by_sub[sub_id].forEach(
            raw_course => {
              data_sub.course_sessions.push({
                day: parseInt(raw_course.day_index),
                start_at: raw_course.start_at,
                end_at: raw_course.end_at
              })
            }
          )
          sa_formated.push(data_sub)
        }
      }
    )
    
    onSubmit({
      values: {
        ...childValues,
        subjects_affected: sa_formated,
      },
      index: index
    })
    .then(onClose)
    .catch( err => {
      console.log(err)
      onClose()
    })
    .finally(() => setState(prev => ({...prev, isSubmitting: false})))
  }

  const handleRemoveTimeLine = (day, index) => {
    setState(prev => {
      const length = prev.timeplan[day].length
      const day_program = prev.timeplan[day]
      
      return {
        ...prev,
        timeplan: {
          ...prev.timeplan,
          [day]: day_program.slice(0, index).concat(day_program.slice(index+1, length))
        }
      }
    })
    
  }

  const handleAddSubject = (values) => {
    setState(prev => {
      prev.timeplan[state.dayToAdd].push(values)
      return {...prev, addSub: false  }
    })
    
  }

  const handleEditSubject = (values) => {
    if(typeof state.sa_index_in_day === 'number'){
      setState(prev => {
        prev.timeplan[state.dayToAdd][state.sa_index_in_day] = values
        // console.log("edit timeplan ", prev.timeplan[state.dayToAdd])
        return {...prev, editSub: false  }
      })
  
    }
  }

  const handleCancelSub = () => setState(prev => ({...prev, addSub: false, editSub: false, selected_s_a: null, sa_index_in_day: null })) 

  useEffect(()=> {
      
    if(initialValues.subjects_affected) {
        const local_timeplan = {
          0: [],
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
        ...initialValues.timeplan_week_model
        }
        
        setState(prev => ({...prev, timeplan: local_timeplan}))
    }
}, [setState])

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose} collapsable={true} >
      <Actionsheet.Content bg="white" >
          <ScrollView showsVerticalScrollIndicator={false} bg="white" w="100%" >
              <Heading color="black" >{Object.keys(initialValues).length > 0 ? "Modifier" : "Renseignez les informations de l'enfant"} </Heading>
              <VStack space="3" pt="3"  >
              <FormControl isRequired>
                <FormControl.Label>Nom complet de l'enfant</FormControl.Label>
                <Input 
                  value={childValues.full_name} 
                  onChangeText={(val) => setChildValues(prev => ({...prev, full_name: val}))} 
                  />
              </FormControl>

              <FormControl isRequired>
                  <FormControl.Label>Classe</FormControl.Label>
                  <Input 
                    value={childValues.child_level} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, child_level: val}))}
                    />
                </FormControl>

              <FormControl isRequired>
                <FormControl.Label>Lieu des cours à domicile</FormControl.Label>
                  <Input 
                    value={childValues.place_courses} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, place_courses: val}))}
                    />
                </FormControl>

              <FormControl isRequired>
                <FormControl.Label>Nom de l'école fréquentée</FormControl.Label>
                  <Input 
                    value={childValues.school} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, school: val}))}
                    />
                </FormControl>
              {/* <Divider /> */}
              <Heading size='lg'>Emploi du temps des cours à domicile</Heading>

              <VStack space='10'>
              {
                Object.keys(state.timeplan).map(
                  day_number => (
                    <VStack key={day_number} alignContent='space-between' >
                      <HStack px='3' justifyContent={'space-between'} >
                        <Heading size='md'>{from_day_index_to_day_name(day_number)}</Heading>
                        <IconButton 
                            // size='lg' 
                            borderRadius={'full'} 
                            icon={
                              <AddIcon size='sm' color='green.500' />
                            }
                            _pressed={{
                              bg: 'gray.300',
                              color: 'black'
                            }}
                          onPress={() => setState(prev => ({...prev, addSub: true, dayToAdd: day_number })) }
                          />
                        {/* <Button  onPress={() => setState(prev => ({...prev, addSub: true, dayToAdd: day_number })) } >Ajouter une matière</Button> */}
                      </HStack>
                      <VStack space='2'>
                        {
                          state.timeplan[day_number].map(
                            (s_a, index) => (
                              <DisplayTimeplanLine 
                                key={index}
                                s_a={s_a} 
                                onRemoveLine={() => handleRemoveTimeLine(day_number, index)} 
                                onEdit={() => setState(prev => ({...prev, editSub: true, dayToAdd: day_number, selected_s_a: s_a, sa_index_in_day: index }))}
                              />
                            )
                          )
                        }
                      </VStack>
                    </VStack>
                  )
                )
                
              }
              </VStack>
                <Button 
                  onPress={handleSubmit} 
                  isDisabled={
                    !childValues.full_name || !childValues.place_courses || !childValues.child_level 
                    || Object.values(state.timeplan).findIndex(list => list.length != 0) == -1
                  } 
                  isLoading={state.isSubmitting}
                >Ajouter</Button>
              </VStack>
              {
                state.dayToAdd !== null && (
                  <>
                    {
                      state.addSub ? (
                        <ModalAddSubject  
                          dayToAdd={state.dayToAdd} 
                          subs={state.subs} 
                          onCancel={handleCancelSub}
                          initialValues={{}}
                          onSubmit={handleAddSubject}
                          label='Ajout'
                          />

                      ) : state.editSub && (
                        <ModalAddSubject  
                        label='edition'
                          dayToAdd={state.dayToAdd} 
                          subs={state.subs} 
                          onCancel={handleCancelSub}
                          initialValues={state.selected_s_a}
                          onSubmit={handleEditSubject}
                          />
                      )
                    }
                  </>
                )
              }
                
          </ScrollView>
      </Actionsheet.Content>
    </Actionsheet>
  )
}