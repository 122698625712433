import Annonce from "components/Annonce";
import Chip from "components/Annonce/Chip";
import ScreenWrapper from "components/ScreenWrapper";
import UserWithoutContract from "components/UserWithoutContract";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { getMyContractParent } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { humanize_natural_date } from "services/utils";
import { getToken } from "store/login/selectors";

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const [contract, setContract] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState(false)
    console.log("Token ", token)
    useEffect(()=> {
        getMyContractParent(token)
            .then(res => {
                setContract(prev => res.data)
                setIsLoaded(true)
            })
            .catch(err => setError("Une erreur est survenue lors du chargement") )

    }, [setIsLoaded, setContract ])

    const collect_by_children = () => {
        const children = {}
        if(contract){
            contract.subjects_affected.forEach(
                sa => {
                    if(!children[sa.child_id]) children[sa.child_id] = []
                    children[sa.child_id].push(sa)
                }
            )
        }
        return children
    }
    const children = collect_by_children()
  return (
    <ScreenWrapper nav={navigation} title="Les détails de mon contrat"  >
        <ScrollView>
            <VStack px="4" py="5" space={"4"} > 
                <>
                    {
                        !isLoaded ? (
                            <Center  >
                                <HStack space={2} justifyContent="center">
                                <Spinner />
                                <Heading color="primary.500" fontSize="md">
                                    Chargement
                                </Heading>
                            </HStack>
                            </Center>
                        ) : !contract ? <UserWithoutContract /> : (
                            <VStack space='5' >
                                <Box minHeight={100}  shadow='5' bg={'white'} p='3' flex={1} borderRadius={10} >
                                    <Heading color="orange.400">Mon contrat</Heading>
                                    <Text color="primary.500">Montant : {parseInt(contract.price).toLocaleString('fr-FR')} FCFA </Text>
                                    <Text color="primary.500">Signé le : {humanize_natural_date(new Date(contract.signed_at))} </Text>
                                    <Text color="primary.500">Date de début : {humanize_natural_date(new Date(contract.start_at))} </Text>
                                    <Text color="primary.500">Date d'expiration : {humanize_natural_date(new Date(contract.end_at))} </Text>
                                </Box>
                                <Box minHeight={100}  bg={'white'} p='3'  flex={1} borderRadius={10} >
                                    <Heading color="green.600">Mes enfants sous contrat</Heading>
                                    <VStack space='2'>
                                        {
                                            Object.values(children).map(
                                                list_sas => {
                                                    return (
                                                        <VStack >
                                                            <Heading color='primary.500' my="1" size='xs'>{list_sas[0].child.full_name} ({list_sas[0].child.child_level}) </Heading>
                                                            {/* <Box> */}
                                                                {/* <ScrollView horizontal showsHorizontalScrollIndicator={false}  > */}
                                                                    <HStack space='2' flexWrap={'wrap'} >
                                                                        {
                                                                            list_sas.map(sa => <Chip my='1' color="white" bg='primary.500' key={sa.id} >{sa.sub.name}</Chip>)
                                                                        }

                                                                    </HStack>
                                                                {/* </ScrollView> */}

                                                            {/* </Box> */}
                                                        </VStack>
                                                    ) 
                                                }
                                            )
                                        }
                                    </VStack>
                                </Box>
                            </VStack>
                        )
                    }
                    
                </>
        </VStack>
            </ScrollView>
    </ScreenWrapper>
  );
}