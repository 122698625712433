import BaseNumberInput from "components/BaseNumberInput"
import { FormControl, Input } from "native-base"
import { useRef } from "react"
import { useState } from "react"

// const format = (value) => {
//     is
// }

export default ({value, onChangeText, checkPrefix = true, prefixReg= "^0[157]+", cleanWhenInvalid= false, ...props}) => {
    const [state, setState] = useState(value)
    const [err, setError] = useState('')

    return (
        <FormControl isInvalid={err} >
            <FormControl.Label>{props.label}</FormControl.Label>
            <BaseNumberInput
                keyboardType="number-pad" 
                value={value} 
                {...props}  
                onChangeText={onChangeText}
            />
            <FormControl.ErrorMessage  >{err}</FormControl.ErrorMessage>
            
        </FormControl>
    )
}