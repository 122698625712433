import { createSlice } from '@reduxjs/toolkit'
import { persist_return, get_item, set_item } from "services/persistence";

const SLICE_NAME = 'login'
export const STORAGE_NAME = `@${SLICE_NAME}`

let initialState  = {}

export const loginSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    login: (state, action) => {
      const new_state = {
        ...state,
        ...action.payload,
        access_token: action.payload.access_token,
        isLoggedIn: true,
        username: action.payload.username,
        full_name: action.payload.full_name,
        is_superuser: action.payload.is_superuser,
        is_manager: action.payload.is_manager,
        is_parent: action.payload.is_parent,
        is_teacher: action.payload.is_teacher,
        is_approuved: action.payload.is_approuved,
        cities: action.payload.cities
      }
        set_item(STORAGE_NAME, new_state)
        return new_state  
    },
    logout: (state) => {
      const new_state = {
        
      }
      set_item(STORAGE_NAME, new_state)
      return new_state
    },
    loadLoginState: (state, action) => {
      set_item(STORAGE_NAME, action.payload)
      return action.payload
    },
    mergeLoginState: (state, action) => {
      const new_state = {
        ...state,
        ...action.payload
      }
      set_item(STORAGE_NAME, new_state)
      return new_state
    },
    getLoginState: (state, action) => {
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { login, logout, loadLoginState, mergeLoginState, getLoginState } = loginSlice.actions

export default loginSlice.reducer