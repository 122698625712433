import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    useToast,
    Divider
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { getMyContractParent } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_time, from_day_index_to_day_name } from "services/utils";
import { apiErrorNotify } from "services/notify";
import UserWithoutContract from "components/UserWithoutContract";
import ScreenWrapper from "components/ScreenWrapper";
import TimeplanWeekDisplay from "components/TimeplanWeekDisplay";
import TimeplanWeekStaticDisplay from "components/TimeplanWeekStaticDisplay";
import PageLoading from "components/PageLoading";



export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        contract: null,
        contract_id: null,
        subjects_affected: [],
        children: {},
        children_ids: [],
        selected_child_index: null,
        selected_week_index: null,
        current_week_index: null,
        nb_weeks: 0,
        isLoaded: false
    })
    
    // if(state.selected_child_index !== null && state.selected_week_index !== null) {
    //     [0, 1, 2, 3, 4, 5, 6].forEach(
    //         day_name => {
    //             if(state.children[state.selected_child_index]) {
    //                 if(state.children[state.selected_child_index]) {
    //                     if(state.children[state.selected_child_index].weeks[state.selected_week_index]) {
    //                         const day_datalist = state.children[state.selected_child_index].weeks[state.selected_week_index][day_name]
    //                         for (let i = 0; i < day_datalist.length; i++) {
    //                             for (let index = 1; index < day_datalist.length; index++) {
    //                                 // if( new Date(`2020-10-10 ${day_datalist[index-1].start_at}`) > `2020-10-10 ${day_datalist[index].start_at}` ){
    //                                 if( day_datalist[index-1].start_at > day_datalist[index].start_at ){
    //                                     let temp = day_datalist[index]
    //                                     day_datalist[index] = day_datalist[index-1]
    //                                     day_datalist[index-1] = temp
    //                                 }   
    //                             }   
    //                         }
    //                     }
    //                 }
    //             }
            
    //     });
    // } 

    
    useEffect(() => {
        getMyContractParent(token).then(
            res_contract => {        
                const children = {}
                // const children_ids = {}
                const contract = res_contract.data
                if(contract) {
                    const date_start = new Date(contract.start_at);
                    const date_now = new Date();
                    const diffTime = Math.abs(date_start - date_now);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    const diffWeeks = Math.floor(diffDays / 7)

                    const date_end = new Date(contract.end_at);
                    const nb_weeks = Math.floor(Math.ceil(Math.abs(date_start - date_end) / (1000 * 60 * 60 * 24)) / 7)

                    
                    contract.subjects_affected.forEach( s_a => {
                        let child = s_a.child
                        if(!children[child.id]) children[child.id] = { child_infos: s_a.child }
                    } )

                    const children_keys_list = Object.keys(children)
                    const initial_child_id = children_keys_list.length > 0 ? children_keys_list[0] : 0
                    

                    setState(prev => ({
                        ...prev, 
                        contract: contract,
                        contract_id: contract.id,
                        subjects_affected: contract.subjects_affected, 
                        children: children,
                        nb_weeks: nb_weeks,
                        selected_child_index: initial_child_id,
                        current_week_index: diffWeeks,
                        isLoaded: true
                    }))
                } else {
                    console.log("No contract found")
                }
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        .finally(() => {
            setState(prev => ({
                ...prev, 
                isLoaded: true}
            ))
        })
    }, [setState])

    return (
    <ScreenWrapper nav={navigation} title="Emploi du temps de mes enfants"  >
        <ScrollView>
            <VStack space={3} >
                {
                    ! state.isLoaded ? (
                        <PageLoading />
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <ScrollView>
                            <VStack space="5" px='3'>
                                <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                    <HStack space={5}>
                                        {
                                            Object.values(state.children).map(
                                                (c, index) => (
                                                    <Pressable key={index} onPress={() => setState(prev => ({...prev, selected_child_index: c.child_infos.id})) } >
                                                        <Text py='2' px='4' bg={state.selected_child_index == c.child_infos.id ? 'gray.800' : 'gray.400'} color={state.selected_child_index == c.child_infos.id ? 'white' : 'black' } borderRadius={20} >{c.child_infos.full_name}</Text>
                                                    </Pressable>
                                                )
                                            )
                                        }
                                    </HStack> 
                                </ScrollView>
                                
                                <VStack space='5'>
                                    {
                                        state.children[state.selected_child_index] && (
                                            <TimeplanWeekStaticDisplay 
                                                _header={{
                                                    _box: {
                                                        bg: 'primary.500',
                                                    } ,
                                                    _title: {
                                                        color: 'white'
                                                    }
                                                }} 
                                                
                                                week_model={state.children[state.selected_child_index].child_infos.timeplan_week_model}
                                            
                                                subjects_affected={state.contract.subjects_affected}
                                            />
                                        )
                                    }
                                    
                                </VStack>
                            </VStack>
                        </ScrollView>
                    
                    )
                }

                </VStack>

            </ScrollView>
    </ScreenWrapper>
  );
}