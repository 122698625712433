import Annonce from "components/Annonce";
import Chip from "components/Annonce/Chip";
import NumberInput from "components/NumberInput";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Avatar,
    Divider,
    Spacer,
    FormControl,
    Input,
    useToast,
    Icon,
    TextArea,
    Select,
    Modal,
    Image,
    IconButton
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Pressable } from 'react-native';
import { createLearningExercise, createLearningLesson, createLearningVideo, getFileLink, getMeUser, listClassLevels, updateMeUser, uploadFile } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { getIsTeacher, getLoginState, getToken, getUserAvatar } from "store/login/selectors";
import { useMergeLoginState } from 'store/login/hooks'
import { apiErrorNotify } from "services/notify";
import { display_user_role } from "services/utils";

import * as DocumentPicker from "expo-document-picker";
import { AntDesign } from "@expo/vector-icons";
import ScreenWrapper from "components/ScreenWrapper";
import { Picker } from "@react-native-picker/picker";
import SelectMultipleInput from "components/SelectMultipleInput";
import { Video } from "expo-av";

export default ({ navigation, route }) => {
  const token = getToken()
  const toast = useToast()
  
  const routeInitialValues = route.params ? route.params.init : {}
  const resource = routeInitialValues.resource
  if(!["videos", "exercises", "lessons"].includes(resource)) throw '`resource` not in ["videos", "exercises", "lessons"]'
    
    const [state, setState] = useState({
      classLevels: [],
      resourceMedia: null,
      imagePreview: null,
      isSubmiting: false,

      imagePreview: null,

      selectedClassLevelIndexes: [],
      selectedSubIndex: []
    })
    
    const [error, setError] = useState(null)
    

    const [formState, setFormState] = useState({
      title: "Mon titrée",
      subtitle: "Subtitle",
      description: "LONGUE DESC",
      // media: "http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4",
      // cover_url: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80",
      target: '0'
    })
    function submit(data) {
      if(resource === 'videos') {
        createLearningVideo(token, data).then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, { init: {msg: 'Vidéos ajouté', time: 2000, navigation: navigation} }) )
      } else if(resource === 'lessons') {
        createLearningLesson(token, data).then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, { init: {msg: 'Cours ajouté',time: 2000, navigation: navigation} }) )
      } else if(resource === 'exercises') {
        createLearningExercise(token, data).then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, { init: {msg: 'Exercice ajouté',time: 2000, navigation: navigation} }) )
      }
    }
    function handleSubmit() {
      const resourceFormData = new FormData()
      resourceFormData.append('file', state.resourceMedia)
      setState(prev => ({...prev, isSubmiting: true}) )
      uploadFile(token, resourceFormData)
      .then(({data: mediaURL}) => {
        const resourceData = {
          ...formState,
          class_level_ids: state.selectedClassLevelIndexes,
          sub_ids: state.selectedSubIndex,
          media: mediaURL
        }

        if (state.imagePreview) {
          const coverFormData = new FormData()
          coverFormData.append('file', state.imagePreview)
          uploadFile(token, coverFormData)
            .then(({data: coverURL}) => submit({...resourceData, cover_url: coverURL}))
            .catch((err) => {
              setState(prev => ({...prev, isSubmiting: true}) )
              apiErrorNotify({response: err.response, toast: toast})
            });
        } else submit(resourceData)
        
        })
      .catch((err) => {
        setState(prev => ({...prev, isSubmiting: true}) )
        apiErrorNotify({response: err.response, toast: toast})
      });
    
    }
    
    const check_file = (file) => {
      if(file.size/(1024*1024*5) > 1 ) {
        return "La taille fichier a dépassé la limite de 5Mo"
      }
      return null
    }

    const pickDocument = async ({media_field, type = ["video/*"]}) => {
      let result = await DocumentPicker.getDocumentAsync({multiple: false, type: type});
  
      if(result.type == 'success'){
        const errors = check_file(result)
        if(false) setError(errors)
        else {
          let { name, size, uri } = result;
          let nameParts = name.split('.');
          let fileType = nameParts[nameParts.length - 1];
          let fileData = {
            name: name,
            size: size,
            uri: uri,
            type: "application/" + fileType
          }
          setState(prev => ({...prev, [media_field]: fileData}) )
        }
          
        }
    }

    function playResource(){
      if(resource === 'videos') {
        navigation.navigate(MENU_NAVIGATION.Common.VideoReaderScreen.name, {
          init: {
            media: state.resourceMedia.uri, 
            videoProps: {
              shouldPlay: true,
              resizeMode: 'contain',
              style: {
                height: '80%'
              }
            }
          }
        })
      } else navigation.push(MENU_NAVIGATION.Common.DisplayPDF.name, {uri: state.resourceMedia.uri})

    }

    function pickResource() {
      if(resource === 'videos') pickDocument({media_field: 'resourceMedia', type: 'video/*'});
      else if(resource === 'lessons' || resource === 'exercises') pickDocument({media_field: 'resourceMedia', type: 'application/pdf'});
      setState(prev => ({...prev, imagePreview: null}));
    }

    function extractSubsFromClassLevel() {
      let classes = state.classLevels.filter(item => state.selectedClassLevelIndexes.includes(item.id))
      let res = classes.reduce((prev, current) => {
        let newSubs = []
        current.subs.forEach(sub => {
          let verite = prev.find(item => item.id === sub.id)
          if(!verite) newSubs.push(sub)
        })
        return prev.concat(newSubs)
      }, [])
      return res
    }


    useEffect(() => {
      listClassLevels(token).then(
        res => {
          setState(prev => ({...prev, classLevels: res.data}))
        }
      )
      pickResource()
    }, [setState])

    console.log(state.selectedClassLevelIndexes, state.classLevels.find(item => state.selectedClassLevelIndexes.includes(item.id)))
  return (
    <ScreenWrapper title={routeInitialValues.title} nav={navigation}>
      <ScrollView>
          <VStack px="4" py="5" space={"4"} > 
          {
          error && (
            <Modal isOpen={true} collapsable={true} onClose={() => setError(null)} >
              <Modal.Content>
                <Modal.CloseButton   />
                <Modal.Header bgColor={'red.500'}>
                  <Heading color='white' size='lg'>Attention ! </Heading>
                  
                </Modal.Header>
                <Modal.Body bg={'white'} >
                  <Center>
                    <Heading size='xs'>{error}</Heading>
                  </Center>
                </Modal.Body>
              </Modal.Content>
              
            </Modal>
          )
        }

        {
          state.resourceMedia && (
            <Pressable onPress={()=> pickDocument({media_field: 'imagePreview', type: 'image/*'})}>
                <VStack>
                  <HStack space={2} alignItems={'center'} >
                    <Icon as={AntDesign} name='camera' />
                    <Text fontWeight='thin'>Photo de présention</Text>
                    <Spacer />
                    {
                      state.imagePreview && <IconButton 
                        icon={<Icon as={AntDesign} name='delete' />}
                        onPress={() => setState(prev => ({...prev, imagePreview: null }))}
                        borderRadius='full'
                      />
                    }
                  </HStack>
                  { state.imagePreview ? <Image h='250px' w='100%' source={{uri: state.imagePreview.uri}} alt="Photo de présention" /> : (
                      resource === 'videos' ? (
                      <Video
                        style={{height: 250}}
                        resizeMode="cover"
                        source={{uri: state.resourceMedia?.uri}}
                      />)
                    : (<Box h='150px' w='100%' bg='gray.300' borderRadius={10} justifyContent='center' alignItems='center' >
                        <Text  >PDF</Text>
                      </Box>)
                  )
              }
              </VStack>
              </Pressable>
          )
        }
        
        <VStack space={3}>
          <Text alignSelf='center' >{state.resourceMedia?.name}</Text>
          <HStack justifyContent={'space-between'} >
            <Button onPress={pickResource} w="2/5" >{state.resourceMedia ? 'Changer la resource' : 'Choisir une ressource'}</Button>
            { state.resourceMedia && <Button onPress={playResource} w="2/5">Voir la resource</Button>}
          </HStack>
        </VStack>
        
            <FormControl>
              <FormControl.Label>Titre</FormControl.Label>
              <Input
                  value={formState.title}
                  onChangeText={(val) => setFormState(prev => ({...prev, title: val }))}
              />
            </FormControl>

            <FormControl>
              <FormControl.Label>Sous-titre</FormControl.Label>
              <Input
                  value={formState.subtitle}
                  onChangeText={(val) => setFormState(prev => ({...prev, subtitle: val }))}
              />
            </FormControl>

            <FormControl>
              <FormControl.Label>Description</FormControl.Label>
              <TextArea
                  value={formState.description}
                  onChangeText={(val) => setFormState(prev => ({...prev, description: val }))}
              />
            </FormControl>

            <FormControl>
              <FormControl.Label>Audience cible</FormControl.Label>
              <Select
                  selectedValue={formState.target}
                  onValueChange={(val) => setFormState(prev => ({...prev, target: val }))}
              >
                {console.log(formState.target)}

                <Select.Item value='0' label='Tout public' />
                <Select.Item value='1' label='Les Parent' />
                <Select.Item value='2' label='Les Enseignants' />
              </Select>
            </FormControl>

            <FormControl>
              <FormControl.Label>Classes</FormControl.Label>
              <SelectMultipleInput
                displayKey={'name'}
                items={state.classLevels.length === 0 ? [] : [{id: 0, name: "Toutes les classe"}].concat(state.classLevels.filter(item => item.subs.length !== 0))}
                onSelectedItemsChange={(val) => setState(prev => ({...prev, selectedClassLevelIndexes: val.includes(0) ? prev.classLevels.map(item => item.id) : val }))}
                selectedItems={state.selectedClassLevelIndexes}
                selectText="Confirmer la sélection "
                />
            </FormControl>

            {
              state.selectedClassLevelIndexes.length > 0 && (
                <FormControl>
                  <FormControl.Label>Matières</FormControl.Label>
                  <SelectMultipleInput
                    displayKey={'name'}
                    items={[{id: 0, name: "Toutes les matières"}].concat(extractSubsFromClassLevel())}
                    onSelectedItemsChange={(val) => setState(prev => ({...prev, selectedSubIndex: val.includes(0) ? extractSubsFromClassLevel().map(item => item.id) : val }))}
                // onSelectedItemsChange={(val) => setFormState(prev => ({...prev, subs_ids: val }))}
                    selectedItems={state.selectedSubIndex}
                    selectText="Confirmer la sélection "
                    />
                </FormControl>
              )
            }

            <Button
              onPress={handleSubmit}
              isLoading={state.isSubmiting}
            >Ajouter cette ressource de formation</Button>
              {/* </VStack> */}
          </VStack>
      </ScrollView>
    </ScreenWrapper>
  );
}