import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    useToast,
    Divider
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { getMyContractTeacher } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_time, from_day_index_to_day_name } from "services/utils";
import UserWithoutContract from "components/UserWithoutContract";
import NoData from "components/NoData";
import PageLoading from "components/PageLoading";
import ScreenWrapper from "components/ScreenWrapper";
import TimeplanWeekDisplay from "components/TimeplanWeekDisplay";
import TimeplanWeekStaticDisplay from "components/TimeplanWeekStaticDisplay";
import { apiErrorNotify } from "services/notify";



export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()
    
    const [ state, setState ] = useState({
        contract: null,
        subjects_affected: [],
        children: {},
        children_ids: [],
        selected_child_index: null,
        selected_child_obj: null,
        selected_week_index: null,
        current_week_index: null,
        nb_weeks: 0,
        isLoaded: false
    })
    
   
    useEffect(() => {
        getMyContractTeacher(token).then(
            res_contract => {        
                const children = {}
                // const children_ids = {}
                const contract = res_contract.data
                if(contract) {
                    const date_start = new Date(contract.start_at);
                    const date_now = new Date();
                    const diffTime = Math.abs(date_start - date_now);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    const diffWeeks = Math.floor(diffDays / 7)

                    const date_end = new Date(contract.end_at);
                    const nb_weeks = Math.floor(Math.ceil(Math.abs(date_start - date_end) / (1000 * 60 * 60 * 24)) / 7)

                    contract.subjects_affected.forEach( s_a => {
                        let child = s_a.child
                        if(!children[child.id]) children[child.id] = { child_infos: s_a.child }
                    } )
                    

                    const initial_child_id = Object.keys(children).length > 0 ? Object.keys(children)[0] : 0
                    setState(prev => ({
                        ...prev, 
                        contract: contract,
                        subjects_affected: contract.subjects_affected, 
                        children: children,
                        nb_weeks: nb_weeks,
                        selected_child_index: initial_child_id,
                        selected_week_index: diffWeeks,
                        current_week_index: diffWeeks,
                        isLoaded: true
                    }))
                } else {
                    setState(prev => ({...prev, isLoaded: true}))
                }
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )

    }, [setState])

    return (
    <ScreenWrapper title="Emploi du temps de mes élèves" nav={navigation}>
        <ScrollView>
            <VStack space={3} >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    ) : !state.contract ? <UserWithoutContract /> : (
                        <ScrollView>
                            {
                                Object.keys(state.children).length === 0 ? <NoData msg='Aucun enfant à encadrer !' /> : (
                                    <VStack space="5" px='3'>
                                        
                                        <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                            <HStack space={5}>
                                                {
                                                    Object.values(state.children).map(
                                                        (c, index) => (
                                                            <Pressable onPress={() => setState(prev => ({...prev, selected_child_index: c.child_infos.id})) } >
                                                                <Text key={index}  py='2' px='4' bg={state.selected_child_index == c.child_infos.id ? 'gray.800' : 'gray.400'} color={state.selected_child_index == c.child_infos.id ? 'white' : 'black' } borderRadius={20} >{c.child_infos.full_name}</Text>
                                                            </Pressable>
                                                        )
                                                    )
                                                }
                                            </HStack> 
                                        </ScrollView>
                                        {
                                            state.children[state.selected_child_index] && (
                                                <Box shadow={4} bg={'white'} p='3'  flex={1} borderRadius={10} >
                                                    <Heading size='lg'>{state.children[state.selected_child_index].child_infos.full_name}</Heading> 
                                                    <Text>Classe: {state.children[state.selected_child_index].child_infos.child_level}</Text>
                                                    <Text>Ecole: {state.children[state.selected_child_index].child_infos.school}</Text>
                                                    <Text>Lieu des cours: {state.children[state.selected_child_index].child_infos.place_courses}</Text>
                                                    <Text>Ville: {state.contract.city.name}</Text>
                                                </Box>
                                            )
                                        }
                                        <VStack space='1'>

                                            {
                                                state.children[state.selected_child_index] && (
                                                    <TimeplanWeekStaticDisplay _header={{
                                                        _box: {
                                                            bg: 'primary.500',
                                                        } ,
                                                        _title: {
                                                            color: 'white'
                                                        }
                                                    }} 

                                                    week_model={state.children[state.selected_child_index].child_infos.timeplan_week_model}

                                                    subjects_affected={state.contract.subjects_affected}
                                                    
                                                 />
                                                )
                                            }
                                            
                                        </VStack>
                                    </VStack>
                                )
                            }
                        </ScrollView>
                    
                    )
                }

                </VStack>

        </ScrollView>
    </ScreenWrapper>
  );
}