import { AlertDialog, Button, Center, Spacer, useDisclose } from "native-base";
import { useRef, useState } from "react";

export default ({msg, onAgree, onCancel, isOpen}) => {
   const {isOpen: open, onClose} = useDisclose(isOpen)
   const [isLoading, setLoading] = useState(false)

    const cancelRef = useRef(null);
    return <Center>
        <AlertDialog leastDestructiveRef={cancelRef} isOpen={open} onClose={onClose}>
          <AlertDialog.Content>
            {/* <AlertDialog.CloseButton /> */}
            <AlertDialog.Header>Confirmation d'une action</AlertDialog.Header>
            <AlertDialog.Body bg='white' _text={{color: 'black'}} >
              {msg}
            </AlertDialog.Body>
            <AlertDialog.Footer bg='white' >
              <Button.Group space={2}>
                <Button variant="ghost" _text={{color: 'white'}}  onPress={() => {
                  try {
                    onCancel()
                  } catch (error) {
                    onClose()
                  }
                }} ref={cancelRef}>
                  Annuler
                </Button>
                <Spacer />
                <Button bg='red.500' isLoading={isLoading} onPress={() => {
                    setLoading(true)
                    setTimeout(() => {
                        onAgree()
                        .then(() => setLoading(false))
                        .catch(() => setLoading(false))
                    }, 1000);
                }}>
                  Confirmer
                </Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </Center>;
  };