import { VStack } from "native-base"
import Content from "./Content"
import Header from "./Header"

export default (props) => (
    <VStack space='1'>
        <Header {...props} />
        <Content {...props} />
    </VStack>
)

export { Content, Header }