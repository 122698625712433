import { Button, Center, ChevronRightIcon, HStack, IconButton, Text, useDisclose } from "native-base"
import DateTimePicker from '@react-native-community/datetimepicker';
import { TimePickerModal as TimePickerWeb } from 'react-native-paper-dates'
import { forwardRef, Fragment, useEffect, useRef } from "react";
import { humanize_date_YMD, humanize_time } from "services/utils";
import { Platform } from "react-native";
import DisplayTime from "components/DisplayTime";

export default ({ value, setValue, is24Hour, ComponentClosed, ComponentOpen, ...props}) => {
    const {isOpen, onClose, onOpen} = useDisclose(false)
    const raw = `2022-01-01`+value
    let calculated_value = new Date(raw)
    // const calculated_value =  new Date(humanize_date_YMD(new Date()))
    // const calculated_value =  humanize_date_YMD(new Date())
    // console.log(`${humanize_date_YMD(new Date())} ${value ? '15:50': '12:30'}`)
    const handleChange = () => {
        if(isOpen) onClose()
        else onOpen()
    }
    
    return (
        <>
            {
                !value ? (
                    ComponentClosed ? <ComponentClosed isOpen={isOpen} onClose={onClose} onOpen={onOpen} /> : (
                        <Button onPress={handleChange} >
                            Selectionnez une heure
                        </Button>
                    )
                ) : (
                        ComponentOpen ? <ComponentClosed isOpen={isOpen} onClose={onClose} onOpen={onOpen} /> : (
                        <HStack space='2'>
                            <HStack space='1'>
                                <DisplayTime value={value} onPress={onOpen} />
                            </HStack>
                        </HStack>
                        )
                )
            }
            {
                isOpen && (
                    Platform.OS === 'web' ? (
                        <TimePickerWeb
                            visible={isOpen}
                            onDismiss={onClose}
                            onConfirm={({ hours, minutes }) => {
                                setValue(humanize_time(new Date(`01/01/2022 ${hours}:${minutes}`)))
                                onClose()
                            }}
                            label="Sélectionner l'heure" // optional, default 'Select time'
                            cancelLabel="Annuler" // optional, default: 'Cancel'
                            confirmLabel="OK" // optional, default: 'Ok'
                            animationType="fade" // optional, default is 'none'
                            locale="fr" // optional, default is automically detected by your system
                            // keyboardIcon="keyboard-outline" // optional, default is "keyboard-outline"
                            // clockIcon="clock-outline" // optional, default is "clock-outline"
                        />
                    ) : (
                        <DateTimePicker
                        testID="dateTimePicker"
                        value={isNaN(calculated_value.getDate()) ? new Date() : calculated_value }
                        mode={'time'}
                        onChange={({ nativeEvent, type }) => {
                            const val = nativeEvent.timestamp
                            if(val) {
                                setValue(humanize_time(new Date(val)))
                            }
                            onClose()
                        }}
                        is24Hour={is24Hour}
                        {...props}
                        />
                    )
                )
            }
        </>
    )
}