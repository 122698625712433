import React from 'react';
import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    Checkbox,
    TextArea,
    Modal,
    useToast,
    Spacer,
    FlatList,
    Fab,
    Icon
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { getMyContractTeacher, setTeacherRemark, setChildrenUnavailability, getMyContractTeacherFull } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_natural_time, from_day_index_to_day_name, range } from "services/utils";
import { COURSE_SESSION_STATUS } from "constant";
import UserWithoutContract from 'components/UserWithoutContract';
import NoData from 'components/NoData';
import DisplayTimeLinear from 'components/DisplayTimeLinear';
import ScreenWrapper from 'components/ScreenWrapper';
import PageLoading from 'components/PageLoading';
import { apiErrorNotify } from 'services/notify';
import TimeplanWeekSelect from 'components/TimeplanWeekSelect';
import { AntDesign } from '@expo/vector-icons';
import ModalSuccess from 'components/ModalSuccess';


export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()
    
    const [ state, setState ] = useState({
        contract_id: null,
        is_contract_expired: false,
        subjects_affected: [],
        nb_weeks: 0,
        children: {},
        selected_child_id: null,
        selected_week_index: null,
        selected_session_id: null,
        selected_session_ids: [],
        selected_session_object: null,
        current_week_index: null,
        isLoaded: false,
        isSubmiting: false,
        success: false,
    })

    // const slice_week_sessions = () => {
    //     const week_sessions = [] 
    //     [0, 1, 2, 3, 4, 5, 6].forEach(
    //         day_key => {
    //             if(state.children[state.selected_child_id]){
    //                 state.children[state.selected_child_id].weeks[state.selected_week_index][day_key].forEach(
    //                     day_session => week_sessions.push(day_session)
    //                 )
    //             }
    //         }
    //     )
    //     return week_sessions
    // }

    // const get_session_in_week = (id) => {
    //     console.log(`child ${state.selected_child_id}, week ${state.selected_week_index}, got ${id}`)
    //     if(state.selected_child_id !== null && state.selected_week_index !== null) {
    //         for (let day_index = 0; day_index <= 6 ; day_index++) {
    //             if(state.children[state.selected_child_id]){
    //                 const day_datalist = state.children[state.selected_child_id].weeks[state.selected_week_index][day_index]
    //                 for (let i = 0; i < day_datalist.length; i++) {
    //                     if(day_datalist[i].id === id) {
    //                         console.log("Found ", day_datalist[i].id)
    //                         return day_datalist[i]
    //                     }
    //                 }
    //             }
    //         };
    //     }
    //     return {}
    // } 
    // //OK Version
    // const updated_session_in_week = state.selected_session_id ? get_session_in_week(state.selected_session_id) : null

    if(state.selected_child_id !== null && state.selected_week_index !== null) {
        [0, 1, 2, 3, 4, 5, 6].forEach(
            day_name => {
                if(state.children[state.selected_child_id]){
                    const day_datalist = state.children[state.selected_child_id].weeks[state.selected_week_index][day_name]
                    for (let i = 0; i < day_datalist.length; i++) {
                        for (let index = 1; index < day_datalist.length; index++) {
                            // if( new Date(`2020-10-10 ${day_datalist[index-1].start_at}`) > `2020-10-10 ${day_datalist[index].start_at}` ){
                            if( day_datalist[index-1].start_at > day_datalist[index].start_at ){
                                let temp = day_datalist[index]
                                day_datalist[index] = day_datalist[index-1]
                                day_datalist[index-1] = temp
                            }
                            
                        }
                        
                    }
                }
        });
    } 
    
    const refresh = (prevent_update_selected_child_id = false) => {
        console.log("Before contract teacher")
        getMyContractTeacherFull(token).
        then(
            res_contract => {        
                const children = {}
                const contract = res_contract.data
                const contract_parents = {} 
                console.log(1, contract.subjects_affected.length)
                if(contract) {
                    contract.subjects_affected.forEach( s_a => {
                        let child = s_a.child
                        // if(!contract_parents[s_a.contract_parent_id]) {

                        //     contract_parents[s_a.contract_parent_id] = aw
                        // }
                        console.log(2)
                        if(!children[child.id]) children[child.id] = {weeks: {}, child_infos: s_a.child}
                        s_a.course_sessions.forEach( c_s => {
                            if(!children[child.id].weeks[c_s.week]) {
                                children[child.id].weeks[c_s.week] = {
                                    0: [],
                                    1: [],
                                    2: [],
                                    3: [],
                                    4: [],
                                    5: [],
                                    6: [],
                                }
                            } 
                            children[child.id].weeks[c_s.week][c_s.day].push({...c_s, sub: s_a.sub})
                        });
                    } )
                    console.log(3)
                    // console.log("Solof ", children[26].weeks[5])
                    const date_start = new Date(contract.start_at);
                    const date_now = new Date();
                    const diffTime = Math.abs(date_start - date_now);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    const diffWeeks = Math.floor(diffDays / 7)
                    
                    const date_end = new Date(contract.end_at);
                    const nb_weeks = Math.floor(Math.ceil(Math.abs(date_start - date_end) / (1000 * 60 * 60 * 24)) / 7)
                    console.log("ok")
                    const initial_child_id = Object.keys(children).findIndex( i => i === state.selected_child_id)  > -1
                            ? state.selected_child_id 
                            : 
                            Object.keys(children).length > 0 ? Object.keys(children)[0] : 0
                    console.log("Finish")
                    setState(prev => ({
                        ...prev, 
                        contract_id: contract.id,
                        subjects_affected: contract.subjects_affected, 
                        children: children,
                        selected_session_ids: [],
                        nb_weeks: nb_weeks,
                        selected_child_id: prevent_update_selected_child_id ? state.selected_child_id : initial_child_id,
                        selected_week_index: diffWeeks,
                        current_week_index: diffWeeks,
                        isLoaded: true,
                        isSubmiting: false,
                        is_contract_expired: date_now > date_end,
                    }))
                } else {
                    setState(prev => ({...prev, isLoaded: true}))
                }
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }

    const handleChange = (ids) => {
        setState(prev => ({...prev, selected_session_ids: ids}))
    }

    const handleCloseSuccessMsg = () => setState(prev => ({...prev, success: false}))

    const handleSubmit = async () => {
        const data = {
            sessions: state.selected_session_ids.map(s_id => ({id: s_id}))
        }
        await setState(prev => ({...prev, isSubmiting: true}))
        setChildrenUnavailability(token, data)
            .then( async res => {
                await setState(prev => ({...prev, isSubmiting: false, success: true}))
                refresh({selected_week_index: state.selected_week_index})
            })
            .catch(err => {
                apiErrorNotify({response: err.response, toast: toast})
                setState()
            })
    }

    useEffect(() => {
        refresh()

    }, [setState])

    return (
    <ScreenWrapper title="Signaler mon indisponibilité à une séance" nav={navigation}>        
        <ScrollView>
            {
                state.success && <ModalSuccess onClose={handleCloseSuccessMsg} />
            }
            <VStack space={3} >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    ) : !state.contract_id ? <UserWithoutContract /> : 
                    state.is_contract_expired ?  <NoData msg={"Votre contrat a expiré !"} /> : (
                        <ScrollView>
                            {
                                Object.keys(state.children).length === 0 ? <NoData msg='Aucun enfant à encadrer !' /> :(
                                    <VStack space="5" px='3'>
                                        {/* {console.log("render")} */}
                                        <VStack>
                                            <Heading size='xs'>Sélectionnez l'enfant</Heading>
                                            <ScrollView  horizontal showsHorizontalScrollIndicator={false} >
                                                <HStack space={5} mx='1' >
                                                    {
                                                        Object.values(state.children).map(
                                                            (c, index) => (
                                                                <Pressable onPress={() => setState(prev => ({...prev, selected_child_id: c.child_infos.id, selected_session_ids: []})) } >
                                                                    <Text key={index}  py='2' px='4' bg={state.selected_child_id == c.child_infos.id ? 'gray.800' : 'gray.400'} color={state.selected_child_id == c.child_infos.id ? 'white' : 'black' } borderRadius={20} >{c.child_infos.full_name} </Text>
                                                                </Pressable>
                                                            )
                                                        )
                                                    }
                                                </HStack> 
                                            </ScrollView>
                                        </VStack>
                                        <VStack space='1' my='5' >
                                            {
                                                range(0, state.nb_weeks).filter((_, i) => state.current_week_index <= i && i <= state.current_week_index+4).map(
                                                    w_index => (
                                                                <Box my='2' >
                                                                    <TimeplanWeekSelect
                                                                        key={w_index} 
                                                                        local_week_index={w_index} 
                                                                        weeks={state.children[state.selected_child_id].weeks} 
                                                                        index={0}
                                                                        current_week_index={state.current_week_index}
                                                                        handleChange={handleChange}
                                                                        selected_session_ids={state.selected_session_ids}
                                                                        // hide_date
                                                                        hide_week
                                                                    />
                                                                </Box>
                                                            )
                                                )
                                            }
                                        </VStack>
                                    </VStack>
                                )
                            }
                             <Fab
                                isLoading={state.isSubmiting} 
                                isDisabled={state.selected_session_ids.length === 0 ? true : false} mt='5' 
                                icon={<Icon name='save' as={AntDesign} size='sm'  />} placement='bottom-right' bottom='1/6' 
                                onPress={handleSubmit}
                            />  
                        </ScrollView>
                    
                    )
                }

                </VStack>

            </ScrollView>
    </ScreenWrapper>
  );
}