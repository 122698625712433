import axios from 'axios';

import { BASE_API_URL } from 'constant';

export const api = axios.create({
    baseURL: BASE_API_URL,
    // timeout: 1000,
});
console.log("Axios file ",  BASE_API_URL)
const authHeaders= (token)=>{
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  
  }
export const login = (data) => {
    console.log("Login to ",  BASE_API_URL)
    return api.post(`/auth/login/`, data)
}

export const signIn = (data) => {
    return api.post(`/auth/signin/`, data)
}

export const changePassword = (token, data) => {
    return api.post(`/auth/change-password/`, data, authHeaders(token))
}

export const createUserAccount = (token, data) => {
    return api.post(`/users/create-for-another/`, data, authHeaders(token))
}

export const getMeUser = (token) => {
    return api.get(`/users/me/`, authHeaders(token))
}

export const updateMeUser = (token, data) => {
  return api.put(`/users/me/`, data, authHeaders(token))
}

export const closeMyAccount = (token) => {
  return api.delete(`/users/me/`, authHeaders(token))
}

export const getTeachersNoApproved = (token) => {
    return api.get(`/users/no-approved-teachers/`, authHeaders(token))
}

export const getParentsNoApproved = (token) => {
    return api.get(`/users/no-approved-parents/`, authHeaders(token))
}

export const listApprovedTeachers = (token) => {
    return api.get(`/users/approved-teachers/`, authHeaders(token))
}

export const listApprovedTeachersWithoutContract = (token) => {
    return api.get(`/users/approved-teachers-without-contract/`, authHeaders(token))
}

export const listApprovedParents = (token) => {
    return api.get(`/users/approved-parents/`, authHeaders(token))
}

export const listApprovedParentsWithoutContract = (token) => {
    return api.get(`/users/approved-parents-without-contract/`, authHeaders(token))
}

export const listAllParents = (token) => {
    return api.get(`/users/parents/`, authHeaders(token))
}

export const listAllTeachers = (token) => {
    return api.get(`/users/teachers/`, authHeaders(token))
}

export const listAllUsers = (token) => {
    return api.get(`/users/`, authHeaders(token))
}

export const approveUser = (token, id) => {
    return api.get(`/users/${id}/approve/`, authHeaders(token))
}

export const rejectUser = (token, id) => {
    return api.get(`/users/${id}/reject/`, authHeaders(token))
}


export const resetUserPassword = (token, id) => {
  return api.post(`/auth/reset-password/${id}/`, {}, authHeaders(token))
}

export const listCities = (token) => {
  return api.get(`/cities/`, authHeaders(token))
}

export const listUsers = (token) => {
  return api.get(`/users/`, authHeaders(token))
}

export const createContractParent = (token, data) => {
  return api.post(`/contracts/parents/`, data, authHeaders(token))
}

export const updateContractParent = (token, contract_id, data) => {
  return api.put(`/contracts/parents/${contract_id}/`, data, authHeaders(token))
}

export const deleteContractParent = (token, contract_id) => {
  return api.delete(`/contracts/parents/${contract_id}/`, authHeaders(token))
}

export const updateContractTeacher = (token, contract_id, data) => {
  return api.put(`/contracts/teachers/${contract_id}/`, data, authHeaders(token))
}

export const createContractTeacher = (token, data) => {
  return api.post(`/contracts/teachers/`, data, authHeaders(token))
}
export const deleteContractteacher = (token, contract_id) => {
  return api.delete(`/contracts/teachers/${contract_id}/`, authHeaders(token))
}

export const createInvoiceParent = (token, id_contract_parent, data) => {
  return api.post(`/contracts/parents/${id_contract_parent}/invoices/`, data, authHeaders(token))
}

export const getInvoiceParent = (token, id_contract_parent, invoice_id) => {
  return api.get(`/contracts/parents/${id_contract_parent}/invoices/${invoice_id}/`, authHeaders(token))
}

export const updateInvoiceParent = (token, id_contract_parent, invoice_id, data) => {
  return api.put(`/contracts/parents/${id_contract_parent}/invoices/${invoice_id}/`, data, authHeaders(token))
}

export const soldInvoice = (token, id_contract_parent, invoice_id) => {
  return api.get(`/contracts/parents/${id_contract_parent}/invoices/${invoice_id}/sold/`, authHeaders(token))
}
export const remindInvoice = (token, id_contract_parent, invoice_id) => {
  return api.get(`/contracts/parents/${id_contract_parent}/invoices/${invoice_id}/remind/`, authHeaders(token))
}

export const revertInvoice = (token, id_contract_parent, invoice_id) => {
  return api.get(`/contracts/parents/${id_contract_parent}/invoices/${invoice_id}/revert/`, authHeaders(token))
}

export const createSalaryTeacher = (token, id_contract_teacher, data) => {
  return api.post(`/contracts/teachers/${id_contract_teacher}/salaries/`, data, authHeaders(token))
}

export const soldSalary = (token, id_contract_teacher, salary_id) => {
  return api.get(`/contracts/teachers/${id_contract_teacher}/salaries/${salary_id}/validate`, authHeaders(token))
}

export const createReportOnTeacher = (token, data) => {
  return api.post(`/contracts/parents/me/reports-on-teacher/`, data, authHeaders(token))
}

export const createReportOnChild = (token, contract_teacher_id, data) => {
  return api.post(`/contracts/teachers/${contract_teacher_id}/reports-on-child/`, data, authHeaders(token))
}

export const getMyContractParent = (token) => {
  return api.get(`/contracts/parents/me/`, authHeaders(token))
}

export const getMyContractParentFull = (token) => {
  return api.get(`/contracts/parents/me/full`, authHeaders(token))
}

export const getAnyContractParent = (token, id_contract_parent) => {
  return api.get(`/contracts/parents/${id_contract_parent}/`, authHeaders(token))
}

export const getMyContractTeacher = (token) => {
  return api.get(`/contracts/teachers/me/`, authHeaders(token))
}

export const getMyContractTeacherFull = (token) => {
  return api.get(`/contracts/teachers/me/full`, authHeaders(token))
}

export const getAnyContractTeacher = (token, id_contract_teacher) => {
  return api.get(`/contracts/teachers/${id_contract_teacher}/`, authHeaders(token))
}


export const getParentInfosFromContractParent = (token, contract_parent_id) => {
  return api.get(`/contracts/parents/${contract_parent_id}/me-user/retrieve-parent-infos/`, authHeaders(token))
}

export const getTeacherInfosFromContractParent = (token, contract_teacher_id) => {
  return api.get(`/contracts/parents/me/retrieve-teacher-infos/${contract_teacher_id}/`, authHeaders(token))
}

export const listContractsParents = (token) => {
  return api.get(`/contracts/parents/`, authHeaders(token))
}

export const listContractParentChildren = (token, contract_id) => {
  return api.get(`/contracts/parents/${contract_id}/children/`, authHeaders(token))
}


export const listContractsTeachers = (token) => {
  return api.get(`/contracts/teachers/`, authHeaders(token))
}

export const listContractTeacherChildren = (token, contract_id) => {
  return api.get(`/contracts/teachers/${contract_id}/children/`, authHeaders(token))
}

export const listContractChildMarks = (token, contract_id, child_id ) => {
  return api.get(`/contracts/parents/${contract_id}/children/${child_id}/marks/`, authHeaders(token))
}

export const listSubs = (token) => {
  return api.get(`/subjects/`, authHeaders(token))
}
export const orderTeacher = (token, data) => {
  return api.post(`/order-teacher/`, data, authHeaders(token))
}

export const orderTeacherNoLogin = (data) => {
  return api.post(`/order-teacher/public`, data)
}

export const setChildrenUnavailability = (token, ids) => {
  return api.post(`/course-sessions/unavailability/`, ids, authHeaders(token))
}

export const setTeacherUnavailability = (token, ids) => {
  return api.post(`/course-sessions/unavailability/`, ids, authHeaders(token))
}

export const setParentRemark = (token, id, remark) => {
  return api.put(`/course-sessions/${id}/parent-remark/`, remark, authHeaders(token))
}

// Old version
export const setTeacherRemark = (token, id, remark) => {
  return api.put(`/course-sessions/${id}/teacher-remark/`, remark, authHeaders(token))
}

export const createTeacherRemark = (token, contract_teacher_id, child_id, remark) => {
  return api.post(`/contracts/teachers/${contract_teacher_id}/children/${child_id}/teacher-remark/`, remark, authHeaders(token))
}

export const createParentRemark = (token, contract_parent_id, child_id, remark) => {
  return api.post(`/contracts/parents/${contract_parent_id}/children/${child_id}/parent-remark/`, remark, authHeaders(token))
}

export const validateCourseSession = (token, id) => {
  return api.get(`/course-sessions/${id}/validate/`, authHeaders(token))
}

export const createPrestationByTeacher = (token, data) => {
  return api.post(`/prestations/`, data, authHeaders(token))
}

export const validatePrestation = (token, prest_id) => {
  return api.get(`/prestations/${prest_id}/validate/`, authHeaders(token))
}

export const listPrestationsByParent = (token) => {
  return api.get(`/prestations/parent/me/`, authHeaders(token))
}

export const listPrestationsByTeacher = (token) => {
  return api.get(`/prestations/teacher/me/`, authHeaders(token))
}

export const updatePrestationsByTeacher = (token, prest_id, data) => {
  return api.put(`/prestations/${prest_id}/by-teacher/`, data, authHeaders(token))
}

export const listPrestationsForAnyUser = (token, id_user) => {
  return api.get(`/prestations/user/${id_user}/`, authHeaders(token))
}

export const updateChild = (token, contract_id, child_id, data) => {
  return api.put(`/contracts/parents/${contract_id}/children/${child_id}/`, data, authHeaders(token))
}

export const deleteChild = (token, contract_id, child_id) => {
  return api.delete(`/contracts/parents/${contract_id}/children/${child_id}/`, authHeaders(token))
}

export const addChildToContract = (token, contract_id, data) => {
  return api.put(`/contracts/parents/${contract_id}/add-child/`, data, authHeaders(token))
}


export const addSASToContract = (token, contract_id, data) => {
  return api.put(`/contracts/teachers/${contract_id}/add-subjects/`, data, authHeaders(token))
}


export const removeSASfromContract = (token, contract_id, data) => {
  return api.put(`/contracts/teachers/${contract_id}/remove-subjects/`, data, authHeaders(token))
}

export const createChildMark = (token, contract_id, child_id, data) => {
  return api.post(`/contracts/parents/${contract_id}/children/${child_id}/marks/`, data, authHeaders(token))
}

export const updateChildMark = (token, contract_id, child_id, mark_id, data) => {
  return api.put(`/contracts/parents/${contract_id}/children/${child_id}/marks/${mark_id}/`, data, authHeaders(token))
}


export const removeChildMark = (token, contract_id, child_id, mark_id) => {
  return api.delete(`/contracts/parents/${contract_id}/children/${child_id}/marks/${mark_id}/`, authHeaders(token))
}

export const listNewMsgs = (token) => {
  return api.get(`/msg/new/`, authHeaders(token))
}

export const listMsgs = (token) => {
  return api.get(`/msg/`, authHeaders(token))
}

export const createMsgMulti = (token, data) => {
  return api.post(`/msg/multi/`, data, authHeaders(token))
}

export const markMsgRead = (token, data) => {
  return api.post(`/msg/mark-as-read/`, data, authHeaders(token))
}

export const createBroadcast = (token, data) => {
  return api.post(`/msg/broadcast/`, data, authHeaders(token))
}

export const listBroadcastForMe = (token) => {
  return api.get(`/msg/broadcast/`, authHeaders(token))
}

export const createCity = (token, data) => {
  return api.post(`/cities/`, data, authHeaders(token))
}

export const updateCity = (token, city_id, data) => {
  return api.put(`/cities/${city_id}/`, data, authHeaders(token))
}

export const createSub = (token, data) => {
  return api.post(`/subjects/`, data, authHeaders(token))
}

export const updateSub = (token, sub_id, data) => {
  return api.put(`/subjects/${sub_id}/`, data, authHeaders(token))
}

export const listClassLevels = (token) => {
  return api.get(`/classlevels/`, authHeaders(token))
}

export const listLearningVideos = (token, filters) => {
  return api.post(`/learning/videos/list`, filters, authHeaders(token))
}

export const listLearningLessons = (token, filters) => {
  return api.post(`/learning/lessons/list`, filters, authHeaders(token))
}

export const listLearningExercises = (token, filters) => {
  return api.post(`/learning/exercises/list`, filters, authHeaders(token))
}

export const createLearningVideo = (token, data) => {
  return api.post(`/learning/videos`, data, authHeaders(token))
}

export const createLearningLesson = (token, data) => {
  return api.post(`/learning/lessons`, data, authHeaders(token))
}

export const createLearningExercise = (token, data) => {
  return api.post(`/learning/exercises`, data, authHeaders(token))
}

export const updateLearningVideo = (token, id, data) => {
  return api.put(`/learning/videos/${id}`, data, authHeaders(token))
}

export const updateLearningLesson = (token, id, data) => {
  return api.put(`/learning/lessons/${id}`, data, authHeaders(token))
}

export const updateLearningExercise = (token, id, data) => {
  return api.put(`/learning/exercises/${id}`, data, authHeaders(token))
}

export const deleteLearningVideo = (token, id) => {
  return api.delete(`/learning/videos/${id}`, authHeaders(token))
}

export const deleteLearningLesson = (token, id) => {
  return api.delete(`/learning/lessons/${id}`, authHeaders(token))
}

export const deleteLearningExercise = (token, id) => {
  return api.delete(`/learning/exercises/${id}`, authHeaders(token))
}

export const uploadFile = (token, data) => {
  const head = authHeaders(token, data)
  return api.post(`/users/upload/`, data, {
    headers: {
      ...head.headers,
      'content-type': 'multipart/form-data'
    }
  })
}

export const getFile = (token, file_name) => {
  return api.get(`/static/${file_name}`, authHeaders(token))
}

export const getFileLink = (file_name = "") => {
  return file_name.includes("://") ? file_name : `${BASE_API_URL}/static/${file_name}`
}

export const listConfigs = (token) => {
  return api.get(`/configs/`, authHeaders(token))
}