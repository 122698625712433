export const apiErrorNotify = ({response, toast, toastProps= {}}) => {
    // console.log(response)
    let title = "Une erreur s'est produite";
    let description =  "Vérifiez votre connection et réessayez";
    const localProps = {} 

    if(response) {
        console.log(response.data)
        
        if(response.data) {
            const data = response.data
            // console.log(data)
            if( typeof data.detail === 'string' ) description = data.detail
            else if(typeof data.detail == 'object') {
                if(data.detail.msg) description = data.detail.msg
            }
        }

        if (response.status === 0) {
            title = "Vous n'êtes pas connecté à internet"
            description = ""
            // localProps
        } else if (response.status === 422) {
            description = "Assurez-vous d'avoir renseigné les informations correctes"
            console.log(response.data.detail)
        }


        else if (response.request._response) {
            try{
                let _r = JSON.parse(response.request._response)
                if(typeof _r  === 'string') title = response.request._response
            } catch{
                title = "Une erreur inatendue est survenue";
                description = "Si le problème persiste, veuillez contacter la direction !"
            }
        } 
        
       
         
        toast.show({
            title:  title,
            variant: "subtle",
            status: 'warning',
            description: description ? description : null,
            bg: 'red.500',
            ...toastProps
        })
    }

}