import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast,
    Divider,
    Modal,
    Spacer,
    IconButton,
    CloseIcon,
    AddIcon,
    Icon
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_natural_time, from_day_index_to_day_name, humanize_date_YMD } from "services/utils";
import { apiErrorNotify } from "services/notify";
import TimeInput from "components/TimeInput";
import DateTimeInput from "components/DateTimeInput";
import DisplayTime from "components/DisplayTime";

import ModalAddSubject from 'stacks/DirectionStack/screens/UpdateContractparent/components/ModalAddSubject';
import DisplayTimeLinear from "components/DisplayTimeLinear";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import { useSelector } from "react-redux";

export default ({isOpen, onClose, onOpen, onSubmit, subs, subjects_affected, initialValues= {}, index, label}) => {
    const token = getToken()
    const toast = useToast()
    const navigation = useNavigation()
    const [state, setState] = useState({
      subs: subs,
      timeplan_week_model: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: []
      },
      addSub: false,
      editSub: false,
      dayToAdd: null,
      isSubmitting: false,
      selected_s_a: null,
      sa_index_in_day: null
    })

    // console.log(initialValues)
  
    const handleSubmit = () => {
      // console.log(state.timeplan_week_model)
      setState(prev => ({...prev, isSubmitting: true}))  
      const local_timeplan = {}  
      for(let key in state.timeplan_week_model){
        local_timeplan[key] = []
        state.timeplan_week_model[key].forEach(
          (_, i) => local_timeplan[key].push({
            start_at: state.timeplan_week_model[key][i].start_at,
            end_at: state.timeplan_week_model[key][i].end_at,
            sub_id: state.timeplan_week_model[key][i].sub_id,
          })
        )
      }
      onSubmit({
        values: {
          timeplan_week_model: local_timeplan
        },
        index: index
      })
      .then((r) =>  {
        console.log(r)
        // s
        onClose()
      })
      // .catch(onClose)
      .catch(err =>  setState(prev => ({...prev, isSubmitting: false})))
    }
  
    const handleRemoveTimeLine = (day, index) => {
      setState(prev => {
        const length = prev.timeplan_week_model[day].length
        const day_program = prev.timeplan_week_model[day]
        
        return {
          ...prev,
          timeplan_week_model: {
            ...prev.timeplan_week_model,
            [day]: day_program.slice(0, index).concat(day_program.slice(index+1, length))
          }
        }
      })
      
    }
  
    const handleAddSubject = (values) => {
      setState(prev => {
        prev.timeplan_week_model[state.dayToAdd].push(values)
        return {...prev, addSub: false  }
      })
      
    }
  
    const handleEditSubject = (values) => {
      if(typeof state.sa_index_in_day === 'number'){
        setState(prev => {
          prev.timeplan_week_model[state.dayToAdd][state.sa_index_in_day] = values
          // console.log("edit timeplan_week_model ", prev.timeplan_week_model[state.dayToAdd])
          return {...prev, editSub: false  }
        })
    
      }
    }
  
    const handleCancelSub = () => setState(prev => ({...prev, addSub: false, editSub: false, selected_s_a: null, sa_index_in_day: null })) 
  
    useEffect(()=> {
        // console.log(initialValues)
        if(initialValues.timeplan_week_model) {
            const local_timeplan = {
              0: [],
              1: [],
              2: [],
              3: [],
              4: [],
              5: [],
              6: [],
            ...initialValues.timeplan_week_model
            }
            setState(prev => ({...prev, timeplan_week_model: local_timeplan}))
        }
    }, [setState])
  
    return (
      <Actionsheet isOpen={isOpen} onClose={onClose} collapsable={true} >
        <Actionsheet.Content bg="white" >
            <ScrollView showsVerticalScrollIndicator={false} bg="white" w="100%" >
              <VStack>
                <Heading size='lg' color='orange.500' >Emploi du temps des cours à domicile</Heading>
                <VStack space='10' my='4' >
                  {
                    Object.keys(state.timeplan_week_model).map(
                      day_number => (
                        <VStack key={day_number} alignContent='space-between' >
                          <HStack px='3' justifyContent={'space-between'} >
                            <Heading size='md'>{from_day_index_to_day_name(day_number)}</Heading>
                            <IconButton 
                                // size='lg' 
                                borderRadius={'full'} 
                                icon={
                                  <AddIcon size='sm' color='green.500' />
                                }
                                _pressed={{
                                  bg: 'gray.300',
                                  color: 'black'
                                }}
                              onPress={() => setState(prev => ({...prev, addSub: true, dayToAdd: day_number })) }
                              />
                            {/* <Button  onPress={() => setState(prev => ({...prev, addSub: true, dayToAdd: day_number })) } >Ajouter une matière</Button> */}
                          </HStack>
                          <VStack>
                            {
                              state.timeplan_week_model[day_number].map(
                                (s_a, index) => {
                                  const currentSub = s_a.sub_id ? state.subs.find(s => s.id == s_a.sub_id) : state.subs.find(s => s.id == s_a.sub.id)
                                  const currentSubjectAffected = subjects_affected?.find(__sa => __sa.sub.id === currentSub?.id)
                                  return (
                                    <VStack key={index}
                                      // borderLeftWidth={1} 
                                      // borderRadius={0} 
                                      pl='2'
                                      
                                    >
                                      <VStack py='4'>
                                        <HStack 
                                          
                                          alignContent='center' 
                                          space='1'
                                          >
                                          <HStack>
                                            <Icon 
                                                as={AntDesign}
                                                name='delete'
                                                alignSelf={'center'}
                                                size='sm'
                                                color='red.500'
                                                _pressed={{
                                                  bg: 'red.500',
                                                  color: 'white'
                                                }}
                                                onPress={() => handleRemoveTimeLine(day_number, index)}
                                                />
                                          </HStack>
                                          <HStack space='0.5'  alignContent={'center'} >
                                            <DisplayTimeLinear fontSize={10} value={s_a.start_at} />
                                            <Text>-</Text>
                                            <DisplayTimeLinear fontSize={10} value={s_a.end_at} />
                                          </HStack>
                                          {/* <Spacer /> */}
                                          <Text fontSize={14} 
                                            onPress={() => setState(prev => ({...prev, editSub: true, dayToAdd: day_number, selected_s_a: s_a, sa_index_in_day: index }))} 
                                          >
                                            {currentSub?.name}
                                            
                                        </Text>
                                          <Spacer />
                                          
                                        </HStack>

                                        {
                                          currentSubjectAffected?.contract_teacher && (
                                            <HStack space='3' pl='8' pr='10' alignItems={'center'} >
                                              {/* <Spacer /> */}

                                              <Text fontSize={11} fontWeight={'thin'}>Encadré par </Text>
                                              <Text fontSize={12} bold>{currentSubjectAffected?.contract_teacher?.teacher?.full_name || currentSubjectAffected?.contract_teacher?.teacher?.username}</Text>
                                              
                                              <Icon 
                                                as={AntDesign}
                                                  name='eye'
                                                  size='sm'
                                                  color='orange.500'
                                                  _pressed={{
                                                    bg: 'orange.200',
                                                    color: 'white'
                                                  }}
                                                  onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.UpdateContract.name, {
                                                    init: {
                                                      contract: {
                                                        id: currentSubjectAffected?.contract_teacher?.id
                                                      }
                                                    }
                                                  })}
                                                />
                                            </HStack>                                      
                                          )
                                        }
                                      </VStack>
                                      { state.timeplan_week_model[day_number]?.length-1 != index && <Divider bg='gray.300' px='15' /> }
                                    </VStack>
                                  )
                                }
                              )
                            }
                          </VStack>
                        </VStack>
                      )
                    )
                  }
                </VStack>
                <Button onPress={handleSubmit} isLoading={state.isSubmitting} >{"Modifier l'emploi du temps"}</Button>
                  {
                          state.dayToAdd !== null && (
                            <>
                              {
                                state.addSub ? (
                                  <ModalAddSubject  
                                    dayToAdd={state.dayToAdd} 
                                    subs={state.subs} 
                                    onCancel={handleCancelSub}
                                    initialValues={{}}
                                    onSubmit={handleAddSubject}
                                    label='Ajout'
                                    />
          
                                ) : state.editSub && (
                                  <ModalAddSubject  
                                  label='edition'
                                    dayToAdd={state.dayToAdd} 
                                    subs={state.subs} 
                                    onCancel={handleCancelSub}
                                    initialValues={state.selected_s_a}
                                    onSubmit={handleEditSubject}
                                    />
                                )
                              }
                            </>
                          )
                        }
              </VStack>
            </ScrollView>
          </Actionsheet.Content>
    </Actionsheet>
    )
  }