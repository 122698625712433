import Annonce from "components/Annonce";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
	Actionsheet,
	Input,
	Pressable,
	useToast,
	FormControl,
	Select,
	CheckIcon,
	Icon,
	Modal
  } from "native-base";
import { Image, StyleSheet } from 'react-native';
import SwitchColorMode from 'components/SwitchColorMode'
import { Layout } from "components/Layout";
import { MasonaryLayout } from "components/MasonaryLayout";
// import { useGetTeachersNoApprovedQuery, useLazyLoginQuery } from "services/api-rest";
import { listCities, signIn as signInAPI } from 'services/api-axios'
import { useState } from "react";
import { useLogin } from "store/login/hooks";
import { getLoginState, getToken } from "store/login/selectors";
import {BACKEND_API} from "@env"
import { BASE_API_URL } from "constant";
import { apiErrorNotify } from "services/notify";
import { MENU_NAVIGATION } from "constant";
import { useEffect } from "react";
import { FontAwesome } from "@expo/vector-icons";
import { delay } from "services/utils";
import PasswordInput from "components/PasswordInput";


export default ({signinData, setSign}) =>{

    return (
        <>
            			<FormControl isRequired>
							<FormControl.Label>Nom et prénoms</FormControl.Label>
							<Input 
								
								value={signinData.full_name} 
								placeholder="Nom et prénoms"
								onChangeText={(val) => setSign(p => ({...p, full_name: val}))} />
						</FormControl>

						<FormControl isRequired>
							<FormControl.Label>Numéro de téléphone</FormControl.Label>
							<Input 
								
								type='number'
								keyboardType="number-pad"
								value={signinData.username} 
								placeholder="Numéro de téléphone"
								onChangeText={(val) => setSign(p => ({...p, username: val}))} />
						</FormControl>

						<FormControl isRequired>
							<FormControl.Label>Mot de passe</FormControl.Label>
							<PasswordInput 
								value={signinData.password} 
								rightElement={ signinData.password && signinData.password === signinData.password2 && <CheckIcon mr='2' size="5" mt="0.5" color="emerald.500" />}
								placeholder="Mot de passe"
								onChangeText={(val) => setSign(p => ({...p, password: val}))} />
						</FormControl>
						
						<FormControl isRequired>
							<FormControl.Label>Confirmer le mot de passe</FormControl.Label>
							<PasswordInput 
								rightElement={ signinData.password && signinData.password === signinData.password2 && <CheckIcon mr='2' size="5" mt="0.5" color="emerald.500" />}
								value={signinData.password2} 
								placeholder="Confirmer le mot de passe"
								onChangeText={(val) => setSign(p => ({...p, password2: val}))} />
						</FormControl>
        </>
    )
} 