import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { HStack, VStack, Box, Icon, Spinner } from "native-base";

import WelcomeStack from "stacks/WelcomeStack";
import SettingsStack from 'stacks/SettingsStack'

import { MaterialIcons,  } from '@expo/vector-icons';
import UserStack from 'stacks/UserStack';
import { getLoginState, getToken, useIsLoggedIn } from "store/login/selectors";
import LoginScreen from "screens/LoginScreen";
import { useState } from "react";
import { persist_return, get_item, set_item } from "services/persistence";
import { MENU_NAVIGATION } from "constant";
import { useEffect } from "react";

import { useLoadLoginState } from "store/login/hooks";
import { STORAGE_NAME as STORAGE_NAME_LOGIN } from "store/login/reducer";
import { getMeUser } from "services/api-axios";
import LoginStack from "stacks/LoginStack";
import AppBar from "components/AppBar";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NOTIFICATION_STORAGE_NAME } from "store/notifications/reducer";
import { useClearNotications, useLoadNotifications } from "store/notifications/hooks";

const Tab = createNativeStackNavigator();

export default () => {
  const reloadReduxLoginStore = useLoadLoginState()
  const reloadReduxNotificationsStore = useLoadNotifications()
  const clearNotif = useClearNotications()

  const isLogged = useIsLoggedIn()
  const token = getToken()
  const [isAppLoaded, setAppLoad] = useState(false)

  useEffect(() => {
    get_item(STORAGE_NAME_LOGIN)
    .then((res) => {
      if(res) {
        const stored_user_data = JSON.parse(res)
        console.log("Local ", res, typeof res)
        getMeUser(token).then(
          ({data}) => {
            console.log("User data got")
            reloadReduxLoginStore(data)
            setTimeout(()=>setAppLoad(true), 1000)
          }
        ).catch(
          err => {
            // useNotify()
            console.log("Server not reched for getting user me")
            reloadReduxLoginStore(stored_user_data)
            setTimeout(()=>setAppLoad(true), 1000)
          }
        )
      } else {
        reloadReduxLoginStore({})
        setTimeout(()=>setAppLoad(true), 1000)
      }
    })
    .catch((err) => {
      console.log("errr ", err)
      reloadReduxLoginStore({})
      setTimeout(()=>setAppLoad(true), 1000)
    })
    .finally(() => console.log("Finally"))

    get_item(NOTIFICATION_STORAGE_NAME)
    .then( res => {
      const datum = JSON.parse(res)
      console.log("3DATUM ", datum)
      if(datum && datum.length > 0) {
        // clearNotif()
        console.log("RELOAD DATUM")
        reloadReduxNotificationsStore(datum)
      }
    })
    .catch(console.warn)

  }, [])

  return (
    <>
      {
        !isAppLoaded ? <Spinner /> :  (
          <>
            {
              !isLogged ? <LoginStack /> : (
                <>
                  <AppBar key={new Date()} /> 
                  <Tab.Navigator screenOptions={{ 
                    headerShown: false,
                    header: props => <AppBar {...props} /> 
                  }} >
                    <Tab.Screen name={MENU_NAVIGATION.User.stackname}
                      component={UserStack}
                      options={{ 
                        title: '', 
                        tabBarIcon: (styleProps) => {
                          return (<Icon as={MaterialIcons} color={styleProps.focused ? 'purple.700' : styleProps.color } name="supervisor-account" size="lg" />)
                        },
                      }}
                    />
                    <Tab.Screen name={MENU_NAVIGATION.Settings.stackname}
                      component={SettingsStack}
                      options={{ 
                        title: '', 
                        tabBarIcon: (styleProps) => {
                          return (<Icon as={MaterialIcons} color={styleProps.focused ? 'purple.700' : styleProps.color } name="settings" size="lg" />)
                        },
                      }}
                    />
                  </Tab.Navigator>

                </>
              )
            }
          </>
        ) 
    }

    </>
  )
}