import Annonce from "components/Annonce";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";
import { INVOICE_STATUS } from "constant";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { listContractsParents } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { format_price, humanize_natural_date } from "services/utils";
import { getToken } from "store/login/selectors";

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const redGrad = {
        linearGradient: {
            colors: [
                'red.500',
                'orange.500',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const [state, setState] = useState({
        contracts: [],
        isLoaded: false,
        error: false,
    })
    useEffect(()=> {
        listContractsParents(token)
            .then(res => {
                setState(prev => ({
                    ...prev, 
                    contracts: res.data,
                    isLoaded: true
                }))
            })
            .catch(err => setState(prev => ({...prev, error: err.data.detail})) )

    }, [setState ])
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  return (
    <ScreenWrapper title="Liste des factures de parents" nav={navigation}>
        <ScrollView>
            <VStack px="2" py="5" > 
                <VStack space={4}>
                    {
                        !state.isLoaded ? (
                            <Center  >
                                <HStack space={2} justifyContent="center">
                                <Spinner />
                                <Heading color="primary.500" fontSize="md">
                                    Chargement
                                </Heading>
                            </HStack>
                            </Center>
                        ) : state.contracts.length === 0 ? <NoData /> : (
                            <>
                                {
                                    state.contracts.map(
                                        contract => (
                                                <Box key={contract.id} bg={'white'} shadow='4' p='3' mx='1' borderRadius={10} >
                                                    <Heading color="primary.500">{contract.parent.full_name}</Heading>
                                                    <HStack justifyContent={'space-between'}>
                                                        <Heading alignSelf={'center'} color="primary.500" size='xs' >Montant du contrat: </Heading>
                                                        <Heading color='orange.400' size='md'>{format_price(contract.price)}F</Heading>    
                                                    </HStack>
                                                    <HStack justifyContent={'space-between'}>
                                                        <Heading alignSelf={'center'} color="primary.500" size='xs' >Factures réglées: </Heading>
                                                        <Heading color='green.600' size='sm'>{contract.invoices.filter( s => s.status === INVOICE_STATUS.achieved).length} / {contract.invoices.length} </Heading>    
                                                    </HStack>
                                                    {/* <HStack justifyContent={'space-around'} space='4' >
                                                        <Text fontSize={12} >Du {humanize_natural_date(new Date(contract.start_at))}</Text>
                                                        <Text fontSize={12}>Au {humanize_natural_date(new Date(contract.end_at))}</Text>
                                                    </HStack> */}
                                                    
                                                    <HStack justifyContent={'space-between'} >
                                                        <Button size='xs' bg='green.600' onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.CreateInvoice.name, {init: { selected_contract_id: contract.id }}) } >Créer une nouvelle facture</Button>
                                                        <Button size='xs' onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ListInvoices.name, {init: {contract_id: contract.id}}) } >Factures associées</Button>    
                                                    </HStack>
                                            </Box>
                                        )
                                    )
                                }
                            </>
                        )
                    }
                    
                </VStack>
        </VStack>
            </ScrollView>
    </ScreenWrapper>
  );
}