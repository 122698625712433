import { StatusBar, Box, HStack, IconButton, Text, Icon, Image, Avatar, Center, Badge, VStack, Button, Modal, useDisclose, FlatList, Spacer, useToast, CircleIcon, Spinner, CheckIcon, Heading, CloseIcon } from "native-base";
import { AntDesign, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import Logo from 'assets/logo.png'
import { getLoginState, getToken, getUserAvatar, getUsername } from "store/login/selectors";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { Pressable, RefreshControl } from "react-native";
import { getFile, getFileLink, listMsgs, listNewMsgs, markMsgRead } from "services/api-axios";
import { MSG_STATUS } from "constant";
import { humanize_date, humanize_date_YMD, humanize_natural_date, humanize_time } from "services/utils";
import { apiErrorNotify } from "services/notify";
import GoBack from "components/GoBack";
import Notifications, { registerForPushNotificationsAsync } from "services/push-notifiy";
import { MENU_NAVIGATION } from "constant";
import { useNavigation } from "@react-navigation/native";



export default function ({ ...rest }) {
    const navigation = useNavigation()
    
    const toast = useToast()

    const blueGrad = {
      linearGradient: {
          colors: [
              '#0D2443',
              '#90C5FC',
          ],
          start: [0, 0],
          end: [1, 1],
      }
    }
    const { isOpen, onClose, onOpen } = useDisclose()
    
    return <>
        <HStack minH={'10px'} bg={'primary.500'} px="3" py="2" pt="4" alignItems="flex-end" w="100%">
          
          <Button variant={'unstyled'} _text={{color: 'white'}}>AS TRAINING</Button>

          <Spacer />    

          <Button bg='white' 
          _text={{color: 'primary.500'}}
          onPress={() => navigation.navigate(MENU_NAVIGATION.Login.Login.name)}
          >Se connecter / S'inscrire</Button>

                
        </HStack>
 
      </>;
  }