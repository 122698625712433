import { AntDesign, Entypo, MaterialIcons } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import ContactOptioinsStagger from "components/ContactOptioinsStagger";
import NoData from "components/NoData";
import PageLoading from "components/PageLoading";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION, INVOICE_STATUS } from "constant";

import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider,
    useToast,
    Icon,
    FlatList,
    Fab,
    IconButton
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { SwipeListView, SwipeRow } from "react-native-swipe-list-view";
import { getAnyContractTeacher, soldSalary } from "services/api-axios";
import { get_screen_height, get_window_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import { format_price, humanize_natural_date } from "services/utils";
import { getToken } from "store/login/selectors";
import SalaryItem from "./components/SalaryItem";


export default ({ navigation, route }) => {
    const routeInitialValues = route.params ? route.params.init : {}
    const toast = useToast()
    const token = getToken()

    const [state, setState] = useState({
        contract: null,
        isLoaded: false,
    })

    const handleSoldSalary = (inv_id) => {
        return soldSalary(token, routeInitialValues.contract_id, inv_id)
    }

    useEffect(()=> {
        getAnyContractTeacher(token, routeInitialValues.contract_id)
            .then(res => {
                setState(prev => ({
                    ...prev, 
                    contract: res.data,
                    isLoaded: true
                }))
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}) )

    }, [setState])
    
    
  return (
    <ScreenWrapper title={"Les factures d'un enseignant "} nav={navigation}>
        <ScrollView   >
            <VStack minH={get_screen_height()/2} >
                {
                    state.isLoaded && state.contract && (
                        // <HStack justifyContent={'flex-end'} mr='4' top={50} >
                            <ContactOptioinsStagger phone={state.contract.teacher.username} />
                        // </HStack>
                    )
                }
                <>
                    {
                        !state.isLoaded ? <PageLoading /> : 
                        !state.contract ? (
                            <NoData />
                        ) : state.contract.salaries.length === 0 ? <NoData /> : (
                            <VStack flex={1}     >
                                
                                <VStack space={4} zIndex={1} >
                                    <VStack>
                                        <Heading color="gray.600" size='md' alignSelf={'center'}>Le contrat</Heading>
                                        <VStack mx='3' shadow='5' bg={'gray.100'} p='3' borderRadius={10} >
                                            <HStack space='3' >
                                                <Text fontSize={12} alignSelf={'center'} color="black">Nom de l'enseignant:</Text>
                                                <Heading size='md' color="green.600">{state.contract.teacher.full_name}</Heading>
                                            </HStack>
                                            <HStack>
                                                <Text fontSize={12} alignSelf={'center'} color="black">Montant du contract :  </Text>
                                                <Heading color="green.600">{format_price(state.contract.price)} F</Heading>
                                            </HStack>
                                            <HStack justifyContent={'space-between'} >
                                                {/* <VStack> */}
                                                    <Text fontSize={12} >Du {humanize_natural_date(new Date(state.contract.start_at))}</Text>
                                                    <Text fontSize={12}>Au {humanize_natural_date(new Date(state.contract.end_at))}</Text>
                                                {/* </VStack> */}
                                            </HStack>
                                            <Text fontSize={14} >Date de signature : {humanize_natural_date(new Date(state.contract.signed_at))} </Text>
                                        </VStack>
                                    </VStack>

                                    <VStack>
                                        <Heading color='gray.600' size='md' alignSelf={'center'} >Les salaires </Heading>
                                        <VStack space={3} mx='2' >
                                            {
                                                <>
                                                        <FlatList
                                                            data={state.contract.salaries.sort((i, j) => i.status !== INVOICE_STATUS.pending )}
                                                            keyExtractor={item => item.id}
                                                            renderItem={({item}) => <SalaryItem invoice={item} toast={toast} handleSubmit={handleSoldSalary} _wrapper={{my: '1'}} />}
                                                        />
                                                </>
                                                
                                            }
                                        </VStack>
                                    </VStack>
                                </VStack>
                            </VStack>
                        )
                    }
                    
                </>

            </VStack>
        {/* </VStack> */}
            </ScrollView>
    </ScreenWrapper>
  );
}