import { View, Text, Button, Heading, HStack, Spacer, VStack, Box, Center, ScrollView, IconButton, ChevronLeftIcon, Input, useDisclose, useToast } from 'native-base'
import { useLogout } from 'store/login/hooks';
import {BACKEND_API} from "@env"
import { useRef, useState } from 'react';
import { Pressable } from 'react-native';
import { MENU_NAVIGATION } from 'constant';
import ScreenWrapper from 'components/ScreenWrapper';
import NumberInput from 'components/NumberInput';
import { closeMyAccount } from 'services/api-axios';
import { getToken } from 'store/login/selectors';
import { apiErrorNotify } from 'services/notify';


export default function ({ navigation }) {
  const [code, setCode] = useState(Math.ceil((Math.random().toFixed(4) * 10000)).toString())
  const [value, setValue] = useState("")
  const { isOpen, onOpen, onClose } = useDisclose(false)
  const logout = useLogout()
  const token = getToken()
  const toast = useToast()

  const submit = () => {
    onOpen()
    
    closeMyAccount(token)
    .then(res => {
      navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {
        color: "danger.500",
        update: logout
      })

      setTimeout(logout, 3000);
    }).catch(err => {
      onClose()
      apiErrorNotify({ response: err, toast: toast })
    })
  }
  const ref = useRef()
  
    return (
      <ScreenWrapper nav={navigation} title="Supprimer mon compte AS - TRAINING">
        <ScrollView>
          <VStack space="4" px="4" pb="5" >
            <Heading color={'danger.500'} >Cette action est irréversible</Heading>
            <Text>En supprimant votre compte, vous ne pourrez plus vous connecter ni vous inscrire avec avec le même numéro de téléphone</Text>
            <Text bold> Pour confirmer la suppression immédiate de votre compte, veuillez d'abord saisir le code de vérification ci-dessous</Text>
            <Center>
              <Heading size={"xl"} letterSpacing={15} >{code}</Heading>
            </Center>
             <NumberInput
                    ref={ref}
                    w='150px'
                    alignSelf='center'
                    fontSize={24}
                    maxLength={code.length}
                    value={value}
                    onChangeText={setValue}
                />
            <Button
            mt='7'
              bg='danger.500'
              isLoading={isOpen}
              isDisabled={value !== code}
              onPress={submit}
            >Supprimer définitivemnt</Button>
          </VStack>
        </ScrollView>
      </ScreenWrapper>
    );
}