import Annonce from "components/Annonce";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue
  } from "native-base";
  import { Image, Pressable } from 'react-native';

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
  return (
    <ScreenWrapper title="Gestion des comptes tous les utilisateurs" nav={navigation}>
        <ScrollView>
            <VStack px="4" pb="5" space={"4"} >
                {/* <Heading m='3'>Gestion des comptes tous les utilisateurs</Heading> */}
                <HStack space={5}  >
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageUsers.CreateUserAccount.name)}>
                            <Center height="100%"><Text fontSize={18} color="white">Créer un nouveau compte</Text></Center>
                        </Pressable>
                    </Box>
                    {/* <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.RejectProfile.name) }>
                            <Center height="100%"><Text fontSize={18} color="white">Retirer des profils</Text></Center>
                        </Pressable>
                    </Box> */}
                </HStack>
                <HStack space={5}  >
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageUsers.ListUserAccounts.name)}>
                            <Center height="100%"><Text fontSize={18} color="white">Liste des comptes</Text></Center>
                        </Pressable>
                    </Box>
                </HStack>
                <HStack space={5}  >
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageUsers.ResetUserAccount.name)}>
                            <Center height="100%"><Text fontSize={18} color="white">Réinitialiser un compte</Text></Center>
                        </Pressable>
                    </Box>
                </HStack>
        </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}