import { extendTheme } from 'native-base';
import { get_screen_height } from 'services/dimensions';

export default  extendTheme({
	// fonts: {
	// 	heading: "Roboto",
	// 	body: "Roboto",
	// 	mono: "Roboto",
	// },

	colors: {
		primary: {
			500: '#282262',
			600: {
				linearGradient: {
					colors: [
						'#0D2443',
						'#90C5FC',
					],
					start: [0, 0],
					end: [1, 1],
				}
			}
		},
		ghost: {
			500: '#BBBBBB'
		}
	},

	components: {
		// Inline components
		Text: {
			defaultProps: {
				fontSize: 15,
				color: 'black'
			},
		},
		Heading: {
			defaultProps: {
				color: 'primary.500'
			}
		},

		// Block compoenents
		View: {
			defaultProps: {
				bg: "white",
				flex: 1
			}
		},
		ScrollView: {
			defaultProps: {
				keyboardShouldPersistTaps: 'handled',
			}
		},
		Modal: {
			defaultProps: {
				// bg: 'white'
			}
		},
		Button: {
			baseStyle: {
			},
			defaultProps: {
				bg: 'primary.500',
				minH: 5,
				borderRadius: 30,
				color: 'white',
				// _text: {
				// 	fontSize: 14
				// }
			}
		},
		Pressable: {
			cursor: 'pointer',
		},

		// Inputs
		Input: {
			defaultProps: {
				color: 'black',
				borderRadius: 15,
				borderColor: "ghost.500",
				minH: 50,
				px: 5,
				fontSize: 18,
				selectionColor: 'black',
				_focus: {
					borderColor: 'primary.500'
				},
				returnKeyType: "next"
			}
		},
		TextArea: {
			defaultProps: {
				color: 'black',
				borderRadius: 15,
				borderColor: "ghost.500",
				px: 5,
				fontSize: 18,
				selectionColor: 'black',
				returnKeyType: "next",
				_focus: {
					borderColor: 'primary.500'
				},
			}
		},
		Select:{
			defaultProps: {
				_item: {
					bg: 'white',
					my: 2,
					_text: {
						color: 'black',
						fontSize: 18,
						fontWeight: 'bold',
						_pressed: {
							color: 'white'
						}
					},
					_pressed: {
						bg: 'gray.800',
						borderRadius: 15,
					}
				},
				_actionSheetContent: {
					bg: 'white',
				},
				_selectedItem: {
					bg: '#282262',
					borderRadius: 15,
					_text: {
						color: 'white'
					}
				},
				
				
			}
		}

	},

	config: {
		// Changing initialColorMode to 'dark'
		initialColorMode: 'dark',
		dependencies: {
			// For Expo projects (Bare or managed workflow)
			'linear-gradient': require('expo-linear-gradient').LinearGradient,
			
		  },
	},
});
