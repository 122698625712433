import { AlertDialog, Button, Center, Spacer, useDisclose } from "native-base";
import { useRef, useState } from "react";

export default ({title, description, onClose, isOpen}) => {
   const [isLoading, setLoading] = useState(false)

    const cancelRef = useRef(null);
    return <Center>
        <AlertDialog leastDestructiveRef={cancelRef} closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
          <AlertDialog.Content>
            {/* <AlertDialog.CloseButton /> */}
            <AlertDialog.Header>{title}</AlertDialog.Header>
            <AlertDialog.Body bg='white' _text={{color: 'black'}} >
              {description}
            </AlertDialog.Body>
          </AlertDialog.Content>
        </AlertDialog>
      </Center>;
  };