import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Divider,
    IconButton,
    Icon,
    useToast,
    Spacer,
    AddIcon
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { listContractTeacherChildren, getMyContractTeacher, listSubs, getMyContractParent, listContractParentChildren } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import UserWithoutContract from "components/UserWithoutContract";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";
import PageLoading from "components/PageLoading";
import ChildManager from "./components/ChildManager";
import { apiErrorNotify } from "services/notify";



export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        contract_id: null,
        children: [],
        subs: [],
        selected_child_index: 0,
        selected_child_obj: null,
        selected_subject_affected: null,
        isLoaded: false
    })
    

    const refresh = () => {
        getMyContractParent(token).then(
            res_contract => {
                if(res_contract.data) {
                    listContractParentChildren(token, res_contract.data.id).then(
                        res_children => {
                            // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                            // setState(prev => ({...prev, children: res.data, isLoaded: true }))
                            // console.log("Got children length ", res_children.data.length)
                            listSubs(token)
                            .then( res_subs => 
                                setState(prev => ({
                                    ...prev, 
                                    children: res_children.data, 
                                    subs: res_subs.data,
                                    contract_id: res_contract.data.id,
                                    isLoaded: true}
                                )) 
                            )
                            .catch(err => apiErrorNotify({response: err.response, toast: toast}))

                        }
                    )
                    .catch(err => apiErrorNotify({response: err.response, toast: toast}))
                } else {
                    setState(prev => ({...prev, isLoaded: true}))
                }
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }
    

    useEffect(() => {
        refresh()
    }, [setState])

    return (
    <ScreenWrapper title="Notes de mes enfants" nav={navigation}>
        <ScrollView>
            <VStack space={3} mb='4' >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <>
                            <VStack space="5" px='3'>
                                <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                    <HStack space={5}>
                                        {
                                            state.children.map(
                                                (c, index) => (
                                                    <Pressable key={index} onPress={() => setState(prev => {
                                                        return {...prev, selected_child_index: index, selected_child_obj: c}
                                                    }) } >
                                                        <Text py='2' px='4' bg={state.selected_child_index == index ? 'gray.800' : 'gray.400'} color={state.selected_child_index == index ? 'white' : 'black' } borderRadius={20} >{c.full_name} </Text>
                                                    </Pressable>
                                                )
                                            )
                                        }
                                    </HStack>
                                </ScrollView>

                                {
                                    Object.keys(state.children).length === 0 && <NoData msg={"Aucun enfant encadré !"} />
                                }

                                {
                                    state.children[state.selected_child_index] && <ChildManager 
                                    refresh={refresh} 
                                    contract_id={state.contract_id} 
                                    child={state.children[state.selected_child_index]}
                                    subs={state.subs}
                                    />
                                }

                            </VStack>
                        </>
                    
                    )
                }

                </VStack>

        </ScrollView>
    </ScreenWrapper>
  );
}