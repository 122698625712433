import MultiSelect from "react-native-multiple-select"

export default ({ items= [], uniqueKey= 'id', displayKey="name", fixedHeight = true, onSelectedItemsChange, selectedItems, ...props }) => {
    return (
        <MultiSelect
        items={items}
        uniqueKey={uniqueKey}
        displayKey={displayKey}
        onSelectedItemsChange={onSelectedItemsChange}
        selectedItems={selectedItems}

        selectText="Sélection"
        selectedItemTextColor="#282262"
        selectedItemIconColor="#BBB"
        noItemsText="Aucun élément"
        
        tagRemoveIconColor="red"
        tagBorderColor="#BBB"
        tagTextColor="#282262"
        

        itemTextColor="black"
        fixedHeight={fixedHeight}
        
        submitButtonColor="#282262"
        submitButtonText="Sélectionner"
        
        
        styleRowList={{
            marginVertical: 2,
            
        }}
        
        searchInputPlaceholderText="Recherche..."
        searchInputStyle={{ 
            color: 'black',
            borderRadius: 15,
            borderColor: "#BBB",
            minHeight: 50,
            fontSize: 16,
         }}
         

        {...props}
        
    />
    )
}