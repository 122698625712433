import SelectMultipleInput from "components/SelectMultipleInput"
import TimeInput from "components/TimeInput"
import { Box, Button, FormControl, Heading, HStack, Input, Modal, Select, useDisclose, VStack } from "native-base"
import { useEffect, useState } from "react"
import { from_day_index_to_day_name } from "services/utils"

export default ({dayToAdd, subs= [], onCancel, initialValues= {}, onSubmit}) => {
    const [selected, setSelected] = useState(initialValues.sub || initialValues.sub_id ? [initialValues?.sub?.id ?? initialValues.sub_id] : [])
    const [start_at, setStart] = useState(initialValues.start_at || '')
    const [end_at, setEnd] = useState(initialValues.end_at || '')
    const [sub_name, setSubName] = useState('')
    const { isOpen, onToggle } = useDisclose()
  
    const handleSubmit = () => {
      selected.forEach(
        sub_id => {
          let sub = subs.find(s => s.id == sub_id)
          onSubmit({
            sub: sub,
            sub_id: sub.id,
            start_at: start_at,
            end_at: end_at,
            coeff: 2,
          })
        }
      )
    }
    console.log("Initial values ", initialValues, selected)
  
    useEffect(() => {
      setSubName(subs.find(item => item.id === selected[0])?.name)
    }, [setSubName])
    return (
      <Modal isOpen={true} collapsable={false}>
      <Modal.Content bg='white' w='90%' >
        <Modal.Header><Heading color='white' alignSelf='center' >{from_day_index_to_day_name(dayToAdd)}</Heading></Modal.Header>
        <Modal.Body>
          <VStack space='3' >
              <FormControl isRequired >
                <FormControl.Label>Matière</FormControl.Label>
                {
                  sub_name ? 
                    <Input
                        value={sub_name} 
                        isDisabled
                        />
                        : 
                        (
                          <SelectMultipleInput
                          displayKey={'name'}
                          items={subs?.filter(s => s.is_active)}
                          onSelectedItemsChange={setSelected}
                          selectedItems={selected}
                          selectText="Confirmer la sélection "
                          onToggleList={onToggle}                          
                        />
                        )
                }
              </FormControl>
              { !sub_name && <Box h='150px' /> }
              <FormControl isRequired>
                <FormControl.Label>Heure de début</FormControl.Label>
                <TimeInput 
                  value={start_at} 
                  setValue={setStart}
                  placeholder='ex: 14:30'
                  />
              </FormControl>
              <FormControl isRequired>
                <FormControl.Label>Heure de fin</FormControl.Label>
                <TimeInput 
                  value={end_at} 
                  setValue={setEnd}
                  placeholder='ex: 17:00'
                  />
              </FormControl>
              <HStack justifyContent={'space-between'} >
                <Button onPress={onCancel ? onCancel : () => null } bg='gray.600' >Annuler</Button>
                <Button onPress={handleSubmit}
                    isDisabled={selected === null || !start_at || !end_at }
                  >Intégrer</Button>
              </HStack>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  
    )
  }