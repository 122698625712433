import { Pressable, VStack, HStack, Heading, Button, Text, Spacer } from "native-base";

export default ({childValues, onEdit, onRemove, index}) => {
  return (
    <Pressable>
      <VStack bg='gray.200' borderRadius={5} p="3" >
        <HStack>
          <Heading size={"sm"}>Enfant {index+1}</Heading>
          <Spacer />
          <HStack space='2' >
            <Button bg='red.700' _text={{fontSize: 12}} p='1' colorScheme={'danger'} onPress={onRemove} >Supprimer</Button>
            <Button _text={{fontSize: 12}} p='1'  colorScheme='lightBlue' onPress={onEdit} >Modifier</Button>
          </HStack>
        </HStack>
          <Text>Nom: {childValues.full_name}</Text>
          <Text>Classe: {childValues.child_level}</Text>
          <Text>Lieu des cours: {childValues.place_courses}</Text>
      </VStack>
    </Pressable>
  )
}