import React from 'react';
import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    Checkbox,
    TextArea
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { getMyContractTeacher, setTeacherRemark } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { from_day_index_to_day_name } from "services/utils";
import { COURSE_SESSION_STATUS } from "constant";
import ScreenWrapper from 'components/ScreenWrapper';
import PageLoading from 'components/PageLoading';



export default ({ navigation }) => {
    const token = getToken()
    const [ state, setState ] = useState({
        subjects_affected: [],
        nb_weeks: 0,
        children: {},
        selected_child_id: null,
        selected_week_index: null,
        selected_session_id: null,
        selected_session_object: null,
        current_week_index: null,
        remark: '',
        isLoaded: false,
        isSubmiting: false
    })

    const week_data_for_child = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    }
    const slice_week_sessions = () => {
        const week_sessions = [] 
        [0, 1, 2, 3, 4, 5, 6].forEach(
            day_key => {
                state.children[state.selected_child_id].weeks[state.selected_week_index][day_key].forEach(
                    day_session => week_sessions.push(day_session)
                )
            }
        )
        return week_sessions
    }

    const get_session_in_week = (id) => {
        console.log(`child ${state.selected_child_id}, week ${state.selected_week_index}, got ${id}`)
        if(state.selected_child_id !== null && state.selected_week_index !== null) {
            for (let day_index = 0; day_index <= 6 ; day_index++) {
                const day_datalist = state.children[state.selected_child_id].weeks[state.selected_week_index][day_index]
                for (let i = 0; i < day_datalist.length; i++) {
                    if(day_datalist[i].id === id) {
                        console.log("Found ", day_datalist[i].id)
                        return day_datalist[i]
                    }
                }
                
            };
        }
        return {}
    } 

    const updated_session_in_week = state.selected_session_id ? get_session_in_week(state.selected_session_id) : null

    if(state.selected_child_id !== null && state.selected_week_index !== null) {
        [0, 1, 2, 3, 4, 5, 6].forEach(
            day_name => {
                const day_datalist = state.children[state.selected_child_id].weeks[state.selected_week_index][day_name]
                for (let i = 0; i < day_datalist.length; i++) {
                    for (let index = 1; index < day_datalist.length; index++) {
                        // if( new Date(`2020-10-10 ${day_datalist[index-1].start_at}`) > `2020-10-10 ${day_datalist[index].start_at}` ){
                        if( day_datalist[index-1].start_at > day_datalist[index].start_at ){
                            let temp = day_datalist[index]
                            day_datalist[index] = day_datalist[index-1]
                            day_datalist[index-1] = temp
                        }
                        
                    }
                    
                }
            
        });
    } 
    
    const refresh = (prevent_update_selected_child_id = false) => {
        getMyContractTeacher(token).then(
            res_contract => {        
                const children = {}
                const contract = res_contract.data
                if(contract) {
                    contract.subjects_affected.forEach( s_a => {
                        let child = s_a.child
                        if(!children[child.id]) children[child.id] = {weeks: {}, child_infos: s_a.child}
                        // else {
                            s_a.course_sessions.forEach( c_s => {
                                if(!children[child.id].weeks[c_s.week]) {
                                    children[child.id].weeks[c_s.week] = {
                                        0: [],
                                        1: [],
                                        2: [],
                                        3: [],
                                        4: [],
                                        5: [],
                                        6: [],
                                    }
                                } 
                                // else {
                                    children[child.id].weeks[c_s.week][c_s.day].push({...c_s, sub: s_a.sub})
                                // }
                            });
                        // }
                    } )
                    // console.log("Solof ", children[26].weeks[5])
                    const date_start = new Date(contract.start_at);
                    const date_now = new Date();
                    const diffTime = Math.abs(date_start - date_now);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    const diffWeeks = Math.floor(diffDays / 7)
                    
                    const date_end = new Date(contract.end_at);
                    const nb_weeks = Math.floor(Math.ceil(Math.abs(date_start - date_end) / (1000 * 60 * 60 * 24)) / 7)

                    const initial_child_id = Object.keys(children).findIndex( i => i === state.selected_child_id)  > -1
                            ? state.selected_child_id 
                            : 
                            Object.keys(children).length > 0 ? Object.keys(children)[0] : 0
                    setState(prev => ({
                        ...prev, 
                        subjects_affected: contract.subjects_affected, 
                        children: children,
                        nb_weeks: nb_weeks,
                        selected_child_id: prevent_update_selected_child_id ? state.selected_child_id : initial_child_id,
                        selected_week_index: diffWeeks,
                        current_week_index: diffWeeks,
                        remark: '',
                        isLoaded: true,
                        isSubmiting: false
                    }))
                } else {
                    console.log("No contract found")
                }
                console.log("Refreshed ")
            }
        ).catch(err => console.log("contr error", err))
    }

    
    useEffect(() => {
        refresh()

    }, [setState])

    return (
    <ScreenWrapper title="Signaler une observation sur un parent d'élève" nav={navigation}>
        <ScrollView>
            <VStack space={3} >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    ) : (
                        <View>
                            <VStack space="5" px='3'>
                                {/* {console.log("render")} */}
                                <ScrollView horizontal>
                                    <HStack space={5}>
                                        {
                                            Object.values(state.children).map(
                                                (c, index) => (
                                                    <Pressable key={index} onPress={() => setState(prev => ({...prev, selected_child_id: c.child_infos.id, selected_session_id: null, selected_session_object: null})) } >
                                                        <Text key={index}  py='2' px='4' bg={state.selected_child_id == c.child_infos.id ? 'gray.800' : 'gray.400'} color={state.selected_child_id == c.child_infos.id ? 'white' : 'black' } borderRadius={20} >{c.child_infos.full_name} </Text>
                                                    </Pressable>
                                                )
                                            )
                                        }
                                    </HStack> 
                                </ScrollView>
                                
                                <VStack space='1'>
                                <Select defaultValue={state.selected_week_index} onValueChange={val => setState(prev => ({...prev, selected_week_index: val})) }>
                                        {
                                            Array(state.nb_weeks).fill(0).map(
                                                (elt, index) => <Select.Item color={'white'} key={index} value={index} label={`Semaine ${index+1} ${index === state.current_week_index ? '(Cette semaine)' : '' } `}  />
                                            )
                                        }
                                </Select>
                                    {
                                        state.selected_week_index != null && (
                                            <Select 
                                                defaultValue={state.selected_session_id} 
                                                value={state.selected_session_id} 
                                                onValueChange={val => setState(prev => ({
                                                    ...prev, 
                                                    selected_session_id: val, 
                                                    selected_session_object: get_session_in_week(val)})
                                                )}>
                                                    <Select.Item color={'white'}  label={`Sélection de la séance `}  />
                                                    {
                                                        Object.keys(state.children[state.selected_child_id].weeks[state.selected_week_index]).map(
                                                            (day_key, d_index) => {
                                                                let elements = []
                                                                if(state.children[state.selected_child_id].weeks[state.selected_week_index][day_key].length > 0){
                                                                    elements = state.children[state.selected_child_id].weeks[state.selected_week_index][day_key].map(
                                                                        (course_merged_data, cm_index) => (
                                                                            <Select.Item color={'white'} key={cm_index} value={course_merged_data.id} label={`${from_day_index_to_day_name(day_key)} ${course_merged_data.start_at}-${course_merged_data.end_at} : ${course_merged_data.sub.name} `}  />
                                                                        )
                                                                    )
                                                                }

                                                                return elements
                                                            }
                                                        )
                                                    }
                                            </Select>
                                        )
                                    }
                                    {
                                        state.selected_session_id && (
                                            <>
                                                <TextArea placeholder="Ecrivez votre observation..." 
                                                        isDisabled={state.selected_session_id && updated_session_in_week.teacher_remark} 
                                                        defaultValue={ state.selected_session_id && (state.remark ? state.remark : (updated_session_in_week && updated_session_in_week.teacher_remark )) }
                                                        value={state.selected_session_id && (state.remark ? state.remark : (updated_session_in_week && updated_session_in_week.teacher_remark ))} 
                                                        onChangeText={val => setState(prev => ({...prev, remark: val})) }
                                                        minH={100} color='black' />
                                                <Text color='red.500'>Vous ne pourrez plus la modifier (alors soyez concis)</Text>
                                            </>
                                        )
                                    }
                                <Button onPress={
                                    async () => {
                                        console.log("Gonna sen remark :: ", state.remark)
                                        setTeacherRemark(token, state.selected_session_id, {teacher_remark: state.remark})
                                            .then( async res => {
                                                await setState(prev => ({...prev, isSubmiting: true}))
                                                refresh({prevent_update_selected_child_id : true})
                                            } )
                                    }
                                    } isLoading={state.isSubmiting} isDisabled={state.selected_session_id === null || (state.selected_session_id !== null && state.selected_session_object.teacher_remark )} mt='5' >Soumettre</Button>
                                
                                </VStack>
                            </VStack>
                        </View>
                    
                    )
                }

                </VStack>

        </ScrollView>
    </ScreenWrapper>
  );
}