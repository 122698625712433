import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { Box, Center, CloseIcon, HStack, Icon, IconButton, Stagger, useDisclose } from "native-base";
import { Linking } from 'react-native';
import * as SMS from 'expo-sms';
import { useEffect, useState } from "react";

export default ({phone}) => {
    const {
      isOpen,
      onToggle
    } = useDisclose();

    const [coords, setCoords] = useState({
        top:  0,
        left: '5/6'
    })
    
    const [isSMSAvailable,  setIsSMSAvailable] = useState(false)
    const [isLoadingSMS,  setIsLoadingSMS] = useState(false)
    
    useEffect(async () => {
        const isAvailable = await SMS.isAvailableAsync();
        setIsSMSAvailable(isAvailable)
    }, [setIsSMSAvailable])
    
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }

    return (
        <Box position={'absolute'} zIndex={1000} top={coords.top} left={coords.left} 
            // onTouchMove={({nativeEvent}) => {
            //     setCoords({ left: nativeEvent.locationX, top: nativeEvent.locationY })
            //     console.log(nativeEvent)
            // } }
             >
             <HStack alignItems="center">
                <IconButton 
                    variant="solid" borderRadius="full" size="lg" 
                    _pressed={{
                        bg: 'primary.500',
                        opacity: 0.5
                    }}
                    onPress={onToggle} bg="primary.500" 
                    icon={
                    <Icon 
                        as={MaterialCommunityIcons} 
                        size="6" name={isOpen ? 'close': "animation-outline"} 
                        color="warmGray.50" _dark={{
                            color: "warmGray.50"
                            }} />
                } 
                    />
                
            </HStack>
            <Box alignItems="center" h={isOpen ? 'auto' : 0}  >
                <Stagger visible={isOpen} initial={{
                opacity: 0,
                scale: 0,
                translateY: 34
                }} 
                animate={{
                translateY: 30,
                scale: 1,
                opacity: 1,
                transition: {
                    type: "spring",
                    mass: 0.8,
                    stagger: {
                    offset: 30,
                    reverse: true
                    }
                }
                }} exit={{
                translateY: 34,
                scale: 0.5,
                opacity: 0,
                transition: {
                    duration: 100,
                    stagger: {
                    offset: 30,
                    reverse: true
                    }
                }
                }}>
                    <IconButton 
                        onPress={() => Linking.openURL(`tel:${phone}`)} 
                        mb="4" variant="solid" bg="green.600" colorScheme="indigo" borderRadius="full" 
                        icon={<Icon as={MaterialIcons} size="6" name="phone" _dark={{
                    color: "warmGray.50"
                }} color="warmGray.50" />} />

                    <IconButton 
                        onPress={async () => {
                            // await setIsLoadingSMS(true)
                            const { result } = await SMS.sendSMSAsync(
                                [phone],
                                ''
                            ) 
                            
                        }}
                    mb="4" variant="solid" bg="yellow.400" colorScheme="yellow" borderRadius="full" 
                    icon={<Icon as={MaterialCommunityIcons} _dark={{
                    color: "warmGray.50"
                }} size="6" name="message" color="warmGray.50" />} />

                    <IconButton 
                        onPress={() => Linking.openURL(`whatsapp://send?phone=+225${phone}`) }
                        mb="4" 
                        variant="solid" bg="teal.400" 
                        colorScheme="teal" borderRadius="full" 
                        icon={<Icon as={MaterialCommunityIcons} _dark={{
                            color: "warmGray.50"
                        }} size="6" name="whatsapp" color="warmGray.50" />}
                     />
                    
                </Stagger>
            </Box>
           
        </Box>
    )
  };