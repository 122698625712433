import { useState } from "react"
import { SwipeRow } from "react-native-swipe-list-view"
import { apiErrorNotify } from "services/notify"
import ButtonValidateSalary from "../ButtonValidateSalary"
import DisplayDetailsSalary from "../DisplayDetailsSalary"
import { MENU_NAVIGATION, INVOICE_STATUS } from "constant";
import { Box } from "native-base"

export default  ({invoice, handleSubmit, toast, _wrapper}) => {
    const [isSubmiting, setSubmitting] = useState(false)
    const [invoiceStatus, setStatus] = useState(invoice.status)
    const handlePress = () => {
        setSubmitting(true)
        handleSubmit(invoice.id)
        .then(res => {
            setStatus(res.data.status) 
            setSubmitting(false)
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }
    const isPending = invoiceStatus == INVOICE_STATUS.pending
    return (
        <Box {..._wrapper} >
        {
            // isPending ? (
                <SwipeRow rightOpenValue={-100} >
                    <ButtonValidateSalary invoice_id={invoice.id} status={invoiceStatus} setStatus={setStatus} handleSubmit={handleSubmit} toast={toast}  />
                    <DisplayDetailsSalary invoice={invoice} pending={!isPending} />
                </SwipeRow>
            // ) : <DisplayInvoiceDetails invoice={invoice} pending={true} />
        }
        </Box>
    )
}
