import { FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import MenuBoxWrapper from "components/MenuBoxWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Divider,
    Icon,
    Image
  } from "native-base";
  import { Pressable } from 'react-native';
import { getIsSuperuser } from "store/login/selectors";
import IconPedagogy from 'assets/pedagogy.jpeg';
import ScreenWrapper from "components/ScreenWrapper";

export default ({ navigation }) => {
    const isSu = getIsSuperuser()

    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const redGrad = {
        linearGradient: {
            colors: [
                'orange.700',
                'red.500',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
  return (
    // <ScrollView bg='white' >
    <ScreenWrapper hideBackButton nav={navigation} title={isSu ? 'Profil direction centrale ' : 'direction' }>
        <ScrollView>
            <Annonce isParentRefreshing={false} />
            {/* <Annonce isParentRefreshing={false} /> */}
            <VStack px="4" py="5" space={"4"} > 
                <HStack space={20}  >
                    <MenuBoxWrapper _wrapper={{bg: blueGrad}}>
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.Main.name)}  >
                            <Box>
                                <Center height="100%">
                                    <HStack>
                                        <VStack>
                                            <Heading color='white' >Gestion</Heading>
                                            <Heading color='white' >des parents</Heading>
                                        </VStack>
                                        <Icon mx='2' color='white' as={FontAwesome5} name='users' size={'xl'}  />
                                    </HStack>
                                </Center>
                            </Box>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                <HStack space={5} >
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}}>
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.CreateBroadcast.name)}>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='pen' size={'xl'} color="white" />
                                <Text color="white" fontSize={18} >Ecrire une annonce</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                    <MenuBoxWrapper _wrapper={{bg: greenGrad}}>
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.CreateDirectMessage.name)}>
                            <Center height="100%">
                                <Icon mt='2' translate={80}  mr='2' as={FontAwesome5} name='pen' size={'xl'} color="white" />
                                <Text color="white" fontSize={18} >Ecrire un</Text>
                                <Text color="white" fontSize={18} >message privé</Text>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                <HStack >
                    <MenuBoxWrapper _wrapper={{bg: blueGrad}} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.Main.name)}>
                            <Center height="100%">
                                <HStack>
                                    <VStack>
                                        <Heading color='white' >Gestion des </Heading>
                                        <Heading color='white' >enseignants</Heading>
                                    </VStack>
                                    <Icon  color='white' mx='2' as={FontAwesome5} name='user-tie' size={'xl'} />
                                </HStack>
                            </Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                <HStack >
                    <MenuBoxWrapper _wrapper={{bg: blueGrad}}>
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageLearning.Main.name)}>
                            <Center height="100%">
                                <Icon mt='2' color='white' mr='2' as={FontAwesome5} name='book' size={'xl'} />
                                <Heading color='white'>Gestion pédagogique</Heading></Center>
                        </Pressable>
                    </MenuBoxWrapper>
                </HStack>
                {
                    isSu && (
                        <>
                            <Divider />
                            <Center>
                                <Heading size='xs' color='red.500' >Zone réservée à la direction centrale</Heading>
                            </Center>
                            <HStack >
                                <MenuBoxWrapper _wrapper={{bg: greenGrad}} >
                                    <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageUsers.Main.name)}>
                                        <Center height="100%"><Heading color='white' >Gestion des comptes utilisateurs</Heading></Center>
                                    </Pressable>
                                </MenuBoxWrapper>
                            </HStack>
                            <HStack >
                                <MenuBoxWrapper _wrapper={{bg: greenGrad}} >
                                    <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageConfigs.Main.name)}>
                                        <Center height="100%" px='10' >
                                            <Heading color='white' >Configurations de l'application</Heading></Center>
                                    </Pressable>
                                </MenuBoxWrapper>
                            </HStack>
                        </>
                    )
                }
        </VStack>
        </ScrollView>
    </ScreenWrapper>
    // </ScrollView>
  );
}