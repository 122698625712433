import Annonce from "components/Annonce";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";
import { INVOICE_STATUS } from "constant";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider,
    ArrowForwardIcon
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { listContractsParents } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { humanize_natural_date } from "services/utils";
import { getToken } from "store/login/selectors";

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const redGrad = {
        linearGradient: {
            colors: [
                'red.500',
                'orange.500',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const [state, setState] = useState({
        contracts: [],
        isLoaded: false,
        error: false,
    })
    useEffect(()=> {
        listContractsParents(token)
            .then(res => {
                setState(prev => ({
                    ...prev, 
                    contracts: res.data,
                    isLoaded: true
                }))
            })
            .catch(err => setState(prev => ({...prev, error: err.data.detail})) )

    }, [setState ])
  return (
    <ScreenWrapper title="Liste des contrats de parents" nav={navigation}>
        <ScrollView>
            <VStack px="2" py="5" > 
                { state.error && <Text color='red.500'>{state.error}</Text> }
                <VStack space={4}>
                    {
                        !state.isLoaded ? (
                            <Center  >
                                <HStack space={2} justifyContent="center">
                                <Spinner />
                                <Heading color="primary.500" fontSize="md">
                                    Chargement
                                </Heading>
                            </HStack>
                            </Center>
                        ) : state.contracts.length === 0 ? <NoData /> : (
                            <>
                                {
                                    state.contracts.map(
                                        contract => (
                                            <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.UpdateContract.name, {init: {contract}}) }>
                                                <Box bg={'gray.300'} shadow='2' p='3' mx='1' borderRadius={10} >
                                                    <Heading color="primary.500">{contract.parent.full_name}</Heading>
                                                    <VStack>
                                                        <HStack space='5'>
                                                            <VStack>
                                                                <Text>Du {humanize_natural_date(new Date(contract.start_at))}</Text>
                                                                <Text>Au {humanize_natural_date(new Date(contract.end_at))}</Text>
                                                                <Text>Signé le: {humanize_natural_date(new Date(contract.signed_at))}</Text>    
                                                            </VStack>
                                                            <VStack>
                                                                <Heading color='primary.500' size='md'>{contract.price}F</Heading>    
                                                            </VStack>
                                                        </HStack>
                                                        <HStack justifyContent={'flex-end'} >
                                                            <ArrowForwardIcon size='lg' color='primary.500'  />
                                                        </HStack>
                                                    </VStack>
                                                </Box>
                                            </Pressable>
                                        )
                                    )
                                }
                            </>
                        )
                    }
                    
                </VStack>
        </VStack>
            </ScrollView>
    </ScreenWrapper>
  );
}