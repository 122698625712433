import * as React from 'react'
import { View } from 'react-native'
import PDFReader from 'rn-pdf-reader-js'
import { getFileLink } from 'services/api-axios'

export default ({ navigation, route }) => {
    const params = !route ? {} : route.params ? route.params : {}
    return (
      <PDFReader
        source={{
          uri: params.uri ? getFileLink(params.uri) : 'http://gahp.net/wp-content/uploads/2017/09/sample.pdf',
        }}
      />
    )
  
}