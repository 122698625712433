import { Button, Spinner } from "native-base"
import { useState } from "react"

export default ({ callback }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [msg, setMsg] = useState('Retier')
    return (
        <>
            { isLoading ? (
                <Spinner /> ) : (
                <Button bg='red.500' onPress={async () => {
                    setIsLoading(prev => true )
                    callback()
                        .catch(err => setMsg("Erreur") )
                } } >
                    {msg}
                </Button>
                )
            }
        </>
    )
}