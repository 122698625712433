import { StatusBar, Box, HStack, VStack, IconButton, Text, Icon, Image, Avatar, Center, Spacer, useColorModeValue, Button, ScrollView, Heading, useToast, Spinner, useDisclose, Pressable } from "native-base";
import { MaterialIcons } from '@expo/vector-icons';
import Logo from 'assets/logo.png'
import { LinearGradient } from "expo-linear-gradient";
import { useEffect } from "react";
import { listBroadcastForMe } from "services/api-axios";
import { getToken } from "store/login/selectors";
import { useState } from "react";
import { get_window_width } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import { delay } from "services/utils";
import Displayer from "./Displayer";

function Annonce({ navigation, isParentRefreshing= true, ...rest }) {
  const token = getToken()
  const [broadcasts, setBroadcast] = useState([]) 
  const [refreshing, setRefreshing] = useState(isParentRefreshing);
  const [ selectedMsg, setSelectedMsg ] = useState({
    isOpen: false,
    title: null,
    description: null
  })

  const toast = useToast()
  useEffect(()=> {
    setRefreshing(true)
    listBroadcastForMe(token)
    .then( res => {
      // console.log(res.data)
      setBroadcast(res.data)
      setRefreshing(false)
    } )
    .catch(err => {
      // apiErrorNotify({response: err.response, toast: toast})
      delay(2000).then(() => setRefreshing(false))
    })
  }, [setBroadcast, setRefreshing])

  const greenGrad = {
      linearGradient: {
          colors: [
              '#28D8B8',
              '#183253',
          ],
          start: [0, 0],
          end: [1, 1],
      }
  }
  const blueGrad = {
    linearGradient: {
        colors: [
            '#0D2443',
            '#90C5FC',
        ],
        start: [0, 0],
        end: [1, 1],
    }
  }
    return (
      <ScrollView>
          <VStack>
            <Box my='3' >
            <Center>
              <Heading fontSize={"lg"} >Annonces</Heading>
            </Center>

            {
              broadcasts.length === 0 ? (
                <Box minH={100} bg={greenGrad} borderRadius='10' justifyContent={'center'} borderColor={"rose.500"} mx="2" mt="2"  space='4' >
                  <Center><Heading color='white' >Aucune nouvelle annonce</Heading></Center>
                </Box>
              ) : (
                <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                  <HStack borderColor={"rose.500"} mx="2" mt="2" py='2' space='4' >
                    {
                      broadcasts.map( br => (
                        <Pressable onPress={() => setSelectedMsg(prev => ({ isOpen: true, ...br}))} >
                          <VStack key={br.id} py="1.5" pl="4"  width={get_window_width()*0.8} bg={'white'} shadow='6' minH={100} borderRadius={"lg"} >
                            <Heading>{br.title}</Heading>
                            <Text color="black">{br.description && br.description.slice(0, 80) }</Text>
                            <HStack space={"md"} my="1" mx='2' flexDir={'row-reverse'} >
                              {
                                br.description && br.description.length > 80 && <Button borderRadius={15} size="sm" bg="gray.300" _text={{color: "black"}} >Voir plus</Button>
                              }
                              {/* <Button borderRadius={15} size="sm" bg="white" _text={{color: "black"}} >Passer une annonce</Button> */}
                            </HStack>
                          </VStack>
                        </Pressable>
                      ) )
                    }
                    {
                      selectedMsg.isOpen && <Displayer isOpen={selectedMsg.isOpen} title={selectedMsg.title} description={selectedMsg.description} onClose={() => setSelectedMsg(prev => ({...prev, isOpen: false}))} />
                    }
                  </HStack>

                </ScrollView>
              )
            }
            {
              refreshing && <Spinner />
            }
            </Box>

        </VStack>
      </ScrollView>
    )
  }

  export default Annonce

  // bg={{
  //   linearGradient: {
  //     colors: gradColors,
  //     start: [0, 1],
  //     end: [1, 30],
  //   },
  // }}