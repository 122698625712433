import {useSelector} from 'react-redux';

/**
 * Custom React Hook to get count value from state.
 * @see https://reactjs.org/docs/hooks-custom.html
 */
export const useIsLoggedIn = () => useSelector(state => state.login.isLoggedIn);
export const getToken = ()=>useSelector(state=>state.login.access_token);
export const getUserLang=()=>useSelector(state=>state.login.lang);
export const getUsername=()=>useSelector(state=>state.login.full_name);
export const getUserAvatar=()=>useSelector(state=>state.login.media_avatar);
export const getFrontLang=()=>useSelector(state=>state.login.frontLang);
export const getIsSuperuser=()=>useSelector(state=>state.login.is_superuser)
export const getIsManager=()=>useSelector(state=> state.login.is_manager)
export const getIsDirection=()=>useSelector(state=> state.login.is_superuser || state.login.is_manager)
export const getIsParent=()=>useSelector(state=>state.login.is_parent)
export const getIsTeacher=()=>useSelector(state=>state.login.is_teacher)
export const getLoginState=()=>useSelector(state=>state.login)