import { StatusBar, Box, HStack, IconButton, Text, Icon, Image, Avatar, Center, Badge, VStack, Button, Modal, useDisclose, FlatList, Spacer, useToast, CircleIcon, Spinner, CheckIcon, Heading, CloseIcon } from "native-base";
import { AntDesign, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import Logo from 'assets/logo.png'
import { getLoginState, getToken, getUserAvatar, getUsername } from "store/login/selectors";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { Pressable, RefreshControl } from "react-native";
import { getFile, getFileLink, listMsgs, listNewMsgs, markMsgRead } from "services/api-axios";
import { MSG_STATUS } from "constant";
import { humanize_date, humanize_date_YMD, humanize_natural_date, humanize_time } from "services/utils";
import { apiErrorNotify } from "services/notify";
import GoBack from "components/GoBack";
import Notifications, { registerForPushNotificationsAsync } from "services/push-notifiy";
import { MENU_NAVIGATION } from "constant";
import { useNavigation } from "@react-navigation/native";
import { useAppendNotifications } from "store/notifications/hooks";


function MessageItem({item, updater, index, token, toast}) {
  const [isOpen, setOpen] = useState(false)
  const [isRead, setRead] = useState(item.status === MSG_STATUS.read)
  
  const handleRead = () => {
    markMsgRead(token, [item.id])
    .then(() => {
      updater(index)
      setRead(true)
    })
    .catch(err => {
      toast.show({
        description: 'Erreur de connexion'
      })
    })
  }
  return (
    <Pressable onPress={() => {
      setOpen(prev => !prev)
      if (!isRead) handleRead()
    }}>
      <VStack mb='2' borderWidth={1} borderColor='gray.200' shadow='3' p='2' borderRadius={5} key={index} bg='white'  >
        <VStack>
            <VStack>
              <HStack>
                <Text color="coolGray.800" bold>
                  {item.is_system_msg ? "Système" : "La Direction"}
                </Text>
                <Spacer />
                <HStack space={2} >
                  <Text fontSize={12} >{humanize_natural_date(new Date(item.created_at))},</Text>
                  <Text fontSize={12} >{humanize_time(new Date(item.created_at), 'h')}</Text>
                </HStack>
              </HStack>
              <HStack justifyContent={'space-between'} >
                <Text color="gray.600" >
                  {item.title}
                </Text>
              </HStack>
            </VStack>  
        </VStack>

        {
          isOpen && (
          <VStack>
              <Text>{item.description}</Text> 
          </VStack>
          )
        }
        {isRead && (
          <HStack space='2' justifyContent={'flex-end'} >
            {/* {isRead && <Icon as={AntDesign} name='check' /> } */}
            <Text fontSize={12} color="green.500" >Lu</Text>
            <CheckIcon size='sm' color='green.500' /> 
          </HStack>
        )}
        
        
      </VStack>
    </Pressable>
  )
}

const ReloadMessages = ({callback, simple}) => {
  const [isLoaded, setLoad] = useState(true)
  const handlePress = ()=> {
    setLoad(false)
    callback().then(() => {
      setLoad(true)
    })
  }
  return (
    <>
      {
        isLoaded ? (
          <Pressable onPress={handlePress}>
            <Icon justifyContent={'flex-end'} as={AntDesign} name='sync' size={'md'} my='1' color='white'  /> 
          </Pressable>
        )
        : <Spinner />
      }
    </>
  )
}

function AppBar({ ...rest }) {
    const navigation = useNavigation()
    

    const username = getUsername()
    const avatar_path = getUserAvatar()
    const user = getLoginState()
    const token = getToken()
    const toast = useToast()

    const appendNotif = useAppendNotifications()

    const [expoPushToken, setExpoPushToken] = useState('');
    const [notification, setNotification] = useState(false);
    const notificationListener = useRef();
    const responseListener = useRef();


    const [messages, setMsg] = useState([])
    const myAvatar = getUserAvatar()
    const notRead = messages.filter(m => m.status !== MSG_STATUS.read ).length
    
    const updateMsg = (index) => setMsg(prev => {
      // const last = prev[index]
      prev[index] = {
        ...prev[index],
        status: MSG_STATUS.read
      }
      return prev
      // const length = prev.length
      // return prev.slice(0, index).concat(prev.slice(index+1, length))
    })

    const addNewMessages = () => {
      //const store_state = messages
      //setMsg([])
      console.log("READING NEW MESSAGES")
      listNewMsgs(token)
          .then(res_new => {
            console.log("\n\n",res_new.data,  "LISTVersion ")
            if(res_new.data?.length > 0) {
              //setMsg(prev => res_new.data.concat(store_state))
              const dicVersion = res_new.data?.reduce((prev, current) => {
                return {
                  ...prev, 
                  [current.id]: current
                }
              }, {})
              console.log("\n\n",dicVersion,  "dicVersion ")
              appendNotif(dicVersion)
            }
          } )
          .catch(err => {
            apiErrorNotify({response: err.response, toast: toast})
            setMsg(store_state)
          })  
      return new Promise((resolve, reject) => {
        setTimeout(()=> resolve(true), 3000)
      })
    }
    
    useEffect(()=> {
      // listMsgs(token)
      //   .then(res_all => {
      //     listNewMsgs(token)
      //     .then(res_new => setMsg(res_new.data.concat(res_all.data)) )
      //     .catch(err => apiErrorNotify({response: err.response, toast: toast}))  
      //   })
      //   .catch(err => apiErrorNotify({response: err.response, toast: toast}))

      registerForPushNotificationsAsync().then(token => setExpoPushToken(token));

      // setTimeout(() => {
      //   Notifications.getExpoPushTokenAsync()
      //   .then((val) => {
      //     alert(`OK ${val}`);
      //     console.log(val)
      //   } )
      //   .catch((val) => alert(`NON ${val}`))
  
      // }, 10000);
      
      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current = Notifications.addNotificationReceivedListener(({date, request}) => {
        //setNotification(notification);
        const {content} = request
        console.log(" <<<<<<<<<<<<<<<< ", content, "notif>>>>>>>>>>>>>>>", date)
        const {title, data, body} = content
        appendNotif(content)
        // setMsg(prev => prev.concat([{
        //   id: data.id,
        //   status: body.status,
        //   title: title,
        //   description: body,
        //   created_at: date
        // }])) 
      });

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
        // onOpen()
      });

      // const msgCron = setInterval(() => {
      //   console.log("Call ")
      //   addNewMessages()
      // }, 10000);

      // addNewMessages()
      
      // return () => clearInterval(msgCron)
      

    }, [setMsg])
    
    
    const { isOpen, onClose, onOpen } = useDisclose()
    return <>
        <HStack minH={'10px'} bg={'primary.500'} px="3" py="2" pt="4" alignItems="flex-end" w="100%">
          <HStack alignItems="center">
            { rest.back ? <GoBack _icon={{color: 'white', mx: '2'}}  nav={navigation} /> : null }
          </HStack>

          <VStack>
              <Pressable >
                {
                  notRead !== 0 && (
                    <Badge // bg="red.400"
                    colorScheme="danger" rounded="full" mb={-5} mr={-1} zIndex={1} variant="solid" alignSelf="flex-end" _text={{
                      fontSize: 12
                    }}>
                   *   
                    </Badge>
                  )
                }
                <IconButton onPress={() => navigation.navigate(MENU_NAVIGATION.Common.NotificationsListScreen.name)} icon={<Icon as={FontAwesome} name="bell" size="lg" />} 
                    borderRadius="full" 
                    _icon={{
                      color: "white",
                      size: "md"
                    }} 
                    _hover={{
                      bg: "orange.600:alpha.20"
                    }} 
                    _pressed={{
                      bg: "orange.600:alpha.20",
                      _ios: {
                        _icon: {
                          size: "2xl"
                        }
                      }
                    }} _ios={{
                      _icon: {
                        size: "2xl"
                      }
                    }} />

              </Pressable>       
          </VStack>

          <Spacer />
          
          <HStack space={5} alignItems={"center"} >
                  {
                    username && username.length > 20 ? (
                      <Text color="white" fontSize="15" fontWeight="bold">
                      {username.slice(0, 20)+ '..' } 
                    </Text>
                    ) : (
                      <Text color="white" fontSize="15" fontWeight="bold">
                        {username} 
                      </Text>
                    )
                  }

                  { myAvatar && 
                    <Avatar 
                      bg="white" size={'sm'} 
                      source={{
                          uri: getFileLink(avatar_path)
                      }} />
                  }
                   <IconButton
                      icon={<Icon size="xl" color="white" name='setting' as={AntDesign} />}
                      // bg="amber.200"
                      _pressed={{
                        bg: "white",
                        _icon: {
                          // size: "2xl",
                          color: "primary.500",
                        }
                      }}
                      p="0"
                      borderRadius="full"

                      onPress={
                        () =>navigation.navigate(MENU_NAVIGATION.Settings.stackname)
                      }
                    />
                
              {/* </Box> */}
          </HStack>
        </HStack>
        
        
      </>;
  }

  export default AppBar