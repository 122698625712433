import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast,
    Modal,
    Divider,
    Spacer,
    Checkbox,
    CloseIcon,
    IconButton
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { listApprovedTeachersWithoutContract, listCities, createContractParent, listContractsParents, createContractTeacher, getParentInfosFromContractParent, getAnyContractParent, getAnyContractTeacher } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import PageLoading from "components/PageLoading";
import DateTimeInput from "components/DateTimeInput";
import NumberInput from "components/NumberInput";
import { useCallback } from "react";
import Chip from "components/Annonce/Chip";
import { humanize_date_YMD } from "services/utils";
import ScreenWrapper from "components/ScreenWrapper";
import ChildrenManager from "./components/ChildrenManager";
import ResumeContractInfos from "./components/ResumeContractInfos";
import ExtraOptions from "./components/ExtraOptions";

const aggregate_subjects_affected_by_cp_id = (subjects_affected) => {
  const aggr = {}
  subjects_affected.forEach( s_a => {
      delete s_a.course_sessions
      delete s_a.prestations
      delete s_a.marks

      let child = s_a.child
      let sub = s_a.sub
      let cp_id = s_a.contract_parent_id
      if(!aggr[cp_id]) {
        aggr[cp_id] = []
        // children[cp_id] = {
        //     ...sub,
        //     child: child,
        // }
      }

      aggr[cp_id].push(s_a)
  } )
  return aggr
}
export default ({ navigation, route }) => {
    const token = getToken()
    const toast = useToast()
    const routeInitialValues = route.params ? route.params.init : {}

    const [formValues, setFormvalues] = useState({
      teacher_id: null,
      city_id: null,
      price: 0,
      signed_at: new Date(),
      start_at: new Date(),
      end_at: new Date(),
      subjects_affected_ids: []
    })
    
    const [state, setState] = useState({
      contract: null,
      contract_parents: {},
      mapping_parents_subs: {},
      // teachers: [],
      parents_infos: {},
      cities: [],

      selected_contract_parent_index: null,
      // selected_teacher_index: null,
      selected_subjects_affected_by_cp_id: {},
      display_selector: false,
      isLoaded: false,
      isSubmitting: false,
    })

    const refresh = () => {
      getAnyContractTeacher(token, routeInitialValues.contract.id)
          .then(response => { 
              const ctx = response.data
              const aggreg = aggregate_subjects_affected_by_cp_id(ctx.subjects_affected)
              // console.log("First aggr ", aggreg)
              setState(prev => ({
                ...prev, 
                contract: ctx,
                isLoaded: true,
                mapping_parents_subs: aggreg
              }))

              // console.log("Caaling", aggreg.length)
              setFormvalues({
                price: ctx.price,
                signed_at: new Date(ctx.signed_at),
                start_at: new Date(ctx.start_at),
                end_at: new Date(ctx.end_at),
              })

          })
          .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
      
    }

    const handleUpdate = () => {

    }

    useEffect(() => {
      refresh()
    }, [setState])
    console.log("render PARENT")
    return (
    <ScreenWrapper title="Modifier un contrat enseignant" nav={navigation}>
      <ScrollView>
        {
          !state.isLoaded ? <PageLoading />: (
            <VStack>
                <HStack px='5'>
                    <Spacer />
                    <ExtraOptions contract_id={state.contract.id} />
                  </HStack>
                <Center>
                  <Heading color='orange.500' size={'lg'}>Informations générales</Heading>
                </Center>
                <ResumeContractInfos contract={state.contract} />

                <Center><Divider w='1/2' /></Center>

                <VStack space={3} px="3" py="10" >
                    <Center>
                      <Heading color='orange.500' size={'lg'}>Matières affectées</Heading>
                    </Center>
                    <ChildrenManager mapping={state.mapping_parents_subs} update_mapping={(new_mapping) => setState(prev => ({...prev, mapping_parents_subs: new_mapping})) } contract={state.contract} />          
                </VStack>
            </VStack>


          )
        }
      </ScrollView>
    </ScreenWrapper>
  );
}