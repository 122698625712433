import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Divider,
    IconButton,
    Icon,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { listContractTeacherChildren, getMyContractTeacher, getParentInfosFromContractParent, createPrestationByTeacher, updatePrestationsByTeacher } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import ContactOptions from "./components/ContactOptions";
import ContactOptionsInline from "./components/ContactOptionsInline";
import { apiErrorNotify } from "services/notify";
import PrestationForm from "./components/PrestationForm";
import UserWithoutContract from "components/UserWithoutContract";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";
import PageLoading from "components/PageLoading";
import Chip from "components/Annonce/Chip";



export default ({ navigation, route }) => {
    const token = getToken()
    const toast = useToast()

    const initalPrestation = route.params ? route.params.init : {}

    const [ state, setState ] = useState({
        contract_id: null,
        children: [],
        parents: {},
        selected_child_index: 0,
        selected_child_obj: null,
        selected_subject_affected: null,
        isLoaded: false
    })
    
    const refresh = () => {
        console.log("refresh")
        getMyContractTeacher(token).then(
            res_contract => {
                if(res_contract.data){
                    listContractTeacherChildren(token, res_contract.data.id).then(
                        res_children => {
                            // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                            // setState(prev => ({...prev, children: res.data, isLoaded: true }))
                            console.log("Got children length ", res_children.data.length)
                            setState(prev => ({
                                ...prev, 
                                children: res_children.data, 
                                contract_id: res_contract.data.id,
                                isLoaded: true}
                            ))
                        }
                    )
                    .catch(err => apiErrorNotify({response: err.response, toast: toast}))
                } else {
                    setState(prev => ({...prev, isLoaded: true}))
                }
            }
        ).catch(err => {
            console.log("éeeer")
            apiErrorNotify({response: err.response, toast: toast})
        })
    }
    
    const handleSubmit = (values) => {
        console.log(values)
        createPrestationByTeacher(token, values)
            .then(res => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {time: 2500, navigation: navigation}) )
            .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    
    }
    const handleUpdate = (values) => {
        console.log(values, initalPrestation)
        updatePrestationsByTeacher(token, initalPrestation.id, values)
            .then(res => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {time: 2500, navigation: navigation}) )
            .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    
    }

    useEffect(() => {
        if(!initalPrestation.id) refresh()
        else setState(prev => ({...prev, isLoaded: true, contract_id: initalPrestation.link_subjects_affected[0]?.subject_affected.contract_teacher_id}))
    }, [setState])

    return (
    <ScreenWrapper title={initalPrestation.id ? "Modifier une prestation" : "Enregistrer une prestation effectuée"} nav={navigation}>
        <ScrollView>
            <VStack space={3} my='4' >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <>
                            <VStack space="5" px='3'>
                                {
                                    initalPrestation.id ? (
                                        <>
                                            <Box  bg={'primary.500'} p='3'  flex={1} borderRadius={10} >
                                                <Heading color='white' size='lg'>{initalPrestation.link_subjects_affected[0]?.subject_affected?.child.full_name}</Heading> 
                                                <Text color='white'>Classe: {initalPrestation.link_subjects_affected[0]?.subject_affected?.child.child_level}</Text>
                                                <Text color='white'>Ecole: {initalPrestation.link_subjects_affected[0]?.subject_affected?.child.school}</Text>
                                                <Text color='white'>Lieu des cours: {initalPrestation.link_subjects_affected[0]?.subject_affected?.child.place_courses}</Text>
                                            </Box>
                
                                            <PrestationForm 
                                                onSubmit={handleUpdate}
                                                contract_teacher_id = {initalPrestation.link_subjects_affected[0]?.subject_affected?.contract_teacher_id}
                                                subjects_affected={initalPrestation.link_subjects_affected.map(link => link.subject_affected)}  
                                                initial_values={initalPrestation}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                                <HStack space={5}>
                                                    {
                                                        state.children.map(
                                                            (c, index) => (
                                                                <Pressable key={index} onPress={() => setState(prev => {
                                                                    return {...prev, selected_child_index: index, selected_child_obj: c}
                                                                }) } >
                                                                    <Text py='2' px='4' bg={state.selected_child_index == index ? 'gray.800' : 'gray.400'} color={state.selected_child_index == index ? 'white' : 'black' } borderRadius={20} >{c.full_name} </Text>
                                                                </Pressable>
                                                            )
                                                        )
                                                    }
                                                </HStack>
                                            </ScrollView>
                                            {
                                                Object.keys(state.children).length === 0 && <NoData msg={"Aucun enfant à encadrer !"} />
                                            }
                                            {
                                            state.children[state.selected_child_index] && (
                                                <>
                                                    <Box  bg={'primary.500'} p='3'  flex={1} borderRadius={10} >
                                                        <Heading color='white' size='lg'>{state.children[state.selected_child_index].full_name}</Heading> 
                                                        <Text color='white'>Classe: {state.children[state.selected_child_index].child_level}</Text>
                                                        <Text color='white'>Ecole: {state.children[state.selected_child_index].school}</Text>
                                                        <Text color='white'>Lieu des cours: {state.children[state.selected_child_index].place_courses}</Text>
                                                    </Box>
                        
                                                    <PrestationForm 
                                                        key={state.selected_child_index}
                                                        onSubmit={handleSubmit}
                                                        contract_teacher_id = {state.contract_id}
                                                        subjects_affected={Object.values(state.children[state.selected_child_index].subjects_affected)}  />
                                                        
                                                </>
                                            )
                                        }
                                        </>
                                    )
                                }
                                
                                
                                    
                            </VStack>
                        </>
                    
                    )
                }

                </VStack>

            </ScrollView>
    </ScreenWrapper>
  );
}