import { FormControl, Input, Text } from "native-base"
import { useRef } from "react"
import { useState } from "react"

// const format = (value) => {
//     is
// }

export default ({value, onChangeText, checkPrefix = true, prefixReg= "^0[157]+", cleanWhenInvalid= false, ...props}) => {
    const [state, setState] = useState(value)
    const [err, setError] = useState('')
    const ref = useRef()

    const handleChange = (val= '') => {
        setError("")
        if(typeof val == 'string' ){
            const length = val.length
            // if(length == 1 && val != '0')setError("Le préfixe du numéro est incorrecte")
            // if (length > 1 && checkPrefix && !val.match(prefixReg) ){
            //     // if(cleanWhenInvalid !== true) onChangeText("")
            //     console.log(cleanWhenInvalid)
            //     setError("Le préfixe du numéro est incorrecte")
            //     return
            // }
            val = val.trim()
            for (let i = 0; i < length; i++) {
                val = val.replace('-','')
                val = val.replace(' ','')
                val = val.replace('.','')
                val = val.replace(',','')
            }
            onChangeText(val)
        }
    }
    return (
        <FormControl isInvalid={err} >
            <FormControl.Label>{props.label}</FormControl.Label>
            <Input  
                ref={props.innerRef || ref}
                keyboardType="number-pad" 
                value={value} 
                maxLength={10} 
                {...props}  
                onChangeText={handleChange}
                />
            <Text color='red.500' fontSize={13} >{err || props.error}</Text>
            
        </FormControl>
    )
}