import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { getInvoiceParent, getParentInfosFromContractParent, listContractsParents, revertInvoice, soldInvoice, updateInvoiceParent } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import DateTimeInput from "components/DateTimeInput";
import { apiErrorNotify } from "services/notify";
import { humanize_date_YMD } from "services/utils";
import NumberInput from "components/NumberInput";
import ScreenWrapper from "components/ScreenWrapper";
import { INVOICE_STATUS } from "constant";
import PageLoading from "components/PageLoading";



const DisapearingAlert=({time = 3000, message='Success', update})=>{
  setTimeout(update, time);
  return (
    <Actionsheet isOpen={true} collapsable={false} >
      <Actionsheet.Content bg="white" height={get_screen_height()} >
        <Center>
          <Heading color="red.500" > {message} </Heading>
        </Center>
      </Actionsheet.Content>
    </Actionsheet>  
  )
 
}

export default ({ navigation, route }) => {
    const token = getToken()
    const toast = useToast()
    
    const routeInitialValues = route.params ? route.params.init : {}
    
    const [state, setState] = useState({
      selected_contract_id: null,
      isSubmitting: false,
      parent: null,
      current_invoice: null,
      done: false,
      error: false,
      isLoaded: false,
      isSubmitting: false
    })
    const [formValues, setFormvalues] = useState({
      price: {
        value: 10,
        edit: false
      },
      start_at: {
        value: state.current_invoice?.start_at,
        edit: false
      },
      end_at: {
        value: state.current_invoice?.end_at,
        edit: false
      },
      deadline: {
        value: state.current_invoice?.deadline,
        edit: false
      },
      status: {
        value: state.current_invoice?.status,
        edit: false
      },
    })
    const {
      isOpen,
      onOpen,
      onClose
    } = useDisclose()

    const handleSubmit = () => {
      console.log(formValues, data, )
      console.log("Start ", formValues.start_at, typeof formValues.start_at)
      setState( prev => ({...prev, isSubmitting: true })) 

      const data = {}

      if(formValues.price.edit === true) data.price = formValues.price.value
      if(formValues.start_at.edit === true) data.start_at = humanize_date_YMD(formValues.start_at.value)
      if(formValues.end_at.edit === true) data.end_at = humanize_date_YMD(formValues.end_at.value)
      if(formValues.deadline.edit === true) data.deadline = humanize_date_YMD(formValues.deadline.value)
      if(formValues.status.edit === true) data.status = formValues.status.value

      
      console.log("\nDATA ", data)
      updateInvoiceParent(token, state.current_invoice.contract_parent_id, state.current_invoice.id, data)
      .then( res => {
        console.log("\n Gonna eval status")
        // if(formValues.status.edit === true) {
          
        //   if(formValues.status.value === INVOICE_STATUS.pending) {
        //     console.log("\n\n Setting to PENDING")
        //     revertInvoice(token, state.current_invoice.contract_parent_id, state.current_invoice.id)
        //     .then(res_status => {
        //       console.log("\n SUCCESS")
        //       setState( prev => ({...prev, done: true}) )
        //       navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {navigation: navigation, time: 1500})
        //     })
        //     .catch( err => {
        //       console.log("\n FAILED")
        //       apiErrorNotify({response: err.response, toast: toast})
        //     })
        //   } else {
        //     console.log("\n\n Setting to ACHIEVED")
        //     soldInvoice(token, state.current_invoice.contract_parent_id, state.current_invoice.id)
        //     .then(res_status => {
        //       console.log("\n SUCCESS")
        //       setState( prev => ({...prev, done: true}) )
        //       navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {navigation: navigation, time: 1500})
        //     })
        //     .catch( err => {
        //       console.log("\n FAILED")
        //       apiErrorNotify({response: err.response, toast: toast})
        //     })
        //   }
          
        // }
        navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {navigation: navigation, time: 1500})
        

      }  )
      .catch( err => {
        apiErrorNotify({response: err.response, toast: toast})
      })
    }
  
    useEffect(() => {
      getInvoiceParent(token, routeInitialValues.contract_parent_id, routeInitialValues.invoice_id)
      .then(response => {
        const invoice_data = response.data
        getParentInfosFromContractParent(token, routeInitialValues.contract_parent_id)
        .then(res => {
          setState( prev => ({...prev, current_invoice: invoice_data, parent: res.data, isLoaded: true})) 

          setFormvalues(prev => {
            console.log("INV ", invoice_data)
            return {
              ...prev,
              price: {
                value: invoice_data.price,
                edit: false
              },
              start_at: {
                value: new Date(invoice_data.start_at),
                edit: false
              },
              end_at: {
                value: new Date(invoice_data.end_at),
                edit: false
              },
              deadline: {
                value: new Date(invoice_data.deadline),
                edit: false
              },
              status: {
                value: invoice_data.status,
                edit: false
              }
            }
          })

        })
        .catch(err => setState(prev=> ({...prev, error: err})))

      })
      .catch(err => setState(prev=> ({...prev, error: err})))

    }, [setState])

    return (
    <ScreenWrapper title="Modifier une facture" nav={navigation}>
      <ScrollView>
        {

        ! state.isLoaded ? (
          <Center  >
              <HStack space={2} justifyContent="center">
              <PageLoading />
          </HStack>
          </Center>
        ) : (
            <VStack space={3} px="3"  mb='3' >
                <FormControl isDisabled>
                  <FormControl.Label>Parent</FormControl.Label>
                  <Input value={`${state.parent.full_name || ''} ( ${state.parent.username} )`} />
                   
                </FormControl>

                <FormControl>
                  <FormControl.Label>Montant de la facture</FormControl.Label>
                    <NumberInput
                      value={formValues.price.value.toString()} 
                      placeholder="Entrez le montant"
                      onChangeText={val => setFormvalues(prev => ({...prev, price: {value: val, edit: true}})) } />
                  </FormControl>
                <FormControl isRequired>
                  <FormControl.Label>Date de début</FormControl.Label>
                    <DateTimeInput 
                      value={formValues.start_at.value} 
                      placeholder="Entrez la date"
                      setValue={val => setFormvalues(prev => ({...prev, start_at: {value: val, edit: true}})) } />
                  </FormControl>
                <FormControl isRequired>
                  <FormControl.Label>Date de fin</FormControl.Label>
                    <DateTimeInput
                      value={formValues.end_at.value} 
                      placeholder="Entrez la date"
                      setValue={val => setFormvalues(prev => ({...prev, end_at: {value: val, edit: true}})) } />
                  </FormControl>
                <FormControl isRequired>
                  <FormControl.Label>Deadline de paiement</FormControl.Label>
                    <DateTimeInput 
                      value={formValues.deadline.value} 
                      placeholder="Entrez la date"
                      setValue={val => setFormvalues(prev => ({...prev, deadline: {value: val, edit: true}})) } />
                  </FormControl>
                  <FormControl >
                    <FormControl.Label>La facture est-elle déjà réglée</FormControl.Label>
                      {console.log(formValues.status.value)}
                      <Switch
                        alignSelf={'flex-start'}
                        value={formValues.status.value === INVOICE_STATUS.achieved} 
                        onValueChange={val => setFormvalues(prev => ({...prev, status: {edit: true, value: val ? INVOICE_STATUS.achieved : INVOICE_STATUS.pending} })) } 
                      />
                    </FormControl>
                    <Button 
                      isLoading={state.isSubmitting}
                      isDisabled={Object.values(formValues).every(item => item.edit === false)}
                      onPress={handleSubmit}
                    >Enregistrer</Button>
              
            </VStack>
          )
        }
      </ScrollView>
    </ScreenWrapper>
  );
}