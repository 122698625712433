import { Video } from 'expo-av';
import { VStack } from 'native-base';
import * as React from 'react';
import { StyleSheet } from 'react-native';

export function VideoPlayer({ uri, title, subtitle, description, videoProps, wrapperProps }) {
    const video = React.useRef(null);
    const [status, setStatus] = React.useState({});

    return (
      <VStack {...wrapperProps} >
        <Video
          style={styles.video}
          useNativeControls
          resizeMode="contain"
          {...videoProps}
          
          ref={video}
          source={{uri: uri}}
          onPlaybackStatusUpdate={status => setStatus(() => status)}
          // isLooping
        />
      </VStack>
    );
}
const styles = StyleSheet.create({
video: {
    minHeight: 250
},
});