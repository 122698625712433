import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Spacer,
    FlatList,
    Fab,
    Icon,
    IconButton,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { createSub, listSubs, updateSub } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import ContactOptionsInline from "components/ContactOptionsInline";
import Chip from "components/Annonce/Chip";
import { display_user_role } from "services/utils";
import { AntDesign } from "@expo/vector-icons";
import ScreenWrapper from "components/ScreenWrapper";
import AddSub from "./components/AddSub";
import { apiErrorNotify } from "services/notify";
import PageLoading from "components/PageLoading";
import { SwipeRow } from "react-native-swipe-list-view";
import EditSub from "./components/EditSub";
import ConfirmationDialog from "components/ConfirmationDialog";


export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        subjects: [],
        isLoaded: false,
        isAproving: false,
        display_add: false,
        display_delete: null,
        selected_obj: null,
    })
    const refresh = () => {
        listSubs(token).then(
            res => {
                // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                setState(prev => ({...prev, subjects: res.data, isLoaded: true }))
            }
        )
        .catch(err => console.log(err))
    }

    const toggleAdd = () => setState(prev => ({...prev, display_add: !prev.display_add }))
    const toggleEdit = (init) => setState(prev => ({...prev, selected_obj: init }))
    const toggleDelete = (init) => setState(prev => ({...prev, display_delete: init }))


    const handleCreate = (values) => {
        createSub(token, values)
        .then(response => {
            setState(prev => ({
                ...prev, 
                display_add: false,
                subjects: prev.subjects.concat([response.data])
            }))
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    const handleEdit = (values) => {
        updateSub(token, state.selected_obj.id, values)
        .then(response => {
            setState(prev => ({
                ...prev, 
                selected_obj: null,
                subjects: [response.data].concat(prev.subjects.filter(c => c.id != prev.selected_obj.id))
            }))
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    const handleDelete = () => {
        return new Promise((resolve, reject) => {
            updateSub(token, state.display_delete.id, {is_active: false})
                .then(response => {
                    setState(prev => ({
                        ...prev, 
                        display_delete: null,
                        subjects: [response.data].concat(prev.subjects.filter(c => c.id != prev.display_delete.id))
                    }))
                })
                .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        }) 
    }

    const handleActivate = (obj) => {
        return new Promise((resolve, reject) => {
            updateSub(token, obj.id, {is_active: true})
                .then(response => {
                    setState(prev => ({
                        ...prev, 
                        display_delete: null,
                        subjects: [response.data].concat(prev.subjects.filter(c => c.id != obj.id))
                    }))
                })
                .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        }) 
    }
    
    useEffect(() => {
        refresh()
    }, [setState])

    console.log("selected_obj_to_delete ", state.display_delete)
    return (
    <ScreenWrapper title="Liste des matières de l'application" nav={navigation}>
        <ScrollView>
            <VStack space={3} >
                {
                    state.display_add && <AddSub onSubmit={handleCreate} onClose={toggleAdd} />
                }
                
                {
                    state.selected_obj && <EditSub initialName={state.selected_obj.name} onSubmit={handleEdit} onClose={() => toggleEdit(null)} />
                }

                {
                    state.display_delete && <ConfirmationDialog isOpen={true} onCancel={()=> toggleDelete(null)} onAgree={handleDelete} msg={`Voulez-vous réellement désactiver la matière '${state.display_delete.name}' ? `} />
                }
                
                {
                    ! state.isLoaded && (
                        <PageLoading />
                    )
                }
                
                <IconButton onPress={toggleAdd} bg='primary.500' ml='3/4' rounded={'full'} w={10} icon={<Icon color="white" as={<AntDesign name="plus" />} size={4} />}  />
                
                <VStack px='2' mt='5' space='3' >
                    {
                        state.isLoaded && state.subjects.sort((a, b) => !a.is_active ).map( sub => (
                            <Box key={sub.id} >
                                {console.log(sub)}
                                <SwipeRow leftOpenValue={100} rightOpenValue={-100} alignContent='center' >
                                    {/* <ButtonValidateInvoice invoice_id={invoice.id} status={invoiceStatus} setStatus={setStatus} handleSubmit={handleSubmit} toast={toast}  /> */}
                                    <HStack h='100%' alignContent={'center'} justifyContent={'space-between'} >
                                        {/* <Button startIcon={<Icon as={AntDesign} name='delete' />} p={1} bg='red.500' >Supprimer</Button> */}
                                        {
                                            !sub.is_active ? 
                                                <IconButton 
                                                    bg='green.700' 
                                                    onPress={() => handleActivate(sub)} 
                                                    icon={<Icon as={AntDesign} name='check' color='white' />} 
                                                    size='md' rounded={"full"} /> :
                                                <IconButton 
                                                    bg='red.500' 
                                                    onPress={() => toggleDelete(sub)} 
                                                    icon={<Icon as={AntDesign} name='delete' color='white' />} 
                                                    size='md' rounded={"full"} />
                                        }
                                        <IconButton onPress={() => toggleEdit(sub)} icon={<Icon as={AntDesign} name='edit' color='orange.500' />} size='md' rounded={"full"} />
                                    </HStack>
                                    <Center bg={ sub.is_active ? 'primary.500' : 'red.500'} borderRadius='full' p='2' >
                                        <Heading size='lg' color='white' >{sub.name}</Heading>
                                    </Center>
                                </SwipeRow>
                            </Box>
                        ))
                        
                    }
                </VStack>
            </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}