import Annonce from "components/Annonce";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
	Actionsheet,
	Input,
	Pressable,
	useToast,
	FormControl,
	Select,
	CheckIcon,
	Icon,
	Modal
  } from "native-base";
import { Image, StyleSheet } from 'react-native';
import SwitchColorMode from 'components/SwitchColorMode'
import { Layout } from "components/Layout";
import { MasonaryLayout } from "components/MasonaryLayout";
// import { useGetTeachersNoApprovedQuery, useLazyLoginQuery } from "services/api-rest";
import { createUserAccount, listCities, signIn as signInAPI } from 'services/api-axios'
import { useState } from "react";
import { useLogin } from "store/login/hooks";
import { getLoginState, getToken } from "store/login/selectors";
import {BACKEND_API} from "@env"
import { BASE_API_URL } from "constant";
import { apiErrorNotify } from "services/notify";
import { MENU_NAVIGATION } from "constant";
import { useEffect } from "react";
import { FontAwesome } from "@expo/vector-icons";
import { delay } from "services/utils";
import MultiSelect from "react-native-multiple-select";
import ScreenWrapper from "components/ScreenWrapper";

export default function ({ navigation }){

	const token = getToken()
	const toast = useToast()
	
	const [ signinData, setSign ] = useState({
		username: '',
		role: 'parent'	 
	})
	const [ state, setState ] = useState({
		isPwdOpen: false,
		isPwd2Open: false,
		isSubmitting: false,
		cities: [],
		city_id: null,
		city_ids: [],
		isSuccess: false,
		default_password: ''
	})

	const trigger = () => {
		
		const data = {
			username: signinData.username,
			role: signinData.role,
		}
		if(signinData.role !== 'direction_central' ) {
			data.cities = signinData.role === 'direction' ? state.city_ids : [state.city_id]
		} else data.cities = []
		setState(prev => ({...prev, isSubmitting: true}))
		
		createUserAccount(token, data)
		.then(res => {
			setState(prev => ({...prev, isSuccess: true, default_password: res.data.default_password}))
		})
		.catch(
			err => apiErrorNotify({response: err.response, toast: toast})
		).finally(() => {
			setState(prev => ({...prev, isSubmitting: false}))
		})
	}

	useEffect(() => {
		listCities(token)
			.then(
				({data: cities}) => {
					setState(prev => ({
						...prev,
						cities: cities
					}))
				}
			)
			.catch(
				err => apiErrorNotify({response: err.response, toast: toast})
			)
	}, [setState])

  return (
    <ScreenWrapper title="Création de compte pour un tiers" nav={navigation}>
		{
			state.isSuccess && (
				<Modal isOpen={true} collapsable={false} onClose={() => navigation.goBack()} >
					<Modal.Content>
						<Modal.CloseButton   />
						<Modal.Header bgColor={'green.500'}>
							{/* <Center> */}
								<Heading color='white' size='lg'>Compte créé avec succès ! </Heading>
							{/* </Center> */}
							
						</Modal.Header>
						<Modal.Body>
							<Center>
								<Heading size='xs'>
									Le nouvel utilisateur se connecte avec son numéro et le mot de passe "{state.default_password}"
								</Heading>
							</Center>
						</Modal.Body>
					</Modal.Content>
					
				</Modal>
			)
		}
		{/* <Actionsheet isOpen={true} onClose={() => null} collapsable={false} >
			<Actionsheet.Content  bg="white" > */}
				<ScrollView showsVerticalScrollIndicator={false}  >
					<VStack w="100%"  px={{ base: 4, md: "30%" }} justifyContent="center" space="4" bg="white"  >
						
						<FormControl isRequired>
							<FormControl.Label>Type de compte</FormControl.Label>
							<Select {...styles.input} 
								selectedValue={signinData.role} 
								color="black" 
								onValueChange={val => setSign(prev => ({...prev, role: val}))} 
							>
								<Select.Item value='parent' label='Parent' />
								<Select.Item value='teacher' label='Enseignant' />
								<Select.Item value='direction' label='Membre de la direction' />
								<Select.Item value='direction_central' label='Direction centrale' />
							</Select>
						</FormControl>
						
						
						{
							signinData.role !== 'direction_central' ? signinData.role === 'direction' ? (

							<FormControl isRequired>
							<FormControl.Label>Sélectionnez les villes d'action</FormControl.Label>
							<MultiSelect
								items={state.cities}
								uniqueKey="id"
								displayKey="name"
								// ref={(component) => { this.multiSelect = component }}
								onSelectedItemsChange={val => setState(prev => ({...prev, city_ids: val}))}
								selectedItems={state.city_ids}
								selectText="Villes"
								searchInputPlaceholderText="Recherche ..."
								tagRemoveIconColor="#CCC"
								tagBorderColor="#CCC"
								tagTextColor="#CCC"
								selectedItemTextColor="blue"
								selectedItemIconColor="#CCC"
								itemTextColor="#000"

								
								searchInputStyle={{ color: '#CCC' }}
								submitButtonColor="blue"
								submitButtonText="Confirmer la sélection"
							
							/>
						</FormControl>

							): (
								<FormControl isRequired >
									<FormControl.Label>Ville</FormControl.Label>
									<Select {...styles.input} 
										selectedValue={state.city_id} 
										color="black" 
										onValueChange={val => setState(prev => ({...prev, city_id: val}))} 
									>
										<Select.Item label="Sélectionnez une ville" />
										{
											state.cities.map( (city, ind) =>  <Select.Item key={ind} label={city.name} value={city.id} /> )
										}
									</Select> 
								</FormControl>
							) : null
						}
						<FormControl isRequired>
							<FormControl.Label>Numéro de téléphone</FormControl.Label>
							<Input 
								{...styles.input}
								type='number'
								keyboardType="number-pad"
								value={signinData.username} 
								placeholder="Numéro de téléphone"
								onChangeText={(val) => setSign(p => ({...p, username: val}))} />
						</FormControl>
						<Button 
							onPress={trigger} 
							mx={50} mt={6}
							isDisabled={
								signinData.role === 'direction_central' ? !signinData.username : (
									signinData.role === 'direction' ? (
										!state.city_ids.length || !signinData.username 
									): (
										!state.city_id ||  !signinData.username 
									)
								)
							}
						>
							Continuer
						</Button>
						
					</VStack>
				</ScrollView>
			{/* </Actionsheet.Content>
		</Actionsheet> */}
  </ScreenWrapper>
  );
}

const styles ={
	input: {
		size: "x" ,
		variant: "rounded",
		borderRadius: 10,
		color: 'black',
		p: 2,
		pl: 4
	}
}