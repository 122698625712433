import React from 'react';
import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    Checkbox,
    TextArea,
    Modal,
    useToast,
    Spacer
  } from "native-base";
  import { Pressable } from 'react-native';
import { getMyContractTeacher, setTeacherRemark, setChildrenUnavailability } from "services/api-axios";
import { Fragment, useState, useEffect } from "react";
import { format_natural_time, from_day_index_to_day_name, humanize_natural_date } from "services/utils";
import { COURSE_SESSION_STATUS } from "constant";
import DisplayTimeLinear from 'components/DisplayTimeLinear';
import { apiErrorNotify } from 'services/notify';

export default ({local_week_index, weeks, index, current_week_index, handleChange, selected_session_ids, hide_date, hide_week}) => {
    // const weeks = state.children[state.selected_child_id].weeks
    const week = weeks[local_week_index]

    // const days = weeks[local_week_index]

    return (
        <VStack borderLeftWidth={1} borderLeftColor='primary.500' >
            <Heading bg='gray.300' >
            {!hide_week && `${current_week_index == local_week_index ? 'Cette semaine' : `Dans ${local_week_index-current_week_index} semaine${(local_week_index-current_week_index) > 1 ? 's': ''}` } `}
            </Heading>
            <VStack bg='gray.200' pt='1' pl='2' space='5' >
                <Checkbox.Group onChange={handleChange} value={selected_session_ids} accessibilityLabel="choose numbers">
                    {
                        Object.keys(week).map(
                            day_key => (
                                <>
                                    {
                                        week[day_key].length == 0 ? null : (
                                            <VStack>
                                                <Heading size='sm'>
                                                    {from_day_index_to_day_name(day_key)} {!hide_date && week[day_key][0] && humanize_natural_date(new Date(week[day_key][0].date)) }
                                                </Heading>
                                                <VStack >
                                                    {
                                                        week[day_key].map(
                                                            (course_merged_data, cmd_index) => {
                                                                const isDisabled = course_merged_data.status !== COURSE_SESSION_STATUS.pending
                                                                return (
                                                                    <Checkbox
                                                                        key={cmd_index} 
                                                                        value={course_merged_data.id} 
                                                                        my={2} bg='white'
                                                                            _text={{
                                                                            color: course_merged_data.status === COURSE_SESSION_STATUS.reported ? 'orange.500' : course_merged_data.status === COURSE_SESSION_STATUS.cancelled ? 'red.500' : 'black'  ,
                                                                            fontSize: 11, 
                                                                            letterSpacing: 'xs'
                                                                        }} 
                                                                        _disabled={{
                                                                            bg: 'red.500'
                                                                        }}
                                                                        fontSize={11}
                                                                        isDisabled={isDisabled}
                                                                        >
                                                                            <VStack>
                                                                                <HStack>
                                                                                    {isDisabled && <Text color='danger.600' fontSize={8} >Seance annulée</Text>}
                                                                                </HStack>
                                                                                <HStack space='1' >
                                                                                        <DisplayTimeLinear fontSize={10} padding={1} value={course_merged_data.start_at} />
                                                                                        <DisplayTimeLinear fontSize={10} padding={1} value={course_merged_data.end_at} />
                                                                                    <Spacer />
                                                                                    <Heading  size='sm'>{course_merged_data.sub.name} </Heading>    
                                                                                </HStack>
                                                                            </VStack>
                                                                        {/* {course_merged_data.sub.name} {course_merged_data.start_at} à {course_merged_data.end_at} */}
                                                                    </Checkbox>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </VStack>
                                                    {/* {state.children[state.selected_child_id].weeks[local_week_index][day_key]} */}
                                            </VStack>
                                        )
                                    }
                                </>
                            )
                        )
                    }
                </Checkbox.Group>
            </VStack>
        </VStack>
    )
}