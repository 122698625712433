import React from "react";
import {
  Text,
  Link,
  HStack,
  Center,
  Heading,
  Switch,
  useColorMode,
  NativeBaseProvider,
  extendTheme,
  VStack,
  Box,
  View,
  Button
} from "native-base";
import NativeBaseIcon from "components/NativeBaseIcon";
import { Platform } from "react-native";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Provider as ReduxProvider } from 'react-redux'
import store from "store";
import AppStatck from "stacks/AppStack";
import theme from 'services/theme'
import {BACKEND_API} from "@env"
console.log("BACKEND_API ", BACKEND_API)
// const baseURL = 'http://10.10.10.152:8888'
// const baseURL = 'http://localhost:8888'
//import './App.css'



const config = {
  dependencies: {
    // For Expo projects (Bare or managed workflow)
    'linear-gradient': require('expo-linear-gradient').LinearGradient,
    // For non expo projects
    // 'linear-gradient': require('react-native-linear-gradient').default,
  },
};



export default function App() {
  return (
    <NativeBaseProvider theme={theme} config={config} >
      <ReduxProvider store={store}>
        <NavigationContainer>
          
          <AppStatck />
        
        </NavigationContainer>
      </ReduxProvider>
    </NativeBaseProvider>
  );
}


// function Appo() {
//   let [selectedImage, setSelectedImage] = React.useState(null);

//   let openImagePickerAsync = async () => {
//     // let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();

//     // if (permissionResult.granted === false) {
//     //   alert('Permission to access camera roll is required!');
//     //   return;
//     // }

//     // let pickerResult = await ImagePicker.launchImageLibraryAsync();
//     // if (pickerResult.cancelled === true) {
//     //   return;
//     // }
//     // console.log(pickerResult)
//     // setSelectedImage(pickerResult);
    
//     let doc = await DocumentPicker.getDocumentAsync()
//     console.log(doc)
//     setSelectedImage(doc);
//   };


//   let openShareDialogAsync = async () => {
//       let file = new FormData()
//       console.log(selectedImage)
//       // file.append('filename', selectedImage.name)
//       if(Platform.OS == 'web') {
//             file.append('file', selectedImage.file)
//             axios.post(baseURL + "/api/users/upload", file, {
//               headers: {
//                 "Content-Type": "multipart/form-data"
//               }
//             })
//               .then(()=> console.log("ok"))
//               .catch(err => console.log(err.message) )
//       } else {
//           file.append('file', {
//             uri: selectedImage.uri,
//             type: selectedImage.mimeType,
//             name: selectedImage.name
//           })
//           // file.append('file', new Blob(selectedImage))
//           // Object.keys(selectedImage).forEach(key => {
//           //   file.append(key, selectedImage[key])
//           // })
          
//           axios.post(baseURL + "/api/users/upload/android", file, {
//           // axios.post( baseURL + "/api/users/upload/android", {}, {
//             headers: {
//               "Content-Type": 'multipart/form-data',
//               // "Content-Type": selectedImage.mimeType
//             }
//           })
//             // fetch(baseURL)
//             .then((res)=> console.log(res.data))
//             .catch(err => console.log(err.message) )
          
          
//           // FS.uploadAsync("http://10.10.10.152:8888/api/users/upload/android", selectedImage.uri, {
//           //   httpMethod: 'POST',
//           //   fieldName: selectedImage.name,
//           //   mimeType: selectedImage.mimeType
//           // })
//           // .then(console.log)
//           // .catch(console.log)
//           // .finally(()=> console.log("ENDED"))
//       }
      
//     // else if (!(await Sharing.isAvailableAsync())) {
//     //   alert(`Uh oh, sharing isn't available on your platform`);
//     //   return;
//     // }
//     // else {
//     //   await Sharing.shareAsync(selectedImage.uri);
//     // }
//   };
//   return (
//     <NativeBaseProvider>
//       <Home />
//     </NativeBaseProvider>
//   );
// }