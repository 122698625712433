import { FontAwesome5 } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    Icon,
    CloseIcon
  } from "native-base";
  import { Image, Pressable } from 'react-native';

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
  return (
    <ScreenWrapper title={"Gestion des enseignants"} nav={navigation}>
        <ScrollView>
            <VStack px="4" pb="5" space={"4"} k>
                {/* <Heading m='3'>Gestion des enseignants</Heading> */}
                <HStack space={5} >
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.ValidateProfile.name)}>
                            <Center height="100%">
                            <Icon mt='2'  mr='2' as={FontAwesome5} name='check' size={'xl'} color={greenGrad.linearGradient.colors[1]} />
                                <Text fontSize={18} color="white">Valider les profils</Text>
                            </Center>
                        </Pressable>
                    </Box>
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.RejectProfile.name) }>
                            <Center height="100%">
                                <CloseIcon mt='2'  mr='2' as={FontAwesome5} name='mark' size={'xl'} color="red.500"   />
                                <Text fontSize={18} color="white">Retirer des profils</Text>
                            </Center>
                        </Pressable>
                    </Box>
                </HStack>
                <HStack space={5} >
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.CreateContract.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='file-signature' size={'xl'} color={'orange.300'} />
                                <Text fontSize={18} color="white">Enregistrer un contrat</Text>
                            </Center>
                        </Pressable>
                    </Box>
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.ListContact.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='phone' size={'xl'} color={'green.300'} />
                                <Text fontSize={18} color="white">Contacter un enseignant</Text>
                            </Center>
                        </Pressable>
                    </Box>
                </HStack>
                <HStack space={5}  >
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.CreateSalary.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='wallet' size={'xl'} color="blue.400" />
                                <Text fontSize={18} color="white">Enregistrer un salaire</Text>
                            </Center>
                        </Pressable>
                    </Box>
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.ListSalariesAllTeachers.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='wallet' size={'xl'} color="red.500" />
                                <Text fontSize={18} color="white">Liste des salaires</Text>
                            </Center>
                        </Pressable>
                    </Box>
                </HStack>
                <HStack space={5}  >
                    <Box height={100} width="50%" bg={blueGrad} flex={1} borderRadius={10} >
                        <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageTeachers.ListContracts.name) }>
                            <Center height="100%">
                                <Icon mt='2'  mr='2' as={FontAwesome5} name='file-signature' size={'xl'} color="white" />
                                <Text fontSize={18} color="white">Lister les contrats enregistrés</Text>
                            </Center>
                        </Pressable>
                    </Box>
                </HStack>
        </VStack>
            </ScrollView>
    </ScreenWrapper>
  );
}