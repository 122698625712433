import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Spacer,
    FlatList,
    Fab,
    Icon,
    IconButton,
    useToast,
    Skeleton,
    Actionsheet
  } from "native-base";
  import { Pressable, StyleSheet } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getIsSuperuser, getToken } from "store/login/selectors";
import { deleteLearningLesson, listLearningLessons } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import ContactOptionsInline from "components/ContactOptionsInline";
import Chip from "components/Annonce/Chip";
import { AntDesign } from "@expo/vector-icons";
import ScreenWrapper from "components/ScreenWrapper";
import { apiErrorNotify } from "services/notify";
import PageLoading from "components/PageLoading";
import { Video, AVPlaybackStatus } from 'expo-av';
import * as React from 'react';
import ResourceSelector from "components/ResourceSelector";
import ResourcePDFPreviewer from "components/ResourcePDFPreviewer";
import NoData from "components/NoData";
import { SwipeRow } from "react-native-swipe-list-view";


function RemoveResource({onClose, navigation, initialValues}) {
    const token = getToken()
    const [isSubmitting, setIsSubmitting] = useState(false)

    function handleDelete() {
        setIsSubmitting(true)
        deleteLearningLesson(token, initialValues.id)
        .then(() => {
            console.log("\n well deleted")
            navigation.navigate(MENU_NAVIGATION.StatusScreen.name, { init: {msg: 'Vidéos modfiée', time: 2000, navigation: navigation} }) 
        })
            .catch(err => {
                setIsSubmitting(false)
                apiErrorNotify({response: err.response, toast: toast})
            }) 
    }

    return (
        <Actionsheet isOpen={true} onClose={() => null} collapsable={true} >
            <Actionsheet.Content  bg="white" >
                <VStack w='100%' space={4} >
                    <Text alignSelf={'center'} >Voulez-vous vraiment supprimer ? </Text>
                    <Text alignSelf={'center'} fontSize={24} color='red.400'>{initialValues.title}</Text>
                    <HStack justifyContent={'space-between'} >
                        <Button onPress={onClose} variant={'ghost'} bg='gray.200' >Annuler</Button>
                        <Button onPress={handleDelete} isLoading={isSubmitting} _text={{color: 'white'}} variant={'ghost'} bg='red.500' >Supprimer</Button>
                    </HStack>
                </VStack>
            </Actionsheet.Content>
        </Actionsheet>
    )
}

export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()
    const isSU = getIsSuperuser()
    const [ state, setState ] = useState({
        lessons: [],
        isLoaded: false,
        filtered: false,

        selectedItem: null
    })

    return (
    <ScreenWrapper title="Les cours" nav={navigation}>
        <ScrollView>
            <VStack space={10}>
                <ResourceSelector
                resource={"lessons"}
                initialValues={{
                    isExpanded: true
                }}
                    onSubmit={(lessons) => setState(prev => ({...prev, lessons: lessons, filtered: true}))}
                />

                
                {
                    state.filtered && (
        
                        <FlatList
                            px={2}
                            data={state.lessons}
                            renderItem={
                                ({item}) => (<VStack mb='8' space={1}>
                                    <ResourcePDFPreviewer navigation={navigation} {...item} />
                                    {
                                        isSU && (<HStack>
                                                <SwipeRow leftOpenValue={get_screen_height()/4}  >
                                                    <HStack w={2*get_screen_height()/3}>
                                                        <IconButton ml='3'
                                                            icon={<Icon name='delete' as={AntDesign} color='red.400' size='lg'  />}
                                                            onPress={() => setState(prev => ({...prev, selectedItem: item}))}
                                                        />
                                                        {console.log('ID ', item)}
                                                        <IconButton ml='3'
                                                            icon={<Icon name='edit' as={AntDesign} color='amber.400' size='lg'  />}
                                                            onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageLearning.UpdateLearningResource.name, {init: {resource: 'lessons', title: "Modifier un cours", initialValues: item} }) }
                                                        />             
                                                    </HStack>
                                                    <Box justifyContent='center' h={'50px'} bg='gray.200' borderRadius={'full'}>
                                                        <Text ml='1' fontWeight={'thin'} >Glissez à droite</Text>
                                                    </Box>
                                                </SwipeRow>
                                                <Spacer />
                                                {/* <IconButton ml='3'
                                                    icon={<Icon name='edit' as={AntDesign} color='amber.400' size='lg'  />}
                                                    onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageLearning.UpdateLearningResource.name, {init: {resource: 'lessons', title: "Modifier un cours", initialValues: item} }) }
                                                />     */}
                                        </HStack>)
                                        }
                                </VStack>)
                            }
                            ListEmptyComponent={<NoData />}
                        />
                    )
                }
                {isSU && (
                    <Fab 
                    position='absolute' bottom='5%' 
                    icon={<Icon name='plus' as={AntDesign} size='sm'  />}
                    onPress={() => navigation.navigate(MENU_NAVIGATION.Direction.ManageLearning.CreateLearningResource.name, {init: {
                        title: "Ajouter un cours",
                        resource: "lessons"
                    }})}
                    />
                )}
            </VStack>
        </ScrollView>
        {
            state.selectedItem && <RemoveResource navigation={navigation} initialValues={state.selectedItem} onClose={() => setState(prev => ({...prev, selectedItem: null}))} />
        }
    </ScreenWrapper>
  );
}