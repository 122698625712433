import React from 'react';
import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    Checkbox,
    TextArea,
    IconButton
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { getMyContractParent, listContractsParents, validateCourseSession } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { from_day_index_to_day_name } from "services/utils";
import { COURSE_SESSION_STATUS } from "constant";
import { MaterialIcons } from '@expo/vector-icons';
import ScreenWrapper from 'components/ScreenWrapper';



export default ({ navigation }) => {
    const token = getToken()

    const [contracState, setContractState] = useState({
        all_parent_contracts: [],
        selected_parent_contract: null,
        isLoaded: false,
    })

    const refresh = (prevent_update_selected_child_id = false) => {
            listContractsParents(token)
            .then(res => setContractState(
                prev => ({
                    ...prev,
                    all_parent_contracts: res.data,
                    isLoaded: true
                })
            ))
    }

    useEffect(() => {
        refresh()

    }, [setContractState])

    return (
    <ScreenWrapper title="Sélectionnez d'abord un parent" nav={navigation}>
        <ScrollView>
            <VStack space={3} >
                {
                    ! contracState.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <Spinner />
                            <Heading color="primary.500" fontSize="md">
                                Chargement
                            </Heading>
                        </HStack>
                        </Center>
                    ) : (
                        <ScrollView>
                            <VStack space="2" px='3' mt='3' >
                                {
                                    contracState.all_parent_contracts.map(
                                        contract => (
                                            <Pressable onPress={
                                                () => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ValidatePrestation.name, {id_contract: contract.id})
                                            } >
                                                <HStack bg={'primary.500'} borderRadius={10} p='2' >
                                                    <VStack width={'80%'} alignSelf='center' >
                                                        <HStack>
                                                            <Heading color='white' size='md'>{contract.parent.full_name}</Heading>
                                                        </HStack>
                                                        <HStack>
                                                            <Heading color='white' size='xs'>{contract.parent.username}</Heading>
                                                        </HStack>
                                                    </VStack>
                                                    <IconButton 
                                                            icon={<MaterialIcons name="arrow-right" color='white' size={40} /> } 
                                                            onPress={
                                                                () => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ValidatePrestation.name, {id_contract: contract.id})
                                                            }
                                                            /> 
                                                </HStack>
                                            </Pressable>
                                        )
                                    )
                                }
                            </VStack>
                        </ScrollView>
                    
                    )
                }
                </VStack>

            </ScrollView>
    </ScreenWrapper>
  );
}