import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    TextArea,
    AddIcon,
    Spacer
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { listApprovedTeachers, approveUser, orderTeacher, listSubs } from "services/api-axios";
import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import OrderButton from "./components/OrderButton";
import DisapearingAlert from "components/DisapearingAlert";
import MultiSelect from "react-native-multiple-select";
import { Formik } from 'formik';
import * as Yup from 'yup';
import NumberInput from "components/NumberInput";
import PageLoading from "components/PageLoading";
import PhoneInput from "components/PhoneInput";
import Chip from "components/Annonce/Chip";
import { useFocusEffect } from "@react-navigation/native";
import { getNavigationPreviousScreen, setNavigationPreviousScreen } from "stacks/utils";


const IS_REQUIRED_TEXT = "Ce champs est obligatoire"
export default ({navigation, route}) => {
    const { initialValues, subs, editIndex } = route.params
    console.log("3INIT ", initialValues)
    const [childValues, setChildValues] = useState({
      full_name: '',
      school: '',
      child_level: '',
      place_courses: '',
      frequency: 2,
      child_subs: [],
      comments: '',
      ...initialValues,
    })

    const { isOpen: isSubmitting, onOpen: activeSubmitting, onClose: closeSubmitting } = useDisclose()
    const { isOpen: isEdit } = useDisclose(Object.keys(initialValues).length > 0)

    const ref_child_level = useRef()
    const ref_school = useRef()
    const ref_subs = useRef()
    const ref_place_courses = useRef()
    const ref_frequency = useRef()
    const ref_comments = useRef()

    return (
      
          <ScrollView pt="3" px='4' w="100%" h={'100%'} bg="white">
            <Formik
                initialValues={childValues}
                
                onSubmit={values => {
                    const data = {...values, child_subs: childValues.child_subs}
                    setNavigationPreviousScreen({
                        name: "ADD_CHILD_TO_FORM",
                        seed: Math.ceil(Math.random() * 100),
                        payload: {
                            type: isEdit ? "EDIT" : "ADD",
                            data: {
                                ...data,
                                editIndex
                            }
                        }
                      })
                    activeSubmitting()
                    try {
                        setTimeout(() => {
                            navigation.goBack()
                        }, 500);
                    } catch (e) {
                        closeSubmitting()
                    }
                }}
                validateOnBlur={true}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                    full_name: Yup.string().required(IS_REQUIRED_TEXT),
                    school: Yup.string().required(IS_REQUIRED_TEXT),
                    child_level: Yup.string().required(IS_REQUIRED_TEXT),
                    place_courses: Yup.string().required(IS_REQUIRED_TEXT),
                    frequency: Yup.number("Ceci doit être un nombre").required(IS_REQUIRED_TEXT).min(1, "Vous devez renseigner au moins 1 séance dans la semaine"),
                    child_subs: Yup.array(),
                    comments: Yup.string(),
                })}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors , values }) => (
                        <VStack space="3" pb='4'  >
                            <FormControl isRequired>
                                <FormControl.Label>Nom de l'enfant à encadrer</FormControl.Label>
                                <Input 
                                    value={values.full_name}
                                    onChangeText={handleChange('full_name')}
                                    onBlur={handleBlur('full_name')}
                                    onSubmitEditing={() => {
                                        ref_child_level.current?.focus()
                                    }}
                                    />
                                <Text color={'danger.500'}>{errors.full_name}</Text>
                            </FormControl>
                            <FormControl isRequired>
                                <FormControl.Label>Classe</FormControl.Label>
                                <Input 
                                    value={values.child_level}
                                    onChangeText={handleChange('child_level')}
                                    onBlur={handleBlur('child_level')}
                                    ref={ref_child_level}
                                    />
                                <Text color={'danger.500'}>{errors.child_level}</Text>
                            </FormControl>
                            <FormControl isRequired>
                                <FormControl.Label>Matières à renforcer</FormControl.Label>
                                {/* <Select selectedValue="0" onValueChange={(val) => setChildValues(prev => ({...prev, child_subs: val}))}>
                                    {
                                        subs.map( s => <Select.Item color="yellow" key={s.id} value={s.id} label={s.name} _text={{color: 'black'}} /> )
                                    }
                                </Select> */}
                                <MultiSelect
                                    items={subs}
                                    uniqueKey="id"
                                    displayKey="name"
                                    // ref={(component) => { this.multiSelect = component }}
                                    onSelectedItemsChange={(new_list) => setChildValues(prev => ({...prev, child_subs: new_list}))}
                                    selectedItems={childValues.child_subs}
                                    selectText="Matières"
                                    searchInputPlaceholderText="Recherche ..."
                                    tagRemoveIconColor="#CCC"
                                    tagBorderColor="#CCC"
                                    
                                    tagTextColor="#CCC"
                                    selectedItemTextColor="blue"
                                    selectedItemIconColor="#CCC"
                                    itemTextColor="#000"
                                    
                                    searchInputStyle={{ color: '#CCC' }}
                                    submitButtonColor="blue"
                                    submitButtonText="Confirmer la sélection"
                                    
                                    ref={ref_subs}
                                    />
                            </FormControl>
                            
                        <FormControl isRequired>
                            <FormControl.Label>Nom de l'école fréquentée</FormControl.Label>
                            <Input 
                                value={values.school}
                                onChangeText={handleChange('school')}
                                onBlur={handleBlur('school')}
                                ref={ref_school}
                                onSubmitEditing={() => {
                                    ref_place_courses.current?.focus()
                                }}
                            />
                                <Text color={'danger.500'}>{errors.school}</Text>
                            </FormControl>
                
                        <FormControl isRequired>
                            <FormControl.Label>Lieu des cours à domicile</FormControl.Label>
                            <Input 
                                value={values.place_courses}                            
                                onChangeText={handleChange('place_courses')}
                                onBlur={handleBlur('place_courses')}
                                ref={ref_place_courses}
                                onSubmitEditing={() => {
                                    ref_frequency.current?.focus()
                                }}
                        />
                                <Text color={'danger.500'}>{errors.place_courses}</Text>
                            </FormControl>
                
                        <FormControl isRequired>
                            <FormControl.Label>Fréquence des cours dans la semaine</FormControl.Label>
                            <NumberInput 
                                value={values.frequency}
                                onChangeText={handleChange('frequency')}
                                onBlur={handleBlur('frequency')}
                                ref={ref_frequency}
                                innerRef={ref_frequency}
                                onSubmitEditing={() => {
                                    ref_comments.current?.focus()
                                }}
                            />
                                <Text color={'danger.500'}>{errors.frequency}</Text>
                            </FormControl>
                        
                        <FormControl isRequired>
                            <FormControl.Label>Commentaires</FormControl.Label>
                            <TextArea 
                                value={values.comments}
                                onChangeText={handleChange('comments')}
                                onBlur={handleBlur('comments')}
                                ref={ref_comments}
                                onSubmitEditing={() => {
                                    p_ref.current?.focus()
                                }}
                            />
                            </FormControl>
                
                            <Button onPress={handleSubmit} isLoading={isSubmitting} >{isEdit ? "Modifier" : "Ajouter à ma demande"}</Button>
                        </VStack>
                    ) }
            </Formik>
          </ScrollView>
        
    )
}