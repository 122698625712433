import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { createInvoiceParent, listContractsParents } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import DateTimeInput from "components/DateTimeInput";
import { apiErrorNotify } from "services/notify";
import { humanize_date_YMD } from "services/utils";
import NumberInput from "components/NumberInput";
import ScreenWrapper from "components/ScreenWrapper";
import { INVOICE_STATUS } from "constant";



const DisapearingAlert=({time = 3000, message='Success', update})=>{
  setTimeout(update, time);
  return (
    <Actionsheet isOpen={true} collapsable={false} >
      <Actionsheet.Content bg="white" height={get_screen_height()} >
        <Center>
          <Heading color="red.500" > {message} </Heading>
        </Center>
      </Actionsheet.Content>
    </Actionsheet>  
  )
 
}

export default ({ navigation, route }) => {
    const token = getToken()
    const toast = useToast()
    
    const routeInitialValues = route.params ? route.params.init : {}
    
    const [state, setState] = useState({
      selected_contract_id: null,
      isSubmitting: false,
      contract_parents: [],
      done: false,
      error: false,
      ...routeInitialValues
    })
    const [formValues, setFormvalues] = useState({
      price: 0,
      start_at: '',
      end_at: '',
      deadline: '',
      status: INVOICE_STATUS.pending,
    })
    const {
      isOpen,
      onOpen,
      onClose
    } = useDisclose()

    const handleSubmit = () => {
      console.log(formValues, data, )
      console.log("Start ", formValues.start_at, typeof formValues.start_at)
      const data = {
        price: parseInt(formValues.price),
        status: formValues.status,
        start_at: humanize_date_YMD(formValues.start_at),
        end_at: humanize_date_YMD(formValues.end_at),
        deadline: humanize_date_YMD(formValues.deadline),
      }
      console.log("ID contract ", state.selected_contract_id)
      createInvoiceParent(token, state.selected_contract_id, data)
      .then( res => {
        setState( prev => ({...prev, done: true}) )
        navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {navigation: navigation, time: 1500})
      }  )
      .catch( err => {
        apiErrorNotify({response: err.response, toast: toast})
      })
    }
  
    useEffect(() => {
      listContractsParents(token)
      .then(res => setState( prev => ({...prev, contract_parents: res.data})) )
      .catch(err => setState(prev=> ({...prev, error: err})))
    }, [setState])
    return (
    <ScreenWrapper title="Enregistrer une facture" nav={navigation}>
      <ScrollView>
        {
          (

            <VStack space={3} px="3"  mb='3' >
                <FormControl isRequired>
                  <FormControl.Label>Parent</FormControl.Label>
                    <Select selectedValue={state.selected_contract_id} color="black" onValueChange={val => setState(prev => ({...prev, selected_contract_id: val}))} >
                      <Select.Item label="Sélectionnez le parent"  />
                      {
                        state.contract_parents.map( (p, ind) => (
                          <Select.Item key={ind} 
                                label={p.parent.full_name ? p.parent.full_name : p.parent.username} 
                                value={p.id} /> )
                        )
                      }
                    </Select> 
                </FormControl>
                <FormControl isRequired>
                  <FormControl.Label>Montant de la facture</FormControl.Label>
                    <NumberInput
                      value={formValues.price} 
                      placeholder="Entrez le montant"
                      onChangeText={val => setFormvalues(prev => ({...prev, price: val})) } />
                  </FormControl>
                <FormControl isRequired>
                  <FormControl.Label>Date de début</FormControl.Label>
                    <DateTimeInput 
                      value={formValues.start_at} 
                      placeholder="Entrez la date"
                      setValue={val => setFormvalues(prev => ({...prev, start_at: val})) } />
                  </FormControl>
                <FormControl isRequired>
                  <FormControl.Label>Date de fin</FormControl.Label>
                    <DateTimeInput
                      value={formValues.end_at} 
                      placeholder="Entrez la date"
                      setValue={val => setFormvalues(prev => ({...prev, end_at: val})) } />
                  </FormControl>
                <FormControl isRequired>
                  <FormControl.Label>Deadline de paiement</FormControl.Label>
                    <DateTimeInput 
                      value={formValues.deadline} 
                      placeholder="Entrez la date"
                      setValue={val => setFormvalues(prev => ({...prev, deadline: val})) } />
                  </FormControl>
                  <FormControl >
                    <FormControl.Label>Marquer cette facture comme réglée</FormControl.Label>
                      <Switch
                      alignSelf={'flex-start'}
                        value={formValues.status == INVOICE_STATUS.achieved} 
                        onValueChange={val => setFormvalues(prev => ({...prev, status: val ? INVOICE_STATUS.achieved : INVOICE_STATUS.pending })) } 
                      />
                    </FormControl>
                    <Button 
                      isDisabled={!state.selected_contract_id || !formValues.price || !formValues.start_at || !formValues.end_at || !formValues.deadline }
                      onPress={handleSubmit}
                    >Enregistrer</Button>
              
            </VStack>
          )
        }
      </ScrollView>
    </ScreenWrapper>
  );
}