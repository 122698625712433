import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeUserScreen from 'screens/HomeUserScreen';
import AppBar from "components/AppBar";
import { getIsSuperuser, getIsManager, getIsParent, getIsTeacher } from 'store/login/selectors';
import DirectionStackNavigator from 'stacks/DirectionStack';
import { Box, Center, Text } from 'native-base';
import { MENU_NAVIGATION } from 'constant';
import ParentsStack from 'stacks/ParentsStack';
import TeachersStack from 'stacks/TeachersStack';
import StatusScreen from 'screens/StatusScreen';
import DisplayPDFScreen from 'screens/DisplayPDFScreen';
import ListLearningLessonsScreen from 'screens/ListLearningLessonsScreen';
import ListLearningVideos from 'screens/ListLearningVideos';
import VideoReaderScreen from 'screens/VideoReaderScreen';
import ListLearningExercisesScreen from 'screens/ListLearningExercisesScreen';
import DeleteMyAccount from 'screens/DeleteMyAccount';
import NotificationsListScreen from 'screens/NotificationsListScreen';
import NotificationDetailsScreen from 'screens/NotificationDetailsScreen';

const UserStack = createNativeStackNavigator();

const NoManaged = () => (
  <Center>
    <Box width={100} height={100} bg="red.700">
      <Text size="2xl" >Non géré !</Text>
    </Box>
  </Center>
)
export default function UserStackNavigator() {
  const is_superuser = getIsSuperuser()
  const is_manager = getIsManager()
  const is_direction = is_superuser || is_manager || is_superuser
  const is_parent = getIsParent()
  const is_teacher = getIsTeacher()
  console.log("Check role", is_superuser, is_manager, is_parent, is_teacher)
  return (
    <UserStack.Navigator screenOptions={{ 
      headerShown: false,  
    }}>
      {
        is_direction && <UserStack.Screen name={MENU_NAVIGATION.Direction.stackname} component={DirectionStackNavigator} /> 
      }
      {
        is_parent && <UserStack.Screen name={MENU_NAVIGATION.Parents.stackname} component={ParentsStack} /> 
      }
      {
        is_teacher && <UserStack.Screen name={MENU_NAVIGATION.Teachers.stackname} component={TeachersStack} /> 
      }

    <UserStack.Screen name={MENU_NAVIGATION.StatusScreen.name} component={StatusScreen} /> 
    <UserStack.Screen name={MENU_NAVIGATION.Common.DisplayPDF.name} component={DisplayPDFScreen} /> 
    <UserStack.Screen name={MENU_NAVIGATION.Common.ListLearningLessons.name} component={ListLearningLessonsScreen} />
    <UserStack.Screen name={MENU_NAVIGATION.Common.ListLearningVideos.name} component={ListLearningVideos} /> 
    <UserStack.Screen name={MENU_NAVIGATION.Common.ListLearningExercises.name} component={ListLearningExercisesScreen} /> 
    <UserStack.Screen name={MENU_NAVIGATION.Common.VideoReaderScreen.name} component={VideoReaderScreen} /> 

    <UserStack.Screen name={MENU_NAVIGATION.Common.DeleteMyAccount.name} component={DeleteMyAccount} /> 
    <UserStack.Screen name={MENU_NAVIGATION.Common.NotificationsListScreen.name} component={NotificationsListScreen} /> 
    <UserStack.Screen name={MENU_NAVIGATION.Common.NotificationDetailsScreen.name} component={NotificationDetailsScreen} /> 

    </UserStack.Navigator>
  );
}