import {
    Text,
    VStack,
    ScrollView,
    FlatList,
    Fab,
    Icon,
    useToast,
    Button,
    HStack,
    Box,
    Spacer,
    IconButton,
    Actionsheet,
    Center,
    FormControl,
    Input,
    Select,
    TextArea,
  } from "native-base";
import { getIsSuperuser, getToken } from "store/login/selectors";
import { useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import ScreenWrapper from "components/ScreenWrapper";
import * as React from 'react';
import ResourceSelector from "components/ResourceSelector";
import ResourcePDFPreviewer from "components/ResourcePDFPreviewer";
import { MENU_NAVIGATION } from "constant";
import NoData from "components/NoData";
import { SwipeRow } from "react-native-swipe-list-view";
import { get_screen_height } from "services/dimensions";
import { listClassLevels, updateLearningExercise, updateLearningLesson, updateLearningVideo } from "services/api-axios";
import PageLoading from "components/PageLoading";
import SelectMultipleInput from "components/SelectMultipleInput";
import { useNavigation } from "@react-navigation/native";
import { apiErrorNotify } from "services/notify";


function ResourceMediaUpdate({isOpen, onClose, resource, initialValues, navigation}) {
    const token = getToken()
    const toast = useToast()

    const [formState, setFormState] = useState({
        title: initialValues.title || "",
        subtitle: initialValues.subtitle || "",
        description: initialValues.description || "",
        target: initialValues.target?.toString(),
        class_levels:  initialValues.class_levels,
        subs: initialValues.subs
      })

      const [state, setState] = useState({
        selectedClassLevelIndexes: [],
        selectedSubIndexes: [],

        classLevels: [],
        isLoaded: false,
        isSubmitting: false,
        areSubsLoaded: false
      })

      function handleUpdate() {
        const data = {
            title: formState.title,
            subtitle: formState.subtitle,
            description: formState.description,
            target: parseInt(formState.target),
            sub_ids: state.selectedSubIndexes,
            class_level_ids: state.selectedClassLevelIndexes
        }
        console.log("\n\n DATA ", data)
        setState(prev => ({
            ...prev, 
            isSubmitting: true
        }))
        if(resource === 'videos') {
            updateLearningVideo(token, initialValues.id, data)
            .then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, { init: {msg: 'Vidéos modfiée', time: 2000, navigation: navigation} }) )
            .catch(err => {
                setState(prev => ({
                    ...prev, 
                    isSubmitting: false
                }))
                apiErrorNotify({response: err.response, toast: toast})
            }) 
        } else if(resource === 'lessons') {
            updateLearningLesson(token, initialValues.id, data)
            .then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, { init: {msg: 'Cours modfiée',time: 2000, navigation: navigation} }) )
            .catch(err => {
                setState(prev => ({
                    ...prev, 
                    isSubmitting: false
                }))
                apiErrorNotify({response: err.response, toast: toast})
            }) 
        } else if(resource === 'exercises') {
            updateLearningExercise(token, initialValues.id, data)
            .then(() => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, { init: {msg: 'Exercice modfiées',time: 2000, navigation: navigation} }) )
            .catch(err => {
                setState(prev => ({
                    ...prev, 
                    isSubmitting: false
                }))
                apiErrorNotify({response: err.response, toast: toast})
            }) 
        }
      }

      function extractSubsFromClassLevel() {
        let classes = state.classLevels.filter(item => state.selectedClassLevelIndexes.includes(item.id))
        let res = classes.reduce((prev, current) => {
          let newSubs = []
          current.subs.forEach(sub => {
            let verite = prev.find(item => item.id === sub.id)
            if(!verite) newSubs.push(sub)
          })
          return prev.concat(newSubs)
        }, [])
        return res
      }

      React.useEffect(() => {
        listClassLevels(token).then(
          res => {
            // let cl_indexes = initialValues.class_levels.map(item => {
            //     return res.data.findIndex(cl => cl.id === item.id)
            // })
            // cl_indexes = cl_indexes.map(i => i+1)
            
            setState(prev => ({
                ...prev, 
                classLevels: res.data, 
                selectedClassLevelIndexes: initialValues.class_levels.map(item => item.id )
            }))
            setTimeout(function () {
                setState(prev => ({
                    ...prev, 
                    isLoaded: true
                }))
            }, 2000)
          }
        )
      }, [setState])

    return (
        <>
        {
            state.isLoaded === false ? (
                <Center >
                    <HStack space={2} justifyContent="center">
                        <PageLoading />
                    </HStack>
                </Center>
            ) : (
                <ScrollView px="3" w='100%'>
                    <VStack space={2} >
                        <FormControl>
                            <FormControl.Label>Titre</FormControl.Label>
                            <Input
                                value={formState.title}
                                onChangeText={(val) => setFormState(prev => ({...prev, title: val }))}
                            />
                        </FormControl>

                        <FormControl>
                        <FormControl.Label>Sous-titre</FormControl.Label>
                        <Input
                            value={formState.subtitle}
                            onChangeText={(val) => setFormState(prev => ({...prev, subtitle: val }))}
                        />
                        </FormControl>

                        <FormControl>
                        <FormControl.Label>Description</FormControl.Label>
                        <TextArea
                            value={formState.description}
                            onChangeText={(val) => setFormState(prev => ({...prev, description: val }))}
                        />
                        </FormControl>

                        <FormControl>
                        <FormControl.Label>Audience cible</FormControl.Label>
                        <Select
                            selectedValue={formState.target}
                            onValueChange={(val) => setFormState(prev => ({...prev, target: val }))}
                        >
                            {console.log(formState.target)}

                            <Select.Item value='0' label='Tout public' />
                            <Select.Item value='1' label='Les Parent' />
                            <Select.Item value='2' label='Les Enseignants' />
                        </Select>
                        </FormControl>

                        <FormControl  zIndex={100} >
                        <FormControl.Label>Classes</FormControl.Label>
                        {console.log("\n state.classLevels ", state.classLevels.length, state.classLevels.map(i => ({n: i.name, id: i.id})))}
                        {console.log("\n Selected ", state.selectedClassLevelIndexes.length, state.selectedClassLevelIndexes )}
                        <SelectMultipleInput
                            displayKey={'name'}
                            items={state.classLevels.length === 0 ? [] : [{id: 0, name: "Toutes les classe"}].concat(state.classLevels)}
                            onSelectedItemsChange={(val) => {
                                // console.log("onSelectedItemsChange ", val, '//', state.selectedClassLevelIndexes)
                                setState(prev => ({...prev, selectedClassLevelIndexes: val.includes(0) ? ( prev.selectedClassLevelIndexes.length === state.classLevels.length  ? [] : prev.classLevels.map(item => item.id)) : val }))
                            }}
                            selectedItems={state.selectedClassLevelIndexes}
                            selectText="Confirmer la sélection "
                            fixedHeight={false}
                            />
                        </FormControl>

                        {
                            state.selectedClassLevelIndexes.length > 0 && (
                                <FormControl  zIndex={100} >
                                <FormControl.Label>Matières</FormControl.Label>
                                <SelectMultipleInput
                                 zIndex={100} 
                                    displayKey={'name'}
                                    items={[{id: 0, name: "Toutes les matières"}].concat(extractSubsFromClassLevel())}
                                    onSelectedItemsChange={(val) => {
                                        console.log("onSelectedItemsChange ", val, '//-->', state.selectedSubIndexes)
                                        const extracted = extractSubsFromClassLevel()
                                        setState(prev => ({...prev, selectedSubIndexes: val.includes(0) ? ( prev.selectedSubIndexes.length === extracted.length  ? [] : extracted.map(item => item.id) ) : val }))
                                    }}
                                    selectedItems={function () {
                                        if(state.areSubsLoaded === false) {
                                            return setState(prev => ({...prev, areSubsLoaded: true, selectedSubIndexes: extractSubsFromClassLevel().filter(sub => {
                                                return initialValues.subs.findIndex(s => s.id === sub.id) > -1
                                            } ).map(sub => sub.id)}))
                                        }
                                        return state.selectedSubIndexes
                                        // state.selectedSubIndexes.length > 0 ? state.selectedSubIndexes : 
                                    }()}
                                    // selectedItems={state.selectedSubIndexes}
                                    selectText="Confirmer la sélection "
                                    fixedHeight={false}
                                    />
                                </FormControl>
                            )
                        }

                        <HStack justifyContent={'space-between'} w='100%' >
                            <Button
                                onPress={() => navigation.goBack()}
                                variant={'ghost'}
                                bg='gray.200'
                                >Annuler</Button>

                            <Button
                                onPress={handleUpdate}
                                isLoading={state.isSubmiting}
                            >Modifier</Button>

                        </HStack>

                        <Spacer h={'100px'} />
                    </VStack>
                </ScrollView>
            )
        }
        </>
    )
}

export default ({ navigation, route }) => {
    const token = getToken()
    const toast = useToast()
    const routeInitialValues = route.params ? route.params.init : {}
    const resource = routeInitialValues.resource

    if(!["videos", "exercises", "lessons"].includes(resource)) throw '`resource` not in ["videos", "exercises", "lessons"]'

    return (
        <ScreenWrapper title={routeInitialValues.title} hideBackButton nav={navigation}>
            <ResourceMediaUpdate navigation={navigation} resource={resource} isOpen={true} initialValues={routeInitialValues.initialValues} />
        </ScreenWrapper>
    )
}