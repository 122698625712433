import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import GoBack from "components/GoBack";
import PageLoading from "components/PageLoading";
import ScreenWrapper from "components/ScreenWrapper";
import UserWithoutContract from "components/UserWithoutContract";
import { INVOICE_STATUS } from "constant";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider,
    useToast,
    Icon
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { getMyContractTeacher } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import { format_price, humanize_natural_date } from "services/utils";
import { getToken } from "store/login/selectors";

const SalaryItem = ({salary}) => (
    <HStack py='2' space="2" bg='gray.200' shadow={5} borderTopLeftRadius={10}  borderTopRadius={10}  >
        <Center>
            <Text></Text>
        </Center>
        <VStack>
            <HStack>
                <Text>Du </Text>
                <Text fontWeight={'bold'} >{humanize_natural_date(new Date(salary.start_at))}</Text>
            </HStack>
            <HStack>
                <Text>Au </Text>
                <Text fontWeight={'bold'} >{humanize_natural_date(new Date(salary.end_at))}</Text>
            </HStack>
        </VStack>
        <Center borderLeftWidth={0} pl='3'  >
            <Heading size="lg">{format_price(salary.price)}F</Heading>
        </Center>
        
    </HStack>
)

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const redGrad = {
        linearGradient: {
            colors: [
                'red.500',
                'orange.500',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const token = getToken()
    const toast = useToast()

    const [state, setState] = useState({
        contract: null,
        isLoaded: false,
        error: false,
        lates_salaries: [],
        achieved_salaries: [],
        to_pay_invoices: [],
        current_salary: null
    })
    useEffect(()=> {
        getMyContractTeacher(token)
            .then(res => {
                const sal_done = []
                const sal_lates = []
                const sal_to_pay = []
                let current_salary = null
                const today = new Date()
                if(res.data) {
                    res.data.salaries.forEach(sal => {
                        if(sal.status === INVOICE_STATUS.pending ) {
                            if(new Date(sal.start_at) <= new Date() && today <= new Date(sal.end_at)) {
                                current_salary = sal
                            } 
                            else sal_to_pay.push(sal)
                            
                        } else if(sal.status === INVOICE_STATUS.achieved) {
                            sal_done.push(sal)
                        }
                    })
                    setState(prev => ({
                        ...prev, 
                        contract: res.data, 
                        current_salary: current_salary,
                        achieved_salaries: sal_done,
                        to_pay_salaries: sal_to_pay,
                        isLoaded: true
                    }))
                } else {
                    
                }
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
            .finally(() => setState(prev => ({
                ...prev, 
                isLoaded: true
            })))

    }, [setState ])
  return (
    <ScreenWrapper title="Mes salaires" nav={navigation}>
        <ScrollView>
            <VStack px="2" py="5" > 
                <VStack space={4}>
                    {
                        !state.isLoaded ? (
                            <Center  >
                                <HStack space={2} justifyContent="center">
                                <PageLoading />
                            </HStack>
                            </Center>
                        ) : !state.contract ? <UserWithoutContract /> : (
                            <>
                                <Box minHeight={70} m='2' borderRadius={10} >
                                    <Box   bg={'primary.500'} mx='6' my='1' p='3' borderRadius={10} >
                                        <Heading color='white' >Mon salaire du mois</Heading>
                                    </Box>
                                    {
                                        state.current_salary ? (
                                            state.current_salary.status === INVOICE_STATUS.achieved ? (
                                                <Heading size='md'>Déjà réglé</Heading>
                                            ): (
                                                // <HStack space='5' py='2' >
                                                   <SalaryItem salary={state.current_salary} />
                                                // </HStack>
                                            )
                                        ): (
                                            <Center>
                                                <Text>Aucun salaire</Text>
                                            </Center>
                                        )
                                    }
                                </Box>
                            
                                <Box  minHeight={70} mx='2' borderRadius={10} >
                                    <Box   bg={redGrad} p='3' mx='6' flex={1} borderRadius={10} >
                                        <Heading color="white">Non reçus</Heading>
                                    </Box>
                                    
                                    {
                                        state.to_pay_salaries.length === 0 ? (
                                            <Center>
                                                <Text>Vous êtes à jour</Text>
                                            </Center>
                                        ) : (
                                                <VStack my='2'>
                                                    
                                                    {
                                                        state.to_pay_salaries.map(
                                                            sal => (
                                                                <VStack key={sal.id} >
                                                                    <SalaryItem salary={sal} />
                                                                    <Divider />
                                                                </VStack>
                                                            )
                                                        )
                                                    }

                                                </VStack>
                                        )
                                    }
                                </Box>
                            
                                <Box  minHeight={70} mx='2' borderRadius={10} >
                                    <Box   bg={'green.500'} p='3' mx='6' flex={1} borderRadius={10} >
                                        <Heading color="white">Déjà reçus</Heading>
                                    </Box>
                                    
                                    {
                                        state.achieved_salaries.length === 0 ? (
                                            <Center>
                                                <Text>Aucun salaire reçu</Text>
                                            </Center>
                                        ) : (
                                                <VStack my='2'>
                                                    
                                                    {
                                                        state.achieved_salaries.map(
                                                            sal => (
                                                                <VStack key={sal.id} >
                                                                    <SalaryItem salary={sal} />
                                                                    <Divider />
                                                                </VStack>
                                                            )
                                                        )
                                                    }

                                                </VStack>
                                        )
                                    }
                                </Box>
                            
                            </>
                        )
                    }
                    
                </VStack>
        </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}