import { HStack, Text } from "native-base"
import { Pressable } from "react-native"
import { from_month_index_to_name, split_time } from "services/utils"

export default ({value, onPress, fontSize = 14, space =  0.5, padding}) => {
    padding = padding || fontSize / 4

    let [hour, min, sec] = split_time(value)
    return (
        <Pressable onPress={onPress} >
            <HStack bg='primary.500' rounded='full' py={1}  >
                <Text color='white' fontSize={fontSize} fontWeight={'bold'} pl={padding}  >{hour}</Text>
                <Text color='white' fontSize={fontSize} px={space} >H</Text>
                <Text color='white' fontSize={fontSize} fontWeight={'bold'} pr={padding}   >{min}</Text>
            </HStack>
        </Pressable>
    )
}