import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import { appendNotifications, appendOldNotifications, clearAllNotifications, loadNotifications } from './reducer';

export const useAppendNotifications = () => {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch(appendNotifications(payload));
  }
}


export const useAppendOldNotifications = () => {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch(appendOldNotifications(payload));
  }
}

export const useLoadNotifications = () => {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch(loadNotifications(payload));
  }
}
export const useClearNotications = () => {
  const dispatch = useDispatch();
  return () => dispatch(clearAllNotifications());
}
