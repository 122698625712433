import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Divider,
    IconButton,
    Icon,
    useToast,
    TextArea
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { listContractTeacherChildren, getParentInfosFromContractParent, getMyContractParent, listContractParentChildren, getTeacherInfosFromContractParent, createParentRemark } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import UserWithoutContract from "components/UserWithoutContract";
import { apiErrorNotify } from "services/notify";
import ContactOptionsInlineFlex from "components/ContactOptionsInlineFlex";
import Chip from "components/Annonce/Chip";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";



export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        contract_id: null,
        children: [],
        teachers: {},
        selected_child_index: 0,
        selected_teacher_index: 0,
        selected_child_obj: null,
        selected_subject_affected: null,
        contract_teacher_ids_dict: {},
        isLoaded: false,
        isSubmiting: false
    })
    const [description, setDescription] = useState('')
    
    const refresh = () => {
        getMyContractParent(token).then(
            res_contract => {
                if(res_contract.data){
                    listContractParentChildren(token, res_contract.data.id).then(
                        res_children => {
                            // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                            // setState(prev => ({...prev, children: res.data, isLoaded: true }))
                            const contract_teacher_ids_dict = {}
                            setState(prev => ({
                                ...prev, 
                                children: res_children.data, 
                                contract_id: res_contract.data.id,
                                contract_teacher_ids_dict: contract_teacher_ids_dict
                            }
                            ))
                            res_children.data.map(
                                (child, child_index) => {
                                    child.subjects_affected.forEach(
                                        s_a => {
                                            if(!contract_teacher_ids_dict[child_index]) contract_teacher_ids_dict[child_index] = new Set()
                                            contract_teacher_ids_dict[child_index].add(s_a.contract_teacher_id) 
                                        }
                                    )
                                }
                            )
                            // const contract_teacher_ids_set = new Set(Object.values(contract_teacher_ids_dict).reduce((total, current) => ([...total, ...current]), []))
                            let teachers = {}
                            const list_version = Object.keys(contract_teacher_ids_dict)
                            // console.log("contract_teacher_ids_set", contract_teacher_ids_set)
                            Object.keys(contract_teacher_ids_dict).forEach(
                                (child_index, loop_index) => {
                                    const cp_ids = contract_teacher_ids_dict[child_index]
                                    teachers[child_index] = []
                                    // if( ! contract_teacher_ids_set.has(cp_id) ) {
                                        console.log("\n ReFetching  ", cp_ids)
                                        
                                        cp_ids.forEach( cp_id => {
                                            getTeacherInfosFromContractParent(token, cp_id)
                                                .then( res_parent => setState(prev => {
                                                
                                                    teachers = {
                                                        ...teachers,
                                                        [child_index]: teachers[child_index].concat(res_parent.data)
                                                    } 
                                                    return {
                                                        ...prev, 
                                                        isLoaded: loop_index === list_version.length-1,
                                                        teachers: teachers
                                                    }
                                                }) ).catch(err => {
                                                    console.log("Error for teacher ", !err.response ? err.response.request._response : err)
                                                    
                                                })
                                        })
                                        if(loop_index === list_version.length-1) setState(prev => ({...prev, isLoaded: true}))
                                    // } 
                                }
                            )
                           
                        }
                    )
                    .catch(err => apiErrorNotify({response: err.response, toast: toast}) )

                } else {
                    setState(prev => ({
                        ...prev, 
                        isLoaded: true
                    }
                    ))
                }
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }

    const handleSubmit = async () => {
        const teacher = state.teachers[state.selected_child_index][state.selected_teacher_index] 
        // await setState(prev => ({...prev, isSubmiting: true}))
        const data = {
            comments: description,
            contract_teacher_id: state.children[state.selected_child_index].subjects_affected[0].contract_teacher_id,
            
        }
        console.log(data)
        createParentRemark(token, state.contract_id, state.children[state.selected_child_index].id, data)
            .then( async res => {
                navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {navigation: navigation})
                // await refresh({prevent_update_selected_child_id : true})
                // await setState(prev => ({...prev, selected_session_id: null, isSubmiting: false, remark: "", success: true}))
            } )
            .catch(err => {
                apiErrorNotify({response: err.response, toast: toast});
                setState(prev => ({...prev, isSubmiting: true}))
            })
    }

    useEffect(() => {
        refresh()
    }, [setState])

    return (
    <ScreenWrapper nav={navigation} title="Signaler une observation sur l'enseignant">
        <ScrollView>
            <VStack space={3} my='4' >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <Spinner />
                            <Heading color="primary.500" fontSize="md">
                                Chargement
                            </Heading>
                        </HStack>
                        </Center>
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <>
                            <VStack space="5" px='3'>
                                <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                    <HStack space={5}>
                                        {
                                            state.children.map(
                                                (c, index) => (
                                                    <Pressable key={index} onPress={() => setState(prev => {
                                                        return {...prev, selected_child_index: index, selected_child_obj: c, selected_teacher_index: 0}
                                                    }) } >
                                                        <Text py='2' px='4' bg={state.selected_child_index == index ? 'gray.800' : 'gray.400'} color={state.selected_child_index == index ? 'white' : 'black' } borderRadius={20} >{c.full_name} </Text>
                                                    </Pressable>
                                                )
                                            )
                                        }
                                    </HStack>
                                </ScrollView>
                                <VStack>
                                    { state.teachers[state.selected_child_index] && <Heading size='sm'>Les enseignants de  {state.children[state.selected_child_index].full_name}</Heading>}
                                </VStack>
                                
                                {console.log(state.selected_child_index)}
                                <ScrollView horizontal showsHorizontalScrollIndicator={false} mt={-5} >
                                    <HStack space={5}>
                                    {
                                        !state.teachers[state.selected_child_index] ? <NoData msg="Aucun enseignant assigné à cet enfant !" /> : state.teachers[state.selected_child_index].map( 
                                            (teacher, index) => (
                                                <Pressable key={index} onPress={() => setState(prev => {
                                                    return {...prev, selected_teacher_index: index, selected_teacher_obj: teacher}
                                                }) } >
                                                    <Text py='2' px='4' bg={state.selected_teacher_index == index ? 'gray.800' : 'gray.400'} color={state.selected_teacher_index == index ? 'white' : 'black' } borderRadius={20} >{teacher.full_name || teacher.username} </Text>
                                                </Pressable>
                                            )
                                        )
                                    }
                                    </HStack>
                                </ScrollView>
                                
                                <VStack>
                                    {
                                        state.teachers[state.selected_child_index] && state.teachers[state.selected_child_index][state.selected_teacher_index] && (
                                            <VStack space='5'>
                                                <Box  bg={'blue.500'} p='3'  flex={1} borderRadius={10} >
                                                    <Heading color='white' size='lg'>{state.teachers[state.selected_child_index][state.selected_teacher_index].username}</Heading> 
                                                    <Text>Profession: Etudiant</Text>
                                                    {/* <Text>Ville: {state.contract.city.name}</Text> */}
                                                </Box>
                                                <Heading size='sm'>L'observation portera sur l'enseignant {state.teachers[state.selected_child_index][state.selected_teacher_index].username} </Heading>
                                                <TextArea 
                                                    placeholder="Ecrivez votre observation"
                                                    numberOfLines={16}
                                                    value={description}
                                                    onChangeText={setDescription}
                                                    />
                                                <Button onPress={handleSubmit} isLoading={state.isSubmiting} isDisabled={!description} >Envoyer mon observation</Button>
                                            </VStack>
                                        )
                                    }
                                </VStack>

                                    
                            </VStack>
                        </>
                    
                    )
                }

                </VStack>

        </ScrollView>
    </ScreenWrapper>
  );
}