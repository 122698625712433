import { StatusBar, Box, HStack, IconButton, Text, Icon, Image, Avatar, Center, Badge, VStack, Button, Modal, useDisclose, FlatList, Spacer, useToast, CircleIcon, Spinner, CheckIcon, Heading, CloseIcon } from "native-base";
import { AntDesign, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import Logo from 'assets/logo.png'
import { getLoginState, getToken, getUserAvatar, getUsername } from "store/login/selectors";
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { Pressable, RefreshControl } from "react-native";
import { getFile, getFileLink, listMsgs, listNewMsgs, markMsgRead } from "services/api-axios";
import { MSG_STATUS } from "constant";
import { humanize_date, humanize_date_YMD, humanize_natural_date, humanize_time } from "services/utils";
import { apiErrorNotify } from "services/notify";
import GoBack from "components/GoBack";
import Notifications, { registerForPushNotificationsAsync } from "services/push-notifiy";
import { MENU_NAVIGATION } from "constant";
import { useNavigation } from "@react-navigation/native";
import ScreenWrapper from "components/ScreenWrapper";
import { getNotifications } from "store/notifications/selectors";
import { useAppendNotifications, useClearNotications, useLoadNotifications } from "store/notifications/hooks";

export default function ({ route }) {
    const navigation = useNavigation()
    const token = getToken()
    const toast = useToast()

    const { id, title, description, status } = route.params

    const { isOpen: isLoading, onOpen: startLoading, onClose: stopLoading } = useDisclose(true)

    const allNotifications = getNotifications()
    const updateNotifications = useLoadNotifications()
    useEffect(() =>  {
      if(status !== MSG_STATUS.read) {
        const newNotifications = []
        for(let index in allNotifications) {
          let currentNotif = allNotifications[index]
          if(currentNotif?.id === id) {
            currentNotif = {
              ...currentNotif,
              status: MSG_STATUS.read
            }
          } //endif
          newNotifications.push(currentNotif)
        } // endfor

        updateNotifications(newNotifications)
      }
    }, [])
    
    return (
        <ScreenWrapper title={title} nav={navigation} _view={{bg: 'gray.100'}} _title={{size: 'md'}} >
            <Text px='5' >{description}</Text>

      </ScreenWrapper>
    )
}
