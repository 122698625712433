import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast,
    Divider,
    Modal,
    Spacer,
    IconButton,
    CloseIcon,
    AddIcon
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { listCities, createContractParent, listApprovedParentsWithoutContract, listSubs } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_natural_time, from_day_index_to_day_name, humanize_date_YMD } from "services/utils";
import { apiErrorNotify } from "services/notify";
import TimeInput from "components/TimeInput";
import DateTimeInput from "components/DateTimeInput";
import ResumeChildInfos from "./components/ResumeChildInfos";
import NumberInput from "components/NumberInput";
import DisplayTime from "components/DisplayTime";
import DisplayTimeLinear from "components/DisplayTimeLinear";
import ScreenWrapper from "components/ScreenWrapper";
import SelectMultipleInput from "components/SelectMultipleInput";

const DisapearingAlert=({time = 3000, message='Success', update})=>{
  setTimeout(update, time);
  return (
    <Actionsheet isOpen={true} collapsable={false} >
      <Actionsheet.Content bg="white" height={get_screen_height()} >
        <Center>
          <Heading color="red.500" > {message} </Heading>
        </Center>
      </Actionsheet.Content>
    </Actionsheet>  
  )
 
}

const ModalAddSubject = ({dayToAdd, subs= [], onCancel, initialValues= {}, onSubmit}) => {
  const [selected, setSelected] = useState(initialValues.sub ? [initialValues.sub.id] : [])
  const [start_at, setStart] = useState(initialValues.start_at || '')
  const [end_at, setEnd] = useState(initialValues.end_at || '')
  // const { isOpen, onOpen, onClose } = useDisclose()

  const handleSubmit = () => {
    selected.forEach(
      sub_id => {
        onSubmit({
          sub: subs.find(s => s.id == sub_id),
          start_at: start_at,
          end_at: end_at,
          coeff: 2,
        })
      }
    )
  }
  console.log("Initial values ", initialValues)

  return (
    <Modal isOpen={true} collapsable={false}>
    <Modal.Content bg='white' w='90%' >
      <Modal.Header><Heading color='white' alignSelf='center' >{from_day_index_to_day_name(dayToAdd)}</Heading></Modal.Header>
      <Modal.Body>
        <VStack space='3' >
            <FormControl isRequired >
              <FormControl.Label>Matière</FormControl.Label>
              <SelectMultipleInput
                  displayKey={'name'}
                  items={subs}
                  onSelectedItemsChange={setSelected}
                  selectedItems={selected}
                  selectText="Confirmer la sélection "
                  fixedHeight={false}
              />
                {/* <Select 
                  selectedValue={selected} 
                  color="black" 
                  onValueChange={setSelected} >
                  <Select.Item label="Sélectionnez la matière"  />
                  {
                    subs.map( (sub) =>  <Select.Item key={sub.id} label={sub.name} value={`${sub.id}`} /> )
                  }
                </Select>  */}
            </FormControl>
            <FormControl isRequired>
              <FormControl.Label>Heure de début</FormControl.Label>
              <TimeInput 
                value={start_at} 
                setValue={setStart}
                placeholder='ex: 14:30'
                />
            </FormControl>
            <FormControl isRequired>
              <FormControl.Label>Heure de fin</FormControl.Label>
              <TimeInput 
                value={end_at} 
                setValue={setEnd}
                placeholder='ex: 17:00'
                />
            </FormControl>
            <HStack justifyContent={'space-between'} >
              <Button onPress={onCancel ? onCancel : () => null } bg='gray.600' >Annuler</Button>
              <Button onPress={handleSubmit}
                  isDisabled={selected === null || !start_at || !end_at }
                >Intégrer</Button>
            </HStack>
        </VStack>
      </Modal.Body>
    </Modal.Content>
  </Modal>

  )
}

const AddChild = ({isOpen, onClose, onOpen, onSubmit, subs, initialValues= {}, index, label}) => {
  const token = getToken()
  const toast = useToast()

  const [state, setState] = useState({
    subs: subs,
    timeplan: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: []
    },
    addSub: false,
    editSub: false,
    dayToAdd: null,
    isSubmitting: false,
    selected_s_a: null,
    sa_index_in_day: null
  })
  const [childValues, setChildValues] = useState({
    full_name: '',
    school: '',
    place_courses: '',
    child_level: '',
    subjects_affected: [],
    ...initialValues,
  })
  

  const handleSubmit = () => {
      // setSta
    const sa_by_sub = {}
    console.log(childValues, state.timeplan)
    Object.keys(state.timeplan).forEach(
      day_index => {
        state.timeplan[day_index].forEach(
          raw_sa => {
            if(!sa_by_sub[raw_sa.sub.id]) sa_by_sub[raw_sa.sub.id] = []
            sa_by_sub[raw_sa.sub.id].push({
              ...raw_sa,
              day_index: day_index
            })
          }
        )
      }
    )
      const sa_formated = []
    Object.keys(sa_by_sub).forEach(
      sub_id => {
        if(sa_by_sub[sub_id].length){
          const coeff = sa_by_sub[sub_id][0].coeff
          const data_sub = {
            sub_id: parseInt(sub_id),
            coeff: coeff,
            course_sessions: []
          }
          sa_by_sub[sub_id].forEach(
            raw_course => {
              data_sub.course_sessions.push({
                day: parseInt(raw_course.day_index),
                start_at: raw_course.start_at,
                end_at: raw_course.end_at
              })
            }
          )
          sa_formated.push(data_sub)
        }
      }
    )
      
    onSubmit({
      values: {
        ...childValues,
        subjects_affected: sa_formated
      },
      index: index
    })
  }

  const handleRemoveTimeLine = (day, index) => {
    setState(prev => {
      const length = prev.timeplan[day].length
      const day_program = prev.timeplan[day]
      
      return {
        ...prev,
        timeplan: {
          ...prev.timeplan,
          [day]: day_program.slice(0, index).concat(day_program.slice(index+1, length))
        }
      }
    })
    
  }

  const handleAddSubject = (values) => {
    console.log("Gonna handleAddSubject ", values)
    setState(prev => {
      prev.timeplan[state.dayToAdd].push(values)
      return {...prev, addSub: false  }
    })
    
  }

  const handleEditSubject = (values) => {
    console.log("Gonna EDIT ", values)
    if(typeof state.sa_index_in_day === 'number'){
      setState(prev => {
        prev.timeplan[state.dayToAdd][state.sa_index_in_day] = values
        // console.log("edit timeplan ", prev.timeplan[state.dayToAdd])
        return {...prev, editSub: false  }
      })
  
    }
  }

  const handleCancelSub = () => setState(prev => ({...prev, addSub: false, editSub: false, selected_s_a: null, sa_index_in_day: null })) 

  useEffect(()=> {
    
      if(initialValues.subjects_affected) {
          const local_timeplan = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: []
          }
          initialValues.subjects_affected.map(s_a => {
            s_a.course_sessions.map(
              c_s => {
                local_timeplan[c_s.day].push({
                  coeff: s_a.coeff || 2,
                  sub: subs.find(s => s.id === s_a.sub_id),
                  start_at: c_s.start_at,
                  end_at: c_s.end_at,
                })
              }
            )
          })
          setState(prev => ({...prev, timeplan: local_timeplan}))
      }
  }, [setState])

  return (
    <Actionsheet isOpen={isOpen} onClose={onClose} collapsable={true} >
      <Actionsheet.Content bg="white" >
          <ScrollView showsVerticalScrollIndicator={false} bg="white" w="100%" >
              <Heading color="black" >{Object.keys(initialValues).length > 0 ? "Modifier" : "Renseignez les informations de l'enfant"} </Heading>
              <VStack space="3" pt="3"  >
              <FormControl isRequired>
                <FormControl.Label>Nom complet de l'enfant</FormControl.Label>
                <Input 
                  value={childValues.full_name} 
                  onChangeText={(val) => setChildValues(prev => ({...prev, full_name: val}))} 
                  />
              </FormControl>

              <FormControl isRequired>
                  <FormControl.Label>Classe</FormControl.Label>
                  <Input 
                    value={childValues.child_level} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, child_level: val}))}
                    />
                </FormControl>

              <FormControl isRequired>
                <FormControl.Label>Lieu des cours à domicile</FormControl.Label>
                  <Input 
                    value={childValues.place_courses} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, place_courses: val}))}
                    />
                </FormControl>

              <FormControl isRequired>
                <FormControl.Label>Nom de l'école fréquentée</FormControl.Label>
                  <Input 
                    value={childValues.school} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, school: val}))}
                    />
                </FormControl>
              {/* <Divider /> */}
              <Heading size='lg'>Emploi du temps des cours à domicile</Heading>

              <VStack space='10'>
              {
                Object.keys(state.timeplan).map(
                  day_number => (
                    <VStack key={day_number} alignContent='space-between' >
                      <HStack px='3' justifyContent={'space-between'} >
                        <Heading size='md'>{from_day_index_to_day_name(day_number)}</Heading>
                        <IconButton 
                            // size='lg' 
                            borderRadius={'full'} 
                            icon={
                              <AddIcon size='sm' color='green.500' />
                            }
                            _pressed={{
                              bg: 'gray.300',
                              color: 'black'
                            }}
                          onPress={() => setState(prev => ({...prev, addSub: true, dayToAdd: day_number })) }
                          />
                        {/* <Button  onPress={() => setState(prev => ({...prev, addSub: true, dayToAdd: day_number })) } >Ajouter une matière</Button> */}
                      </HStack>
                      <VStack space='2'>
                        {
                          state.timeplan[day_number].map(
                            (s_a, index) => (
                              <HStack 
                                key={index} 
                                borderLeftWidth={1} 
                                borderRadius={0} 
                                alignContent='center' 
                                space='1'
                                >
                                <HStack pl='2'>
                                  <CloseIcon 
                                      alignSelf={'center'}
                                      size='sm'
                                      color='red.500'
                                      _pressed={{
                                        bg: 'red.500',
                                        color: 'white'
                                      }}
                                      onPress={() => handleRemoveTimeLine(day_number, index)}
                                      />
                                </HStack>
                                <HStack space='0.5'  alignContent={'center'} >
                                  <DisplayTimeLinear fontSize={10} value={s_a.start_at} />
                                  <Text>-</Text>
                                  <DisplayTimeLinear fontSize={10} value={s_a.end_at} />
                                </HStack>
                                {/* <Spacer /> */}
                                <Text fontSize={14} onPress={() => setState(prev => ({...prev, editSub: true, dayToAdd: day_number, selected_s_a: s_a, sa_index_in_day: index }))} >{s_a.sub.name}</Text>
                                <Spacer />
                                
                              </HStack>
                            )
                          )
                        }
                      </VStack>
                    </VStack>
                  )
                )
              }
              </VStack>
                <Button onPress={handleSubmit} >{Object.keys(initialValues).length > 0 ? "Modifier" : "Ajouter"}</Button>
              </VStack>
              {
                state.dayToAdd !== null && (
                  <>
                    {
                      state.addSub ? (
                        <ModalAddSubject  
                          dayToAdd={state.dayToAdd} 
                          subs={state.subs} 
                          onCancel={handleCancelSub}
                          initialValues={{}}
                          onSubmit={handleAddSubject}
                          label='Ajout'
                          />

                      ) : state.editSub && (
                        <ModalAddSubject  
                        label='edition'
                          dayToAdd={state.dayToAdd} 
                          subs={state.subs} 
                          onCancel={handleCancelSub}
                          initialValues={state.selected_s_a}
                          onSubmit={handleEditSubject}
                          />
                      )
                    }
                  </>
                )
              }
                
          </ScrollView>
      </Actionsheet.Content>
    </Actionsheet>
  )
}

export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [formValues, setFormvalues] = useState({
      parent_id: 0,
      city: 0,
      price: 0,
      signed_at: new Date(),
      start_at: new Date(),
      end_at: new Date(),
      children: []
    })
    const [state, setState] = useState({
      display_add_child: false,
      display_edit_child: false,
      selected_child_index: null,
      done: false,
      isCreatingContract: false,
      parents: [],
      cities: [],
      subs: []
    })
    const {
      isOpen,
      onOpen,
      onClose
    } = useDisclose()

    const handleCreateContract = () => {
      const data = {
        ...formValues, 
        signed_at: humanize_date_YMD(formValues.signed_at),
        start_at: humanize_date_YMD(formValues.start_at),
        end_at: humanize_date_YMD(formValues.end_at),
        price: parseInt(formValues.price)
      }
      console.log(data, "Sent values to server")
      createContractParent(token, data)
        .then(res => navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {navigation: navigation}) )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }

    
    const handleEditChild = async ({values}) => {
      // console.log("Gona edit a child with ", state.selected_child_index, values)
      await setFormvalues(prev => {
        const new_children = prev.children
        console.log("\n LAST --------------------------", new_children[state.selected_child_index] )
        console.log("\n NEW --------------------------", values )
        new_children[state.selected_child_index] = values
        return {
          ...prev,
          children: new_children
          // children: prev.children.slice(0, _index).concat(values).concat(prev.children.slice(_index+2, prev.children.length))
        }
      })
      setState(prev => ({...prev, display_edit_child: false}))
    }

    const handleAddchild = ({values}) => {
      setFormvalues(prev => {
        return {
          ...prev,
          children: prev.children.concat([values])
        }
      })
      setState(prev => ({...prev, display_add_child: false}))
    }

    const handleRemovechild = (_index) => {
      setFormvalues(prev => {
        return {
          ...prev,
          children: prev.children.slice(0, _index).concat(prev.children.slice(_index+1, prev.children.length))
        }
      })
    }
  
    const handleModalChildClose = () => {
      setState(prev => ({...prev, display_add_child: false, display_edit_child: false}))
    }
  
    useEffect(() => {
      listApprovedParentsWithoutContract(token).then(
        res => {
          setState(prev => ({...prev, parents:  res.data}))
        }
      )
      listCities(token).then(
        res => {
          setState(prev => ({...prev, cities:  res.data?.filter(s => s.is_active === true)}))
        }
      )
      listSubs(token).then(
        res => {
          setState(prev => ({...prev, subs:  res.data?.filter(s => s.is_active === true)}))
        }
      )
    }, [setState])

    return (
      <ScreenWrapper title="Enregistrer un contrat de parent" nav={navigation}>
        <ScrollView>

            <VStack space={3} px="3" pt="10" >
                <FormControl isRequired >
                  <FormControl.Label>Parent</FormControl.Label>
                    <Select selectedValue={formValues.parent_id} color="black" onValueChange={val => setFormvalues(prev => ({...prev, parent_id: val}))} >
                      <Select.Item label="Sélectionnez le parent"  />
                      {
                        state.parents.map( (p, ind) =>  <Select.Item key={ind} label={p.full_name ? p.full_name : p.username} value={`${p.id}`} /> )
                      }
                    </Select> 
                </FormControl>
                <FormControl isRequired isDisabled={!formValues.parent_id}>
                  <FormControl.Label>Ville</FormControl.Label>         
                    <Select 
                    selectedValue={formValues.city} 
                      onValueChange={val => setFormvalues(prev => ({...prev, city: val}))  }
                      color="black"  >
                      <Select.Item label="Ville des cours" value={'0'} />
                      {
                        state.cities.map( (c, ind) =>  <Select.Item key={ind} label={c.name} value={`${c.id}`} /> )
                      }
                    </Select> 
                </FormControl>
                
                <FormControl  isRequired isDisabled={formValues.parent_id == 0}>
                    <FormControl.Label>Date de signature</FormControl.Label>
                      <DateTimeInput 
                        value={formValues.signed_at} 
                        placeholder=""
                        setValue={val => setFormvalues(prev => ({...prev, signed_at: val})) } />
                </FormControl>
                
                  <FormControl width={"45%"} isRequired isDisabled={formValues.parent_id == 0}>
                    <FormControl.Label>Date de début</FormControl.Label>
                      <DateTimeInput 
                        value={formValues.start_at} 
                        setValue={val => setFormvalues(prev => ({...prev, start_at: val})) } />
                  </FormControl>

                  <FormControl width={"45%"}isRequired isDisabled={formValues.parent_id == 0}>
                    <FormControl.Label>Date de fin</FormControl.Label>
                      <DateTimeInput 
                        value={formValues.end_at} 
                        placeholder=""
                        setValue={val => setFormvalues(prev => ({...prev, end_at: val})) } />
                  </FormControl>

                <FormControl isRequired isDisabled={formValues.parent_id == 0}>
                  <FormControl.Label>Montant</FormControl.Label>
                    <NumberInput 
                      value={formValues.price} 
                      placeholder="Entrez un montant"
                      onChangeText={val => setFormvalues(prev => ({...prev, price: val})) } />
                  </FormControl>

                {
                  formValues.children.map( (c, index) => (
                      <>
                        {console.log("initialValues in DisplayChild ", c)}
                        <ResumeChildInfos 
                        key={index} 
                        index={index} 
                        childValues={c} 
                        onRemove={() => handleRemovechild(index)}
                        onEdit={() => {
                          setState(prev => ({...prev, selected_child_index: index, display_add_child: false, display_edit_child: true}))
                        }} 
                      />
                      </>
                    )
                    )
                }
                <Center>
                  <Button rounded={'lg'} isDisabled={formValues.parent_id == 0}  onPress={() => setState(prev => ({...prev, display_add_child: true}))} >Ajouter un enfant</Button>
                </Center>


                { !state.isCreatingContract ? <Button 
                    mt="10" 
                    isDisabled={formValues.parent_id == 0 || formValues.city == 0 || formValues.children.length === 0 }
                    onPress={handleCreateContract} 
                    >Enregistrer ce contrat</Button> : <Spinner /> }
                
                
                
                {
                  state.display_add_child ? <AddChild onClose={handleModalChildClose} isOpen={state.display_add_child} onSubmit={handleAddchild} subs={state.subs} label='Creation' /> 
                    : state.display_edit_child && <AddChild onClose={handleModalChildClose} index={state.selected_child_index} isOpen={state.display_edit_child} subs={state.subs} initialValues={formValues.children[state.selected_child_index]} onSubmit={handleEditChild} label='Editting' /> 
                } 
            </VStack>
          
        </ScrollView>
      </ScreenWrapper>
  );
}