import { createSlice } from '@reduxjs/toolkit'
import { persist_return, get_item, set_item } from "services/persistence";

const SLICE_NAME = 'notifications'
const STORAGE_NAME = `@${SLICE_NAME}`
export const NOTIFICATION_STORAGE_NAME = STORAGE_NAME

let initialState  = []

export const notificationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    appendNotifications: (state, action) => {
      const new_state = [
        ...action.payload,
        ...state
      ]
        set_item(STORAGE_NAME, new_state)
        return new_state  
    },
    appendOldNotifications: (state, action) => {
      const new_state = [
        ...state,
        ...action.payload
      ]
        set_item(STORAGE_NAME, new_state)
        return new_state  
    },
    loadNotifications: (state, action) => {
      set_item(STORAGE_NAME, action.payload)
      return action.payload
    },
    clearAllNotifications: (state) => {
      const new_state = []
      set_item(STORAGE_NAME, new_state)
      return new_state
    }
  },
})

// Action creators are generated for each case reducer function
export const { appendNotifications, appendOldNotifications, loadNotifications, clearAllNotifications } = notificationSlice.actions

export default notificationSlice.reducer