import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { getTeachersNoApproved, approveUser, getFileLink } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import ValidationButton from "./components/ValidationButton";
import ScreenWrapper from "components/ScreenWrapper";

export default ({ navigation }) => {
    const token = getToken()
    const [ state, setState ] = useState({
        teachers: [],
        isLoaded: false,
        isAproving: false,
    })
    const refresh = () => {
        console.log("Calling refresh")
        getTeachersNoApproved(token).then(
            res => {
                // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                setState(prev => ({...prev, teachers: res.data, isLoaded: true }))
            }
        )
        .catch(err => console.log(err))
    }
    
    useEffect(() => {
        refresh()
    }, [setState])
    console.log(state.teachers.length)
    return (
    <ScreenWrapper title="Compte enseignants en attente de validation" nav={navigation}>
        <ScrollView>
            <VStack space={3} >
                {
                    ! state.isLoaded && (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <Spinner />
                            <Heading color="primary.500" fontSize="md">
                                Chargement
                            </Heading>
                        </HStack>
                        </Center>
                    )
                }
                {
                    state.isLoaded && state.teachers.map( p => (
                    <HStack key={p.id} space={30}  onPress={() => console.log("Touch validate parent profile ")} >
                        <Box maxW={100} height={100}  flex={1} >
                            { p.media_avatar ? 
                                <Image borderRadius={'full'} height={"100%"} alt="Une photo" source={{uri: getFileLink(p.media_avatar)}} />
                                :
                                <Box maxW={100} height={100} rounded='full' bg='gray.300' />
                            }
                        </Box>
                        <VStack pt={5} >
                            <Text color="black" > { p.full_name ? p.full_name : p.username } </Text>
                            <HStack space={1} >
                                <ValidationButton callback={() => {
                                    return approveUser(token, p.id).then( res => {
                                        setState( prev => ({...prev, isAproving: false}) )
                                        refresh()
                                    } )
                                }
                                } />
                                <Button size='sm' onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ShowTeacherProfile.name, {teacher: p})} >Voir le profil</Button>

                            </HStack>
                        </VStack>
                    </HStack>
                    ))
                }
                </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}