import { AntDesign, FontAwesome5 } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import MenuBoxWrapper from "components/MenuBoxWrapper";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Icon
  } from "native-base";
  import { Image, Pressable } from 'react-native';

Text.defaultProps = {
    fontWeight: 'bold'
}

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#163253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
  return (
    <>
        {/* <ScrollView bg='white' > */}
            <ScreenWrapper hideBackButton nav={navigation} title='Profil enseignant'>
                <ScrollView pb='10' >
                    <Annonce isParentRefreshing={false} />
                    <VStack mx="4" my="5" space={"4"} > 
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.ListApprovedTeachers.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='user-tie' size={'3xl'} color='primary.500' />
                                        <Text fontSize={18} >Voir les autres enseignants</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>

                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.ListPrestation.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='user-tie' size={'3xl'} color='primary.500' />
                                        <Text fontSize={18} >Mes prestations enregistrées</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.CreatePrestation.name)}>
                                    <Center height="100%">
                                        {/* <HStack> */}
                                            <Icon mr='4' as={FontAwesome5} name='pen-nib' size={'xl'} color="orange.500" />
                                            <Text fontSize={18} >Enregistrer une prestation</Text>
                                        {/* </HStack> */}
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.ShowContract.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='file-signature' size={'3xl'} color="green.700" />
                                        <Text fontSize={18} >Voir mon contrat</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.ListSalaries.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='file-invoice-dollar' size={'3xl'} color="red.500" />
                                        <Text fontSize={18}>Mes salaires</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.Timeplan.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='calendar' size={'3xl'} color="yellow.500" />
                                        <Text fontSize={18} >Emplois du temps de mes élèves</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.ListChildrenMarks.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='marker' size={'3xl'} color="blue.400" />
                                        <Text fontSize={18}>Notes de mes élèves</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.ContactParent.name)}>
                                    <Center height="100%">
                                        <Icon as={AntDesign} name='phone' size={'xl'} color="orange.500" />
                                        <Text fontSize={18}>Contacter un parent</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.ContactDirection.name, { phone: "0759632788", is_whatsapp: true })}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='phone' size={'xl'} color="green.700" />
                                        <Text fontSize={18} >Contacter la Direction</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.CreateRemarkOnChild.name)}>
                                    <Center height="100%">
                                        <HStack>
                                            <Icon mt='2'  mr='2' as={FontAwesome5} name='scroll' size={'xl'} color="pink.500" />
                                            <Center>
                                                <Text fontSize={18}>Signaler une observation </Text>
                                                <Text fontSize={18}>sur un élève</Text>
                                            </Center>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.CreateReportOnChild.name)}>
                                    <Center height="100%">
                                        <HStack>
                                            <Icon mt='2'  mr='2' as={FontAwesome5} name='scroll' size={'xl'} color="blue.500" />
                                            <Center>
                                                <Text fontSize={18}>Rapports mensuels </Text>
                                            </Center>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            {/* <MenuBoxWrapper height={100}  bg='white' shadow='4' px='auto' flex={1} borderRadius={10} >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.CreateRemarkOnParent.name)}>
                                    <Center height="100%">
                                        <Text fontSize={18} color="primary.500">Rapport mensuel sur un parent</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper> */}
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Teachers.CreateTeacherUnavailability.name)}>
                                    <Center height="100%">
                                        <HStack>
                                            <Icon mt='2'  mr='2' as={FontAwesome5} name='scroll' size={'xl'} color="orange.500" />
                                            <Center>
                                                <Text fontSize={18}>Signaler mon indisponibilité </Text>
                                            </Center>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ListLearningVideos.name)}>
                                    <Center height="100%">
                                        <HStack>
                                            <Icon mt='2'  mr='2' as={FontAwesome5} name='video' size={'xl'} color="emerald.500" />
                                            <Center>
                                                <Text fontSize={18}>Voir les vidéos de formation </Text>
                                            </Center>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ListLearningExercises.name)}>
                                    <Center height="100%">
                                        <HStack>
                                            <Icon mt='2'  mr='2' as={FontAwesome5} name='book' size={'xl'} color="violet.500" />
                                            <Center>
                                                <Text fontSize={18}>Les exercices </Text>
                                            </Center>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ListLearningLessons.name)}>
                                    <Center height="100%">
                                        <HStack>
                                            <Icon mt='2'  mr='2' as={FontAwesome5} name='book' size={'xl'} color="yellow.500" />
                                            <Center>
                                                <Text fontSize={18}>Les cours</Text>
                                            </Center>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                </VStack>
                </ScrollView>
            </ScreenWrapper>
        {/* </ScrollView> */}
    </>
  );
}