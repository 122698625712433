import { AntDesign } from "@expo/vector-icons";
import ConfirmationDialog from "components/ConfirmationDialog";
import { CloseIcon, HStack, Icon, IconButton, Spinner, Text } from "native-base";
import { useState } from "react";

export default ({s_a, onDelete}) => {
    const [isLoading, setLoading] = useState(false)
    const [confirm, setConfirm] = useState(false)
    // const handleSubmit = () => {
    //     setLoading(true);
    //     setTimeout(onDelete, 1000)
    // }
    return (
        <HStack key={s_a.id} rounded='full' space='3' p='1' px='2' bg='white' alignContent='center' >
            {
                isLoading ? <Spinner /> : <IconButton 
                bg='gray.200' 
                p='2' 
                rounded='full' 
                _pressed={{
                    bg: 'primary.500'
                }}
                onPress={() => setConfirm(true)}
                icon={
                    <Icon 
                        size='sm'   
                        name='delete'
                        color='red.500' 
                        onPress={() => {
                            setConfirm(true)
                        }}
                        as={AntDesign}
                    />
                } 
                />
            }
            <Text fontSize='15'  >{s_a.child.full_name}</Text>
            {confirm &&  <ConfirmationDialog
                isOpen={confirm} 
                onAgree={onDelete} 
                onCancel={() => setConfirm(false)} msg={`Cet enseignant ne pourra plus encadrer l'enfant ${s_a.child.full_name} en ${s_a.sub.name} ?`}
             />}
        </HStack>
    )
} 