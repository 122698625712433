import { StatusBar, Box, HStack, IconButton, Text, Icon, Image, Avatar, Center, Badge, VStack, Button, Modal, useDisclose, FlatList, Spacer, useToast, CircleIcon, Spinner, CheckIcon, Heading, CloseIcon } from "native-base";
import { AntDesign, FontAwesome, MaterialIcons } from '@expo/vector-icons';
import Logo from 'assets/logo.png'
import { getLoginState, getToken, getUserAvatar, getUsername } from "store/login/selectors";
import { useCallback, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { Pressable, RefreshControl } from "react-native";
import { getFile, getFileLink, listMsgs, listNewMsgs, markMsgRead } from "services/api-axios";
import { MSG_STATUS } from "constant";
import { humanize_date, humanize_date_YMD, humanize_natural_date, humanize_time } from "services/utils";
import { apiErrorNotify } from "services/notify";
import GoBack from "components/GoBack";
import Notifications, { registerForPushNotificationsAsync } from "services/push-notifiy";
import { MENU_NAVIGATION } from "constant";
import { useNavigation } from "@react-navigation/native";
import ScreenWrapper from "components/ScreenWrapper";
import { getNotifications } from "store/notifications/selectors";
import { useAppendNotifications, useAppendOldNotifications, useClearNotications } from "store/notifications/hooks";


function MessageItem({item, updater, index, token, navigation, toast}) {
  const [isOpen, setOpen] = useState(false)
  const [isRead, setRead] = useState(item?.status === MSG_STATUS.read)
  
  const today = new Date()
  const date = new Date(item.created_at)
  let dateAgo = humanize_natural_date(date)
  if(date.getFullYear() === today.getFullYear()) {
    if(date.getMonth() === today.getMonth()) {
      const diffDates = date.getDate() - today.getDate()
      if (diffDates === 0) dateAgo = "Ajourd'hui"
      else if (diffDates === -1) dateAgo = "Hier"
      else if (diffDates === -2) dateAgo = "Avan-hier"
    }
  }
  const handleRead = () => {
    markMsgRead(token, [item.id])
    .then(() => {
      updater(index)
      setRead(true)
    })
    .catch(err => {
      toast.show({
        description: 'Erreur de connexion'
      })
    })
  }
  return (
    <>
      <Pressable onPress={() => {
        navigation.navigate(MENU_NAVIGATION.Common.NotificationDetailsScreen.name, item)
      }}>
        <VStack p='3' pl={("5")} mb='3' borderRadius={10} bg={isRead ? 'gray.50' : 'white'}  >
          <VStack>
            
              <HStack alignItems={'center'} space={2}>
                { !isRead && <Box w='10px' h='10px' bg='red.400' rounded={'full'} /> }
                <Text color="coolGray.800" bold>
                  {(item?.title?.length > 20 ? item?.title?.slice(0, 20) : item?.title) || "Information"}
                </Text>
      
              </HStack>
              <Text fontWeight={'light'} fontSize={12}>
                {(item?.description?.length > 50 ? item?.description?.slice(0, 100)?.trim() : item?.description)}
              </Text>
          </VStack>

          
            <HStack space='2' mt='2' justifyContent={'flex-start'} >
              <HStack space={2} >
                <Text fontSize={12} fontWeight={'light'} >{dateAgo}, {humanize_time(new Date(item.created_at), 'h')}</Text>
              </HStack>
              {
                isRead && (
                  <>
                    <Text fontSize={12} color="green.500" >Lu</Text>
                    <CheckIcon size='sm' color='green.500' /> 
                  </>
                )
              }
            </HStack>
          
          
        </VStack>
      </Pressable>
    </>
  )
}

const ReloadMessages = ({callback, simple}) => {
  const [isLoaded, setLoad] = useState(true)
  const handlePress = ()=> {
    setLoad(false)
    callback().then(() => {
      setLoad(true)
    })
  }
  return (
    <>
      {
        isLoaded ? (
          <Pressable onPress={handlePress}>
            <Icon justifyContent={'flex-end'} as={AntDesign} name='sync' size={'md'} my='1' color='white'  /> 
          </Pressable>
        )
        : <Spinner />
      }
    </>
  )
}

export default function ({ ...rest }) {
    const navigation = useNavigation()
    
    const token = getToken()
    const toast = useToast()
    const { isOpen: isLoading, onOpen: startLoading, onClose: stopLoading } = useDisclose(true)

    const messages = getNotifications()
    const clear = useClearNotications()
    
    const appendNotif = useAppendNotifications()
    const appendOLDNotif = useAppendOldNotifications()

    const updateMsg = (index) => {
    }

    const addNewMessages = useCallback(() => {
      listNewMsgs(token)
          .then(res_new => {
            if(res_new.data?.length > 0) {
              appendNotif(res_new.data)
            }
            stopLoading()
          })
          .catch(err => {
            apiErrorNotify({response: err.response, toast: toast})
            stopLoading()
          })  
      return new Promise((resolve, reject) => {
        setTimeout(()=> resolve(true), 3000)
      })
    }, [])
    
    
    
    useEffect(() =>  {
      if(messages.length === 0) {
        listMsgs(token)
        .then(res_all => {
          if(res_all.data?.length > 0) {
            appendOLDNotif(res_all.data)
          }
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))

      }
      addNewMessages()
      // clear()
    }, [addNewMessages])
    
    return (
        <ScreenWrapper title="Notifications" nav={navigation} _view={{bg: 'gray.100'}} >
            { isLoading && <Spinner /> }
            {
                messages.length > 0 ? (
                  <>
                    <FlatList 
                      px="4"
                      keyExtractor={item => item.id} data={messages} renderItem={
                        ({item, index}) => (
                            <MessageItem key={index} item={item} toast={toast} token={token} updater={updateMsg} navigation={navigation} index={index} />
                        
                        )
                    } />
                    <Box h='30px' />
                  </>
            ): <Center><Text>Aucun message</Text></Center>
            }

      </ScreenWrapper>
    )
}
