import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeUserScreen from 'screens/HomeUserScreen';
import AppBar from "components/AppBar";
import { MENU_NAVIGATION } from 'constant';
import MainScreen from './screens/MainScreen';
import ContactDirection from './screens/ContactDirection';
import ShowContract from './screens/ShowContract';
import Timeplan from './screens/Timeplan';
import ListChildrenMarks from './screens/ListChildrenMarks';
import CreateRemarkOnChild from './screens/CreateRemarkOnChild';
import ContactParent from './screens/ContactParent';
import CreateRemarkOnParent from './screens/CreateRemarkOnParent';
import CreatePrestation from './screens/CreatePrestation';
import ListSalaries from './screens/ListSalaries';
import ListApprovedTeachers from './screens/ListApprovedTeachers';
import CreateTeacherUnavailability from './screens/CreateTeacherUnavailability';
import ShowTeacherProfile from 'screens/ShowTeacherProfile';
import ListPrestation from './screens/ListPrestation';
import CreateReportOnChild from './screens/CreateReportOnChild';
// import ListApprovedTeachers from './screens/ListApprovedTeachers';
// import OrderTeacher from './screens/OrderTeacher';
// import CreateChildUnavailability from './screens/CreateChildUnavailability';
// import CreateRemarkOnTeacher from './screens/CreateRemarkOnTeacher';
// import ValidateCourseSession from './screens/ValidateCourseSession';
// import ListInvoices from './screens/ListInvoices';

const TeacherStack = createNativeStackNavigator();

export default () => {
  return (
    <TeacherStack.Navigator screenOptions={{ 
      headerShown: false,
      // headerBackButtonMenuEnabled: true,
      // headerBackground: (navigationProps) => <AppBar {...navigationProps} />,
    }}>
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.Main.name} component={MainScreen} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ContactDirection.name} component={ContactDirection} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ContactParent.name} component={ContactParent} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ShowContract.name} component={ShowContract} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.Timeplan.name} component={Timeplan} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ListChildrenMarks.name} component={ListChildrenMarks} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.CreateRemarkOnChild.name} component={CreateRemarkOnChild} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.CreateRemarkOnParent.name} component={CreateRemarkOnParent} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.CreatePrestation.name} component={CreatePrestation} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ListSalaries.name} component={ListSalaries} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ListApprovedTeachers.name} component={ListApprovedTeachers} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Common.ShowTeacherProfile.name} component={ShowTeacherProfile} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.CreateTeacherUnavailability.name} component={CreateTeacherUnavailability} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ListPrestation.name} component={ListPrestation} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.CreateReportOnChild.name} component={CreateReportOnChild} />
      {/* 
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.CreateChildUnavailability.name} component={CreateChildUnavailability} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ValidateCourseSession.name} component={ValidateCourseSession} />
      <TeacherStack.Screen name={MENU_NAVIGATION.Teachers.ListInvoices.name} component={ListInvoices} /> */}
    
    </TeacherStack.Navigator>
  );
}