import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeWelcomeScreen from 'screens/HomeWelcomeScreen';
import DetailsScreen from 'screens/DetailsScreen';
import AppBar from "components/AppBar";
import { MENU_NAVIGATION } from 'constant';

const HomeStack = createNativeStackNavigator();

export default function HomeStackNavigator() {
  return (
    <HomeStack.Navigator screenOptions={{ 
      headerShown: false, 
      header: (navigationProps) => <AppBar {...navigationProps} /> 
    }}>
      <HomeStack.Screen name={MENU_NAVIGATION.Welcome.Main.name} component={HomeWelcomeScreen} />
    </HomeStack.Navigator>
  );
}