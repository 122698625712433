import NoData from "components/NoData"
import { VStack, HStack, Spacer, Heading, Text, ScrollView, IconButton, CloseIcon, Button, Center, useToast, Spinner } from "native-base"
import { useEffect } from "react"
import { useState } from "react"
import { addSASToContract, getParentInfosFromContractParent, listCities, listContractsParents, removeSASfromContract } from "services/api-axios"
import { apiErrorNotify } from "services/notify"
import { getToken } from "store/login/selectors"
import AddChild from "../AddChild"
import ResumeChild from "../ResumeChild"

export default ({mapping, contract, update_mapping}) => {
    const token = getToken()
    const toast = useToast()

    const [state, setState] = useState({
        cities: [],
        contract_parents: {},
        parents_infos: {},
        mapping: mapping,
        display_selector: false,
        selected_contract_parent_index: null,
        selected_subjects_affected_by_cp_id: {},
        isLoaded: false,
    })

    const toggleSelector = () => setState(prev =>  ({...prev, display_selector: false}))
    
    const handleAffectSubject = ({contract_parent_index, selected_sas_list}) => {
        // console.log("Received ", selected_sas_list)
        // console.log("Before spread ", state.selected_subjects_affected_by_cp_id)
        // let tempo = Object.values(state.selected_subjects_affected_by_cp_id).reduce(
        //   (total, current) => [...total, ...current],
        //   []
        // )
        // tempo = tempo.concat(selected_sas_list)
        // let set_version = new Set(tempo)
  
        // let locale_selected_sas_by_cp = {}
        // tempo.forEach(
        //   s_a => {
        //     // console.log("Found at ", s_a, '\n')
        //     if(!locale_selected_sas_by_cp[s_a.contract_parent_id]) locale_selected_sas_by_cp[s_a.contract_parent_id] = []
        //     if(locale_selected_sas_by_cp[s_a.contract_parent_id].findIndex(s => s.id === s_a.id) === -1) {
        //       locale_selected_sas_by_cp[s_a.contract_parent_id].push(s_a)
        //     }
            
        //   }
        // )
        if(selected_sas_list.length > 0){
            addSASToContract(token, contract.id, selected_sas_list.map(item => item.id))
            .then(() => {
                setState(prev => {
                    let mapping = prev.mapping
                    selected_sas_list.forEach(
                        s_a => {
                            if(!mapping[s_a.contract_parent_id]) mapping[s_a.contract_parent_id] = []
                            mapping[s_a.contract_parent_id].push(s_a)
                        }
                    )
        
                    console.log("Mapping subs ", Object.keys(mapping).length)
        
                    return {
                        ...prev,
                        // selected_subjects_affected_by_cp_id: locale_selected_sas_by_cp,
                        mapping: mapping,
                        display_selector: false
                    }
                })
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}))
        }

        
    }
  
    const handleDeleteSA = ({cp_id, sa_id}) => {
        const new_mapping = state.mapping[cp_id].filter( _ => _.id != sa_id)
        // console.log("Gnonna delete ", cp_id, " for s_a_id = ", sa_id, " \n Others are  ", state.mapping[cp_id].map(i => ({sub: i.sub.name, child: i.child.full_name })), "\n\n new ones are ", new_mapping)
        return new Promise((resolve, reject) => {
            removeSASfromContract(token, contract.id, [sa_id])
            .then(() => {
                resolve(true)
                // setState(prev => ({
                //     ...prev,
                //     mapping: {
                //         ...prev.mapping,
                //         [cp_id]: prev.mapping[cp_id].filter( _ => _.id != sa_id)
                //     }
                // }))
                update_mapping(new_mapping)
            })
            .catch(err => {
                apiErrorNotify({response: err.response, toast: toast});
                reject(false)
            })
        }) 
      }

      useEffect(() => {
        const parents = {} 
        if(contract.subjects_affected.length == 0) setState(prev => ({...prev, isLoaded: true}))
        contract.subjects_affected.forEach(
        s_a => {
            getParentInfosFromContractParent(token, s_a.contract_parent_id)
            .then( res_parent => setState(prev => {
                return {
                    ...prev, 
                    parents_infos: {
                        ...prev.parents,
                        [s_a.contract_parent_id]: res_parent.data
                    },
                    isLoaded: true
                }
            }) )
            .catch(err => apiErrorNotify({response: err.response, toast: toast}))

        }
        )

        listCities(token).then(
        res_cities => {
            listContractsParents(token).then( res_contract_parents => {
            let selected_subjects_affected_by_cp_id = {}
            // res_contract_parents.data.forEach(
            //   cp => selected_subjects_affected_by_cp_id[cp.id] = {}
            // )
            setState(prev => ({
                ...prev, 
                cities:  res_cities.data, 
                // teachers: res_teachers.data, 
                contract_parents: res_contract_parents.data,
                selected_subjects_affected_by_cp_id: selected_subjects_affected_by_cp_id,
                // isLoaded: true,
                }))
            
            })
        }
        )
        
        
      }, [setState])
    return (
        <VStack>
            {
                !state.isLoaded ? <Spinner /> : Object.keys(state.mapping).length == 0 ? <NoData /> : (
                    <VStack space='2'>
                        {console.log("render", Object.values(state.mapping).length)}
                    {
                        Object.keys(state.mapping).map(
                        cp_id => {
                            const _parent = state.parents_infos[cp_id] || {}
                            
                            const _ordered_by_subs = {}
                            mapping[cp_id]?.forEach(
                            s_a => {
                                if(!_ordered_by_subs[s_a.sub.name]) _ordered_by_subs[s_a.sub.name] = []
                                _ordered_by_subs[s_a.sub.name].push(s_a)
                            }
                            )
                            // return null
                            return (
                            <VStack key={new Date()} >
                                <HStack>
                                    <Text>Les enfants du parent : </Text>
                                    {/* <Spacer /> */}
                                    <Heading alignSelf={'center'} size='sm'> {_parent ? (_parent.full_name || _parent.username) : "Nom non trouvé" }</Heading>
                                    <Spacer />
                                </HStack>
                                
                                <VStack space='5' bg='gray.300' p='2' borderRadius={10}  >
                                {
                                Object.keys(_ordered_by_subs).map(
                                    sub => (
                                        <VStack space='1' ml='2' pl='2' borderLeftWidth={1} >
                                            <Heading size='xs'>{sub} </Heading>
                                            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                                                <HStack flexWrap={'wrap'}  space='4' >
                                                {_ordered_by_subs[sub].map(
                                                    s_a => (
                                                        <ResumeChild key={new Date()} s_a={s_a} onDelete={() => handleDeleteSA({cp_id: cp_id, sa_id: s_a.id}) } />
                                                    )
                                                )}
                                                    
                                                </HStack>
                                            </ScrollView>
                                        </VStack> 
                                    )
                                )
                                }
                                </VStack>
                            </VStack>
                            )

                        }
                        )
                    }
                    </VStack>
                ) 
            }
                
            {
            state.display_selector && (
                <AddChild 
                    isOpen={state.display_selector}
                    onClose={toggleSelector}
                    onConfirm={handleAffectSubject}
                    list_contract_parents={state.contract_parents}
                    // global_list_sas_selected_ids={[]}
                    global_list_sas_selected_ids={Object.values(state.mapping).reduce(
                    (total, current) => [...total, ...current],
                    []
                    )}
                    teacher_obj={contract}
                    // list_checked_sas={state.selected_subjects_affected_by_cp_id[state.selected_contract_parent_index] || [] }
                />  
            )
            }
            <Center>
            <Button mt="10" 
            
            onPress={()=> setState(prev => ({...prev, display_selector: true}) )} >
                Affecter des matières
            </Button>
            
            </Center>
        </VStack>
    )
}