import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Divider,
    IconButton,
    Icon,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { listContractTeacherChildren, getMyContractTeacher, getParentInfosFromContractParent } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import ContactOptions from "./components/ContactOptions";
import ContactOptionsInline from "./components/ContactOptionsInline";
import UserWithoutContract from "components/UserWithoutContract";
import { apiErrorNotify } from "services/notify";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";
import PageLoading from "components/PageLoading";



export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        contract_id: null,
        children: [],
        parents: {},
        selected_child_index: 0,
        selected_child_obj: null,
        selected_subject_affected: null,
        isLoaded: false
    })
    
    const refresh = () => {
        getMyContractTeacher(token).then(
            res_contract => {
                if(res_contract.data){
                    listContractTeacherChildren(token, res_contract.data.id).then(
                        res_children => {
                            // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                            // setState(prev => ({...prev, children: res.data, isLoaded: true }))
                            setState(prev => ({
                                ...prev, 
                                children: res_children.data, 
                                contract_id: res_contract.data.id,
                                isLoaded: true}
                            ))
                            const contract_parent_ids_dict = {}
                            res_children.data.map(
                                (child, child_index) => {
                                    child.subjects_affected.forEach(
                                        s_a => {
                                            if(!contract_parent_ids_dict[child_index]) contract_parent_ids_dict[child_index] = s_a.contract_parent_id 
                                        }
                                    )
                                }
                            )
                            const contract_parent_ids_set = new Set(Object.values(contract_parent_ids_dict))
                            const parents = {}
                            Object.keys(contract_parent_ids_dict).forEach(
                                (child_index) => {
                                    const cp_id = contract_parent_ids_dict[child_index]
                                    // if( ! contract_parent_ids_set.has(cp_id) ) {
                                        getParentInfosFromContractParent(token, cp_id)
                                        .then( res_parent => setState(prev => {
                                            console.log(res_parent.data.full_name)
                                            return {
                                                ...prev, 
                                                parents: {
                                                    ...prev.parents,
                                                    [child_index]: res_parent.data
                                                } 
                                            }
                                        }) )
                                        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
                                    // } 
                                }
                            )
                           
                        }
                    )
                    .catch(err => apiErrorNotify({response: err.response, toast: toast}))
                }  else {
                    setState(prev => ({...prev, isLoaded: true}))
                }
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }

    useEffect(() => {
        refresh()
    }, [setState])

    return (
    <ScreenWrapper title="Contacter un parent d'élève" nav={navigation}>
        <ScrollView>
            <VStack space={3} my='4' >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <>
                            <VStack space="5" px='3'>
                                <ScrollView horizontal showsHorizontalScrollIndicator={false} >
                                    <HStack space={5}>
                                        {
                                            state.children.map(
                                                (c, index) => (
                                                    <Pressable key={index} onPress={() => setState(prev => {
                                                        return {...prev, selected_child_index: index, selected_child_obj: c}
                                                    }) } >
                                                        <Text py='2' px='4' bg={state.selected_child_index == index ? 'gray.800' : 'gray.400'} color={state.selected_child_index == index ? 'white' : 'black' } borderRadius={20} >{c.full_name} </Text>
                                                    </Pressable>
                                                )
                                            )
                                        }
                                    </HStack>
                                </ScrollView>
                                {
                                    Object.keys(state.children).length === 0 && <NoData msg={"Aucun enfant à encadrer !"} />
                                }
                                {
                                    state.children[state.selected_child_index] && (
                                        <>
                                            <VStack space='1' >
                                                <Heading size='md' color='orange.500' >L'enfant</Heading>
                                                <Box  bg={'white'} shadow="7" p='3'  flex={1} borderRadius={10} >
                                                    <Heading size='lg'>{state.children[state.selected_child_index].full_name}</Heading> 
                                                    <Text>Classe: {state.children[state.selected_child_index].child_level}</Text>
                                                    <Text>Ecole: {state.children[state.selected_child_index].school}</Text>
                                                    <Text>Lieu des cours: {state.children[state.selected_child_index].place_courses}</Text>
                                                    {/* <Text>Ville: {state.contract.city.name}</Text> */}
                                                </Box>
                                            </VStack>

                                            {
                                                !state.parents[state.selected_child_index]  ? <Spinner /> : (
                                                    <VStack space='5'>
                                                        <Box>
                                                            <Heading size='md' color='orange.500' >Son parent</Heading>
                                                            <Center>
                                                                <Text fontSize={24}>{state.parents[state.selected_child_index].full_name}</Text>
                                                            </Center>
                                                        </Box>
                                                        <ContactOptions 
                                                            phone={state.parents[state.selected_child_index].username} 
                                                            is_whatsapp={true}
                                                        />
                                                    
                                                    {/* {
                                                        state.parents[state.selected_child_index].contacts.map(
                                                            contact => <ContactOptionsInline key={contact.id} 
                                                                        phone={contact.phone} 
                                                                        is_whatsapp={contact.is_whatsapp}
                                                                        leftItem={<Text>{contact.phone} </Text>} />
                                                        ) 
                                                    } */}
                                                    </VStack>
                                                )
                                            }
                                        </>
                                    )
                                }
                                    
                                    
                            </VStack>
                        </>
                    
                    )
                }

                </VStack>

            </ScrollView>

    </ScreenWrapper>
  );
}