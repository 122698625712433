import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { getTeachersNoApproved, approveUser } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { Linking } from 'react-native';
import * as SMS from 'expo-sms';
import ContactScreen from "screens/ContactScreen";
import ScreenWrapper from "components/ScreenWrapper";


export default ({ navigation, route }) => {
  return (
    <ScreenWrapper title="Contacter un parent" nav={navigation}>
      <ContactScreen navigation={navigation} route={route} title={''} />
    </ScreenWrapper>
  ) 
}