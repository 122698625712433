import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast,
    Divider,
    Modal,
    Spacer,
    IconButton,
    CloseIcon,
    AddIcon,
    Icon,
    ZStack
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { listCities, createContractParent, listApprovedParentsWithoutContract, listSubs, getAnyContractParent } from "services/api-axios";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_natural_time, from_day_index_to_day_name, humanize_date_YMD } from "services/utils";
import { apiErrorNotify } from "services/notify";

import TimeInput from "components/TimeInput";
import DateTimeInput from "components/DateTimeInput";
import ResumeChildInfos from "./components/ResumeChildInfos";
import NumberInput from "components/NumberInput";
import DisplayTime from "components/DisplayTime";
import DisplayTimeLinear from "components/DisplayTimeLinear";
import ScreenWrapper from "components/ScreenWrapper";

import ModalAddSubject from "./components/ModalAddSubject";
import AddChild from "./components/AddChild";
import PageLoading from "components/PageLoading";
import ChildrenManager from "./components/ChildrenManager";
import ResumeContractInfos from "./components/ResumeContractInfos";
import ExtraOptions from './components/ExtraOptions';
import { useFocusEffect, useNavigationState } from "@react-navigation/native";
import { getNavigationPreviousScreen } from "stacks/utils";

const aggregate_subjects_affected_by_child = (subjects_affected) => {
  const children = {}
  subjects_affected.forEach( s_a => {
      delete s_a.course_sessions
      delete s_a.prestations
      delete s_a.marks

      let child = s_a.child
      if(!children[child.id]) {
        children[child.id] = {
            ...child, 
            timeplan_week_model: child.timeplan_week_model || {} ,
            subjects_affected: [],  
          }
        }

      children[child.id].subjects_affected.push({
        id: s_a.id,
        sub: s_a.sub,
        contract_teacher: s_a.contract_teacher
      })
  } )
  return children
}

export default ({ navigation, route }) => {
    const token = getToken()
    const toast = useToast()
    const routeInitialValues = route.params ? route.params.init : {}

    const [formValues, setFormvalues] = useState({
      parent_id: 0,
      city: 0,
      price: 0,
      signed_at: new Date(),
      start_at: new Date(),
      end_at: new Date(),
      children: []
    })
    const [state, setState] = useState({
      contract: null,
      display_add_child: false,
      display_edit_child: false,
      selected_child_index: null,
      done: false,
      isCreatingContract: false,
      parents: [],
      cities: [],
      subs: [],
      seed: null
    })
 
    useFocusEffect(useCallback(
      () => {
        setTimeout(() => {
          getNavigationPreviousScreen().then(
            raw => {
              const parsed = JSON.parse((raw ?? null))
              const prevName = parsed?.name
              const newSeed = parsed?.seed
              if(prevName === MENU_NAVIGATION.Direction.ManageTeachers.UpdateContract.name) {
                if(state.seed == newSeed) return
                refreshContract(newSeed)
              } 
            } 
          )
        }, 1000);
      }, [state.seed]
    ))
  
    const refreshContract = useCallback((seed) => {
        if(state.seed == seed) return

        setState(prev => ({...prev, contract: null}))
        getAnyContractParent(token, routeInitialValues.contract.id)
        .then(response => {
          
            const ctx = response.data
            setState(prev => ({
                ...prev, 
                contract: ctx,
                seed: seed,
              }))

              const aggreg = Object.values(aggregate_subjects_affected_by_child(ctx.subjects_affected))
              setFormvalues({
                price: ctx.price,
                signed_at: new Date(ctx.signed_at),
                start_at: new Date(ctx.start_at),
                end_at: new Date(ctx.end_at),
                children: aggreg
              })

        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )

    })

    useEffect(() => {
      listCities(token).then(
        res => {
          setState(prev => ({...prev, cities:  res.data}))
        }
      )
      listSubs(token).then(
        res => {
          setState(prev => ({...prev, subs:  res.data}))
        }
      )

      refreshContract(2)
      
    }, [setState, setFormvalues])

    return (
      <ScreenWrapper title="Modifier un contrat de parent" nav={navigation}>
        { !state.contract ? <PageLoading /> : (
          <>
            <ScrollView>
                <VStack space={10} px="3" py="10" >
                    {/* <Center>Contrat de {state.contract.parent.full_name || state.contract.parent.username }</Center> */}
                    <HStack px='5'>
                      <Spacer />
                      <ExtraOptions contract_id={state.contract.id} />
                    </HStack>
                    <Center><Heading size='md' color='orange.500' >Informations générales</Heading></Center>
                    <VStack>
                      <ResumeContractInfos contract={state.contract} cities={state.cities} />
                    </VStack>

                    <Divider />
                    <Center><Heading size='md' color='orange.500' >Les enfants</Heading></Center>

                    <VStack>
                          {
                            <ChildrenManager key={new Date} contract_id={state.contract.id} children_data={formValues.children} subs={state.subs} />
                          }
                    </VStack>

                </VStack>
              
            </ScrollView>
          </>
        ) }
      </ScreenWrapper>
  );
}