import { Entypo, MaterialIcons } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import ContactOptioinsStagger from "components/ContactOptioinsStagger";
import NoData from "components/NoData";
import PageLoading from "components/PageLoading";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION, INVOICE_STATUS } from "constant";

import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Spinner,
    Divider,
    useToast,
    Icon,
    FlatList
  } from "native-base";
import { useEffect } from "react";
import { useState } from "react";
  import { Image, Pressable } from 'react-native';
import { SwipeListView, SwipeRow } from "react-native-swipe-list-view";
import { getAnyContractParent, getMyContractParent, listContractsParents, remindInvoice, soldInvoice } from "services/api-axios";
import { get_screen_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import { format_price, humanize_natural_date } from "services/utils";
import { getToken } from "store/login/selectors";
import InvoiceItem from "./components/InvoiceItem";


export default ({ navigation, route }) => {
    const routeInitialValues = route.params ? route.params.init : {}
    const toast = useToast()
    const token = getToken()

    const [state, setState] = useState({
        contract: null,
        isLoaded: false,
    })

    const handleSoldInvoice = (inv_id) => {
        return soldInvoice(token, routeInitialValues.contract_id, inv_id)
    }

    const handleEdit = (invoice_id) => {
        navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.UpdateInvoice.name, { init: { invoice_id: invoice_id, contract_parent_id: state.contract.id } })
    }

    const handleNotify = (invoice_id) => {
        return remindInvoice(token, routeInitialValues.contract_id, invoice_id)
    }

    useEffect(()=> {
        getAnyContractParent(token, routeInitialValues.contract_id)
            .then(res => {
                setState(prev => ({
                    ...prev, 
                    contract: res.data,
                    isLoaded: true
                }))
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}) )

    }, [setState])
    
    
  return (
    <ScreenWrapper title={"Les factures du parent "} nav={navigation}>
        <ScrollView>
            <VStack space={4}>
                {
                    !state.isLoaded ? <PageLoading /> : 
                    !state.contract ? (
                        <NoData />
                    ) : state.contract.invoices.length === 0 ? <NoData /> : (
                        <>
                            <ContactOptioinsStagger phone={state.contract.parent.username} />
                            <VStack zIndex={1} >
                                <Heading color="gray.600" alignSelf={'center'}>Le contrat</Heading>
                                <VStack mx='3' shadow='5' bg={'gray.200'} p='3' borderRadius={10} >
                                    <HStack space='3' >
                                        <Text fontSize={12} alignSelf={'center'} color="black">Nom du parent:</Text>
                                        <Heading size='md' color="green.600">{state.contract.parent.full_name}</Heading>
                                    </HStack>
                                    <HStack>
                                        <Text fontSize={12} alignSelf={'center'} color="black">Montant du contract :  </Text>
                                        <Heading color="green.600">{format_price(state.contract.price)} F</Heading>
                                    </HStack>
                                    <HStack justifyContent={'space-between'} >
                                        {/* <VStack> */}
                                            <Text fontSize={12} >Du {humanize_natural_date(new Date(state.contract.start_at))}</Text>
                                            <Text fontSize={12}>Au {humanize_natural_date(new Date(state.contract.end_at))}</Text>
                                        {/* </VStack> */}
                                    </HStack>
                                    <Text fontSize={14} >Date de signature : {humanize_natural_date(new Date(state.contract.signed_at))} </Text>
                                </VStack>
                            </VStack>

                            <VStack>
                                <Heading color='gray.600' alignSelf={'center'} >Les factures </Heading>
                                <VStack space={3} mx='2' >
                                    {
                                        // state.contract.invoices.sort((i, j) => i.status == INVOICE_STATUS.pending ).map(
                                        //     inv => (
                                        //         <InvoiceItem invoice={inv} toast={toast} handleSubmit={handleSoldInvoice} />
                                        //     )
                                        // )
                                        <FlatList
                                            data={state.contract.invoices.sort((i, j) => i.status !== INVOICE_STATUS.pending )}
                                            keyExtractor={item => item.id}
                                            renderItem={({item}) => <InvoiceItem 
                                                invoice={item} 
                                                toast={toast} 
                                                handleNotify={handleNotify}
                                                handleEdit={handleEdit} 
                                                handleSubmit={handleSoldInvoice} 
                                                _wrapper={{my: '1'}} 
                                            />}
                                        />
                                    }
                                    {console.log("render")}
                                </VStack>
                            </VStack>
                        </>
                    )
                }
                
            </VStack>
        {/* </VStack> */}
            </ScrollView>
    </ScreenWrapper>
  );
}