import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { listApprovedTeachers, approveUser, getFileLink } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { apiErrorNotify } from "services/notify";
import NoData from "components/NoData";
import ScreenWrapper from "components/ScreenWrapper";



export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()

    const [ state, setState ] = useState({
        teachers: [],
        isLoaded: false
    })
    

    const refresh = () => {
        listApprovedTeachers(token).then(
            res => {
                setState(prev => ({...prev, teachers: res.data }))
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        .finally(() => {
            setState(prev => ({
                ...prev, 
                isLoaded: true}
            ))
        })
    }
    
    useEffect(() => {
        refresh()
    }, [setState])

    return (
    <ScreenWrapper nav={navigation} title="Profils des enseignants"  >
        <ScrollView>
            <VStack space={2} >
                {
                    !state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <Spinner />
                            <Heading color="primary.500" fontSize="md">
                                Chargement
                            </Heading>
                        </HStack>
                        </Center>
                    ) : state.teachers.length === 0 ? <NoData msg={"Aucun enseignant disponible"} /> : (
                        <>
                            {
                                state.teachers.map( p => (
                                    <HStack key={p.id} space={3} px='2' >
                                        <Box w={100} h={100} >
                                            { p.media_avatar ? 
                                                <Image borderRadius={'full'} h={100} w={100} alt="Une photo" source={{uri: getFileLink(p.media_avatar)}} />
                                                :
                                                <Box w={100} h={100} rounded='full' bg='gray.300' />
                                            }
                                        </Box>
                                        <VStack>
                                                <Text color="orange.500" fontWeight={'bold'} > { p.full_name ? p.full_name?.slice(0, 20) : p.username } </Text>
                                                <HStack alignItems={'center'} >
                                                    <Text color="black" fontWeight={'thin'} fontSize={12} > Profession - </Text>
                                                    <Text color="black" fontSize={15}> {p?.teacher_metadata?.job} </Text>
                                                </HStack>
                                                <HStack alignItems={'center'} >
                                                    <Text color="black" fontWeight={'thin'} fontSize={12} > Niveau - </Text>
                                                    <Text color="black" fontSize={15} > {p.teacher_metadata?.level?.slice(0, 18)}</Text>
                                                </HStack>
                                                <Button size='sm' w={100} onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ShowTeacherProfile.name, {teacher: p})} >Voir le profil</Button>
                                        </VStack>
                                    </HStack>
                                    ))
                            }
                        </>
                    )
                }
                </VStack>

        </ScrollView>
    </ScreenWrapper>
  );
}