import DisplayTimeLinear from "components/DisplayTimeLinear"
import { Divider, Heading, HStack, VStack, Text, Spacer, Center } from "native-base"
import { format_time, from_day_index_to_day_name } from "services/utils"

export default ({ week_model, subjects_affected }) => {
    return (
        <>
            {
                week_model && Object.keys(week_model).map(
                    day_name => (
                        <>
                            {
                                week_model[day_name].length == 0 ? null : (
                                <VStack mt='2' mb='3' >
                                    <HStack space='10' bg='gray.300' px='2' >
                                        <Heading color='gray.700'>{from_day_index_to_day_name(day_name)}</Heading>
                                    </HStack>
                                    
                                    <VStack bg='gray.200' px='2'> 
                                        {
                                            week_model[day_name].map(
                                                (course_merged_data, i) => {

                                                    const sa = subjects_affected.find( s => s.sub.id ==  course_merged_data.sub_id)

                                                    return (
                                                        <VStack> 
                                                            <HStack my='2' key={i} space='2'  >
                                                                <HStack>
                                                                    <DisplayTimeLinear space='0' fontSize={12} value={course_merged_data.start_at} />
                                                                    <Text color='primary.500' > - </Text>
                                                                    <DisplayTimeLinear space='0' fontSize={12} value={course_merged_data.end_at} />
                                                                </HStack>
                                                                <Spacer />
                                                                <Text fontSize={14}>{sa ? sa.sub.name : 'Inconnu' }</Text>
                                                                <Spacer />
                                                            </HStack>
                                                            <Divider opacity={0.2} />
                                                        </VStack>
                                                    )
                                                }
                                            )
                                        }
                                    </VStack>
                                </VStack>   
                                )
                            }

                        </>
                    )
                )
            }
        </>
    )
}