import { MENU_NAVIGATION } from "constant"
import { Box, Center, Icon, Text } from "native-base"
import { Pressable } from "react-native"

export default ({children, _wrapper}) => {
    return (
        <Box shadow={'4'} bg='white' height={120}  px='2' alignContent={'center'} justifyContent={'center'} flex={1} borderRadius={10} {..._wrapper} >
            {children}
        </Box>
    )
}