import Annonce from "components/Annonce";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
	Actionsheet,
	Input,
	useToast,
	Image
  } from "native-base";
import { Pressable, SafeAreaView, TextInput } from 'react-native';
import SwitchColorMode from 'components/SwitchColorMode'
import { Layout } from "components/Layout";
import { MasonaryLayout } from "components/MasonaryLayout";
// import { useGetTeachersNoApprovedQuery, useLazyLoginQuery } from "services/api-rest";
import { login as loginAPI } from 'services/api-axios'
import { useState } from "react";
import { useLogin } from "store/login/hooks";
import { getLoginState } from "store/login/selectors";
import {BACKEND_API} from "@env"
import { BASE_API_URL } from "constant";
import { MENU_NAVIGATION } from "constant";
import { apiErrorNotify } from "services/notify";
import { get_window_height } from "services/dimensions";
import PhoneInput from 'components/PhoneInput';
import PasswordInput from "components/PasswordInput";
import { useRef } from "react";
import Notifications from "services/push-notifiy";

const selectedImage = null;
export default function LoginScreen({ navigation }){
	const login = useLogin()
	const toast = useToast()
	const logState = getLoginState()
	const [ loginData, setLoginData ] = useState({
		username: '',
		password: '',
		error: false,
		loading: false
	})
	const trigger = () => {
		setLoginData(prev => ({...prev, loading: true}))
		setTimeout(() => {
			// Notifications.getExpoPushTokenAsync().then(
			// 	({data}) => {
				// loginAPI({ username: loginData.username, password: loginData.password, expo_token: data })
				loginAPI({ username: loginData.username, password: loginData.password })
					.then(res => {
							console.log(res.data)
							login(res.data)
						})
						.catch(
							err => {
								apiErrorNotify({response: err.response, toast: toast})
								setLoginData(prev => ({...prev, error: true, loading: false}))
							}
						)	
			// 	}
			// ).catch(err => apiErrorNotify({response: err.response, toast: toast}))
		}, 1000);
		
	}
	const u_ref = useRef()
	const p_ref = useRef()

  return (
    <SafeAreaView flex={1} >
		{/* <Actionsheet isOpen={true} onClose={() => null} collapsable={false} >
			<Actionsheet.Content  bg="white" > */}
			{/* <ScrollView showsVerticalScrollIndicator={false}> */}
				<VStack w="100%" h={get_window_height()} px={{ base: 4, md: "30%" }} space="4"  >
					{/* <Center><Heading>Renseignez les champs suivants</Heading></Center> */}
					<Image source={require("assets/icon.png")} alignSelf='center' borderRadius={50} w={100} h={100} alt='Logo' />
					
					<PhoneInput 
						label='Numéro de téléphone'
						innerRef={u_ref}
						onSubmitEditing={() => {
							p_ref.current.focus()
						}}
						returnKeyType={"next"}
						value={loginData.username} 
						onChangeText={txt => setLoginData(prev => ({...prev, username: txt, error: null}))} 
						placeholder="Numéro de téléphone"
						// rightElement={<Text>YES</Text>}
						/>
					<PasswordInput
						label='Mot de passe'
						innerRef={p_ref}
						onSubmitEditing={trigger}
						value={loginData.password} 
						returnKeyType="submit"
						onChangeText={txt => setLoginData(prev => ({...prev, password: txt, error: null}))}  
						placeholder="Mot de passe"  />
			
					{
						loginData.error && (
							<Center>
								<Text color="red.600" >Mot de passe oublié ? </Text>
							</Center>
						)
					}
					<Button 
						disabled={!loginData.username || !loginData.password}
						isLoading={loginData.loading} 
						onPress={trigger}
						>Continuer</Button>

					<VStack>
						<Center>
							<Text fontWeight={'bold'}>Vous n'avez pas de compte ? </Text>
						</Center>
						<Button 
						bg='emerald.400'
						_text={{color: 'white'}}
						onPress={() => navigation.navigate(MENU_NAVIGATION.Login.SignIn.name) }
						>Créer un compte</Button>

					</VStack>

					<Center mt='1'><Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.Agreement.name) } ><Text underline fontSize={12} color='green.700' >Notre politique de confidentialité</Text></Pressable></Center>
					
					<Center>
						{/* <Text  color="gray.400" > Débogage: ({BASE_API_URL}) </Text> */}
						{/* <Text color="gray.400" > User: ({JSON.stringify(getLoginState())}) </Text> */}
					</Center>
				</VStack>
			{/* </ScrollView> */}
			{/* </Actionsheet.Content>
		</Actionsheet> */}
  </SafeAreaView>
  );
}