import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    TextArea
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { listApprovedTeachers, approveUser, orderTeacher, listSubs } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import OrderButton from "./components/OrderButton";
import DisapearingAlert from "components/DisapearingAlert";
import MultiSelect from "react-native-multiple-select";


const AddChild = ({isOpen, onClose, onOpen, onSubmit, initialValues= {}, subs = []}) => {
    const token = getToken()
    const [childValues, setChildValues] = useState({
      full_name: '',
      school: '',
      child_level: '',
      place_courses: '',
      frequency: '2',
      child_subs: [],
      comments: '',
      ...initialValues,
    })

    return (
      <Actionsheet isOpen={isOpen} onClose={onClose} collapsable={true} >
        <Actionsheet.Content bg="white" >
          <Heading color="black" >Mon enfant</Heading>
          <ScrollView pt="3" w="100%" h={'100%'} bg="white">
            <VStack space="3" pb='4'  >
    
                <FormControl isRequired>
                    <FormControl.Label>Nom complet de l'enfant</FormControl.Label>
                    <Input 
                        value={childValues.full_name} 
                        onChangeText={(val) => setChildValues(prev => ({...prev, full_name: val}))}
                        />
                </FormControl>
                <FormControl isRequired>
                    <FormControl.Label>Classe</FormControl.Label>
                    <Input 
                        value={childValues.child_level} 
                        onChangeText={(val) => setChildValues(prev => ({...prev, child_level: val}))}
                        />
                </FormControl>
                <FormControl isRequired>
                    <FormControl.Label>Matières</FormControl.Label>
                    {/* <Select selectedValue="0" onValueChange={(val) => setChildValues(prev => ({...prev, child_subs: val}))}>
                        {
                            subs.map( s => <Select.Item color="yellow" key={s.id} value={s.id} label={s.name} _text={{color: 'black'}} /> )
                        }
                    </Select> */}
                    <MultiSelect
                        items={subs}
                        uniqueKey="id"
                        displayKey="name"
                        // ref={(component) => { this.multiSelect = component }}
                        onSelectedItemsChange={(new_list) => setChildValues(prev => ({...prev, child_subs: new_list}))}
                        selectedItems={childValues.child_subs}
                        selectText="Matières"
                        searchInputPlaceholderText="Recherche ..."
                        tagRemoveIconColor="#CCC"
                        tagBorderColor="#CCC"
                        
                        tagTextColor="#CCC"
                        selectedItemTextColor="blue"
                        selectedItemIconColor="#CCC"
                        itemTextColor="#000"
                        
                        searchInputStyle={{ color: '#CCC' }}
                        submitButtonColor="blue"
                        submitButtonText="Confirmer la sélection"
                        
                        />
                </FormControl>
                
            <FormControl isRequired>
                <FormControl.Label>Nom de l'école fréquentée</FormControl.Label>
                <Input 
                    value={childValues.school} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, school: val}))}
                    />
                </FormControl>
    
            <FormControl isRequired>
                <FormControl.Label>Lieu des cours à domicile</FormControl.Label>
                <Input 
                    value={childValues.place_courses} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, place_courses: val}))}
                    />
                </FormControl>
    
            <FormControl isRequired>
                <FormControl.Label>Fréquence</FormControl.Label>
                <Input 
                    value={childValues.frequency} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, frequency: val}))}
                    />
                </FormControl>
            
            <FormControl isRequired>
                <FormControl.Label>Commentaires</FormControl.Label>
                <TextArea 
                    value={childValues.comments} 
                    onChangeText={(val) => setChildValues(prev => ({...prev, comments: val}))}
                    />
                </FormControl>
    
                <Button onPress={() => onSubmit(childValues)} >Ajouter cet enfant à ma demande</Button>
            </VStack>
          </ScrollView>
        </Actionsheet.Content>
      </Actionsheet>
    )
}


export default ({navigation, route, ...rest}) => {
    const token = getToken();
    const [children, setChildren] = useState([])
    const [error, setError] = useState('')
    const [isDone, setIsDone] = useState('')
    const { teacher } = route.params
    const { isOpen, onClose, onOpen } = useDisclose()
    const submitApplyment = () => {
        const data = { 
            teacher_id: teacher.id,
            children_details: children.map(c => ({...c, child_subs: [1, 2], frequency: parseInt(c.frequency)}) )
        }
        console.log(data)
        orderTeacher(token, data)
        .then(res => setIsDone(true) )
        .catch(err => setError("Erreur") )
    }
    return (
        <ScrollView>
            {
                error ? <Heading size={'2xl'}>{error}</Heading> :
                !isDone ? (
                    <VStack bg="white" p='4' space='6' >
                        <Heading>Formulaire de demande d'enseignant</Heading>
                        <FormControl>
                            <FormControl.Label>Nom l'enseignant</FormControl.Label>
                            <Text>{teacher.full_name}</Text>
                        </FormControl>
                        {
                            children.map(
                                (c, index) => (
                                    <Box index={index} borderWidth='1' borderRadius={5} p="3" >
                                            {/* <Button ml='1/2' >Modifer</Button> */}
                                            <Heading size={"sm"}>Enfant {index+1}</Heading>
                                            <Text>Nom de l'enfant: {c.full_name}</Text>
                                            <Text>Classe: {c.child_level}</Text>
                                            <Text>Lieu des cours: {c.place_courses}</Text>
                                            <Text>Nombre de cours par semaine: {c.frequency}</Text>
                                            <Text>Matières: {c.child_subs.length}</Text>
                                            <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                                                <HStack py='3' >
                                                    {
                                                        c.child_subs.map(cs => <Chip px='2' mx='1' bg='gray.500'>{subs.find(s => s.id == cs).name}</Chip> )
                                                    }
                                                </HStack>   
                                            </ScrollView>
                                    </Box>
                                )
                            )
                        }
                        {
                            true && (
                                <AddChild key={children.length} isOpen={isOpen} 
                            onClose={onClose} 
                            teacher={teacher}
                            onSubmit={(values) => {
                                setChildren(prev => prev.concat([values]) )
                                onClose()
                            } } />
                            )
                        }
                        
                        { children.length > 0 ? <Button onPress={submitApplyment}>Soumettre ma demande</Button> : <Button onPress={onOpen} >Ajouter un enfant</Button> }
                    </VStack>
                ):(
                    <DisapearingAlert update={()=> navigation.goBack()} />
                )
            }

        </ScrollView>
    )
}
