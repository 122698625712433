import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    Input,
    Select,
    FormControl,
    WarningOutlineIcon,
    CheckIcon,
    Actionsheet,
    useDisclose,
    useToast,
    Divider,
    Modal,
    Spacer,
    IconButton,
    CloseIcon,
    AddIcon,
    Icon,
    Pressable
  } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useNavigation } from "@react-navigation/native";
import { getToken } from "store/login/selectors";
import { apiErrorNotify } from "services/notify";
import { MENU_NAVIGATION } from "constant";

import { deleteContractParent } from "services/api-axios";

export default ({ contract_id }) => {
    const navigation = useNavigation()
    const token = getToken()
    const toast = useToast()
    const { isOpen, onToggle } = useDisclose()
    const { isOpen: isConfiramtionOpen, 
        onToggle: toggleConfirm, 
        onClose: closeConfirm,
        onOpen: openConfirm
    } = useDisclose()

    const handleDeleteContract = () => {
        return new Promise((resolve, reject) => {
            deleteContractParent(token, contract_id)
                .then(response => {
                    console.log("GOT RESPONSE ")
                    navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {
                        nav: navigation,
                        msg: "Contrat supprimé !",
                        update: () => {
                            navigation.goBack()
                            navigation.goBack()
                            navigation.goBack()
                        }
                    })
                })
                .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
        }) 
    }

    return (
        <>
            {
                contract_id && (
                    <VStack space={3} >
                        <HStack>
                            <Spacer />
                            <IconButton
                            w='30px'
                            h='30px'
                            rounded={"full"}
                                onPress={onToggle}
                                icon={<Icon name={isOpen ? 'close' : "pushpin"} as={AntDesign} color={'white'}  />}
                                bg={'orange.400'}

                            />
                        </HStack>
                        {
                            isOpen && (
                                <VStack>
                                    <Button 
                                        bg='red.400' _text={{color: 'white'}} 
                                        size={'xs'}
                                        onPress={() => {
                                            console.log("open")
                                            openConfirm()
                                        }}
                                        >Supprimer le contrat</Button>
                                </VStack>
                            )
                        }
                        {console.log(isConfiramtionOpen)}
                        {isConfiramtionOpen && (
                            <ConfirmationDialog 
                                isOpen={isConfiramtionOpen} 
                                onCancel={toggleConfirm} 
                                onAgree={handleDeleteContract} 
                                msg={`Voulez-vous réellement supprimer ce contrat ? Tous les enfants et matières affectées aux enseignant seront supprimé également ! `} />

                        )}
                    </VStack>
                )
            }
        </>
    )
}