import { View, Text, Button, Heading, HStack, Spacer, VStack, Box, Center, ScrollView, IconButton, ChevronLeftIcon } from 'native-base'
import { useLogout } from 'store/login/hooks';
import {BACKEND_API} from "@env"
import { useState } from 'react';
import { Pressable } from 'react-native';
import { MENU_NAVIGATION } from 'constant';
import { getIsDirection, getIsManager } from 'store/login/selectors';
import { useClearNotications } from 'store/notifications/hooks';


export default function SettingsScreen({ navigation }) {
  console.log(BACKEND_API)
  const logoutHook = useLogout()
  const clearNotificationsHook = useClearNotications()
  const logout = () => {
    clearNotificationsHook()
    logoutHook()
  }
  const isManager = getIsDirection()
    return (
      <ScrollView>
        <VStack space="4" px="4" pb="5" >
          {/* <Text>Settings screen</Text> */}
          <Heading >Page de configurations</Heading>
          <HStack pr='2'>
            <IconButton
            color='primary.500'
              icon={<ChevronLeftIcon color='primary.500' />}
              onPress={navigation.goBack}
            />
            <Spacer />
            <Button variant={'solid'} bg='red.600' rounded={'2xl'} onPress={logout}  >Se déconnecter</Button>
          </HStack>

          <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Settings.ShowMyProfile.name) }>
            <HStack space={5}>
              <Box height={50} width="50%" bg={'primary.500'} flex={1} borderRadius={10} >
                  <Center height="100%"><Text fontSize={18} color="white">Voir mon profil</Text></Center>
              </Box>
            </HStack>
          </Pressable>

          <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Settings.EditMyProfile.name) }>
            <HStack space={5}>
                <Box height={50} width="50%" bg={'primary.500'} flex={1} borderRadius={10} >
                    <Center height="100%"><Text fontSize={18} color="white">Changer mon profil</Text></Center>
                </Box>
            </HStack>
          </Pressable>

          <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Settings.ChangePassword.name) }>
            <HStack space={5}>
              <Box height={50} width="50%" bg={'primary.500'} flex={1} borderRadius={10} >
                    <Center height="100%"><Text fontSize={18} color="white">Changer mon mot de passe</Text></Center>
              </Box>
            </HStack>
          </Pressable>

          <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.Agreement.name) }>
            <HStack space={5}>
              <Box height={50} width="100%" bg={'primary.500'} flex={1} borderRadius={10} >
                    <Center height="100%"><Text fontSize={18} color="white">Politique de confidentialité</Text></Center>
              </Box>
            </HStack>
          </Pressable>


          <Box h='50px' />
          
          {
            !isManager && (
              <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.DeleteMyAccount.name) }>
                <HStack space={5}>
                  <Box height={50} width="100%" bg={'red.500'} flex={1} borderRadius={10} >
                        <Center height="100%"><Text fontSize={18} color="white">Supprimer mon compte</Text></Center>
                  </Box>
                </HStack>
              </Pressable>
            )
          }
        </VStack>
      </ScrollView>
    );
}