import { AntDesign } from "@expo/vector-icons"
import DateTimeInput from "components/DateTimeInput"
import NumberInput from "components/NumberInput"
import { Button, FormControl, Heading, Icon, Select, useToast, VStack } from "native-base"
import { useState } from "react"
import { updateContractParent } from "services/api-axios"
import { apiErrorNotify } from "services/notify"
import { humanize_date_YMD } from "services/utils"
import { getToken } from "store/login/selectors"

export default ({  cities, contract  }) => {
    const token = getToken()
    const toast = useToast()

    const [formValues, setFormvalues] = useState({
        price: contract.price,
        signed_at: new Date(contract.signed_at),
        start_at: new Date(contract.start_at),
        end_at: new Date(contract.end_at),
    })
    const [isSubmiting, setSubmiting] = useState(false)
    const handleSubmit = () => {
        setSubmiting(true)
        const data = {
            price: parseInt(formValues.price),
            start_at: humanize_date_YMD(formValues.start_at),
            end_at: humanize_date_YMD(formValues.end_at),
            signed_at: humanize_date_YMD(formValues.signed_at),
        }
        console.log("Submited contract ", data)
        updateContractParent(token, contract.id, data)
            .then(response => {
                toast.show({
                    title:  "Opération effectuée",
                    variant: "subtle",
                    status: 'success',
                    bg: 'green.600'
                })
                const ctx = response.data
                const _new = {
                    price: ctx.price,
                    signed_at: new Date(ctx.signed_at),
                    start_at: new Date(ctx.start_at),
                    end_at: new Date(ctx.end_at),
                }
                console.log(_new)
                setFormvalues(_new)
                setSubmiting(false)
            })
            .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }
    return (
        <VStack space='3' >
            <FormControl isRequired isDisabled >
            <FormControl.Label>Parent</FormControl.Label>
                <Select >
                <Select.Item label={contract.parent.full_name || contract.parent.username }  />
                </Select> 
            </FormControl>

            <FormControl isRequired isDisabled >
            <FormControl.Label>Ville</FormControl.Label>         
                <Select>
                <Select.Item label={ cities.length != 0 && cities.find(c => c.id == contract.city).name}  />
                </Select> 
            </FormControl>

            <FormControl isRequired isDisabled={formValues.parent_id == 0}>
            <FormControl.Label>Montant</FormControl.Label>
                <NumberInput 
                value={formValues.price.toString()} 
                placeholder="Entrez un montant"
                onChangeText={val => setFormvalues(prev => ({...prev, price: val})) } />
            </FormControl>
            
            <FormControl  isRequired >
                <FormControl.Label>Date de signature</FormControl.Label>
                <DateTimeInput 
                    value={formValues.signed_at} 
                    placeholder=""
                    setValue={val => setFormvalues(prev => ({...prev, signed_at: val})) } />
            </FormControl>
            
            <FormControl width={"45%"} isRequired>
            <FormControl.Label>Date de début</FormControl.Label>
                <DateTimeInput 
                value={formValues.start_at} 
                setValue={val => setFormvalues(prev => ({...prev, start_at: val})) } />
            </FormControl>

            <FormControl width={"45%"} isRequired>
            <FormControl.Label>Date de fin</FormControl.Label>
                <DateTimeInput 
                value={formValues.end_at} 
                placeholder=""
                setValue={val => setFormvalues(prev => ({...prev, end_at: val})) } />
            </FormControl>

            <Button onPress={handleSubmit} startIcon={<Icon as={AntDesign} name='edit' />} rounded='full' isLoading={isSubmiting} bg='primary.500'>Modifier les informations générales</Button>
        
        </VStack>
    )
}