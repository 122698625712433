import { get_screen_height } from "services/dimensions";

const { Actionsheet, Center, Heading } = require("native-base");

export default ({time = 3000, autorun = false, message='Success', update, color})=>{
  if(autorun) {
    setTimeout(update, time);
  }
    return (
      <Actionsheet isOpen={true} collapsable={false} onClose={update} >
        <Actionsheet.Content bg="white" minH={'100px'}>
          <Center>
            <Heading color={color || "emerald.500"} > {message} </Heading>
          </Center>
        </Actionsheet.Content>
      </Actionsheet>  
    )
  }