import React from 'react';
import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    useDisclose,
    Actionsheet,
    FormControl,
    Input,
    Select,
    Checkbox,
    TextArea,
    Modal,
    useToast
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { createTeacherRemark, getMyContractTeacher, setTeacherRemark } from "services/api-axios";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_natural_time, from_day_index_to_day_name, humanize_date_YMD } from "services/utils";
import { COURSE_SESSION_STATUS } from "constant";
import UserWithoutContract from 'components/UserWithoutContract';
import NoData from 'components/NoData';
import DisplayTimeLinear from 'components/DisplayTimeLinear';
import ScreenWrapper from 'components/ScreenWrapper';
import PageLoading from 'components/PageLoading';
import { apiErrorNotify } from 'services/notify';
import TimeplanWeekStaticDisplay from 'components/TimeplanWeekStaticDisplay';
import DateTimeInput from 'components/DateTimeInput';



export default ({ navigation }) => {
    const token = getToken()
    const toast = useToast()
    
    const [ state, setState ] = useState({
        contract_id: null,
        subjects_affected: [],
        nb_weeks: 0,
        children: {},
        selected_child_id: null,
        selected_week_index: null,
        selected_session_id: null,
        selected_session_object: null,
        current_week_index: null,
        
        remark: '',
        dateValue: '',
        sa_id: null,

        isLoaded: false,
        isSubmiting: false,
        success: false,
    })

    // if(state.selected_child_id !== null && state.selected_week_index !== null) {
    //     [0, 1, 2, 3, 4, 5, 6].forEach(
    //         day_name => {
    //             if(state.children[state.selected_child_id]){
    //                 const day_datalist = state.children[state.selected_child_id].weeks[state.selected_week_index][day_name]
    //                 for (let i = 0; i < day_datalist.length; i++) {
    //                     for (let index = 1; index < day_datalist.length; index++) {
    //                         // if( new Date(`2020-10-10 ${day_datalist[index-1].start_at}`) > `2020-10-10 ${day_datalist[index].start_at}` ){
    //                         if( day_datalist[index-1].start_at > day_datalist[index].start_at ){
    //                             let temp = day_datalist[index]
    //                             day_datalist[index] = day_datalist[index-1]
    //                             day_datalist[index-1] = temp
    //                         }
                            
    //                     }
                        
    //                 }
    //             }
    //     });
    // } 
    
    const refresh = (prevent_update_selected_child_id = false) => {
        getMyContractTeacher(token).then(
            res_contract => {        
                const children = {}
                const contract = res_contract.data
                if(contract) {
                    contract.subjects_affected.forEach( s_a => {
                        let child = s_a.child
                        if(!children[child.id]) children[child.id] = { 
                            child_infos: s_a.child, 
                            subjects_affected: [] 
                        }
                        children[child.id].subjects_affected.push(s_a)
                        // }
                    } )
                    // console.log("Solof ", children[26].weeks[5])
                    // const date_start = new Date(contract.start_at);
                    // const date_now = new Date();
                    // const diffTime = Math.abs(date_start - date_now);
                    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    // const diffWeeks = Math.floor(diffDays / 7)
                    
                    // const date_end = new Date(contract.end_at);
                    // const nb_weeks = Math.floor(Math.ceil(Math.abs(date_start - date_end) / (1000 * 60 * 60 * 24)) / 7)

                    const initial_child_id = Object.keys(children).findIndex( i => i === state.selected_child_id)  > -1
                            ? state.selected_child_id 
                            : 
                            Object.keys(children).length > 0 ? Object.keys(children)[0] : 0
                    setState(prev => ({
                        ...prev, 
                        contract_id: contract.id,
                        subjects_affected: contract.subjects_affected, 
                        children: children,
                        // nb_weeks: nb_weeks,
                        selected_child_id: prevent_update_selected_child_id ? state.selected_child_id : initial_child_id,
                        // selected_week_index: diffWeeks,
                        // current_week_index: diffWeeks,
                        remark: '',
                        isLoaded: true,
                        isSubmiting: false
                    }))
                } else {
                    setState(prev => ({...prev, isLoaded: true}))
                }
            }
        )
        .catch(err => apiErrorNotify({response: err.response, toast: toast}))
    }

    const handleSubmit = async () => {
        await setState(prev => ({...prev, isSubmiting: true}))
        const data = {
            comments: state.remark,
            date: humanize_date_YMD(state.dateValue),
            contract_parent_id: state.children[state.selected_child_id].subjects_affected[0].contract_parent_id
        }
        console.log(data)
        createTeacherRemark(token, state.contract_id, state.selected_child_id, data)
            .then( async res => {
                navigation.navigate(MENU_NAVIGATION.StatusScreen.name, {navigation: navigation})
                // await refresh({prevent_update_selected_child_id : true})
                // await setState(prev => ({...prev, selected_session_id: null, isSubmiting: false, remark: "", success: true}))
            } )
            .catch(err => {
                apiErrorNotify({response: err.response, toast: toast});
                setState(prev => ({...prev, isSubmiting: true}))
            })
    }

    
    useEffect(() => {
        refresh()

    }, [setState])

    return (
    <ScreenWrapper title="Signaler une observation sur un élève" nav={navigation}>        
        <ScrollView>
            {
                state.success && (
                    <Modal isOpen={true} collapsable={true} onClose={() => setState(prev => ({...prev, success: false}))} >
                        <Modal.Header bg='green.600' >Observation créée</Modal.Header>
                    </Modal>
                )
            }
            <VStack space={3} >
                {
                    ! state.isLoaded ? (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    ) : !state.contract_id ? <UserWithoutContract /> : (
                        <ScrollView>
                            {
                                Object.keys(state.children).length === 0 ? <NoData msg='Aucun enfant à encadrer !' /> :(
                                    <VStack space="5" px='3'>
                                        {/* {console.log("render")} */}
                                        <HStack space={5}>
                                            {
                                                Object.values(state.children).map(
                                                    (c, index) => (
                                                        <Pressable key={index} onPress={() => setState(prev => ({...prev, selected_child_id: c.child_infos.id, selected_session_id: null, selected_session_object: null})) } >
                                                            <Text key={index}  py='2' px='4' bg={state.selected_child_id == c.child_infos.id ? 'gray.800' : 'gray.400'} color={state.selected_child_id == c.child_infos.id ? 'white' : 'black' } borderRadius={20} >{c.child_infos.full_name} </Text>
                                                        </Pressable>
                                                    )
                                                )
                                            }
                                        </HStack> 
                                        <VStack space='1'>
                                            {
                                                    <VStack space='2' >
                                                        {/* <TimeplanWeekStaticDisplay 
                                                            week_model={state.children[state.selected_child_id].child_infos.timeplan_week_model} 
                                                            subjects_affected={state.children[state.selected_child_id].subjects_affected} 
                                                        /> */}
                                                    <FormControl isRequired >
                                                        <FormControl.Label>Sélectionnez la date de la séance</FormControl.Label>
                                                        <DateTimeInput 
                                                            value={state.dateValue}
                                                            setValue={(val) => setState(prev => ({...prev, dateValue: val }))}
                                                        />
                                                    </FormControl>
                                                    {/* <FormControl isRequired >
                                                        <FormControl.Label>Sélectionnez la matière</FormControl.Label>
                                                        <Select 
                                                            selectedValue={state.sa_id} 
                                                            onValueChange={val => setState(prev => ({...prev, sa_id: val}))  }
                                                            color="black" >
                                                            <Select.Item color='white' label='Choisissez une matière' ></Select.Item>
                                                           {
                                                                state.children[state.selected_child_id].subjects_affected.map(
                                                                    s_a => <Select.Item value={s_a.id} label={s_a.sub.name} color='white' />
                                                                )
                                                           } 
                                                        </Select>
                                                    </FormControl> */}
                                                        <TextArea placeholder="Ecrivez votre observation..." 
                                                                // isDisabled={!state.remark || !state.dateValue } 
                                                                defaultValue={ state.selected_session_id && (state.remark ? state.remark : (updated_session_in_week && updated_session_in_week.teacher_remark )) }
                                                                value={state.remark} 
                                                                onChangeText={val => setState(prev => ({...prev, remark: val})) }
                                                                minH={100} color='black' />
                                                        <Text color='red.500'>Vous ne pourrez plus la modifier (alors soyez concis)</Text>
                                                    </VStack>
                                                
                                            }
                                        <Button onPress={handleSubmit} isLoading={state.isSubmiting} isDisabled={!state.remark || !state.dateValue} mt='5' >Soumettre</Button>
                                        
                                        </VStack>
                                    </VStack>
                                )
                            }
                        </ScrollView>
                    
                    )
                }

                </VStack>

            </ScrollView>
    </ScreenWrapper>
  );
}