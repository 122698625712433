import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeUserScreen from 'screens/HomeUserScreen';
import AppBar from "components/AppBar";
import { MENU_NAVIGATION } from 'constant';
import MainScreen from './screens/MainScreen';
import ShowContract from './screens/ShowContract';
import ContactDirection from './screens/ContactDirection';
import ListApprovedTeachers from './screens/ListApprovedTeachers';
import ListChildrenMarks from './screens/ListChildrenMarks';
import Timeplan from './screens/Timeplan';
import OrderTeacher from './screens/OrderTeacher';
import CreateChildUnavailability from './screens/CreateChildUnavailability';
import CreateRemarkOnTeacher from './screens/CreateRemarkOnTeacher';
import ValidateCourseSession from './screens/ValidateCourseSession';
import ListInvoices from './screens/ListInvoices';
import OrderTeacherWithoutTeacher from './screens/OrderTeacherWithoutTeacher';
import ListPrestation from './screens/ListPrestation';
import CreateReportOnTeacher from './screens/CreateReportOnTeacher';
import ContactTeacher from './screens/ContactTeacher';
import ShowTeacherProfile from 'screens/ShowTeacherProfile';

const ParentStack = createNativeStackNavigator();

export default () => {
  return (
    <ParentStack.Navigator screenOptions={{ 
      headerShown: false,
      // headerBackButtonMenuEnabled: true,
      // headerBackground: (navigationProps) => <AppBar {...navigationProps} />,
    }}>
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.Main.name} component={MainScreen} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.ShowContract.name} component={ShowContract} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.ContactDirection.name} component={ContactDirection} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.ContactTeacher.name} component={ContactTeacher} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.ListTeachers.name} component={ListApprovedTeachers} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.OrderTeacher.name} component={OrderTeacher} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.OrderTeacherWithoutTeacher.name} component={OrderTeacherWithoutTeacher} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.ListChildrenMarks.name} component={ListChildrenMarks} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.Timeplan.name} component={Timeplan} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.CreateChildUnavailability.name} component={CreateChildUnavailability} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.CreateRemarkOnTeacher.name} component={CreateRemarkOnTeacher} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.CreateReportOnTeacher.name} component={CreateReportOnTeacher} />
      {/* <ParentStack.Screen name={MENU_NAVIGATION.Parents.ValidateCourseSession.name} component={ValidateCourseSession} /> */}
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.ListPrestation.name} component={ListPrestation} />
      <ParentStack.Screen name={MENU_NAVIGATION.Common.ShowTeacherProfile.name} component={ShowTeacherProfile} />
      <ParentStack.Screen name={MENU_NAVIGATION.Parents.ListInvoices.name} component={ListInvoices} />
    
    </ParentStack.Navigator>
  );
}