import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { listAllParents } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import ContactOptionsInline from "components/ContactOptionsInline";
import ScreenWrapper from "components/ScreenWrapper";
import PageLoading from "components/PageLoading";


export default ({ navigation }) => {
    const token = getToken()
    const [ state, setState ] = useState({
        parents: [],
        isLoaded: false,
        isAproving: false,
    })
    const refresh = () => {
        console.log("Calling refresh")
        listAllParents(token).then(
            res => {
                // console.log('---Parent no approuved', res.status, res.isSuccess, res.data)
                setState(prev => ({...prev, parents: res.data, isLoaded: true }))
            }
        )
        .catch(err => console.log(err))
    }
    
    useEffect(() => {
        refresh()
    }, [setState])
    console.log(state.parents.length)
    return (
    <ScreenWrapper title="Contacter un parent" nav={navigation}>
        <ScrollView>
            <VStack space={3} >
                {
                    ! state.isLoaded && (
                        <Center  >
                            <HStack space={2} justifyContent="center">
                            <PageLoading />
                        </HStack>
                        </Center>
                    )
                }
                {
                    state.isLoaded && state.parents.map( p => (
                    <HStack key={p.id} space={30}  onPress={() => console.log("Touch validate parent profile ")} >
                        <Box maxW={100} height={100}  flex={1} >
                            <Image borderRadius={10} height={"100%"} alt="Une photo" source={{uri: "https://img.le-dictionnaire.com/face-2.jpg"}} />
                        </Box>
                        <VStack pt={5}>
                            <Center  >
                                <Text color="black" > { p.full_name ? p.full_name : p.username } </Text>
                                <HStack space='1' >
                                    <Button ml='3' onPress={
                                        () => navigation.navigate(MENU_NAVIGATION.Direction.ManageParents.ContactParent.name, { phone: p.username, is_whatsapp: true })
                                    } >Contacter</Button>
                                    {/* <ContactOptionsInline {...{ phone: p.username, is_whatsapp: true }} /> */}

                                </HStack>
                            </Center>
                        </VStack>
                    </HStack>
                    ))
                }
                </VStack>
        </ScrollView>
    </ScreenWrapper>
  );
}