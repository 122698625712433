import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import { logout, login, loadLoginState, mergeLoginState, getLoginState } from './reducer';

const useLogin = () => {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch(login(payload));
  }
}

const useLoadLoginState = () => {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch(loadLoginState(payload));
  }
}

const useMergeLoginState = () => {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch(mergeLoginState(payload));
  }
}

const useLogout = (payload) => {
    const dispatch = useDispatch();
    return () => dispatch(logout());
    }

const useGetLoginState = (payload) => {
  const dispatch = useDispatch();
  return () => dispatch(getLoginState());
  }

export {useLogin, useLogout, useLoadLoginState, useMergeLoginState, useGetLoginState};