import { MENU_NAVIGATION } from "constant";
import { Box, HStack, Image, VStack, Text, Skeleton, ZStack, SunIcon, Icon } from "native-base";
import { useEffect, useState } from "react";
import { Platform, Pressable } from "react-native";
import * as VideoThumbnails from 'expo-video-thumbnails';
import { getFileLink } from "services/api-axios";
import { AntDesign } from "@expo/vector-icons";

export default function ({navigation, ...props}) {
    const [image, setImage] = useState(props.cover_url);

    const generateThumbnail = async () => {
      try {
        const { uri } = await VideoThumbnails.getThumbnailAsync(
          props.cover_url,
          {
            time: 15000,
          }
        );
        setImage(uri);
      } catch (e) {
        console.warn(e);
      }
    };

    useEffect(()=> {
      if(!image && !props.prevenThumgenerate && Platform.OS !== 'web') generateThumbnail()
    }, [setImage])

    
      return (
        <VStack space='1'>
            <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.VideoReaderScreen.name, {init: {...props, videoProps: {shouldPlay: true}}}) } >
              <ZStack h={200} w='100%' justifyContent={'center'} >
                  {image ? <Image alt="image" source={{uri: getFileLink(image)}} h={200} w='100%' />  : <Box h={200} w='100%' bg='gray.500' />  }
                  <Icon as={AntDesign} name='play' alignSelf={'center'} color='white' size={'xl'} />
              </ZStack>
              <Box h={1} />
              <HStack px='4' space='10' alignItems={'center'} >
                  {/* <Skeleton h={10} w={10} color='blue.200' borderRadius={'full'} /> */}
                  <Box bg='gray.500' h={10} w={10} color='blue.200' borderRadius={'full'} >
                    <Image alt="As Training" source={require("assets/icon.png")} h={10} w={10} />  
                  </Box> 
                  <VStack w='80%'>
                    <Text fontSize={15} >{props.title}</Text>
                    { props.subtitle && <Text fontWeight={'light'}>{props.subtitle}</Text> }
                  </VStack>
              </HStack>

            </Pressable>
        </VStack>
      )
}