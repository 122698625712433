import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner
  } from "native-base";
  import { Pressable } from 'react-native';
import FaceImg from 'assets/face.jpg'
import { getToken } from "store/login/selectors";
import { getTeachersNoApproved, approveUser } from "services/api-axios";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { Linking } from 'react-native';
import * as SMS from 'expo-sms';


export default ({ phone, is_whatsapp }) => {
    const [isSMSAvailable,  setIsSMSAvailable] = useState(false)
    const [isLoadingSMS,  setIsLoadingSMS] = useState(false)
    useEffect(async () => {
        const isAvailable = await SMS.isAvailableAsync();
        setIsSMSAvailable(isAvailable)
    }, [setIsSMSAvailable])
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    return (
    <ScrollView>
        <VStack p="4" space={3} >
            <Box height={50}  bg={greenGrad} px='auto' width="100%" flex={1} borderRadius={10} >
                <Pressable  onPress={() => Linking.openURL(`tel:${phone}`)} > 
                    <Center height="100%">
                        <Text fontSize={18} color="white">Appel</Text>
                    </Center>
                </Pressable>
            </Box>
            {
                isSMSAvailable && (
                    <Box height={50}  bg={greenGrad} px='auto' width="100%" flex={1} borderRadius={10} >
                        { isLoadingSMS ? <Spinner /> : (
                            <Pressable onPress={async () => {
                                // await setIsLoadingSMS(true)
                                const { result } = await SMS.sendSMSAsync(
                                    [phone],
                                    ''
                                ) 
                                
                            }}>
                                <Center height="100%">
                                    <Text fontSize={18} color="white">SMS</Text>
                                </Center>
                            </Pressable>
                        ) }
                    </Box>
                )
            }
            {   
                is_whatsapp && (
                    <Box height={50}  bg={greenGrad} px='auto' width="100%" flex={1} borderRadius={10} >
                        <Pressable onPress={() => Linking.openURL(`whatsapp://send?phone=+225${phone}`) }>
                            <Center height="100%">
                                <Text fontSize={18} color="white">Whastapp</Text>
                            </Center>
                        </Pressable>
                    </Box>
                )
            }
            {/* <Center><Text  >({route.params.phone})</Text></Center>     */}
        </VStack>
        
        </ScrollView>
  );
}