import Annonce from "components/Annonce";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
	Actionsheet,
	Input,
	Pressable,
	useToast,
	FormControl,
	Select,
	CheckIcon,
	Icon,
	Modal,
	Image,
	ChevronLeftIcon,
	ChevronRightIcon,
	useDisclose,
	Checkbox,
	InfoIcon
  } from "native-base";
import SwitchColorMode from 'components/SwitchColorMode'
import { Layout } from "components/Layout";
import { MasonaryLayout } from "components/MasonaryLayout";
// import { useGetTeachersNoApprovedQuery, useLazyLoginQuery } from "services/api-rest";
import { listCities, signIn as signInAPI, login as loginAPI, uploadFile, listSubs } from 'services/api-axios'
import { useState } from "react";
import { useLogin } from "store/login/hooks";
import { getLoginState, getToken } from "store/login/selectors";
import {BACKEND_API} from "@env"
import { BASE_API_URL } from "constant";
import { apiErrorNotify } from "services/notify";
import { MENU_NAVIGATION } from "constant";
import { useEffect } from "react";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { delay } from "services/utils";
import PasswordInput from "components/PasswordInput";
import BaseUserForm from "./BaseUserForm";

import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from 'expo-image-picker';
import PhoneInput from "components/PhoneInput";
import SelectMultipleInput from "components/SelectMultipleInput";
import Notifications from "services/push-notifiy";


export default function SignInScreen({ navigation }){

	const token = getToken()
	const toast = useToast()
	const login = useLogin()
	const disclose = useDisclose()
	
	const [ signinData, setSign ] = useState({
		username: '',
		password: '',
		full_name: '',
		role: 'parent',
		password2: '',
		
		disired_areas: [],
		disired_subs: [],
		level: null,
		job: 'Etudiant',
		expo_token: null
	})

	const [ state, setState ] = useState({
		isPwdOpen: false,
		isPwd2Open: false,
		isSubmitting: false,
		cities: [],
		subs: [],
		step: 0,
		city_id: null,

		agreement: false,

		media_identity: null,
		media_diploma: null,
		media_cv: null,
		media_avatar: null,
		
		isSuccess: false,
		error: null
	})
	
	const trigger = () => {
		setState(prev => ({...prev, isSubmitting: true}))
		const data = {
			expo_token: signinData.expo_token,
			username: signinData.username,
			password: signinData.password,
			full_name: signinData.full_name,
			role: signinData.role,
			cities: [state.city_id],
			media_avatar: state.media_avatar ? state.media_avatar.remote_name : null
		}
		if(signinData.role == 'teacher') {
			data['teacher_metadata'] = {
				disired_areas: signinData.disired_areas,
				disired_subs: signinData.disired_subs,
				level: signinData.level,
				job: signinData.job,
				media_cv: state.media_cv ? state.media_cv.remote_name : null,
				media_identity: state.media_identity ? state.media_identity.remote_name : null,
				media_diploma: state.media_diploma ? state.media_diploma.remote_name : null
			}
		}
		
		// console.log("My data ", data)
		signInAPI(data)
		.then(res => {
			// console.log("SINGNI RES ", res.data)
			// loginAPI({ username: res.data.username, password: data.password, expo_token: signinData.expo_token })
			loginAPI({ username: res.data.username, password: data.password })
				.then(res => {
					login(res.data)
				})
				.catch(
					err => {
						// console.log("NOOOOOOOOOOOOOOO")
						apiErrorNotify({response: err.response, toast: toast, toastProps: {placement: 'top'}})
						setState(prev => ({...prev, isSubmitting: false}))
					}
				)	
			// setState(prev => ({...prev, isSuccess: true, isSubmitting: false}))
		})
		.catch(
			err => {
				// console.log("Not sub")
				apiErrorNotify({response: err.response, toast: toast, toastProps: {placement: 'top'}});
				setState(prev => ({...prev, isSubmitting: false}))
			}
		).finally(() => {
			// setState(prev => ({...prev, isSubmitting: false}))
		})
	}

	const check_file = (file) => {
		if(file.size/(1024*1024*3) > 3 ) {
			return "La taille fichier a dépassé la limite de 3Mo"
		}
		return null
	}

	const pickDocument = async ({media_field, type = ["application/pdf", "image/jpeg", "image/jpg", "image/png" ]}) => {

		let result = await DocumentPicker.getDocumentAsync({multiple: false, type: type});

		if(result.type == 'success'){
			const errors = check_file(result)
			// if(errors) navigation.navigate(MENU_NAVIGATION.ListErrors.name, {errors: errors} )
			if(errors) setState(prev => ({...prev, error: errors}))
			else {
					const formData = new FormData()
					let { name, size, uri } = result;
					let nameParts = name.split('.');
					let fileType = nameParts[nameParts.length - 1];
					formData.append('file', {
						name: name,
						size: size,
						uri: uri,
						type: "application/" + fileType
					})
		
					const options = {
						method: 'POST',
						body: formData,
						headers: {
						'content-type': 'multipart/form-data',
						},
					};
					console.log(formData);
			
					// fetch("http://192.168.1.109/api/users/upload/", options)
					uploadFile(token, formData)
					.then(({data: remote_name}) => {
						setState(prev => ({...prev, [media_field]: {
							...result,
							remote_name: remote_name,
						}}))
					})
					.catch((error) => console.log(error.response.data, error.response.status, error.status));
				}
				console.log(result);
				
			}
	}

	const pickImage = async ({media_field}) => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchCameraAsync({
		  mediaTypes: ImagePicker.MediaTypeOptions.All,
		  allowsEditing: false,
		  aspect: [4, 3],
		  quality: 1,
		});
	
		if(!result.cancelled){
			setState(prev => ({...prev, [media_field]: result}))
			const formData = new FormData()
			const fileparts = result.uri.split('.')
			const ext = fileparts[fileparts.length-1]
			formData.append('file', {
				name: "chic." + ext ,
				uri: result.uri,
				type: "application/" + result.type
			})

			console.log(formData);
	
			// fetch("http://192.168.1.109/api/users/upload/", options)
			uploadFile(token, formData)
			.then((response) => console.log("YESSSSSS", response))
			.catch((error) => console.log(error.response.data, error.response.status, error.status));
		}

	  };
	

	useEffect(() => {
		listCities(token)
			.then(
				({data: cities}) => {
					setState(prev => ({
						...prev,
						cities: cities
					}))
				}
			)
			.catch(
				err => apiErrorNotify({response: err.response, toast: toast})
			)
		listSubs(token)
		.then(
			({data: subs}) => {
				setState(prev => ({
					...prev,
					subs: subs.filter(s => s.is_active === true)
				}))
			}
		)
		.catch(
			err => apiErrorNotify({response: err.response, toast: toast})
		)

		Notifications.getExpoPushTokenAsync().then(
			({data}) => {
				setSign(prev => ({...prev, expo_token: data}))
			}
		).catch(err => apiErrorNotify({response: err.response, toast: toast}))
	}, [setState])

  return (
    <View>
		{
			state.isSuccess && (
				<Modal isOpen={true} collapsable={true} onClose={() => navigation.goBack()} >
					<Modal.Content>
						<Modal.CloseButton   />
						<Modal.Header bgColor={'green.500'}>
							<Heading color='white' size='lg'>Compte créé avec succès ! </Heading>
							
						</Modal.Header>
						<Modal.Body bg={'white'} >
							<Center>
								<Heading size='xs'>Veuillez désormais vous connecter avec votre numéro de téléphone et votre mot de passe </Heading>
							</Center>
						</Modal.Body>
					</Modal.Content>
					
				</Modal>
			)
		}
		{
			state.error && (
				<Modal isOpen={true} collapsable={true} onClose={() => setState(prev => ({...prev, error: null}))} >
					<Modal.Content>
						<Modal.CloseButton   />
						<Modal.Header bgColor={'red.500'}>
							<Heading color='white' size='lg'>Attention ! </Heading>
							
						</Modal.Header>
						<Modal.Body bg={'white'} >
							<Center>
								<Heading size='xs'>{state.error}</Heading>
							</Center>
						</Modal.Body>
					</Modal.Content>
					
				</Modal>
			)
		}
		<Actionsheet isOpen={true} onClose={() => null} collapsable={false} >
			<Actionsheet.Content  bg="white"  minH={'2/3'} >
				<HStack justifyContent={'space-between'} >
					<Heading>Création de compte</Heading>
					<Heading> {state.step+1} / 3</Heading>
				</HStack>
				<ScrollView showsVerticalScrollIndicator={false}>
					<VStack w="100%"  px={{ base: 4, md: "30%" }} justifyContent="center" space="4" bg="white"  >
						
						<HStack  >
							{
								state.step == 0 && (
									<VStack w='100%'>
										<FormControl isRequired>
										<FormControl.Label>Je m'inscris en tant que</FormControl.Label>
										<Select  
											selectedValue={signinData.role} 
											color="black" 
											onValueChange={val => setSign(prev => ({...prev, role: val}))} 
										>
											<Select.Item value='parent' label='Parent' />
											<Select.Item value='teacher' label='Enseignant' />
										</Select>
									</FormControl>

									<FormControl isRequired >
										<FormControl.Label>Ville</FormControl.Label>
										<Select
											selectedValue={state.city_id} 
											color="black" 
											onValueChange={val => setState(prev => ({...prev, city_id: val}))} 
										>
											<Select.Item label="Sélectionnez une ville" />
											{
												state.cities.map( (city, ind) =>  <Select.Item key={ind} label={city.name} value={`${city.id}`} /> )
											}
										</Select> 
									</FormControl>

									<FormControl isRequired mt='5' >
										<HStack space={2} alignItems={'center'} justifyContent={'center'}>
											<Checkbox 
												aria-label="Je consens à ce que mes données soient collectées et traitées selon cette politique de confidentialité"
												bg='white'
												_icon={<CheckIcon color='white'  />}
												isChecked={state.agreement}
												onChange={(val) => setState(p => ({...p, agreement: val}))} />

												<Text fontWeight={'light'} fontSize={12}>Je consens à ce que mes données soient collectées et traitées selon cette politique de confidentialité</Text>
										</HStack>
										<Center><Pressable mt='3' onPress={() => navigation.navigate(MENU_NAVIGATION.Common.Agreement.name) } ><Text fontSize={12} underline >Voir la politique de confidentialité</Text></Pressable></Center>
									</FormControl>

										<Button 
											onPress={() => setState(prev => ({...prev, step: prev.step+1}) )} 
											mx={50} mt={6}
											isDisabled={!state.city_id || !state.agreement }
											endIcon={<ChevronRightIcon />}
									>
										Suivant
									</Button>

									</VStack>

								)
							}

							{
								state.step == 1 && (
									<ScrollView w='100%'>
										<VStack w='100%' space='3' >
											<FormControl>
												<FormControl.Label>Nom et prénoms</FormControl.Label>
												<Input 
													
													value={signinData.full_name} 
													placeholder="Nom et prénoms"
													onChangeText={(val) => setSign(p => ({...p, full_name: val}))} />
											</FormControl>
											
											<FormControl>
												<FormControl.Label>Votre photo de profil</FormControl.Label>
												<VStack space='2'>
													<Center>
														{ state.media_avatar && <Image rounded={'full'} source={{uri: state.media_avatar.uri}} alt='Piece' size={'md'} /> }
													</Center>
													
													<HStack space='4' justifyContent={'space-around'} >
														<Button  endIcon={<Icon as={AntDesign} size='md' name='camera' />} onPress={() => pickImage({media_field: 'media_avatar'})}></Button>
														<Button endIcon={<Icon as={AntDesign} name='upload' size='md' />} onPress={() => pickDocument({media_field: 'media_avatar', type: ["image/*"]})} ></Button>
													</HStack>
												</VStack>
											</FormControl>

											{
												signinData.role == 'teacher' && (
													<>
														<FormControl>
															<FormControl.Label>Votre profession</FormControl.Label>
															<Select  
																selectedValue={signinData.job} 
																color="black" 
																onValueChange={val => setSign(prev => ({...prev, job: val}))} 
															>
																<Select.Item value='Etudiant' label='Etudiant' />
																<Select.Item value='Enseignant' label='Enseignant' />
															</Select>
														</FormControl>
														<FormControl>
															<FormControl.Label>Filière et niveau universitaire</FormControl.Label>
															<Input 
																value={signinData.level} 
																onChangeText={(val) => setSign(p => ({...p, level: val}))} />
														</FormControl>

														<FormControl>
															<FormControl.Label>Zone de prestation souhaitée</FormControl.Label>
															<Input 
																value={(signinData.disired_areas || [] ).length == 0 ? '': signinData.disired_areas[0]} 
																onChangeText={(val) => setSign(p => ({...p, disired_areas: [val]}))} />
														</FormControl>

														<FormControl zIndex={100} >
															<FormControl.Label>Domaines d'enseignement souhaités</FormControl.Label>
															<SelectMultipleInput
																items={state.subs}
																onSelectedItemsChange={(ids) => setSign(prev => ({...prev, disired_subs: ids})) }
																selectedItems={signinData.disired_subs}
																selectText="Matières "
																submitButtonText="Confirmer les matières sélectionnées"
																fixedHeight={false}
																/>
														</FormControl>

														<FormControl>
															<FormControl.Label>Joindre un diplome</FormControl.Label>
															<VStack space='2'>
																<Center>
																	{ state.media_diploma && 
																		(
																			!state.media_diploma.mimeType.endsWith("pdf") ? 
																				<Image source={{uri: state.media_diploma.uri}} alt='Piece' size={'md'} /> 
																			:
																				<Box w={70} height={100} bg='primary.500'  flex={1} justifyContent={'center'} rounded='md' >
																					<Heading color='white' alignSelf={'center'} size='xs' >Fichier uploadé</Heading>
																				</Box>
																		)
																	}
																</Center>
																
																<HStack space='4' justifyContent={'space-around'} >
																	{/* <Button isDisabled endIcon={<Icon as={AntDesign} size='md' name='camera' />} onPress={() => pickImage({media_field: 'media_diploma'})}></Button> */}
																	<Button endIcon={<Icon as={AntDesign} name='upload' size='md' />} onPress={() => pickDocument({media_field: 'media_diploma', type: ["application/pdf"]})} >
																		{ state.media_diploma ? 'Modifier' : 'Uploader un fichier' }
																	</Button>
																</HStack>
															</VStack>
														</FormControl>
														<FormControl>
															<FormControl.Label>Joindre un CV</FormControl.Label>
															<VStack space='2'>
																<Center>
																	{ state.media_cv && 
																		(
																			!state.media_cv.mimeType.endsWith("pdf") ? 
																				<Image source={{uri: state.media_cv.uri}} alt='CV' size={'md'} /> 
																			:
																				<Box w={70} height={100} bg='primary.500' rounded='md' justifyContent={'center'}>
																					<Heading color='white' alignSelf={'center'} >Fichier uploadé</Heading>
																				</Box>
																		)
																	}
																</Center>
																
																<HStack space='4' justifyContent={'space-around'} >
																	{/* <Button isDisabled endIcon={<Icon as={AntDesign} size='md' name='camera' />} onPress={() => pickImage({media_field: 'media_cv'})}></Button> */}
																	<Button endIcon={<Icon as={AntDesign} name='upload' size='md' />} onPress={() => pickDocument({media_field: 'media_cv', type: ["application/pdf"]})} >
																		{ state.media_cv ? 'Modifier' : 'Uploader un fichier' }
																	</Button>
																</HStack>
															</VStack>
														</FormControl>
													</>
												)
											}

											<HStack justifyContent={'space-between'} mt={6} >
												<Button startIcon={<ChevronLeftIcon />} onPress={()=> setState(prev => ({...prev, step: prev.step-1}))}  >
												Précédent
												</Button>
												<Button
													// isDisabled={
													// 	!signinData.full_name
													// 	|| (
													// 		signinData.role == 'teacher' && !signinData.level
													// 	)
													// }
													endIcon={<ChevronRightIcon />} 
													onPress={()=> setState(prev => ({...prev, step: prev.step+1}))}
													>Suivant</Button>

											</HStack>

										</VStack>
									</ScrollView>
								)
							}

							{
								state.step == 2 && (
									<ScrollView w='100%'  >
										<VStack w='100%'>
											
											<FormControl isRequired>
												<FormControl.Label>Numéro de téléphone</FormControl.Label>
												<PhoneInput 
													keyboardType="number-pad"
													value={signinData.username} 
													placeholder="Numéro de téléphone"
													onChangeText={(val) => setSign(p => ({...p, username: val}))} />
											</FormControl>

											<FormControl isRequired>
												<FormControl.Label>Mot de passe</FormControl.Label>
												<PasswordInput 
													value={signinData.password} 
													rightElement={ signinData.password && signinData.password === signinData.password2 && <CheckIcon mr='2' size="5" mt="0.5" color="emerald.500" />}
													placeholder="Mot de passe"
													onChangeText={(val) => setSign(p => ({...p, password: val}))} />
											</FormControl>
											
											<FormControl isRequired>
												<FormControl.Label>Confirmer le mot de passe</FormControl.Label>
												<PasswordInput 
													rightElement={ signinData.password && signinData.password === signinData.password2 && <CheckIcon mr='2' size="5" mt="0.5" color="emerald.500" />}
													value={signinData.password2} 
													placeholder="Confirmer le mot de passe"
													onChangeText={(val) => setSign(p => ({...p, password2: val}))} />
											</FormControl>


											<HStack justifyContent={'space-between'} mt={10} >
												<Button startIcon={<ChevronLeftIcon />} onPress={()=> setState(prev => ({...prev, step: prev.step-1}))}  >

												</Button>
												<Button 
													onPress={trigger} 
													isLoading={state.isSubmitting}
													isDisabled={!signinData.username || !state.city_id || !signinData.full_name || !signinData.username || !signinData.password || signinData.password  !== signinData.password2 }
												>
													Créer mon compte
												</Button>

											</HStack>
										</VStack>
									</ScrollView>
								)
							}

						</HStack>


						<Button 
						bg='emerald.400'
						_text={{color: 'white'}}
						onPress={navigation.goBack}
						>Retour à la page de connexion </Button>
						
					</VStack>
				</ScrollView>
			</Actionsheet.Content>
		</Actionsheet>
  </View>
  );
}

const styles ={
	input: {
		size: "x" ,
		variant: "rounded",
		borderRadius: 10,
		color: 'black',
		p: 2,
		pl: 4
	}
}