import { AntDesign, FontAwesome, FontAwesome5 } from "@expo/vector-icons";
import Annonce from "components/Annonce";
import MenuBoxWrapper from "components/MenuBoxWrapper";
import ScreenWrapper from "components/ScreenWrapper";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    useBreakpointValue,
    useColorModeValue,
    Icon
  } from "native-base";
  import { Image, Pressable } from 'react-native';

export default ({ navigation }) => {
    const blueGrad = {
        linearGradient: {
            colors: [
                '#0D2443',
                '#90C5FC',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
    const greenGrad = {
        linearGradient: {
            colors: [
                '#183253',
                '#28D8B8',
            ],
            start: [0, 0],
            end: [1, 1],
        }
    }
  return (
    <>
        {/* <ScrollView bg='white' > */}
            <ScreenWrapper hideBackButton nav={navigation} title='Profil parent'>
                <ScrollView>
                    <Annonce isParentRefreshing={false} />
                    <VStack px="4" py="5" space={"4"} > 
                    <HStack space={5} >
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.OrderTeacherWithoutTeacher.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='book' size={'3xl'} color="orange.500" />
                                        <Text fontSize={18} >Demander un enseignant </Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.ListTeachers.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='user-tie' size={'3xl'} color="primary.500" />
                                        <Text fontSize={18} >Enseignants disponibles</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.ShowContract.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='file-signature' size={'3xl'} color="green.700" />
                                        <Text fontSize={18} >Mon contrat</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.ListInvoices.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='wallet' size={'3xl'} color="red.500" />
                                        <Text fontSize={18} >Mes factures</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.ContactTeacher.name)}>
                                    <Center height="100%">
                                        <Icon as={AntDesign} name='phone' size={'3xl'} color="primary.500" />
                                        <Text fontSize={18} >Contacter un enseignant</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper>
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.ContactDirection.name, { phone: "0759632788", is_whatsapp: true })}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='phone' size={'3xl'} color="green.700" />
                                        <Text fontSize={18} >Contacter la Direction</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        
                        <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.ListChildrenMarks.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='paste' size={'3xl'} color="red.500" />
                                        <Text fontSize={18} >Les notes de mes enfants</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.Timeplan.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='calendar' size={'3xl'} color="yellow.500" />
                                        <Text fontSize={18} >Emploi du temps à domicile</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.ListPrestation.name)}>
                                    <Center height="100%" px='3' >
                                        <HStack>
                                            <Icon as={FontAwesome5} name='pen-nib' m='1' size={'3xl'} color="emerald.500" />
                                            <Text fontSize={16} >Voir les prestations de cours </Text>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.CreateChildUnavailability.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='child' size={'3xl'} color="red.500" />
                                        <Text fontSize={14} >Signaler une indisponibilité de mon enfant</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            {/* <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.CreateReportOnTeacher.name)}>
                                    <Center height="100%">
                                        <HStack>
                                            <Icon as={FontAwesome5} m='1' name='pen' size={'3xl'} color="pink.500" />
                                            <VStack>
                                                <Text fontSize={16} >Rapport mensuel </Text>
                                                <Text fontSize={16} >sur l'enseignant</Text>
                                            </VStack>
                                        </HStack>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper> */}
                        </HStack>
                        {/* <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.CreateChildUnavailability.name)}>
                                    <Center height="100%">
                                        <Icon as={FontAwesome5} name='child' size={'3xl'} color="red.500" />
                                        <Text fontSize={18} >Signaler une indisponibilité de mon enfant</Text>
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack> */}
                        <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Parents.CreateRemarkOnTeacher.name)}>
                                    <Center height="100%">
                                        {/* <HStack> */}
                                            <Icon as={FontAwesome5} m='1' name='pen' size={'3xl'} color="primary.500" />
                                            <Text fontSize={18} >Signaler une observation au niveau d'un enseignant</Text>
                                        {/* </HStack> */}
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ListLearningExercises.name)}>
                                    <Center height="100%">
                                        {/* <HStack> */}
                                            <Icon as={FontAwesome5} m='1' name='book' size={'3xl'} color="orange.500" />
                                            <Text fontSize={18} >Mes exercices</Text>
                                        {/* </HStack> */}
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ListLearningLessons.name)}>
                                    <Center height="100%">
                                        {/* <HStack> */}
                                            <Icon as={FontAwesome5} m='1' name='book' size={'3xl'} color="violet.500" />
                                            <Text fontSize={18} >Mes cours</Text>
                                        {/* </HStack> */}
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                        <HStack space={5} >
                            <MenuBoxWrapper >
                                <Pressable onPress={() => navigation.navigate(MENU_NAVIGATION.Common.ListLearningVideos.name)}>
                                    <Center height="100%">
                                        {/* <HStack> */}
                                            <Icon as={FontAwesome5} m='1' name='video' size={'3xl'} color="emerald.500" />
                                            <Text fontSize={18} >Vidéos</Text>
                                        {/* </HStack> */}
                                    </Center>
                                </Pressable>
                            </MenuBoxWrapper>
                        </HStack>
                </VStack>
                    </ScrollView>
            </ScreenWrapper>
        {/* </ScrollView> */}
    </>
  );
}