import Annonce from "components/Annonce";
import { MENU_NAVIGATION } from "constant";
import {
    Text,
    Link,
    HStack,
    Center,
    Heading,
    Switch,
    useColorMode,
    NativeBaseProvider,
    extendTheme,
    VStack,
    Box,
    View,
    Button,
    ScrollView,
    Image,
    useBreakpointValue,
    Spinner,
    FormControl,
    Input,
    Select,
    TextArea
  } from "native-base";
  import { Pressable } from 'react-native';
import { getToken } from "store/login/selectors";
import { useState } from "react";
import { useEffect } from "react";
import { get_screen_height, get_window_height } from "services/dimensions";
import { format_time, format_time_reactive, humanize_date_YMD } from "services/utils";
import TimeInput from "components/TimeInput";
import DateTimeInput from "components/DateTimeInput";
import SelectMultipleInput from "components/SelectMultipleInput";
import { humanize_time } from "services/utils";


export default ({ subjects_affected = [], onSubmit, contract_teacher_id, initial_values}) => {
    const [date, setDate] = useState(initial_values?.date ? new Date(initial_values.date) : '')
    const [details, setDetails] = useState(initial_values?.details || {} )
    const [start_at, setStart] = useState(initial_values?.start_at || '' )
    const [end_at, setEnd] = useState(initial_values?.end_at || '')
    const [selected_sa_ids, set_select_sa_ids] = useState(initial_values?.link_subjects_affected?.map(_sa => _sa.subject_affected.id) || [] )

    return (
        
        <VStack p='3' space='4'  flex={1} borderRadius={10} >
            {/* <FormControl isRequired>
                <FormControl.Label>Matière effectuée</FormControl.Label>
                <Select
                    onValueChange={setSAID}
                >
                    {
                        subjects_affected.length > 0 && subjects_affected.filter(s => s.contract_teacher_id == contract_teacher_id).map(
                            s_a => (
                                <Select.Item key={s_a.id} value={s_a.id} label={s_a.sub.name} />
                            )
                        )
                    }
                </Select>
                
            </FormControl> */}
            
            <FormControl isRequired>
                <FormControl.Label>Date de réalisation de la séance</FormControl.Label>
                <DateTimeInput
                    value={date} 
                    setValue={setDate}
                    />
            </FormControl>
            
            <HStack space='5' >
                <FormControl isRequired w='45%' >
                    <FormControl.Label>Heure d'arrivée</FormControl.Label>
                    <TimeInput
                        value={start_at} 
                        setValue={setStart}
                        />
                </FormControl>
                
                <FormControl isRequired w='45%'>
                    <FormControl.Label>Heure de fin</FormControl.Label>
                    <TimeInput
                        value={end_at} 
                        setValue={setEnd}
                        />
                </FormControl>
                
            </HStack>

            <FormControl isRequired>
                <FormControl.Label>Matière effectuée</FormControl.Label>
                <SelectMultipleInput
                        items={subjects_affected.filter(s => s.contract_teacher_id == contract_teacher_id).map(
                            s_a => ({id: s_a.id, name: s_a.sub.name})
                        )}
                        onSelectedItemsChange={set_select_sa_ids}
                        selectedItems={selected_sa_ids}
                        selectText="Matières "
                        submitButtonText="Confirmer mon choix"
                    />

            </FormControl>

            {
                selected_sa_ids.map(
                    sa_id => (
                        <VStack space={2} key={sa_id}>
                            <Text alignSelf="center" underline >COMPTE RENDU : {subjects_affected.find(s => s.id == sa_id)?.sub?.name}</Text>
                            {/* <FormControl key={sa_id} >
                                <FormControl.Label>Matières étudiées</FormControl.Label>
                                <TextArea
                                    value={details[sa_id] ? details[sa_id]["Matières étudiées"] : ""} 
                                    onChangeText={val => setDetails(prev => ({
                                        ...prev, 
                                        [sa_id]: {
                                            ...prev[sa_id],
                                            "Matières étudiées": val
                                        }
                                    }))}
                                    color='black'
                                    />
                            </FormControl> */}
                            <FormControl  >
                                <FormControl.Label>Chapitres étudiées</FormControl.Label>
                                <TextArea
                                    value={details[sa_id] ? details[sa_id]["Chapitres étudiées"] : ""}
                                    onChangeText={val => setDetails(prev => ({
                                        ...prev, 
                                        [sa_id]: {
                                            ...prev[sa_id],
                                            "Chapitres étudiées": val
                                        }
                                    }))}
                                    color='black'
                                    />
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>Exercices recommandés</FormControl.Label>
                                <TextArea
                                    value={details[sa_id] ? details[sa_id]["Exercices recommandés"] : ""}
                                    onChangeText={val => setDetails(prev => ({
                                        ...prev, 
                                        [sa_id]: {
                                            ...prev[sa_id],
                                            "Exercices recommandés": val
                                        }
                                    }))}
                                    color='black'
                                    />
                            </FormControl>
                            <FormControl >
                                <FormControl.Label>Appréciation générale</FormControl.Label>
                                <TextArea
                                    value={details[sa_id] ? details[sa_id]["Appréciation générale"] : ""}
                                    onChangeText={val => setDetails(prev => ({
                                        ...prev, 
                                        [sa_id]: {
                                            ...prev[sa_id],
                                            "Appréciation générale": val
                                        }
                                    }))}
                                    color='black'
                                    />
                            </FormControl>
                        </VStack>
                    
                    )
                )
            }

            <Button
                isDisabled={!start_at || !end_at || !date || selected_sa_ids.length === 0 }
                onPress={() => {
                    // onSubmit(
                    //     selected_sa_ids.map(
                    //         sa_id => ({
                    //             date: humanize_date_YMD(date),
                    //             start_at: format_time(start_at, ':'),
                    //             end_at: format_time(end_at, ':'),
                    //             subject_affected_id: sa_id,
                    //             details: {
                    //                 details: details[sa_id]
                    //             }
                    //         })
                    //     )
                    // )
                    onSubmit({
                        date: humanize_date_YMD(date),
                        start_at: format_time(start_at, ':'),
                        end_at: format_time(end_at, ':'),
                        subjects_affected: selected_sa_ids,
                        details: selected_sa_ids.reduce((prev, current) => {
                            return {
                                ...prev,
                                [current]: details[current] 
                            }
                        }, {})
                    })
                }}
            >{ initial_values?.id ? "Modifier" : "Enregistrer"}</Button>

        </VStack>
  );
}