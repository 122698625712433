const { HStack, Button, Text, VStack } = require("native-base")
const { useState } = require("react")
import { MENU_NAVIGATION, INVOICE_STATUS } from "constant";

export default ({invoice_id, status, handleAlert, handleUpdate, handleSubmit, toast, setStatus}) => {
    const [isSubmiting, setSubmitting] = useState(false)
    const [isReminding, setIsReminding] = useState(false)
    const [reminded, setReminded] = useState(false)
    

    const handlePressSubmit= () => {
        setSubmitting(true)
        handleSubmit(invoice_id)
        .then(res => {
            setStatus(res.data.status) 
            setSubmitting(false)
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    const handlePressRemind= () => {
        setIsReminding(true)
        handleAlert(invoice_id)
        .then(res => {
            setIsReminding(false) 
            setReminded(true)
        })
        .catch(err => apiErrorNotify({response: err.response, toast: toast}) )
    }

    return (
        <HStack 
            space={3} 
            justifyContent={'flex-end'} alignContent='center' >
            <VStack pb='1' space={1} justifyContent={'flex-end'} >
                {
                    !reminded 
                        ? <Button bg={'primary.500'} 
                        onPress={handlePressRemind} 
                        isLoading={isReminding} 
                        size='xs'>Envoyer un rappel</Button>
                            :  <Text fontSize='12'>Rappel envoyé</Text>
                }
                {
                    status == INVOICE_STATUS.pending 
                        
                        ? <Button bg={'danger.500'} 
                            onPress={handlePressSubmit} 
                            isLoading={isSubmiting} 
                            size='xs'>Régler</Button>  
                            
                            : <Text fontSize='12' alignSelf={'center'}>Déjà réglée</Text>
                }
            </VStack>
            <VStack space={1} >
                
                <Button bg={'orange.400'} onPress={handleUpdate} size='xs'>Modifier</Button>
            </VStack>  
        </HStack>        
    )
}