import { Center, Heading, HStack, Spinner } from "native-base";
import { get_screen_height } from "services/dimensions";

export default () => (
    <Center h='10'   >
        <HStack space={2}>
            <Spinner />
            <Heading color="primary.500" fontSize="md">
                Chargement
            </Heading>
        </HStack>
    </Center>
)